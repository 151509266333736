import React from "react";
import "./Taskstatus.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Addtask = () => {

    const alet = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure to go back',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => todotask(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to cancel')
                }
            ]
        })
    }

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => todotask(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }


    let navigate = useNavigate();
    const todotask = () => {
        let path = `/todotask`;
        navigate(path);
    }

    return (
        <div className="bg-light container-fluid">

            <div className="row mt-5">

                <div className="col-sm-2">

                </div>
                <div className="col-sm-10 mt-4">

                    <div className="card Card_1task ">
                        <div className="card-body">
                            <div className="row">

                                <div className="col">
                                    <div className='row'>
                                        <div className='col-sm-3  mt-3'>
                                            <p className='text-secondary companynamesize'>Assigne
                                                <p className='text-black fw-bold fs-6'>Livingstone Hentry<p className='text-secondary companynamesize'>Hr Manager</p></p></p>
                                        </div>
                                        <div className='col-sm-3 mt-3'>
                                            <p className='text-secondary companynamesize'>Assign task to
                                                <p className='text-black fw-bold fs-6'>Willford Dicruz<p className='text-secondary companynamesize'>Warehouse manager</p></p></p>
                                        </div>
                                        <div className='col-sm-2 mt-3'>
                                            <p className='text-secondary companynamesize'>Start Date
                                                <p className=''>3/8/2022</p></p>
                                        </div>
                                        <div className='col-sm-2 mt-3'>
                                            <p className='text-secondary companynamesize'>End Date
                                                <p className=''>10/8/2022</p></p>
                                        </div>
                                        <div className='col-sm-1 mt-3'>

                                        </div>
                                    </div>

                                    <p className="mt-1 text-secondary">
                                        Status
                                    </p>
                                    <div className="row">
                                        <select class=" mt-2  text-center tast_textbox1  " aria-label=" select Day">
                                            <option selected>Status</option>
                                            <option value="1">Completed</option>
                                            <option value="2">Incomplete</option>
                                            <option value="3">Cancled</option>
                                            <option value="4">Pending</option>

                                        </select>

                                        <p className="fw-bold mt-3">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
                                        </p>
                                        <p className="text_small">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some variations of passages of Lorem  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                        </p>
                                        <p className="text_small">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some variations of passages of Lorem  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                        </p>
                                        <button class="btn btn-secondary task_btn ms-3 mt-3 " type="button" onClick={alet}>Back</button>
                                        <button class="btn btn-pri task_btn ms-3 mt-3 " type="button" onClick={save}>Save</button>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Lheader />
        </div>
    )


}
export default Addtask