import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';

const Currency = () => {
    const { state } = useContext(store);
    console.log('currency', state.user);

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const clickdelete = (id) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }
    const clickupdate = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdate(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update')
                }
            ]
        })
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }
    const { update, handleSubmit } = useForm();

    // const [name2, setend] = useState("");
    // const [name, setcurrency] = useState("");
    const [editcurrency, set_editcurrency] = useState("");
    const [editshortname, set_editshortname] = useState("");
    const [currency_details, setcurrency_details] = useState([]);
    const [bind_user, setBindUser] = useState({});
    const [symbol, SetSymbol] = useState("");
    const [symbolimg, SetSymbolImg] = useState("");
    const [user_type, setUserType] = useState('');
    // const [bank, set_bank] = useState("")


    const Getcurrency = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/currency/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setcurrency_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        Getcurrency();
    }, [state]);

    const checkifValidemail = (curname) => {

        let isValid = true;
        currency_details.forEach(item => {

            if (item.currency.toLowerCase() === curname.toLowerCase()) {
                isValid = false;
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (curname != "") {
            if (checkifValidemail(curname)) {
                try {
                    let cur = {

                        currency: curname,
                        shortname: curname2,
                        symbol: symbol,
                        user_id: state?.user[0]?.user_id,
                        company_id: state?.user[0]?.company_id,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/currency`, cur).then((res) => {
                        console.log('user updated', res.data);
                        //Getcurrency();
                        window.location = "/currency";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Currency already exist',
                    className: 'text-center',
                    buttons: [
                        {
                            label: 'Close',
                            className: 'mx-auto',
                            onClick: () => { },
                            style:{
                                backgroundColor:'#F36E35'
                               
                           }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: 'Please fill all data',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    },
                ]
            });
        }
    }
    const Delete = (id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/currency/` + id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }

                Getcurrency();
            })
        } catch (err) {
            console.log(err.message);
        }
    }


    const onUpdate = (id, e) => {
        if (!editcurrency || !editshortname) {
            alert('Currency name and shortname is required. Please provide a valid data.');
            return; 
        }
        let upcur = {
            id: bind_user.id,
            currency: editcurrency,
            shortname: editshortname,
            symbol: symbolimg,
        };

        axios.put(`${process.env.REACT_APP_API_URL}/currency/${bind_user.id}`, upcur).then((res) => {

            console.log('currency updated', res.data);
            ShowDetails('');
            Getcurrency();
        }).catch(() => {



        })

    }

    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(clickupdate)} action="#" method="POST">
                            <div class="input-group">
                                <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.currency} onChange={(e) => set_editcurrency(e.target.value)} placeholder="currency" aria-label="Username" />
                            </div>
                            <div class="input-group">
                                <input minlength="1" maxlength="10" type="text" class=" form-control inputcolor mt-3" autocomplete="" Value={bind_user.shortname} onChange={(e) => set_editshortname(e.target.value)} placeholder="shortname" aria-label="Username" />
                            </div>
                            <div class="input-group">
                                <input minlength="1" maxlength="5" type="text" class=" form-control inputcolor mt-3" autocomplete="" Value={bind_user.symbol} onChange={(e) => SetSymbolImg(e.target.value)} placeholder="symbol" aria-label="Username" />
                            </div>
                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(clickupdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">Update</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>Cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        set_editcurrency(data.currency);
        set_editshortname(data.shortname);
        SetSymbolImg(data.symbol);
        setUserType(type);
    }

    // handle events 
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });

    // handle submit


    // watch events 
    const curname = watch('curname')
    const curname2 = watch('curname2')



    // handle disabled submit
    const isValid = curname && curname2

    return (

        <div className='bg-light vh-100 container-fluid'>
            <div class="row">
                <div class="col-2">
                    <div >
                        <div className='setting_side  hide_all hid_tab'>
                            <p className="ms-5 fs-4 fw-bold mt-3">Settings</p>

                            <ul className='pointer_'>


                                <div className='mt-2' onClick={weekday}>Work Schedules </div>
                                <div className='mt-2 ' onClick={loc}>Work Locations </div>


                                <div className='mt-2' onClick={dept}>Departments </div>





                                <div className='mt-2' onClick={job}>Job Titles </div>



                                <div className='mt-2' onClick={salary}>Salary </div>


                                <div className='mt-2' onClick={holidays}>Holidays </div>
                                <div className='mt-2 fw-bold' onClick={currency}>Currency </div>

                                <div className='mt-2' onClick={financial}>Financial year </div>

                                <div className='mt-2' onClick={Leavetype}>Leave Types </div>
                                <div className='mt-2' onClick={allocation}>Leave Allocation </div>
                                <div className='mt-2' onClick={overtype}>Overtime Rates </div>
                                <div className='mt-2' onClick={ins}>Insurance </div>

                                <div className='mt-2' onClick={minwage}>Minimum Wage </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='row mt-3'>
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-2 col-lg-5 col-xl-5'></div>
                    <div className='col-sm-6 col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6'>
                    <div className='row'>
                            <div className='col-8'>
                        <p className=' mt-3 fs-5 fw-bold  grey'>Currency</p>
                        </div>
                        <div className='col-3 onlytab'>
                                            {/* mobile setting sidebar */}
                                            <li class="item dropdown">
                                                <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                </svg> </div>
                                                <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div></div>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70,backgroundColor:"#E7E7E7" }}>
                                                    <p className="text-center fs-4 fw-bold mt-3 ">Settings</p>
                                                    <div className='mt-2  ms-2' onClick={weekday}>Work Schedules </div>
                                                    <div className='mt-2  ms-2' onClick={loc}>Work Locations </div>

                                                    <div className='mt-2 ms-2' onClick={dept}>Departments </div>





                                                    <div className='mt-2 ms-2 ' onClick={job}>Job Titles </div>




                                                    <div className='mt-2 ms-2' onClick={salary}>Salary </div>


                                                    <div className='mt-2 ms-2' onClick={holidays}>Holidays </div>
                                                    <div className='mt-2 ms-2 fw-bold' onClick={currency}>Currency </div>

                                                    <div className='mt-2 ms-2' onClick={financial}>Financial year </div>
                                                    <div className='mt-2 ms-2' onClick={Leavetype}>Leave Types </div>
                                                    <div className='mt-2 ms-2' onClick={allocation}>Leave Allocation </div>
                                                    <div className='mt-2 ms-2' onClick={overtype}>Overtime Rates </div>
                                                    <div className='mt-2 ms-2' onClick={ins}>Insurance </div>
                                                    <div className='mt-2 ms-2' onClick={minwage}>Minimum Wage </div>

                                                </ul>
                                            </li>
                                        </div>
                        </div>
                        <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                            <div className='row '>

                                <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                    <div class="input-group">
                                        {/* <input type="text" class=" form-control inputcolor roles_text ms-3  mt-3 textresp" Value={name} onChange={(e)=>setcurrency(e.target.value)} placeholder="Currency" aria-label="Username" /> */}
                                        <input
                                            // Value={curname} onChange={(e)=>setcurrency(e.target.value)}
                                            type='text'
                                            minlength="2" maxlength="40"
                                            placeholder='Currency'
                                            className={`form-control inputcolor textbox-work  mt-2 ${errors.curname &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("curname", {
                                                required: {
                                                    value: true,
                                                    message: ' *Currency name is required'
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Please enter Full Name",
                                                },
                                                maxLength: {
                                                    value: 39,
                                                    message:
                                                        "Maximum allowed length is 40 characters ",
                                                },
                                                pattern: {
                                                    value: /[a-zA-Z]+/,
                                                    message: 'Please enter only alphabets'
                                                },
                                            })}
                                        />
                                        <div>
                                            {errors.curname && <span className='error_f text-danger'>{errors.curname.message}</span>}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-sm-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>

                                    <input
                                        type='submit'
                                        value='Add'
                                        className={"btn btn-pri w-75 ms-4 setbtn mt-2"}
                                        disabled={!isValid}
                                    />

                                </div>
                                <div className='row '>
                                    <div className='col-sm-6'>
                                        <div class="input-group">

                                            <input placeholder="Shortname"
                                                type='text'
                                                minlength="1" maxlength="10"
                                                className={`form-control inputcolor textbox-work  mt-4 ${errors.curname2 &&
                                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                {...register("curname2", {
                                                    required: {
                                                        value: true,
                                                        message: '* Short name is required'
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter your Short Name ",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message:
                                                            "Maximum allowed length is 20 characters ",
                                                    },
                                                    pattern: {
                                                        value: /[a-zA-Z $]+/,
                                                        message: 'Please enter only alphabets'
                                                    },
                                                })}
                                            />
                                            <div>
                                                {errors.curname2 && <span className='error_s text-danger'>{errors.curname2.message}</span>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-sm-6'>
                                        <div class="input-group mt-4">
                                            <input minlength="1" maxlength="5" type="text" class=" form-control inputcolor textbox-work " Value={symbol} onChange={(e) => SetSymbol(e.target.value)} placeholder="Symbol" aria-label="Username" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> Currency</td>
                                        <td scope="col" className='fs-6 fw-bold grey'>Short Name</td>
                                        <td scope="col" className='fs-6 fw-bold grey' >Symbol</td>
                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Created Date</td>

                                        <td scope="col" ></td>
                                        <td scope="col" className='fs-6 fw-bold grey'>Edit </td>
                                        <td scope="col" className='fs-6 fw-bold grey'>Delete </td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {currency_details.map((x, index) => {
                                        return (
                                            <tr key={index}>

                                                <td>{x.currency}</td>
                                                <td>{x.shortname}</td>
                                                <td className='text-lowercase'>{x.symbol}</td>
                                                <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY')}</td>


                                                <td></td>
                                                <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 pointer_" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></td>

                                                <td><svg onClick={() => clickdelete(x.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash3  mt-3 float-end me-5 pointer_" viewBox="0 0 16 16">

                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                                                </svg></td>
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>




            </div>
            <div>{(() => {
                switch (user_type) {

                    case "edit": return RenderEdit();

                    default: return "";
                }
            })()}</div>
            <Lheader />

        </div>


    )
}
export default Currency