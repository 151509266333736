import React, { useCallback, useState, useContext, useEffect } from 'react';
import "./Signup.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
function Signup() {
  let navigate = useNavigate();
  const Gologin = () => {
    //debugger;
    navigate("/login");
  };
  const Goem = () => {
    //debugger;
    navigate("/emailverification");
  };
  const goland = () => {
    //debugger;
    navigate("/");
  };
  const { update, handleSubmit } = useForm();
  const [first, setfirst] = useState("");
  const [last, setlast] = useState("");
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [cpass, setcpass] = useState("");
  const [loginemail, setloginmail] = useState("");
  // email validator
  const Getemaillist = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/users_email`).then((res) => {

        setloginmail(res.data);
      })
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    Getemaillist();
  }, []);

  const checkifValidemail = (inputmail) => {
    let isValid = true;
    loginemail.forEach(item => {
      if (item.email.match(inputmail)) {
        isValid = false;
      }
    });
    return isValid
  }


  const onInsert = (data, e) => {
    if (email != "") {
      if (checkifValidemail(email)) {
        try {
          let signup = {

            firstname: first,
            lastname: last,
            email: email,
            password: fname,
            trail: true,

          };
          axios.post(`${process.env.REACT_APP_API_URL}/signup`, signup).then((res) => {
            console.log('user updated', res.data);


            onlogin(res.data)
          })
        } catch (err) {
          console.log(err.message);
        }
      } else {
        confirmAlert({
          message: 'Email ID already exists',
          buttons: [
            {
              label: 'Close',
              onClick: () => { },
              style: {
                backgroundColor: '#F36E35'

              }
            },
          ]
        });
      }

    } else {
      confirmAlert({
        message: 'Please fill all data',
        buttons: [
          {
            label: 'Close',
            onClick: () => { },
            style: {
              backgroundColor: '#F36E35'

            }
          },
        ]
      });
    }
  }

  const { register, watch, formState: { errors } } = useForm({
    mode: 'onTouched'
  });
  const fname = watch('fname')
  const femail = watch('email')



  // handle disabled submit
  const isValid = fname && email && (first ?? '').length >= 3 && (last ?? '').length >= 1

  // const onlogin = (data, e) => {
  //   let bas = {

  //     loginmail: email,
  //     password: fname,
  //     admin: 'true',
  //     active_status: true,
  //   };


  //   axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
  //     console.log('user updated', res.data);
  //     // Update();
  //     //save();
  //     alert("Account create succesfully");
  //     Goem();
  //     // window.location = "/personaldetails";
  //   }).catch(() => {

  //   })
  // }
  const onlogin = (data, e) => {
    let bas = {
      userid: data.user_id,
      loginmail: data.email,
      password: fname,
      admin: 'true',
      company: 'false',
      active_status: true,
    };


    axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
      console.log('user updated', res.data);
      checkifinleads(res?.data?.loginmail);
      // alert("Verification Done Succesfully");
    }).catch(() => {

    })
  }
  // get demo and update 
  const onUpdate_demo = (id) => {
    console.log(id, 'emial');
    let demo = {
      email: id,
      status: 'converted',
    };
    axios.put(`${process.env.REACT_APP_API_URL}/demostatusbyemail/${id}`, demo).then((res) => {
      console.log('Demo updated', res.data);
      Goem();
      //  gologin();
    }).catch(() => {
    })

  }
  const checkifinleads = (inputmail) => {

    demolist.forEach(item => {
      if (item.email.match(inputmail)) {
        onUpdate_demo(inputmail);
      } else {
        Goem();
      }
    });

  }
  // get demo
  const [demolist, setdemolist] = useState([])
  const Getdemo = () => {

    axios.get(`${process.env.REACT_APP_API_URL}/demo`).then((res) => {
      console.log(res.data, 'success');
      setdemolist(res.data);

    }).catch(() => {

    })

  }
  useEffect(() => {
    Getdemo();
  }, []);
  const valuepass = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i;

  const emailval = /^(?=.*[a-z A-Z ])(?=.*[.])(?=.*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z])[a-z A-Z 0-9!@#$%^&*.]{8,64}$/i;

  const [show, setShow] = useState(false);

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text');

    // Check if pasted text contains only alphabetical characters
    if (!/^[a-zA-Z]+$/.test(pastedText)) {
      event.preventDefault();
    }
  };

  return (
    <div className=' logincont sceen_size'>
      <div className='row'>
        <div className=' col-xxl-7 col-sm-7 col-md-1 col-lg-1 col-xl-7  '>

          {/* <div className='row'>
            {/* <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
              <div className="text-white fw-bold fs-1 mt-4 ms-4 ">
                <img className='img-fluid logo_size pointer_' onClick={goland} src={`Images/logoq.png`} ></img>
                <img className='img-fluid mt-5' src={`Images/Ellipse12.svg`} ></img>
              </div>
            </div>
            <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
              <div className="text-white fw-bold fs-1 text-center headn ms-5">Designed for a <p>global workforce.</p></div>


            </div>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
              <img className='img-fluid float-end headn mt-5 ms-5' src={`Images/Ellipse12.svg`} ></img>
            </div> */}

          {/* </div> */}
          {/* {/* <div className='row'>

            <div className='col-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
              <img className='img-fluid  mt-5 loginimg' src={`Images/Frame.svg`} ></img>
              <img className='imgbor' src={`/Images/Vectu.png`}></img>
            </div> */}
          {/* </div> * */}

          <div className='row'>
            <div className='col '>
              <img className='img-fluid  loginimg pointer_  tabimgcent' onClick={goland} src={`Images/sygl_img.png`} alt="Logo" />
              <img className='imgbor mobsimplytext hide_tab' src={`/Images/sign.png`} alt="SY Image" />

            </div>


          </div>
        </div>
        <div className=' col-xxl-5 col-sm-5 col-md-11 col-lg-11 col-xl-5 bg-white inpucent'>
          <div className='row mt-5 ms-2 '>
            <div className='col-xxl-2 col-sm-2 col-md-1 col-lg-2 col-xl-2 hide_tab'></div>
            <div className="col-sm-7 col-xxl-7 col-md-12 col-lg-9 col-xl-7 ">
              <br></br>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0080FF" class="bi bi-translate icn_lg" viewBox="0 0 16 16">  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" /></svg>

              <label className='headlanguage '>Change Language</label>
              <label className='headeng' >English</label> */}
               <div className='row topmar1 '>
                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 tmtp'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0080FF" class="bi bi-translate " viewBox="0 0 16 16">  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" /></svg>
                </div>
                <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 tmtp'><label className='text-primary'>Change Language</label><label className='ms-1'>English</label> </div>




              </div>
              <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                <div class="mt-5">
                  <small for="exampleInputEmail1" class="form-label ms-1">FirstName*</small>
                  <input type="text" class="form-control inputcolor signuptxt tabinpwids" value={first} onChange={(e) => setfirst(e.target.value)} onPaste={handlePaste} id="exampleInputEmail1" aria-describedby="emailHelp" minlength="3" maxlength="20"
                    onKeyPress={(event) => {
                      if (
                        first.length === 0 && // Check if input is empty
                        !/[a-zA-Z]/.test(event.key) // Allow only alphabet for the first character
                      ) {
                        event.preventDefault();
                      }
                      if (
                        /[0-9]/.test(event.key) // Disallow numbers
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event) => {
                      if (!/^[a-zA-Z\s]*$/.test(event.target.value)) {
                        event.target.value = ''; // Clear the input if it doesn't match the pattern
                        setfirst(''); // Also clear the state variable
                      }
                    }} />



                </div>

                <div class=" mt-2">
                  <small for="exampleInputEmail1" class="form-label ms-1">LastName*</small>

                  <input type="text" class="form-control inputcolor signuptxt tabinpwids" value={last} onChange={(e) => setlast(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" minlength="1" maxlength="20" pattern="[A-Za-z]+"
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />

                </div>
                <div class="mt-2">
                  <label for="exampleInputEmail1" class="form-label ms-1">Email*</label>
                  <input type="email" minlength="3" maxlength="50" class=" form-control inputcolor signuptxt tabinpwids" Value={email} onChange={(e) => setemail(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" />

                  <div>
                    {errors.email && <span className=' text-danger ms-3'>{errors.email.message}</span>}
                  </div>
                </div>
                <div>
                </div>
                <div class="mt-2">
                  <small for="exampleInputEmail1" class="form-label ms-1">Password*</small>


                  {show ?

                    <input type="text" id="exampleInputEmail1" Value={fname} onChange={(e) => setpass(e.target.value)} aria-describedby="emailHelp"




                      className={`form-control inputcolor signuptxt tabinpwids ${errors.fname &&
                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                      {...register("fname", {
                        required: {
                          value: true,
                          message: '*Password is required'
                        },
                        minLength: {
                          value: 8,
                          message: "Please enter minimum 8 character",
                        },
                        maxLength: {
                          value: 25,
                          message:
                            "Maximum allowed length is 25 characters ",
                        },
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i,
                          message: <smaller className='text_small_error'>Password Should Contain Capital letter,Small letter,Numeric & special Characters</smaller>
                        },
                      })}
                    /> :

                    <input type="password" id="exampleInputEmail1" Value={fname} onChange={(e) => setpass(e.target.value)} aria-describedby="emailHelp"




                      className={`form-control inputcolor signuptxt tabinpwids ${errors.fname &&
                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                      {...register("fname", {
                        required: {
                          value: true,
                          message: '*Password is required'
                        },
                        minLength: {
                          value: 8,
                          message: "Please enter minimum 8 character",
                        },
                        maxLength: {
                          value: 25,
                          message:
                            "Maximum allowed length is 25 characters ",
                        },
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/,
                          message: <smaller className='text_small_error'>Password Should Contain Capital letter,Small letter,Numeric & special Characters</smaller>
                        },
                      })}
                    />}

                  <div>
                    {errors.fname && <span className='text-danger'>{errors.fname.message}</span>}
                  </div>
                  <input type="checkbox" className='mt-2 ms-2' onChange={() => setShow(!show)} />
                  <small className='ms-2'>Show Password</small>
                </div>
                <div class="mt-1">
                  <small for="exampleInputEmail1" class="form-label ms-1">ConfirmPassword*</small>
                  {show ? <input type="text" class="form-control inputcolor signuptxt tabinpwids" id="exampleInputEmail1" Value={cpass} onChange={(e) => setcpass(e.target.value)} aria-describedby="emailHelp" /> :
                    <input type="password" class="form-control inputcolor signuptxt tabinpwids" id="exampleInputEmail1" Value={cpass} onChange={(e) => setcpass(e.target.value)} aria-describedby="emailHelp" />}
                </div>
                <div className='row mt-2'>




                  {/* {(() => {
                    if (cpass != fname && cpass != null && fname != null) {
                      return <div>
                        <small className='text-danger'>Password Is not matching</small>
                        
                        <button type="submit" className='btn btn-pri d-grid mt-1 ' value=" Sign Up" disabled >Sign up</button>
                     
                      </div>
                    }
                    if (cpass == fname && fname.match(valuepass) && email.match(emailval)) {
                      return <div>

                        <button type="submit" className='  btn btn-pri d-grid mt-1' value="Sign Up" disabled={!isValid} >Sign up</button>
                      </div>
                    }
                    if ((email ?? '').length >= 8) {
                      return <div>
                        <small className='text-danger'>Enter a Valid Email / Password</small>
                        <button type="submit" className=' btn btn-pri d-grid mt-1' value=" Sign Up" disabled >Sign up</button>
                      </div>
                    } else {
                      return <div>

                        <button type="submit" className='btn btn-pri d-grid mt-1' value=" Sign Up" disabled >Sign up</button>
                      </div>
                    }

                  })()} */}
                  {(() => {

                    if (cpass != fname && cpass != null && fname != null) {

                      return <div>

                        <small className='text-danger'>Password Is not matching</small>

                        <button type="submit" className='btn btn-pri btns d-grid mt-3 ms-1' value=" Sign Up" disabled >Sign up</button>

                      </div>

                    }

                    if (cpass == fname && fname.match(valuepass) && email.match(emailval)) {

                      return <div>



                        <button type="submit" className=' btn btn-pri btns d-grid mt-3  ms-1' value="Sign Up" disabled={!isValid} >Sign up</button>

                      </div>

                    }

                    if ((email ?? '').length >= 8) {

                      return <div>

                        <small className='text-danger'>Enter a Valid Email / Password</small>

                        <button type="submit" className='btn btn-pri btns  d-grid mt-3 ms-1 ' value=" Sign Up" disabled >Sign up</button>

                      </div>

                    } else {

                      return <div>



                        <button type="submit" className='btn btn-pri btns  d-grid mt-3 ms-1' value=" Sign Up" disabled >Sign up</button>

                      </div>

                    }



                  })()}
                </div>
                <div className='row '>
                  <div className='col'>
                    <div className="ms-4 mt-2">Have an existing account?  <label className='signup pointer_ ms-2' onClick={Gologin}>Sign In</label></div>

                  </div>
                </div>
              </form>
            </div>

          </div>

        </div>
      </div>

    </div>
  )
}

export default Signup
