import { React, useState, useEffect, useContext } from "react";
import './Superadmincss.css';
import Header from '../Header/Header'
import Chart from "react-apexcharts";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Mobside from "../Sidebar/Mobside"
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import { Row } from "antd";
const Leaddetails = () => {
  const [mon, setmon] = useState('');
  const [tue, settue] = useState('');
  useEffect(() => {
    //Getworklist();
    //GetTotalWorkingDays();
    GetTotalWorkingDaysOfWeek();
  }, []);


  const [user_type, setWork_list] = useState([]);

  const GetTotalWorkingDays = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/workschedule`)
      .then((res) => {
        console.log(res.data, 'success');
        let work_list = res.data[0];
        console.log(work_list, 'work_list');
        let date = new Date();
        let start = new Date(date.getFullYear(), date.getMonth(), 1);
        let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let totalWorkingDays = 0;
        while (start <= end) {
          let dayOfWeek = start.getDay();
          let workingHours = 0;
          switch (dayOfWeek) {
            case 0: // Sunday
              workingHours = work_list.sunday;
              break;
            case 1: // Monday
              workingHours = work_list.monday;
              break;
            case 2: // Tuesday
              workingHours = work_list.tuesday;
              break;
            case 3: // Wednesday
              workingHours = work_list.wednesday;
              break;
            case 4: // Thursday
              workingHours = work_list.thursday;
              break;
            case 5: // Friday
              workingHours = work_list.friday;
              break;
            case 6: // Saturday
              workingHours = work_list.saturday;
              break;
            default:
              console.log(`Invalid day of week: ${dayOfWeek}`);
              break;
          }
          if (workingHours === 7) {
            totalWorkingDays++;
          } else if (workingHours === 8) {
            totalWorkingDays += 0.5;
          } else if (workingHours >= 0 && workingHours <= 6) {
            // Week off
          } else {
            console.log(`Invalid working hours for day ${start}`);
          }
          start.setDate(start.getDate() + 1);
        }
        console.log(`Total working days: ${totalWorkingDays}`);
        return totalWorkingDays;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const GetTotalWorkingDaysOfWeek = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/workschedule`)
      .then((res) => {
        console.log(res.data, 'success');
        let work_list = res.data[0];
        console.log(work_list, 'work_list');
  
        let totalWorkingDaysWeek = 0;
        let currentDate = new Date();
        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
          let workingHoursWeek = 0;
          switch (dayOfWeek) {
            case 0: // Sunday
              workingHoursWeek = work_list.sunday;
              break;
            case 1: // Monday
            workingHoursWeek = work_list.monday;
              break;
            case 2: // Tuesday
            workingHoursWeek = work_list.tuesday;
              break;
            case 3: // Wednesday
            workingHoursWeek = work_list.wednesday;
              break;
            case 4: // Thursday
            workingHoursWeek = work_list.thursday;
              break;
            case 5: // Friday
            workingHoursWeek = work_list.friday;
              break;
            case 6: // Saturday
            workingHoursWeek = work_list.saturday;
              break;
            default:
              console.log(`Invalid day of week: ${dayOfWeek}`);
              break;
          }
          if (workingHoursWeek === 7) {
            totalWorkingDaysWeek++;
          } else if (workingHoursWeek === 8) {
            totalWorkingDaysWeek += 0.5;
          } else if (workingHoursWeek >= 0 && workingHoursWeek <= 6) {
            // Week off
          } else {
            console.log(`Invalid working hours for day ${dayOfWeek}`);
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        console.log(`Total working days: ${totalWorkingDaysWeek}`);
        let totalWorkingDaysPerMonth = Math.round((totalWorkingDaysWeek * 52) / 12);
        console.log(`Total working days per month: ${totalWorkingDaysPerMonth}`);
        return totalWorkingDaysPerMonth;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  
  const getWorkingDays = () => {
    let date = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let holidays = 0; console.log(start);
    console.log(end);
    while (start <= end) {
      let day = start.getDay();
      if (day !== mon && day !== tue) {
        holidays++;
      }
      console.log(holidays);
      start.setDate(start.getDate() + 1);
    }
    return holidays;
  }
// page not required


  return (
    <div className="bg-light vh-100 row container-fluid " ><h1 className="">
     </h1><div> {user_type.map((x, index) => {
      return (<div> <p className="text-dark">{x.monday}</p>
       </div>
      );
    })}
      </div>
    </div>
  )

}
export default Leaddetails