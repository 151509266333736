import { React, useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import Chart from "react-apexcharts";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Dashboard_admin = () => {
    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    const { state } = useContext(store);
    console.log('company list', state.user);
    const [company_list, setCompany_list] = useState([])
    const { update, handleSubmit } = useForm();
    const [holiday_title, setHoliday_title] = useState([])
    const [tasklist, setTask_list] = useState([])
    const [currency_details, setcurrency_details] = useState([]);
    const [stdate, startdate] = useState('');
    const [trail, gettrail] = useState('');
    const [comp_details, setCompany_data] = useState([]);
    const current = new Date();
    const todaedate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    useEffect(() => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getloginid/${state?.user[0]?.login_id}`)
                .then((res) => {
                    console.log(res.data, 'setguide');

                    // Check if company_id is null and company is false
                    if (res?.data[0]?.company_id == null && res?.data[0]?.company == false) {
                        // Delete the companycycle using company_id from session storage
                        const storedCompanyData = JSON.parse(localStorage.getItem('company_id'));
                        const company_id = storedCompanyData.company_id;
                        console.log(storedCompanyData, 'cmp')

                        if (company_id === "null") {

                            localStorage.removeItem('user_details');
                            localStorage.removeItem('company_id');

                            window.location = '/';
                        } else {
                            axios.delete(`${process.env.REACT_APP_API_URL}/companycycle/` + company_id)
                                .then((res) => {
                                    console.log(res.data, 'delete status');
                                })
                                .catch((error) => {
                                    console.log(error, 'error');
                                    // Handle error as needed

                                });

                            localStorage.removeItem('user_details');
                            localStorage.removeItem('company_id');

                            window.location = '/';
                        }



                    } else {


                        Getpaytotalall();
                        Getholidaylist();
                        Gettasklist();
                        Getpaytotal();
                        Getcompanylist();
                        Getcurrency();
                        empcount();
                        Getleave();
                        Getwar();
                        Getcompanydata();



                    }
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
        }
    }, [state.user]);

    const Getcompanylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address_count/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }


    const Getholidaylist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/holidayslistcurrentmonth/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'holiday');
                setHoliday_title(res.data);
            }).catch(() => {

            })
    }

    const Gettasklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/task_list/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'succ_ess');
                setTask_list(res.data);

            }).catch(() => {

            })

    }
    const Getcurrency = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/currency_1/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/curr/6`).then((result) => {
                        console.log(result.data, 'curr');
                        setcurrency_details(result.data);
                    })
                } else { setcurrency_details(res.data); }

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }




    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const todotask = () => {
        let path = `/todotask`;
        navigate(path);
    }
    const [paylist, setPaylist] = useState([]);
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'bar',

                zoom: {
                    enabled: false

                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                categories: [],


            },

            title: {
                text: 'Employee Headcount by Month',
                align: 'left',
                style: {
                    fontSize: "15px",
                    fontFamily: 'Comfortaa',
                    color: '#717372'
                }
            },
            fill: {

                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 1,
                max: 100,

            },
            fill: {

                colors: ['#0080FF', 'transparent']
            },
            dataLabels: {
                hideOverlappingLabels: true,
                style: {
                    colors: ['transparent'],

                }
            }

        },
        series: [{
            name: 'Employee count',
            data: [],


        }],


    })



    const Getpaytotalall = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payroll_graph/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'paylist');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
                        console.log(result.data, 'pay');
                        setPaylist(result.data);
                    })
                } else {
                    setPaylist(res.data);
                }
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }


    // useEffect(() => {
    //     const grossAmountData = paylist.map((entry) => Math.round(entry.grosamount));
    //     const netPayData = paylist.map((entry) => Math.round(entry.payrollcycle_amount));
    //     const taxAmountData = paylist.map((entry) => Math.round(entry.incometax));
    //     const currentYear = new Date().getFullYear(); // Get current year
    //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; // List of months
    //     const categories = months.map((month) => `${month} ${currentYear}`); // Add current year to each month

    //     setstate((prevState) => ({
    //         ...prevState,
    //         xaxis: {
    //             type: 'datetime',
    //             categories: months,


    //         },
    //         series: [
    //             {
    //                 name: 'Gross Amount',
    //                 data: grossAmountData,
    //             },
    //             {
    //                 name: 'Net Pay',
    //                 data: netPayData,
    //             },
    //             {
    //                 name: 'Tax Amount',
    //                 data: taxAmountData,
    //             },
    //         ],
    //     }));
    // }, [paylist]);
    const [emp_count, setempcount] = useState([])
    const empcount = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_emp_countg/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'co_unt');
                setempcount(res.data);

            }).catch(() => {

            })
        }

    }
    let monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Assuming emp_count contains data for specific months
    const empCountByMonth = {}; // Create an object to store counts by month

    // Initialize counts for all months as 0
    monthNames.forEach((monthName) => {
        empCountByMonth[monthName] = 0;
    });

    // Update counts based on the data in emp_count
    emp_count.forEach((entry) => {
        const monthName = monthNames[entry.month - 1];
        empCountByMonth[monthName] = entry.employee_count;
    });

    // Now, empCountByMonth contains counts for all months

    // Use empCountByMonth to update your state and chart data
    useEffect(() => {
        const empc = Object.values(empCountByMonth); // Get counts from the object
        const emp_c = Object.keys(empCountByMonth); // Get month names from the object

        setstate((prevState) => ({
            ...prevState,
            series: [
                {
                    name: 'Employee Count',
                    data: empc,
                }
            ],
            options: {
                xaxis: {
                    categories: emp_c, // Use emp_c array for x-axis categories
                },
            },
        }));
    }, [emp_count]);



    const [paytotal, setPaytotal] = useState([]);

    const [datedif, setdatediff] = useState('');
    const [leave_details, setUserDetails] = useState([]);

    const Getwar = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/employee_basic/${state?.user[0]?.company_id}`)
                .then((res) => {
                    console.log(res.data, 'success');

                    res?.data?.forEach(item => {
                        if (!item.manager || !item.worklocation || !item.workschedule) {
                            war();
                        }


                    });

                })
                .catch(() => { });
        }
    };

    const Getleave = (x) => {
        const data = localStorage.getItem('hireAlertFlag');
        console.log('logged', data);

        if (state.user) {
            // localStorage.setItem("hireAlertFlag", "true");
            axios.get(`${process.env.REACT_APP_API_URL}/employee_basic/${state?.user[0]?.company_id}`)
                .then((res) => {
                    console.log(res.data, 'empsuccess');
                    let temp = [];
                    let employeeNames = [];
                    res?.data?.forEach(item => {
                        if (item.contractduedate) {
                            const currentDate = new Date();
                            const contractDueDate = new Date(item.contractduedate);
                            const timeDiff = Math.abs(currentDate - contractDueDate);
                            const diffInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            console.log(diffInDays, item.emp_id, "contractalert");
                            if (diffInDays >= 1 && diffInDays <= 15) {
                                employeeNames.push(item.full_name);
                            }
                        }
                        temp.push(item);
                        console.log(item, "reminder")
                    });
                    setUserDetails(temp);
                    if (employeeNames.length > 0) {
                        if (data == "true") {
                            console.log(data, "remi")
                            reminder();
                        }
                    }
                })
                .catch(() => { });
        }
    };


    const updatehire = (employeeNames) => {
        console.log(employeeNames, 'testempnamein popup');
        confirmAlert({
            title: 'Reminder',
            message: (
                <div className="text-black">
                    <p>The Hire Date of employees listed below has completed 85 days, Contract period will expire soon ,<p>Please update the labour type in employee list.</p></p> {employeeNames.map((name, index) => (
                        <div className="text-black text-capitalize fw-bold" key={index}>{name},</div>
                    ))}
                </div>
            ),
            buttons: [
                {
                    label: 'Ok',
                    class: "popbtn"
                },
            ]
        });
    };





    const Getpaytotal = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payroll1cycle/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
                        console.log(result.data, 'pay');
                        setPaytotal(result.data);
                    })
                } else { setPaytotal(res.data); }


            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }


    // const Getpaytotalall = () => {
    //     if (state.user) {
    //         axios.get(`${process.env.REACT_APP_API_URL}/payroll_graph/${state?.user[0]?.company_id}`).then((res) => {
    //             console.log(res.data, 'paylist');
    //             if (res.data.length == 0) {
    //                 axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
    //                     console.log(result.data, 'pay');
    //                     setPaylist(result.data);
    //                 })
    //             } else { setPaylist(res.data); }


    //         }).catch((error) => {
    //             console.log(error, 'success');
    //         });
    //     }
    // }

    // company for auto update

    const Getcompanydata = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/userscomp/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'datefunc');
                setCompany_data(res.data);
                startdate(res?.data[0]?.createdon);
                gettrail(res?.data[0]?.trail);
                console.log(state?.user[0]?.user_id, 'date');
                console.log(comp_details.trail, 'da_te');

                gotrail();
            }).catch(() => {

            })
        }
    }

    // auto update trail period
    const onUpdateauto_null = (id) => {

        let demo = {
            user_id: id,
            trail: null,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${id}`, demo).then((res) => {

            console.log('trail period updated', res.data);
            alert("updated trail status");
            logOut();
        }).catch(() => {



        })

    }
    // logout
    const logOut = () => {

        if (islogged == true) {
            localStorage.removeItem('user_details');
            setUser(false);
            window.location = '/';
        }
        else {
            localStorage.setItem('user_details');
        }
    }
    const reminder = () => {
        navigate("/reminder");

    };
    const war = () => {
        navigate("/warning");

    };


    const gotrail = () => {
        console.log(trail, 'traildate');
        const today = new Date();
        const otherDate = new Date(stdate);
        const diffTime = Math.abs(today - otherDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setdatediff(diffDays);
        console.log(otherDate, 'datediff1');
        console.log(today, 'todays date');
        console.log(diffDays, 'todays date');
        if (trail == true && diffDays > 30) {
            alert('completed');
            onUpdateauto_null(state?.user[0]?.user_id);
        }
    }
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const emped = () => {
        let path = `/EmpProfileedit`;
        navigate(path);
    }
    const [empdata, setempdata] = useState([]);

    const Getempdata = (emp_id) => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/emp_data/${state?.user[0]?.emp_id}`)
                .then((res) => {
                    console.log(res.data, "empdata");
                    setempdata(res.data);
                })
                .catch((err) => {
                    console.error(err.message);
                    ale_rt(); // Show the error popup
                });
        }
    };
    useEffect(() => {

        if (state.user && state.user[0]?.emp_id) {
            Getempdata();
        }
    },

        [state]);
    const ale_rt = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Please complete the profile data ',
            buttons: [
                {
                    label: 'Complete',
                    onClick: () => emped(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                }

            ]
        })
    }





    // const Getlogdata = () => {
    //     if (state.user) {
    //         axios.get(`${process.env.REACT_APP_API_URL}/getloginid/${state?.user[0]?.login_id}`).then((res) => {
    //             console.log(res.data, 'setguide');
    //             if (res?.data[0]?.company_id == null && res?.data[0]?.company == false) {
    //                 deletetabledata();
    //                 localStorage.removeItem('user_details');
    //                 localStorage.removeItem('company_id');
    //                 window.location = '/';
    //             } else {

    //             }
    //         }).catch((error) => {
    //             console.log(error, 'success');
    //         });
    //     }
    // }
    // const deletetabledata = () => {
    //     const storedCompanyData = JSON.parse(localStorage.getItem('company_id'));
    //     const company_id = storedCompanyData.company_id;
    //     try {
    //         axios.delete(`${process.env.REACT_APP_API_URL}/companycycle/` + company_id).then((res) => {
    //             console.log(res.data, 'success');
    //             window.location.reload();
    //         })
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // };

    // useEffect(() => {
    //     Getlogdata();
    // },[state]);

    return (
        <div className="bg-light vh-100 container-fluid">

            <div className="row mt-5">


                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab ">


                </div>
                <div className="col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 bg-light mt-3 ">


                    <div className="mt-3 lefdash">
                        <div class="card viewdetails ms-5 ">

                            <div class="card-body">
                                <blockquote class="blockquotemb-0">

                                    <div className="row">


                                        <div className="col-sm-8">
                                            {company_list.map((x, index) => {
                                                return (<p className="mt-2 fw-bold grey cents"> Total number of employees: {x.case}</p>

                                                );
                                            })}
                                        </div>
                                        <div className="col-3">  <button class="btn btn-pri ms-3  viewdetails_btn" type="button" onClick={employee}>View Details </button></div>

                                    </div>

                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="ms-4 mt-2 onlytab hide_tab">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                    {currency_details.map((y, index) => {
                        return (<div>
                            {paytotal.map((x, index) => {
                                return (

                                    <div className="row scroll-container" >
                                        <div className="col-xxl-4 col-4 col-md-4 col-lg-4 col-xl-4 lefdash">
                                            <div class="card card_1 ms-5 mt-3 " >


                                                <div class="card-body">
                                                    <img className="mx-auto d-block" src="./Images/card_1.svg"></img>

                                                    {(() => {
                                                        if (x.grosamount == null) {
                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                0
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text-center text-lowercase mt-1 fs-5">

                                                                {x.grosamount.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} {y.symbol}
                                                            </p>
                                                        }
                                                    })
                                                        ()}
                                                    {(() => {
                                                        if (x.startdate == null) {
                                                            return <p className="text-white text_small_mid text-center mt-3">
                                                                Total Payroll Cost
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text_small_mid  text-center mt-3">
                                                                Total Payroll Cost: {moment(x.startdate).format('MMM')} {moment(x.startdate).format('yy')}
                                                            </p>
                                                        }
                                                    })
                                                        ()}


                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-xxl-4 col-4 col-md-4 col-lg-4 col-xl-4">
                                            <div class="card card_3 ms-4  mt-3 " >



                                                <div class="card-body ">
                                                    <img className="mx-auto d-block" src="./Images/card_2.svg"></img>

                                                    {(() => {
                                                        if (x.compinsurance == null) {
                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                0
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text-center text-lowercase mt-1 fs-5">
                                                                {x.compinsurance.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} {y.symbol}

                                                            </p>
                                                        }
                                                    })
                                                        ()}
                                                    {(() => {
                                                        if (x.startdate == null) {
                                                            return <p className="text-white text_small_mid text-center mt-3">
                                                                Compulsory Insurance Summary
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text_small_mid text-center mt-3">
                                                                Compulsory Insurance Summary: {moment(x.startdate).format('MMM')} {moment(x.startdate).format('yy')}
                                                            </p>
                                                        }
                                                    })
                                                        ()}


                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-xxl-4 col-4 col-md-4 col-lg-4 col-xl-4">
                                            <div class="card card_2 ms-4 me-3 mt-3" >


                                                <div class="card-body">

                                                    <img className="mx-auto d-block" src="./Images/card_1.svg"></img>

                                                    {(() => {
                                                        if (x.payrollcycle_amount == null) {
                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                0
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text-center text-lowercase mt-1 fs-5">
                                                                {x.payrollcycle_amount.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} {y.symbol}
                                                            </p>
                                                        }
                                                    })
                                                        ()}
                                                    {(() => {
                                                        if (x.startdate == null) {
                                                            return <p className="text-white text_small_mid text-center mt-3">
                                                                Net Salary Paid
                                                            </p>
                                                        } else {
                                                            return <p className="text-white text_small_mid text-center mt-3">
                                                                Net Salary Paid: {moment(x.startdate).format('MMM')} {moment(x.startdate).format('yy')}
                                                            </p>
                                                        }
                                                    })
                                                        ()}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        );
                    })}
                    <div className="row">
                        <div className="col-sm-6 col-xxl-6 col-md-9 col-lg-6 col-xl-6 graphleft grlf" >
                            <div class="card  ms-5 mt-3 graphcardwidthmob " >

                                <div class="card-body">
                                    <Chart

                                        options={stat_e.options}
                                        series={stat_e.series}
                                        type="bar"
                                        height="300px"
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 col-xxl-3 col-md-3 col-lg-3 col-xl-3 ">
                            <div class="card card_holiday ms-2 mt-3" >

                                <div class="card-body _text">
                                    < p className=" fw-bold fs-6 grey text-center">Holidays in {month}</p>
                                    {holiday_title.length > 0 ? (
                                        holiday_title.map((x, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className="text_small">
                                                        <p className="fw-bold  text-center">{moment(x.holidaydate).format('DD MMMM,dddd')}
                                                            <p className="text-center fw-normal mt-1">{x.holiday_name}</p></p>
                                                        <p className="border-bottom"></p>
                                                    </p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center greyh">
                                            There are no holidays in this month
                                        </div>
                                    )}

                                    <div className="row">
                                        <div className="col-8">

                                        </div>


                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">

                                    </div>

                                    <div className="col-4">
                                        <a href="" className="text-primary _text  hide_all_pc" > view all</a>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 col-xxl-3 col-md-8 col-lg-3 col-xl-3 cardlef">
                            <div class="card card_todo ms-1 mt-3" >

                                <div class="card-body">
                                    < p className=" fw-bold  text-center grey "> To do list </p>
                                    {tasklist.length > 0 ? (
                                        tasklist.map((x, index) => {
                                            return (<div>
                                                <div className="card-title dasbo  mt-1" >
                                                    <p className="fw-bold text-center text-capitalize grey  ">{x.headline}
                                                        <p className="text-center fw-normal mt-1 text-capitalize " > Name: {x.full_name}</p>
                                                    </p>




                                                </div>
                                            </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center">
                                            Congratulations!  You have no tasks today
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-8">

                                        </div>

                                        <div className="col-4">
                                            <a className="text-primary _text  pointer_" onClick={todotask}> view all</a>

                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Lheader />

        </div>
    )
}
export default Dashboard_admin