import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';

const Leavetype = () => {
    const { state } = useContext(store);
    console.log('leavetype', state.user);
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const clickdelete = (leave_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(leave_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }
    const clickupdate = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update')
                }
            ]
        })
    }
    const { update, handleSubmit } = useForm();
    const [name, setleave_type] = useState("");
    const [leave_type_details, setUserDetails] = useState([]);
    const [editleave, set_editleave] = useState("");
    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    // const [leave_type, set_leave_type] = useState("")
    const [monthleave, setmonthleave] = useState("");
    const [monthle, setmonthle] = useState(0);
    const Getleave_type = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leave_type/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }



    useEffect(() => {
        Getleave_type();

    }, [state]);

    const [selectedLeaveTypes, setSelectedLeaveTypes] = useState('false');
    const [encashmentTypes, setEncashmentTypes] = useState('false');
    const [encashments, setEncashments] = useState('');
    const [carrydayss, setcarrydayss] = useState('');
    const [earnedTypes, setearnedTypes] = useState('false');
    const [earneddayss, setearneddayss] = useState('');
    const [waitings, setwaitings] = useState(0);
    const [earneddaysfres, setearneddaysfreqs] = useState('');
    const [closed, setclosed] = useState('false');
    const [negativee, setnegativee] = useState('false');
    const checkifValidemail = (curname) => {

        let isValid = true;
        leave_type_details.forEach(item => {

            if (item.leave_type.toLowerCase() === curname.toLowerCase()) {
                isValid = false;
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (bname != "") {
            if (checkifValidemail(bname)) {
                try {
                    let leavetype = {

                        leave_type: bname,
                        leavepermonth: lname,
                        user_id: state?.user[0]?.user_id,
                        company_id: state?.user[0]?.company_id,
                        applicableafter: waitings,
                        carryforward: selectedLeaveTypes,
                        carrydays: carrydayss || null,
                        encashment: encashmentTypes,
                        encashdays: encashments,
                        earned: earnedTypes,
                        earneddayfreq: earneddaysfres,
                        earnerdays: earneddayss,
                        closedtype: closed,
                        negativeleave: negativee
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/leave_type`, leavetype).then((res) => {
                        console.log('user updated', res.data);
                        //Getleave_type();
                        window.location = "/leavetype";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Leave type already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: 'Close',
                            className: 'mx-auto',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: 'Please fill all data',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }

    const Delete = (leave_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/leave_type/` + leave_id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }

                Getleave_type();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onUpdate = (leave_id, e) => {
        let upcur = {
            leave_id: bind_user.leave_id,
            leave_type: editleave,
            leavepermonth: monthleave,


        };

        axios.put(`${process.env.REACT_APP_API_URL}/leave_type/${bind_user.leave_id}`, upcur).then((res) => {

            console.log('department updated', res.data);
            Getleave_type();
            ShowDetails('');
        }).catch(() => {



        })

    }


    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(clickupdate)} action="#" method="POST">
                            <div class="input-group">
                                <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.leave_type} onChange={(e) => set_editleave(e.target.value)} placeholder="leave_type" aria-label="Username" />
                            </div>
                            <div class="input-group mt-3">
                                <input minlength="1" maxlength="10" type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.leavepermonth} onChange={(e) => setmonthleave(e.target.value)} placeholder="leave_type" aria-label="Username" />
                            </div>

                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(clickupdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">Update</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>Cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }
    const [selectedLeaveType, setSelectedLeaveType] = useState('');
    const [encashmentType, setEncashmentType] = useState('');
    const [encashment, setEncashment] = useState('');
    const [carrydays, setcarrydays] = useState('');
    const [earnedType, setearnedType] = useState('');
    const [earneddays, setearneddays] = useState('');
    const [waiting, setwaiting] = useState('');
    const [earneddaysfre, setearneddaysfreq] = useState('');

    const [negative, setnegative] = useState('');
    const btnvalid = waiting !== null;
    const onUpdateleave = () => {
        let upcur = {


            applicableafter: waiting,
            carryforward: selectedLeaveType,
            carrydays: carrydays,
            encashment: encashmentType,
            encashdays: encashment,
            earned: earnedType,
            earneddayfreq: earneddaysfre,
            earnedays: earneddays,
            negativeleave: negative,
        };

        axios.put(`${process.env.REACT_APP_API_URL}/leave_type_update/${bind_user.leave_id}`, upcur).then((res) => {

            console.log('department updated', res.data);
            Getleave_type();
            ShowDetails('');
        }).catch(() => {



        })

    }
    const [empid, setempid] = useState("");
    const [empdetails_details, setempdata] = useState([]);
    const Getemplist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/employee_basic/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');



                setempdata(res.data);

            }).catch(() => {

            })
        }
    }
    useEffect(() => {

        Getemplist();
    }, [state]);
    const Render_Edit = () => {
        console.log('bind', bind_user);
        return <div className='row mt-3'>
            <div className='col-5 col-md-6 col-lg-6 col-xl-5 col-xxl-5'></div>
            <div className='col-5   '>
                <div class="card tabeditcard">

                    <div class="card-body">
                        <form onSubmit={handleSubmit(onUpdateleave)} action="#" method="POST">
                            <p for="formGroupExampleInput" class="">{bind_user.leave_type}</p>

                            <label for="formGroupExampleInput" class="fw-bold ms-1">Waiting period days</label>

                            <div class="input-group">
                                <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.applicableafter} onChange={(e) => setwaiting(e.target.value)} placeholder="" aria-label="Username" />
                            </div>
                            <label for="formGroupExampleInput" class="fw-bold ms-1">Carry Forward :</label>
                            <label for="formGroupExampleInput" class=" ms-1">{bind_user.carryforward ? 'Enabled' : 'Disabled'}</label>

                            <div class="input-group mt-3">
                                <select className='form-select inputcolor' onChange={(e) => setSelectedLeaveType(e.target.value)}>
                                    <option>Select Carry Forward</option>
                                    <option className="text-dark" value='true' disabled={selectedLeaveType === 'true'}>Enable</option>
                                    <option className="text-dark" value='false' disabled={selectedLeaveType === 'false'}>Disable</option>
                                </select>

                            </div>
                            {selectedLeaveType === 'true' && (
                                <div>
                                    <label for="formGroupExampleInput" className="fw-bold ms-1">
                                        Carry Forward days
                                    </label>
                                    <div className="input-group mt-3">
                                        <input
                                            minlength="1"
                                            maxlength="10"
                                            type="text"
                                            className="form-control inputcolor"
                                            Value={bind_user.carrydays}
                                            onChange={(e) => setcarrydays(e.target.value)}
                                            placeholder=""
                                            aria-label="Username"
                                        />
                                    </div>
                                </div>
                            )}
                            <label for="formGroupExampleInput" class="fw-bold ms-1">Encashment :</label>
                            <label for="formGroupExampleInput" class=" ms-1">{bind_user.encashment ? 'Enabled' : 'Disabled'}</label>
                            <div class="input-group mt-3">
                                <select className='form-select inputcolor' onChange={(e) => setEncashmentType(e.target.value)}>
                                    <option>Select Encashment</option>
                                    <option className="text-dark" value='true' disabled={encashmentType === 'true'} >Enable</option>
                                    <option className="text-dark" value='false' disabled={encashmentType === 'false'} >Disable</option>
                                </select>  </div>
                            {encashmentType === 'true' && (
                                <div>
                                    <label for="formGroupExampleInput" className="fw-bold ms-1">
                                        Encashment days
                                    </label>
                                    <div className="input-group mt-3">
                                        <input
                                            minlength="1"
                                            maxlength="10"
                                            type="text"
                                            className="form-control inputcolor"
                                            Value={bind_user.encashdays}
                                            onChange={(e) => setEncashment(e.target.value)}
                                            placeholder=""
                                            aria-label="Username"
                                        />
                                    </div>
                                </div>
                            )}
                            <label for="formGroupExampleInput" class="fw-bold ms-1">Earned :</label>
                            <label for="formGroupExampleInput" class=" ms-1">{bind_user.earnedType ? 'Enabled' : 'Disabled'}</label>

                            <div class="input-group mt-3">
                                <select className='form-select inputcolor' onChange={(e) => setearnedType(e.target.value)}>
                                    <option>Select  Earned Leave</option>
                                    <option className="text-dark" value='true' disabled={earnedType === 'true'} >Enable</option>
                                    <option className="text-dark" value='false' disabled={earnedType === 'false'} >Disable</option>

                                </select>  </div>
                            {earnedType === 'true' && (
                                <div>
                                    <label for="formGroupExampleInput" className="fw-bold ms-1">
                                        Earned Leave days
                                    </label>
                                    <div className="input-group mt-3">
                                        <input
                                            minlength="1"
                                            maxlength="10"
                                            type="text"
                                            className="form-control inputcolor"
                                            Value={bind_user.encashdays}
                                            onChange={(e) => setearneddays(e.target.value)}
                                            placeholder=""
                                            aria-label="Username"
                                        />
                                    </div>
                                    <label for="formGroupExampleInput" className="fw-bold ms-1">
                                        Earned Leave Per Month
                                    </label>
                                    <div className="input-group mt-3">
                                        <input
                                            minlength="1"
                                            maxlength="10"
                                            type="text"
                                            className="form-control inputcolor"
                                            Value={bind_user.encashdays}
                                            onChange={(e) => setearneddaysfreq(e.target.value)}
                                            placeholder=""
                                            aria-label="Username"
                                        />
                                    </div>
                                </div>
                            )}
                            <label for="formGroupExampleInput" className="fw-bold ms-1">
                                Negative Leaves :
                            </label>
                            <label for="formGroupExampleInput" class=" ms-1">{bind_user.negativeleave ? 'Enabled' : 'Disabled'}</label>

                            <div class="input-group mt-3">
                                <select className='form-select inputcolor' onChange={(e) => setnegative(e.target.value)}>
                                    <option>Negative Leaves </option>
                                    <option className="text-dark" value='true' disabled={negative === 'true'} >Enable</option>
                                    <option className="text-dark" value='false' disabled={negative === 'false'} >Disable</option>

                                </select>  </div>
                            {/* <label for="formGroupExampleInput" className="fw-bold ms-1">
                                Leave Allocatation
                                    </label>
                                <div class="input-group mt-3">
                                
                               
                                <select class="form-select inputcolor text-start text_box_size" onChange={(e) => setempid(e.target.value)}>
  <option value="">Select  Employee to Allocate Leave </option>
  {empdetails_details.map((x) => (
    <option key={x} value={x.full_name}>
      {x.full_name}
    </option>
  ))}
</select>
                                  </div> */}
                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(onUpdateleave)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn" disabled={!btnvalid}>Update</button>
                                <button class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>Cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        set_editleave(data.leave_type);
        setmonthleave(data.leavepermonth);

        setwaiting(data.applicableafter);
        setSelectedLeaveType(data.carryforward);
        setcarrydays(data.carrydays);
        setEncashmentType(data.encashment);
        setEncashment(data.encashdays);
        setnegative(data.negativeleave);
        setearneddaysfreq(data.earneddayfreq);
        setearneddays(data.earnedays);
        setearnedType(data.earnedType)

        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    const bname = watch('fname')
    const lname = watch('lname')
    const isValid = bname && lname

    return (
        <div className='bg-light vh-100 container-fluid'>
            <div class="row">
                <div class="col-2">
                    <div >
                        <div className='setting_side hide_all hid_tab'>
                            <p className="ms-5 fs-4 fw-bold mt-3">Settings</p>

                            <ul className='pointer_'>


                                <div className='mt-2' onClick={weekday}>Work Schedules </div>
                                <div className='mt-2 ' onClick={loc}>Work Locations </div>


                                <div className='mt-2' onClick={dept}>Departments </div>





                                <div className='mt-2' onClick={job}>Job Titles </div>



                                <div className='mt-2' onClick={salary}>Salary </div>


                                <div className='mt-2' onClick={holidays}>Holidays </div>
                                <div className='mt-2' onClick={currency}>Currency </div>

                                <div className='mt-2' onClick={financial}>Financial year </div>

                                <div className='mt-2 fw-bold' onClick={Leavetype}>Leave Types </div>
                                <div className='mt-2 ' onClick={allocation}>Leave Allocation </div>

                                <div className='mt-2' onClick={overtype}>Overtime Rates</div>
                                <div className='mt-2' onClick={ins}>Insurance </div>

                                <div className='mt-2' onClick={minwage}>Minimum Wage </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5'>

                <div className='row mt-3'>
                    {(() => {
                        switch (user_type) {

                            case "edit": return RenderEdit();
                            case "view": return Render_Edit();
                            default: return "";
                        }
                    })()}
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-2 col-lg-5 col-xl-5'></div>
                    <div className='col-sm-6 col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6'>
                    <div className='row'>
                            <div className='col-8'>
                        <p className=' mt-3 fs-5 fw-bold  grey'> Leave Type</p>
                        </div>
                        <div className='col-3 onlytab'>
                                            {/* mobile setting sidebar */}
                                            <li class="item dropdown">
                                                <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                </svg> </div>
                                                <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div></div>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70,backgroundColor:"#E7E7E7" }}>
                                                    <p className="text-center fs-4 fw-bold mt-3 ">Settings</p>
                                                    <div className='mt-2  ms-2' onClick={weekday}>Work Schedules </div>
                                                    <div className='mt-2  ms-2' onClick={loc}>Work Locations </div>

                                                    <div className='mt-2 ms-2' onClick={dept}>Departments </div>





                                                    <div className='mt-2 ms-2 ' onClick={job}>Job Titles </div>




                                                    <div className='mt-2 ms-2' onClick={salary}>Salary </div>


                                                    <div className='mt-2 ms-2' onClick={holidays}>Holidays </div>
                                                    <div className='mt-2 ms-2' onClick={currency}>Currency </div>

                                                    <div className='mt-2 ms-2' onClick={financial}>Financial year </div>
                                                    <div className='mt-2 ms-2 fw-bold' onClick={Leavetype}>Leave Types </div>
                                                    <div className='mt-2 ms-2' onClick={allocation}>Leave Allocation </div>
                                                    <div className='mt-2 ms-2' onClick={overtype}>Overtime Rates </div>
                                                    <div className='mt-2 ms-2' onClick={ins}>Insurance </div>
                                                    <div className='mt-2 ms-2' onClick={minwage}>Minimum Wage </div>

                                                </ul>
                                            </li>
                                        </div>
                        </div>


                        <div className="col-sm-4 d-grid" >
                            <button className="btn btn-pri" data-bs-toggle="modal" data-bs-target="#exampleModal" >Add New Leave Type</button>
                        </div>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> Leave Type</td>
                                        <td scope="col" className='fs-6 fw-bold grey' >Days Per Year</td>
                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Created Date</td>
                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Edit </td>
                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Delete </td>


                                    </tr>
                                </thead>
                                <tbody>
                                    {leave_type_details.map((x, index) => {
                                        return (
                                            <tr key={index}>

                                                <td>{x.leave_type}
                                                    {(() => {

                                                        if (x.createdby === 1) {
                                                            return (
                                                                <svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil ms-2 pointer_" viewBox="0 0 16 16">

                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />

                                                                </svg>
                                                            );
                                                        } else {
                                                            return
                                                        }
                                                    })()}
                                                </td>
                                                <td>{x.leavepermonth}</td>
                                                <td className='mobsimplytext'>{moment(x.created_on).format('MMM Do YYYY')}</td>






                                                <td><svg onClick={() => ShowDetails(x, 'view')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 pointer_" viewBox="0 0 16 16">

                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />

                                                </svg></td>

                                                {(() => {

                                                    if (x.createdby === 1) {
                                                        return (
                                                            <td>
                                                                <svg
                                                                    onClick={() => clickdelete(x.leave_id)}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="red"
                                                                    className="bi bi-trash3 mt-3 float-end me-5 pointer_"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                </svg>
                                                            </td>
                                                        );
                                                    } else {
                                                        return <td>
                                                            <svg
                                                                disabled
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="red"
                                                                className="bi bi-trash3 mt-3 float-end me-5"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                            </svg>
                                                        </td>
                                                    }
                                                })()}
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>


                {/* Add New  */}
                <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content ">
                            <div class="">
                                <div className="row mt-4">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div class=" text-center text-primary fs-4 " id="exampleModalLabel">Add New Leave Type</div>
                                    </div>
                                    <div className="col-1">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                    </div>
                                </div>

                            </div>
                            <div class="modal-body ">
                                <div className='row '>
                                    <div className="col-1"></div>
                                    <div className='col-5'>
                                        <div class="input-group">
                                            <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor textbox-work  mt-3" Value={bname} onChange={(e) => setleave_type(e.target.value)} placeholder="Leave type" aria-label="Username" name="fname"
                                                className={` form-control inputcolor txtaddr ${errors.bname &&
                                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                {...register("fname", {
                                                    required: {
                                                        value: true,
                                                        message: ' *Data Required'
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter min 3 characters",
                                                    },
                                                    maxLength: {
                                                        value: 60,
                                                        message:
                                                            "Maximum allowed length is 40 characters ",
                                                    },
                                                    pattern: {
                                                        value: /^[A-Z0-9.-]{2,}$/i,
                                                        message: 'Please enter  Proper Name'
                                                    },
                                                })} />
                                            <div>
                                                {errors.fname && <span className=' text-danger error_f mt-5'>{errors.fname.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <div class="input-group">
                                            <input minlength="1" maxlength="10" type="text" class=" form-control inputcolor textbox-work  mt-3" Value={lname} onChange={(e) => setmonthle(e.target.value)} placeholder="Leave per year" aria-label="Username" name="fname"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                className={` form-control inputcolor txtaddr ${errors.lname &&
                                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                {...register("lname", {
                                                    required: {
                                                        value: true,
                                                        message: ' *Data Required'
                                                    },
                                                    minLength: {
                                                        value: 1,
                                                        message: "Please enter value",
                                                    },
                                                    maxLength: {
                                                        value: 60,
                                                        message:
                                                            "Maximum allowed length is 40 characters ",
                                                    },

                                                })} />
                                            <div>
                                                {errors.lname && <span className=' text-danger error_f mt-5 ms-3'>{errors.lname.message}</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-3'>
                                    <div className="col-1"></div>
                                    <div className='col-10'>
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary">Waiting period days</label>
                                        <div class="input-group mt-2">
                                            <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor txtaddr" Value={waitings} onChange={(e) => setwaitings(e.target.value)} placeholder="Waiting period days" aria-label="Username" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-1"></div>
                                    <div className='col-10'>
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary">Leave carry forward days</label>
                                        <div class="input-group mt-2">
                                            {selectedLeaveTypes !== 'true' && (
                                                <select
                                                    className='form-select inputcolor text-secondary txtaddr'
                                                    value={selectedLeaveTypes}
                                                    onChange={(e) => setSelectedLeaveTypes(e.target.value)}
                                                >
                                                    <option className="text-dark" value='false'>Disable</option>
                                                    <option className="text-dark" value='true'>Enable</option>

                                                </select>
                                            )}
                                        </div>
                                        {selectedLeaveTypes === 'true' && (
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <select
                                                        className='form-select inputcolor text-secondary txtaddr'
                                                        value={selectedLeaveTypes}
                                                        onChange={(e) => setSelectedLeaveTypes(e.target.value)}
                                                    >

                                                        <option className="text-dark" value='true'>Enable</option>
                                                        <option className="text-dark" value='false'>Disable</option>
                                                    </select>
                                                </div>
                                                <div className='col-6'>
                                                    {selectedLeaveTypes === 'true' && (
                                                        <div className="input-group">
                                                            <input
                                                                minLength="1"
                                                                maxLength="10"
                                                                type="text"
                                                                className="form-control inputcolor txtaddr"
                                                                value={carrydayss}
                                                                onChange={(e) => setcarrydayss(e.target.value)}
                                                                placeholder="Carry forward days"
                                                                aria-label="Carry forward days"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-1"></div>
                                    <div className='col-10'>
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary">Leave encashment days</label>
                                        <div class="input-group mt-2">
                                            {encashmentTypes !== 'true' && (
                                                <select className='form-select inputcolor txtaddr text-secondary'
                                                    value={encashmentTypes}
                                                    onChange={(e) => setEncashmentTypes(e.target.value)}>
                                                    <option className="text-dark" value='false' >Disable</option>
                                                    <option className="text-dark" value='true' >Enable</option>

                                                </select>
                                            )}
                                        </div>

                                        {encashmentTypes === 'true' && (
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <select
                                                        className='form-select inputcolor text-secondary txtaddr'
                                                        value={encashmentTypes}
                                                        onChange={(e) => setEncashmentTypes(e.target.value)}
                                                    >

                                                        <option className="text-dark" value='true'>Enable</option>
                                                        <option className="text-dark" value='false'>Disable</option>
                                                    </select>
                                                </div>
                                                <div className='col-6'>
                                                    {encashmentTypes === 'true' && (
                                                        <div className="input-group">
                                                            <input
                                                                minlength="1"
                                                                maxlength="10"
                                                                type="text"
                                                                className="form-control inputcolor txtaddr"
                                                                value={encashments}
                                                                onChange={(e) => setEncashments(e.target.value)}
                                                                placeholder="Encashment days"
                                                                aria-label="Username"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className="col-1"></div>
                                    <div className='col-10'>
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary">Earned leave</label>
                                        <div class="input-group mt-2">
                                            <select className='form-select inputcolor text-secondary txtaddr' onChange={(e) => setearnedTypes(e.target.value)}>
                                                <option className="text-dark" value='false' >Disable</option>
                                                <option className="text-dark" value='true' >Enable</option>

                                            </select>  </div>
                                        {earnedTypes === 'true' && (
                                            <div className='row mt-2'>

                                                {/* <div className='col-6'>
                                                    <div className="input-group">
                                                        <input
                                                            minlength="1"
                                                            maxlength="10"
                                                            type="text"
                                                            className="form-control inputcolor txtaddr"
                                                            value={earneddayss}
                                                            onChange={(e) => setearneddayss(e.target.value)}
                                                            placeholder=" Earned leave days"
                                                            aria-label="Username"
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className='col'>
                                                    <div className="input-group">
                                                        <input
                                                            minlength="1"
                                                            maxlength="10"
                                                            type="text"
                                                            className="form-control inputcolor txtaddr"
                                                            value={earneddaysfres}
                                                            onChange={(e) => setearneddaysfreqs(e.target.value)}
                                                            placeholder="Earned leave per month"
                                                            aria-label="Username"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'></div>
                                    <div class="col-10">
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary ">Leave type</label>
                                        <select className='form-select inputcolor txtaddr' onChange={(e) => setclosed(e.target.value)}>
                                            <option>Select leave type</option>
                                            <option className="text-dark" value='true' >Selected Employees</option>
                                            <option className="text-dark" value='false' >All Employees</option>
                                        </select>



                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'></div>
                                    <div class="col-10">
                                        <label for="formGroupExampleInput" class="fw-bold ms-1 text-secondary ">Negative Leave</label>

                                        <div class="input-group mt-3">
                                            <select className='form-select inputcolor' onChange={(e) => setnegativee(e.target.value)}>
                                                <option>Negative Leaves</option>
                                                <option className="text-dark" value='true' >Enable</option>
                                                <option className="text-dark" value='false' >Disable</option>
                                            </select>  </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                            <button type='submit' class="btn btn-pri w-75 ms-5 setbtn" disabled={!isValid}> Add</button>
                                        </form>
                                    </div>
                                    {/* <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                        <button type="button" class="btn btn-pri text_box_size">Submit</button>
                                    </div> */}
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div></div>
            <Lheader />

        </div>
    )
}
export default Leavetype