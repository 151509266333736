import { React, useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import Superheader from "./Superheader";
import { useNavigate } from "react-router-dom";
import Superside from "./Superside";
import ReactPaginate from "react-paginate";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';


const Superadmindues = () => {
    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setUserType(type);
    }
    const [payments, setpayment] = useState([]);
    const Getpayment = (x) => {
        axios.get(`${process.env.REACT_APP_API_URL}/paymentdue`).then((res) => {
            console.log(res.data, 'success');

            // Filter the rows that are not in the current month
            const filteredData = res.data.filter((row) => {
                const rowDate = row.amount;
                const currentDate = "2500000";
                return rowDate < currentDate || rowDate == null;
            });

            setpayment(filteredData);
        }).catch(() => {
            // Handle error
        })
    }


    useEffect(() => {
        Getpayment();

    }, []);
    const RenderView = () => {
        return (
            <div className="container-fluid row sprender">
                <div className="col-9  col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 posit">

                </div>
                <div className="col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  bg-white">
                    <div className="row ms-3 mt-4 ">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" onClick={() => ShowDetails('')} height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>

                    </div>
                    <div className="row ms-3 mt-4">
                        <small className="text-primary">Name</small>
                        <p>Alexander George</p>


                    </div>
                    <div className="row ms-3 mt-2">
                        <small className="text-primary">Email</small>
                        <p>Alexander George@gmail.com</p>

                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-primary">Phone Number</small>
                        <p>+44 5595 6969</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Company</small>
                        <p>XYZ Pvt LTd</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Address</small>
                        <p>Paris, St Lorem ,3467</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Order Id</small>
                        <p>#001</p>


                    </div>
                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Amount</small>
                        <p>$5200</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Due Date</small>
                        <p>22/12/2022</p>


                    </div>

                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Today Overdue Days</small>
                        <p>10 Days</p>


                    </div>


                </div>
            </div>
        )
    }
    let navigate = useNavigate();
    const custom = () => {
        let path = `/superadmincustomers`;
        navigate(path);
    }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = payments

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(payments.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const differenceInTime = today.getTime() - firstDayOfMonth.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));


    return (<div>
        <div className="bg-light vh-100 row container-fluid">
            <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">

            </div>

            <div className="col-10">

                <input type="text" class=" form-control grey ms-5 searchboxcrm  mt-3 " placeholder="Search License,Order ID" id="" aria-label="Username" />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>

                <div className="row mt-3">

                    <div className="col-1 ms-3 ">
                        <p className="fnt-clr ms-5  pointer_ text-center" onClick={custom}>ALL</p>
                    </div>
                    <div className="col-1 ">
                        <p className="fnt-clr fw-bold border-bottom border-primary border-4 text-center">Dues</p>
                    </div>
                    <div className="col-2 ">

                    </div>
                    <div className="col-2 ms-5">

                    </div>

                </div>

                <div className="row ms-1">
                    <div className="card Card_1task mt-4 ms-5">
                        <div className="row mt-3">

                            <div className="col-2 d-grid" >

                            </div>
                            <div className="col-6 d-grid" >
                            </div>
                            <div className="col-2 d-grid" >

                            </div>
                            <div className="col-2 " >
                                {/* <svg data-bs-toggle="modal" data-bs-target="#exampleModal1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders float-end me-5 " viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                </svg> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                <div className="col">
                                    <div className='row'>

                                        <div className='col'>

                                            <table class="table table-bordered mt-2">
                                                <table class="table">
                                                    <thead>
                                                        <tr>  <td scope="col"></td>

                                                            <td scope="col" className='headfont grey '>Order ID</td>

                                                            <td scope="col" className='headfont grey mobsimplytext'>Customer</td>
                                                            <td scope="col" className='headfont grey mobsimplytext'>Amount Due </td>
                                                            <td scope="col" className='headfont grey mobsimplytext'>Due Date</td>
                                                            <td scope="col" className='headfont grey mobsimplytext'>Overdue Days</td>



                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {displayUsers.map((x, index) => {

                                                            return (
                                                                <tr>
                                                                    <th scope="row"></th>

                                                                    <td className=''><p>{x.user_id}</p></td>
                                                                    <td className='pointer_'><p >{x.first_name}</p></td>
                                                                    <td className=''><p>{Math.round(2500000 - x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                                                    <td className=''>
                                                                        {(() => {
                                                                            if (x.amount != null) {
                                                                                return <p>{moment(x.payment_date).format('MMM Do YY')}</p>
                                                                            } else {
                                                                                return <p> {moment(firstDayOfMonth).format('MMM Do YY')}</p>
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td className='text-danger '>
                                                                        {(() => {
                                                                            const paymentDate = new Date(x.payment_date);
                                                                            const differenceIn_Time = new Date().getTime() - paymentDate.getTime();
                                                                            const difference_InDays = Math.floor(differenceIn_Time / (1000 * 3600 * 24));

                                                                            if (x.amount != null) {
                                                                                return <p>{difference_InDays} Days</p>;
                                                                            } else {
                                                                                return <p className="">{differenceInDays} Days</p>;
                                                                            }
                                                                        })()}

                                                                    </td>
                                                                    <td className=''>

                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>

                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassN
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Filter */}
                    <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                            <div class="modal-content ">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body ">
                                    <p className="text-secondary">All</p>
                                    <p className="text-secondary">Overdue Days</p>
                                    <div className='row mt-4'>

                                        <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                            <button type="button" class="btn btn-pri">Filter</button>
                                        </div>
                                        <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>


        </div>
        <div>{(() => {
            switch (user_type) {
                case "view": return RenderView();
                default: return "";
            }
        })()}</div>

    </div>

    )
}
export default Superadmindues