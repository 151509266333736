import React, { useState, useEffect } from 'react';
import './EmailVerification.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import CryptoJS from 'crypto-js';

const EmailVerification = (props) => {

    const [email_title, setEmail_title] = useState([])
    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const { update, handleSubmit } = useForm();

    const generatePassword = () => {

        const randomPassword =
            Math.random().toString(6).slice(16);

        setPassword(randomPassword);
        
       
    };
    useEffect(() => {
        generatePassword();
    }, []);
    const form = useRef();

    const send_Email = (e) => {
        //  e.preventDefault();

        emailjs.sendForm('service_hrquipa', 'template_gsdw8vs', form.current, 'CG6sgr1nRYDufVqn2')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    // console.log(password, 'test');

    const date = new Date();
    const sendEmail = (x) => {
        const emailData = {

            user_email: `${x.email}`,
            user_name: `${x.first_name}`,
            message: `${password}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailv`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }
    const sendEmailadmin = (x) => {
        const emailData = {

            user_email: `${x.email}`,
            user_name: `${x.first_name}`,
            message: `${date}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailadmin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }

    const Getsignemail = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/signupemail`).then((res) => {
                console.log(res.data, 'success');
                setEmail_title(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getsignemail();
    }, []);

    const emailverify = (x) => {
        let bas = {

            //otpemail: email,
            otppassword: password,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/signupemail`, bas).then((res) => {
            console.log('user updated', res.data);
            sendEmailadmin(x);
            sendEmail(x);

            //allert();

        }).catch(() => {

        })
    }

    const [otpassword, setOTpassword] = useState("");
    const [mail, setMail] = useState("");
    const [ename, setname] = useState("");

    const onotp = (data, e) => {
        let bas = {
            //optemail: mail,
            otppassword: otpassword,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/otpemployee`, bas).then((res) => {
            console.log('valid User', res.data);

            if (res.data.length > 0) {
                gologin();

            } else {
                alert("validation failed please enter valid otp and try again.");
            }
        }).catch(() => {
            //   alert("wrong OTP");
        })
    }
    const onlogin = (data, e) => {
        let bas = {
            userid: bind_user.user_id,
            loginmail: bind_user.email,
            password: bind_user.password,
            admin: 'true',
            company: 'false',
            active_status: true,
        };


        axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
            console.log('user updated', res.data);
            checkifinleads(res?.data?.loginmail);
            // alert("Verification Done Succesfully");
        }).catch(() => {

        })
    }
    // get demo and update 
    const onUpdate_demo = (id) => {
        console.log(id, 'emial');
        let demo = {
            email: id,
            status: 'converted',
        };
        axios.put(`${process.env.REACT_APP_API_URL}/demostatusbyemail/${id}`, demo).then((res) => {
            console.log('Demo updated', res.data);

            //  gologin();
        }).catch(() => {
        })

    }
    const checkifinleads = (inputmail) => {

        demolist.forEach(item => {
            if (item.email.match(inputmail)) {
                onUpdate_demo(inputmail);
            } else {

                gologin();
            }
        });

    }
    // get demo
    const [demolist, setdemolist] = useState([])
    const Getdemo = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/demo`).then((res) => {
            console.log(res.data, 'success');
            setdemolist(res.data);

        }).catch(() => {

        })

    }
    useEffect(() => {
        Getdemo();
    }, []);

    let navigate = useNavigate();
    const gologin = () => {
        navigate("/login");
    };
    const ShowDetails = (data, type) => {
        // console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }
    const refresh = () => {
        window.location.reload();
    }
    const RenderView = () => {
        // console.log('bind', bind_user);
        return <div className='row'>
            <div className=' card comediemail'>
                <nav className=''>
                    <div class="nav mx-3  " id="nav-tab" role="tablist">
                        <div className='fw-bold text-center mt-4 text-secondary fs-4'>Please Enter the OTP code to verify your Account</div>
                        <div className='text-secondary text-center ms-sm-4'>OTP has been sent to {bind_user.email}.</div>

                    </div>
                </nav>
                <form onSubmit={handleSubmit(onotp)} action="#" method="POST">
                    <div className='row'>
                        <div className='col-sm-5 ms-5'>
                            <p className=' fw-bold mt-4 ms-3'>OTP Code</p>
                            <input type='text' onChange={(e) => { setOTpassword(e.target.value) }} className='ms-sm-3 otptext' />
                        </div>
                        <div className='col-5 ms-5 mt-5'>
                            <button className='btn btn-pri email_btn  ms-sm-3' type='submit'> Validate</button>
                        </div>
                    </div></form>
            </div>
        </div>;
    }

    return (
        <div class="bg-primary vh-100 container-fluid logincont sceen_size sceenmob">
            <div className='row'>

                <div class="circle1img"> <img className='img-fluid mobsimplytext' src={`Images/smallcrl.png`} ></img></div>

                <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
                    <img className='img-fluid logo_size ms-1 mt-2' src={`Images/logoq.png`} ></img>
                </div>
                <div className='col-sm-8 col-xxl-8  col-md-8 col-lg-8 col-xl-8'>
                    <div className='row mt-5'>
                        <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ms-5 hide_tab'> </div>
                        <div className='col-sm-8 col-xxl-8  col-md-8 col-lg-10 col-xl-8  ms-sm-4 emailtittpmob'>
                            <img className='img-fluid  float-end mobsimplytext' src={`Images/bigcircle.png`} ></img>
                            <div className="text-white fw-bold fs-2 text-center mt-5 ms-sm-5 ">Designed for a <p>global workforce.</p>
                            </div>
                            <div className='col-2'>

                            </div>
                        </div>
                    </div>

                    {email_title.map((x, index) => {
                        return (
                            <div className='mobhide text-white fs-2 mobalign mobsimplytext'>Hey {x.first_name}</div>
                        );
                    })}
                    <div className='mobhide text-white  mobalign1 text-center fs-6 w-75 mobsimplytext'>Thanks for signing-up with Symplefy! The Modern Platform for Time, Attendance and Payroll.
                        Before we get started, please confirm your email </div>
                    <div class=""> <img className='img-fluid mobemailimg mobhide mobsimplytext' src={`Images/mobemailimg.svg`} ></img></div>
                    <button type="button" class="btn btn-secondary  border-0 mobverifybtn mobhide mobsimplytext"><p className='text-primary fw-bold mt-1 fs-5'>Verify Email</p></button>
                    <div className='row'>

                        <div className='col-sm-12'>
                            <div class="card email_cardsize mx-auto">

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-3 mx-auto mt-5'>
                                            <div class=""> <img className='img-fluid' src={`Images/email.png`} ></img></div>
                                        </div>
                                    </div>
                                    {email_title.map((x, index) => {
                                        return (<div>
                                            <h5 class="card-title text-center mt-2">Hey {x.first_name}</h5>

                                            <div class="card-text text-center mt-2">Thanks for signing-up with Symplefy! The Modern Platform for Time,Attendance and Payroll.
                                                Before we get started, please confirm your email</div>
                                            <div className='row'>
                                                <div className='col-5 ms-5'>

                                                    <div class="ml-5">
                                                        <input type="text " className='hide_all_pc' name="user_name" value={x.first_name} onChange={(e) => setname(x.first_name)} />

                                                        <input type="text " className='hide_all_pc' name="user_email" value={x.email} onChange={(e) => setEmail(x.email)} />
                                                        <input type="text " className='hide_all_pc' name="message" value={password} />
                                                    </div>
                                                    <br></br>
                                                    <button class="btn btn-pri ms-2 email_btn" onClick={() => (ShowDetails(x, 'view'), emailverify(x))}>Verify Email</button>

                                                </div>
                                            </div>
                                        </div>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className='row mobsimplytext hide_tab'>

                <img className=" mx-auto image_email img-fluid d-block " src="./Images/emailback.svg" />

            </div>
            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();
                    default: return "";
                }
            })()}</div>
        </div>
    );
}
export default EmailVerification;
