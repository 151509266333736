import React, { useCallback, useState } from 'react';
import { useContext, useEffect } from 'react';
import "./Guide.css";
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
import axios from 'axios';
const Empquick = () => {

    const { state } = useContext(store);
    console.log('profile', state.user);
  const [activePage, setActivePage] = useState(0);

  const handlePageChange = (page) => {
    setActivePage(page);
  };
 
  const pages = [


    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4 col-md-1 col-lg-1   col-xl-4 col-xxl-4 '></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-black text-center'> Let's get started. First, we'll add your Personal information.</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10   col-xl-6 col-xxl-6'>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/1-personal.png`} ></img>

          </div>
        </div>
      



      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10   col-xl-6 col-xxl-6'>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>Then we'll add your Tax information. </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10   col-xl-6 col-xxl-6'>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/2-tax.png`} ></img>

          </div>
        </div>
       



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10   col-xl-6 col-xxl-6'>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>Finally we'll add your Bank details.
</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10   col-xl-6 col-xxl-6'>

            <img className='img-fluid d-grid mx-auto  im_hi' src={`Images/3-bank.png`} ></img>

          </div>
        </div>



      </div>
    </div>
  ];
  return (
    <div>
      {pages && <BottomDots activePage={activePage} onPageChange={handlePageChange} pages={pages} />}
    </div>
  );
};

function BottomDots({ activePage, onPageChange, pages }) {
  const { state } = useContext(store);
  let navigate = useNavigate();

  const goaddress = () => {
    //debugger;
    navigate("/employeedashboard");
  };
  const onUpdate = () => {
    
    axios.put(`${process.env.REACT_APP_API_URL}/updatequide/${state?.user[0]?.login_id}`, ).then((res) => {

        console.log('leave updated', res.data);
        godash()
    }).catch(() => {



    })

}

  const godash = () => {
    //debugger;
    navigate("/employeedashboard");
  };
  const [dots, setDots] = useState([]);

  useEffect(() => {
    if (pages) {
      const newDots = [];
      for (let i = 0; i < pages.length; i++) {
        newDots.push(i);
      }
      setDots(newDots);
    }
  }, [pages]);

  if (!pages) {
    return null;
  }

  return (
    <div className='bg-light'>
      <div className='row'>
        <div className="page-content">{pages[activePage]}</div>
      </div>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-3'></div>
        <div className='col-3'>
          <div className="bottom-dots-wrapper ms-5">
            <div className="bottom-dots">
              {/* <span className="arrow" onClick={() => onPageChange(activePage - 1)}>{'<'}</span> */}
              {dots.map((dotIndex) => (
                <span
                  key={dotIndex}
                  className={`dotbtm ${dotIndex === activePage ? "active" : ""}`}
                  onClick={() => onPageChange(dotIndex)}
                />
              ))}
              {/* <span className="arrow" onClick={() => onPageChange(activePage + 1)}>{'>'}</span> */}
            </div>

          </div>
        </div>

        <div className='col-1 ms-sm-5 viewtop'>
          {(() => {
            if (activePage > 1) {
              return <>
               

                   <button onClick={onUpdate} className=' btn d-grid btn-pri bottombtn mobnextbt'>Get Started</button>


                  

              </>

            }
            else {
              return <button onClick={() => onPageChange(activePage + 1)} className=' btn d-grid btn-pri bottombtn mobnextbt'>Next</button>

            }
          })()}
        </div>
        <div className='col-sm-1 viewtop  skipleft'>

          <button onClick={onUpdate} className='ms-3 d-grid btn btn-secondary bottombtn'>Skip</button> </div>


      </div>

    </div>
  );
}

export default Empquick;


