import { React, useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { store } from '../../store/user';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Remainder = () => {
    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    const { state } = useContext(store);
    console.log('company list', state.user);


    let navigate = useNavigate();
    const admin = () => {
        let path = `/admindashboard`;
        navigate(path);
    }
    const hire = () => {
        localStorage.setItem("hireAlertFlag", "false");
        admin()
    }

    const [leave_details, setUserDetails] = useState([]);


    const Getleave = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/employee_basic/${state?.user[0]?.company_id}`)
                .then((res) => {
                    console.log(res.data, 'success');
                    let temp = [];
                    let employeeNames = [];
                    res?.data?.forEach(item => {
                        if (item.contractduedate) {
                            const currentDate = new Date();
                            const contractDueDate = new Date(item.contractduedate);
                            const timeDiff = Math.abs(currentDate - contractDueDate);
                            const diffInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            console.log(diffInDays, item.emp_id, "contractalert");
                            if (diffInDays >= 1 && diffInDays <= 15) {
                                employeeNames.push(item.full_name);
                            }
                        }
                        temp.push(item);
                    });
                    setUserDetails(temp);
                    setEmployeeNames(employeeNames);
                })
                .catch(() => { });
        }
    };




    useEffect(() => {
        Getleave();
    }, [state]);





    const [employeeNames, setEmployeeNames] = useState([]);

    return (
        <div className="bg-light vh-100 container-fluid">

            <div className="row mt-5">


                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab ">


                </div>
                <div className="col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 bg-light mt-3 ">

                    <h4 className="text-center mt-2">Reminder</h4>
                    <div>
                        {employeeNames.length > 0 && (
                            <div className="text-black">
                                <div className="text-center text-secondary">The following employees' contract will expire soon. Their contract due date is within the next 15 days,<div>Please update the contract expire date in employee list.</div></div>
                                <div className="row ms-2">
                                    {employeeNames.map((name, index) => (
                                        <div className="col-2 card ms-5 text-black text-capitalize fw-bold mt-3 remindercard" key={index}><p className="mt-3">{name}</p></div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="row mt-5"></div>
                        <div className="row mt-5"></div>
                        <div className="row">
                            <div className="col-10"></div>
                            <div className="col-2">
                                <button type="submit" className="btn btn-pri w-75" onClick={hire}>OK</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Lheader />

        </div>

    )
}
export default Remainder