import { React, useState, useEffect, useContext } from "react";
import Superheader from "./Superheader";
import Superside from "./Superside";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import Leaddetails from "./Leaddetails";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
const Superdemo = () => {

    const [demolist, setdemolist] = useState([])
    const Getdemo = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/demo`).then((res) => {
            console.log(res.data, 'success');
            setdemolist(res.data);

        }).catch(() => {

        })

    }




    useEffect(() => {
        Getdemo();

    }, []);

    let navigate = useNavigate();
    const lead = () => {
        let path = `/superleads`;
        navigate(path);
    }
    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setUserType(type);


    }
    const onUpdate_open = () => {
        let demo = {
            demo_id: bind_user.demo_id,
            status: "Open",
        };
        axios.put(`${process.env.REACT_APP_API_URL}/demostatus/${bind_user.demo_id}`, demo).then((res) => {

            console.log('Demo updated', res.data);
            ShowDetails('');
            Getdemo();
        }).catch(() => {



        })

    }
    const onUpdate_lost = () => {
        let demo = {
            demo_id: bind_user.demo_id,
            status: "close",
        };



        axios.put(`${process.env.REACT_APP_API_URL}/demostatus/${bind_user.demo_id}`, demo).then((res) => {

            console.log('Demo updated', res.data);



            ShowDetails('');
            Getdemo();
        }).catch(() => {



        })

    }
    const update = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Select update option',
            buttons: [
                {
                    label: 'Open',
                    class: 'btn btn-success',
                    onClick: () => onUpdate_open()

                },
                {
                    label: 'Lost',
                    class: 'btn btn-danger',
                    onClick: () => onUpdate_lost()
                }
            ]
        })
    }
    const RenderView = () => {
        return (
            <div className="container-fluid row sprender">
                <div className="col-9  col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 posit">

                </div>
                <div className="col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  bg-white">
                    <div className="row ms-3 mt-4 ">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" onClick={() => ShowDetails('')} height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>

                    </div>
                    <div className="row ms-3 mt-4">
                        <small className="text-primary">Name</small>
                        <p>{bind_user.full_name}</p>


                    </div>
                    <div className="row ms-3 mt-2">
                        <small className="text-primary">Email</small>
                        <p className="text-lowercase">{bind_user.email}</p>

                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-primary">Phone Number</small>
                        <p>{bind_user.phone_no}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Company</small>
                        <p>{bind_user.comp_name}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Demo Date</small>
                        <p>{moment(bind_user.demodate).format('MMM Do YY, h:mm a')}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Order ID</small>
                        <p className="text-danger">{bind_user.order_id}</p>


                    </div>
                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Status</small>
                        {(() => {
                            if (bind_user.status == true) {
                                return <p className="text-success">Open</p>
                            } if (bind_user.status == false) {
                                return <p className="text-danger">LOST</p>
                            }
                            if (bind_user.status == null) {
                                return <p className="text-warning">Pending</p>
                            }

                        })()}


                    </div>
                    <div className="row ms-3 mt-3 ">
                        <div className="col-7 d-grid">
                            {(() => {
                                if (bind_user.status === "Open") {
                                    return <button className="btn btn-pri" onClick={update}>Convert</button>
                                } if (bind_user.status === "Lost") {
                                    return <button className="btn btn-pri" onClick={update}>Convert</button>
                                }
                                if (bind_user.status === "converted") {
                                    return <button className="btn btn-pri" disabled onClick={update}>Convert</button>
                                }
                                if (bind_user.status == null) {
                                    return <button className="btn btn-pri" onClick={update}>Convert</button>
                                }


                            })()}

                        </div>


                    </div>
                    <div className="row ms-3  mt-3  ">
                        <div className="col-7 d-grid">

                        </div>


                    </div>
                </div>
            </div>
        )
    }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = demolist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(demolist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [query_date, SetdateQuery] = useState([]);
    return (
        <>
            <div className="bg-light vh-100 row  container-fluid">




                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">

                </div>

                <div className="col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10  ">

                    <input type="text" class=" form-control grey  searchboxcrm ms-5 mt-3 " onChange={(e) => SetdateQuery(e.target.value)} placeholder="Search License,Order ID" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>


                    <div className="row">

                        <div className="col-1 ms-5">
                            <p className="fnt-clr text-center pointer_ ms-3 " onClick={lead}>Leads</p>
                        </div>
                        <div className="col-1 ">
                            <p className="fnt-clr fw-bold border-bottom border-primary border-4 text-center">Demos</p>
                        </div>
                        <div className="col-6 ms-5">

                        </div>
                        <div className="col-2">
                            <input type="date" class="form-control inputcolor d2 mt-1 ms-3" placeholder=" Month" aria-label="" onChange={(e) => { console.log(e.target.value); SetdateQuery(e.target.value) }} />


                        </div>
                    </div>

                    <div className="row ms-1">
                        <div className="card Card_1task mt-4 ms-5  ">
                            <div className="row mt-3">

                                <div className="col-2 d-grid" >

                                </div>
                                <div className="col-6 d-grid" >
                                </div>
                                <div className="col-2 d-grid" >

                                </div>
                                <div className="col-2 " >

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col">
                                        <div className='row'>


                                            <div className='col'>

                                                <table class="table table-bordered mt-2">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>  <td scope="col"></td>

                                                                <td scope="col" className='fs-6 grey fw-bold'>ID</td>

                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Customer</td>
                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'> Email  </td>
                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'> Phone No </td>
                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Demo Date  </td>
                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Status </td>
                                                                <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Convert </td>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {displayUsers.filter((x) => { console.log(moment(x.created_on).format('YYYY-MM-DD'), query_date); return (moment(x.created_on).format('YYYY-MM-DD')).includes(query_date) || x.full_name.toLowerCase().includes(query_date) }).map((x, index) => {
                                                                return (
                                                                    <tr>
                                                                        <th scope="row"><div className="ms-3 ">  </div></th>

                                                                        <td className=''><p>{x.demo_id}</p></td>
                                                                        <td className='pointer_'  > <p onClick={() => ShowDetails(x, 'view')}>{x.full_name}</p></td>
                                                                        <td className=''><p className="text-lowercase">{x.email}</p></td>
                                                                        <td className=''><p>{x.phone_no}</p></td>
                                                                        <td className=''><p>{moment(x.demodate).format('MMM Do YY, h:mm a')}</p></td>

                                                                        <td> {(() => {
                                                                            if (x.status === "Open") {
                                                                                return <p className="text-success">Open</p>
                                                                            } if (x.status === "Lost") {
                                                                                return <p className="text-danger">LOST</p>
                                                                            }
                                                                            if (x.status == null) {
                                                                                return <p className="text-warning">Pending</p>
                                                                            } else {
                                                                                return <p className="text-success">converted</p>
                                                                            }

                                                                        })()}
                                                                        </td>
                                                                        <td className=''>
                                                                            {(() => {

                                                                                if (x.status == null) {
                                                                                    return <svg xmlns="http://www.w3.org/2000/svg" onClick={() => ShowDetails(x, 'view')} width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right fw-bold ms-3" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                    </svg>
                                                                                } else {
                                                                                    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right fw-bold ms-3" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                    </svg>
                                                                                }

                                                                            })()}
                                                                        </td>

                                                                        <td className=''><svg id="dropdownMenuButton1 " data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />

                                                                        </svg></td>

                                                                    </tr>

                                                                );
                                                            })}




                                                        </tbody>

                                                    </table>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"paginationBttns"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"paginationDisabled"}
                                                    activeClassName={"paginationActive"}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                {/* Filter */}
                <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                        <div class="modal-content ">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body ">
                                <p className="text-secondary">All</p>
                                <p className="text-secondary">Pending</p>
                                <p className="text-secondary">Processed</p>
                                <p className="text-secondary">Canceled</p>
                                <div className='row mt-4'>

                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                        <button type="button" class="btn btn-pri">Filter</button>
                                    </div>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();


                    default: return "";
                }
            })()}</div>
        </>




    )
}
export default Superdemo