import { React, useState, useEffect, useContext } from "react";
import "./Reports.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const ReportsDashboard = () => {

    let navigate = useNavigate();
    const incometax = () => {
        let path = `/incometax`;
        navigate(path);
    }
    const insurancedetails = () => {
        let path = `/insurancedetails`;
        navigate(path);
    }
    const payrollcost = () => {
        let path = `/payrollcost`;
        navigate(path);
    }
    const payrolltake = () => {
        let path = `/payrolltake`;
        navigate(path);
    }

    const emplist = () => {
        let path = `/employeereport`;
        navigate(path);
    }
    const empenc = () => {
        let path = `/reportencash`;
        navigate(path);
    }

    return (
        <div className='bg-light vh-100 container-fluid logscr logscre'>
            <div className='row mt-4'>
                <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2 hid_tab'>

                </div>
                <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3 col-sm-10 mt-4 tbl'>
                    
                    <div class="card card ms-sm-5 shadow mb-5 p-2 bg-white rounded mt-4">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-sm-11'>
                                <p className=" fs-4 ms-sm-1 fnt-clr">Reports</p>
                                </div>

                                <div className="row ">

                                    <div className="col-sm-4 ">
                                        <div className="card text-center repsmallcard ">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-3"></div>
                                                <p className="card-text fw-bold mb-sm-0 greyh pointer_" onClick={payrollcost}>Total Payroll <p>Cost Details</p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card text-center repsmallcard  ms-sm-3 crdb">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-2"></div>
                                                <p className="card-text fw-bold mb-0 greyh pointer_" onClick={payrolltake}>Employee <p>Take Home Pay<p>Summary</p></p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card text-center repsmallcard  ms-sm-3 crdb">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-2"></div>
                                                <p className="card-text fw-bold mb-0 greyh pointer_" onClick={incometax}>Employee <p>Income Tax<p>Summary</p></p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-4">
                                   
                                    <div className="col-sm-4">
                                        <div className="card text-center repsmallcard  ">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-2"></div>
                                                <p className="card-text fw-bold mb-0 greyh pointer_" onClick={insurancedetails}>Social,Health  <p>& Unemployment<p>Insurance Details</p></p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card text-center repsmallcard  ms-sm-3 crdb">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-2"></div>
                                                <p className="card-text fw-bold mb-0 greyh pointer_" onClick={emplist}>Employee <p>Listing</p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card text-center repsmallcard  ms-sm-3 crdb">
                                            <div className="card-body">
                                                <div className="row mt-5"></div>
                                                <div className="row mt-2"></div>
                                                <p className="card-text fw-bold mb-0 greyh pointer_" onClick={empenc}>Employee <p>Encashment</p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <Lheader />

        </div>
    )
}
export default ReportsDashboard