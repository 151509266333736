import React from "react";
import "./Header.css";
import { useCallback, useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Workshedule from "../Workshedule/Workshedule";
const Header = () => {
    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    let navigate = useNavigate();

    const companyaddress = () => {
        let path = `/companyaddress`;
        navigate(path);
    }
    const Workshedule = () => {
        let path = `/workshedule`;
        navigate(path);
    }
    const addemployee = () => {
        let path = `/baseprofile`;
        navigate(path);
    }
    const logOut = () => {

        if (islogged == true) {
            localStorage.removeItem('user_details');
            setUser(false);
            goLogin();
        }
        else {
            localStorage.setItem('user_details');
        }
    }

    const goLogin = () => {
        navigate("/");

    };
    return (
        <div className=" ">
            <div className="row mobsimplytext container-fluid fix ">
                <div className="col-2 hid_tab"></div>
                <div className="col-10">
                    <div className="row" id="header">
                        <div className="col"  >

                            <ul class="nav nav-tabs headeralignment headerposition" >

                                <li class="nav-item ms-2">
                                    <a class="nav-link fw-bold text-primary" aria-current="page" onClick={companyaddress}><img className="" src="./Images/Cpyinfo.png" onClick={companyaddress}></img> Company Information</a>
                                </li>
                                <li class="nav-item headerposition_i">
                                    <a class="nav-link fw-bold text-primary" onClick={Workshedule}><img className="" src="./Images/Work.png" onClick={Workshedule}></img>Work Schedules</a>
                                </li>
                                <li class="nav-item headerposition_i">
                                    <a class="nav-link fw-bold text-secondary" onClick={addemployee}><img className="" src="./Images/Account.png" onClick={addemployee}></img>Add Employes</a>
                                </li>
                                <li class="nav-item dropdown profile_icon hid_tab">
                                    <div className="mt-2 ms-5"> <img src={`Images/u5.svg`} style={{ height: 40 }} ></img> </div>
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    </a>
                                    <ul class="dropdown-menu dropleft" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" href="#">Profile</a></li>
                                        <li><a class="dropdown-item mb-4" href="" onClick={() => { logOut() }}>Logout</a></li>

                                    </ul>
                                </li>

                            </ul>

                        </div>

                    </div>


                </div>

            </div>

            <nav class="navbar mobhide  navbar-light bg-primary container-fluid fix ">

                <span class="navbar-brand mb-0 h5 text-white ms-5"> Overview</span>
                <span>

                </span>

                <span class="navbar-brand mb-0 h5  "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class=" ms-1 bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="white" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>

                </span>


            </nav>
        </div>
    )
}
export default Header