import React from 'react'
import "./Login/Login.css"
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { store } from '../store/user';

const Password= () =>{
  const [loginuser, setloginuser] = useState('');
  const [user_details, setuserDetails] = useState('');


    let navigate = useNavigate();
    const Gosignin = () => {
        //debugger;
        navigate("/signup");
    };

    const gohome = () => {
      //debugger;
      navigate("/admindashboard");
  };
  const login = () => {
    let path = `/login`;
    navigate(path);
}
  const globalState = useContext(store);
  const { dispatch } = globalState;
// login method update data 
    const Getloginuser = () => {
       // console.log(type, 'success');
        setuserDetails('');
        localStorage.setItem('user_details',JSON.stringify('symplefy'));
        dispatch({ type: 'ADD_USER', payload: { user: 'symplefy'} });//context api updated.  
        gohome();  

    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem('user_details');
        console.log('loggedInUser', loggedInUser);
        if (loggedInUser != null) {
            gohome();
        }
    }, []);


  return (

    <div className='container-fluid logincont'>
      <div className='row hide_all'>
        <div className='col-7 loginback'>
          <div className='row'>
           
            <div className='col-2'>
              <div className="text-white fw-bold fs-1 mt-4 ms-4 ">Symplefy</div>
            </div>
            <div className='new'>
              <div className="text-white fw-bold fs-1 text-center headn ">Designed for a <p>global workforce.</p></div>
              <div class="circleimg1"><img className='img-fluid' src={`Images/Ellipse12.svg`} ></img></div>
              <div class="circleimg2"><img className='img-fluid' src={`Images/Ellipse12.svg`} ></img></div>
            </div>
            <div className='loginbackground'>
              <img className='loginimg' src={`Images/Frame.svg`} ></img>
            </div>
          </div>
        </div>
        <div className='col-5 loginht'>
          <div className='row'>
          <div className="headings"><label className='fw-bold'>Reset Password</label>
          </div>
            <div className='div3 position-relative'>
             <form>
             <div class="ml-5">
            <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Email*</label>
            <input type="email" class=" txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp"/>
            </div>
            <br></br>
            <div class="mt-3">
           <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Password*</label>
           <input type="email" class="  txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp"/>        
           </div>
           <div class="mt-3">
           <input type="email" class="  txtstyle mt-3 ms-5" id="exampleInputEmail1" aria-describedby="emailHelp"/>        
           <label for="exampleInputEmail1" class="form-label  widd"> Confrim Password*</label>
          
           </div>
           <div class="mt-3">
                          
           </div>
           <p></p>
           <div class="loginbtn">
           <button type="submit" class="btn btn-pri btnc ms-2  " onClick={() => login()}>Save Password</button>                  
           </div>
             </form>
             </div>            
          </div>
         
        </div>
      </div>
<div className='hide_all_pc'>
<div className='row bg-primary'> 
<div className="text-white sym  fw-bold fs-1 bg-primary ">Symplefy</div>
            
<img className=' logbg bg-primary' src={`Images/logbg.svg`} ></img>  
<img className=' log' src={`Images/log.svg`} ></img>
     </div>
      <div className='row'>
      <div className=''>
     
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate icn_lg" viewBox="0 0 16 16">  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/></svg>
           
          <div className="headings"> <label className='headlanguage'>Change Language</label>
          <label className='headeng'>English</label>
          </div>
            <div className='div3 position-relative'>
             <form>
             <div class="ml-5">
            <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Email*</label>
            <input type="email" class=" txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp"/>
            </div>
            <br></br>
            <div class="mt-3">
           <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Password*</label>
           <input type="email" class="  txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp"/>        
           </div>
           <div class="mt-3">
           <input type="checkbox" class="form-check-input chekb" id="exampleCheck1"/>
            <label class="form-check-label lblremeber" for="exampleCheck1">Remember</label>                   
           </div>
           <p></p>
           <div class=" login_btn">
           <button type="submit" class="btn btn-pri btnc  " onClick={() => Getloginuser()}>Log In</button>                  
           </div>
             </form>
             </div>            
          </div>
        
      </div>
</div>
    </div>
  )
}

export default Password
