import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';


const Weekdays = () => {
    const { state } = useContext(store);
    console.log('week', state.user);
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const clickdelete = (id) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }
    const clickupdate = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdate(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update')
                }
            ]
        })
    }
    const { update, handleSubmit } = useForm();
    const [name, setweek] = useState("");
    const [week_details, setUserDetails] = useState([]);
    const [editdept, set_department] = useState("");
    const [bind_user, setBindUser] = useState({});

    const [user_type, setUserType] = useState('');

    const Getweek = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/weekdays/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        Getweek();
    }, [state]);
    const onInsert = (data, e) => {

        let weekd = {

            weekdays: bname,
            user_id: state?.user[0]?.user_id,
            company_id: state?.user[0]?.company_id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/weekdays`, weekd).then((res) => {
            console.log('user updated', res.data);
            //Getweek();
            window.location = "/weekdays";
        }).catch(() => {

        })
    }
    const Delete = (id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/weekdays/` + id).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/weekdays";
                if (res.data == true) {

                }
                Getweek();

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onUpdate = (id, e) => {
        let upcur = {
            id: bind_user.id,
            weekdays: editdept,


        };

        axios.put(`${process.env.REACT_APP_API_URL}/weekdays/${bind_user.id}`, upcur).then((res) => {

            console.log('department updated', res.data);
            ShowDetails('');
            Getweek();
        }).catch(() => {



        })

    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
      });
      const bname = watch('fname')
      
      const isValid = bname 
    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(clickupdate)} action="#" method="POST">
                            <div class="input-group">
                                <input type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.weekdays} onChange={(e) => set_department(e.target.value)} placeholder="currency" aria-label="Username" />
                            </div>

                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(clickupdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">Update</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>Cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        set_department(data.weekdays);
        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    return (
        <div className='bg-light vh-100 container-fluid'>
            <div class="row">
                <div class="col-2">
                    <div >
                        <div className='setting_side'>
                            <p className="ms-5 fs-4 fw-bold mt-3">Settings</p>

                            <ul className='pointer_'>

                                {/* <div className='' onClick={bank}>Bank </div> */}
                                <div className='mt-2 fw-bold' onClick={weekday}>Work-Schedule </div>
                                <div className='mt-2 ' onClick={loc}>Work Location </div>

                                <div className='mt-2' onClick={dept}>Department </div>





                                <div className='mt-2' onClick={job}>Job Title </div>


                                {/* <div className='mt-2' onClick={tax}>Tax </div> */}


                                {/* <div className='mt-2' onClick={national}>Nationality </div> */}


                                <div className='mt-2' onClick={salary}>Salary </div>


                                <div className='mt-2' onClick={holidays}>Holidays </div>
                                <div className='mt-2' onClick={currency}>Currency </div>
                               
                                <div className='mt-2' onClick={financial}>Financial year </div>
                                {/* <div className='mt-2' onClick={shift}>Shift </div> */}
                                <div className='mt-2' onClick={Leavetype}>LeaveType </div>
                                <div className='mt-2' onClick={overtype}>Overtime Rate </div>
                                {/* <div className='mt-2' onClick={ins}>Insurance </div> */}
                                {/* <div className='mt-2 ' onClick={usrtyp}>User Type </div> */}
                                {/* <div className='mt-2' onClick={ipp}>IP White Listing </div> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='row mt-3'>
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'></div>
                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <p className=' mt-5 fs-5 fw-bold '> Week-Days</p>
                        <div className='row '>
                            <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <div class="input-group">
                                    <input type="text" class=" form-control inputcolor textbox-work  mt-3" Value={bname} onChange={(e) => setweek(e.target.value)} placeholder="week Days" aria-label="Username" 
                                     name="fname"
                                     className={` form-control inputcolor txtaddr ${errors.bname &&
                                         " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                     {...register("fname", {
                                         required: {
                                             value: true,
                                             message: ' *Data Required'
                                         },
                                         minLength: {
                                             value: 3,
                                             message: "Please enter Full Name",
                                         },
                                         maxLength: {
                                             value: 60,
                                             message:
                                                 "Maximum allowed length is 40 characters ",
                                         },
                                         pattern: {
                                             value: /^[A-Z0-9.-]{2,}$/i,
                                             message: 'Please enter  Proper Name'
                                         },
                                     })} /> 
                                     <div>
                            {errors.bname && <span className=' text-danger mt-5 ms-3'>{errors.bname.message}</span>}
                          </div>
                                </div>
                                {/* <p className='role_name text-center'> week Days Name</p> */}
                            </div>
                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-75 ms-5 setbtn "  disabled={!isValid} >Add</button>
                                </form>
                            </div>
                        </div>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'>Weekdays</td>

                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Created Date</td>
                                        <td scope="col" ></td>
                                        <td scope="col" ></td>
                                        <td scope="col" className=''>Edit </td>
                                        <td scope="col" className=''>Delete </td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {week_details.map((x, index) => {
                                        return (
                                            <tr key={index}>

                                                <td>{x.weekdays}</td>

                                                <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY, h:mm:ss a')}</td>


                                                <td></td>

                                                <td></td>

                                                <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3" viewBox="0 0 16 16">

                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />

                                                </svg></td>

                                                <td><svg onClick={() => clickdelete(x.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">

                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                                                </svg></td>
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>



                
            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />

        </div>
    )
}
export default Weekdays