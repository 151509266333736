import React, { useCallback, useState } from 'react';
import { useContext, useEffect } from 'react';
import "./Guide.css";
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';

const Guidecm = () => {


  const [activePage, setActivePage] = useState(0);

  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const pages = [


    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4 col-md-1 col-lg-1   col-xl-4 col-xxl-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-black text-center'> Let's get started. First, we'll add your basic company information.</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4 '></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/c_m.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/cmbotm.png`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'> Next, we'll add your work schedule. You can add as many as you need. They form the basis for your leave and overtime calculations. For example, you can have one for the office staff and one for your factory staff to cover your different work schedules.</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/gdwk.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdwkb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>Then, we'll add work locations. These are your office locations where the employees work.

Why do we ask this? It's because employees can only clock-in and clock-out if they are at the office. Our smart-phone enabled attendance functionality ensures that the employee is on-site when they to clock-in; otherwise, they cannot.

If you have remote employees, we handle this too with our work-from-home option
</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto  im_hi' src={`Images/gdwkl.svg`} ></img>

          </div>
        </div>
        <div className='col-sm-2 hide_tab'>
        <img className='img-fluid d-grid mx-auto gbtmimg1 ' src={`Images/gdlcb.svg`} ></img>
</div>


      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>Lastly, we'll add your employees. You can add all of the employee information yourself or you can add the basic details yourself and then provide access to your employees to complete their personal details. It's up to you!</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/gdae.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdaeb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>Once your employees are set-up, you're ready to go. You can manage employee leave and attendance...all online... and it integrates with payroll automatically</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid  mx-auto im_hi ' src={`Images/gdema.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdatb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>You can now put your managers in charge of reviewing and approving their teams' time, attendance and leave.... the approvals are tracked so no more wrangling spreadsheets. The record is there for all to see </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/gdlm.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/mglvb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>Quick Start Guide</h1>

            <div className='text-center text-black'>And, of course you need to run payroll. With time, attendance and leave completely integrated, payroll is a snap. Instant sync and you're ready for final review.

We automatically calculate approved overtime and store wage records to help you achieve compliance.

So if you're ready, let's get started!</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/myrp.png`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdrvb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
  ];
  return (
    <div>
      {pages && <BottomDots activePage={activePage} onPageChange={handlePageChange} pages={pages} />}
    </div>
  );
};

function BottomDots({ activePage, onPageChange, pages }) {
  const { state } = useContext(store);
  let navigate = useNavigate();

  const goaddress = () => {
    //debugger;
    navigate("/companyaddress");
  };

  const godash = () => {
    //debugger;
    navigate("/");
  };
  const [dots, setDots] = useState([]);

  useEffect(() => {
    if (pages) {
      const newDots = [];
      for (let i = 0; i < pages.length; i++) {
        newDots.push(i);
      }
      setDots(newDots);
    }
  }, [pages]);

  if (!pages) {
    return null;
  }

  return (
    <div className='bg-light'>
      <div className='row'>
        <div className="page-content">{pages[activePage]}</div>
      </div>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-3'></div>
        <div className='col-3'>
          <div className="bottom-dots-wrapper ms-5">
            <div className="bottom-dots">
              {/* <span className="arrow" onClick={() => onPageChange(activePage - 1)}>{'<'}</span> */}
              {dots.map((dotIndex) => (
                <span
                  key={dotIndex}
                  className={`dotbtm ${dotIndex === activePage ? "active" : ""}`}
                  onClick={() => onPageChange(dotIndex)}
                />
              ))}
              {/* <span className="arrow" onClick={() => onPageChange(activePage + 1)}>{'>'}</span> */}
            </div>

          </div>
        </div>

        <div className='col-1 ms-sm-5 viewtop'>
          {(() => {
            if (activePage > 5) {
              return <>
                {(() => {
                  if (state.user[0].company == true) {
                    return <>
                      <button onClick={godash} className=' btn d-grid btn-pri bottombtn mobnextbt'>Done</button>


                    </>
                  }

                  else {
                    return <button onClick={goaddress} className=' btn d-grid btn-pri bottombtn mobnextbt'>Get Started</button>


                  }
                })()}

              </>

            }
            else {
              return <button onClick={() => onPageChange(activePage + 1)} className=' btn d-grid btn-pri bottombtn mobnextbt'>Next</button>

            }
          })()}
        </div>
        <div className='col-sm-1  viewtop skipleft'>

          <button onClick={goaddress} className='ms-3 d-grid btn btn-secondary bottombtn'>Skip</button> </div>


      </div>

    </div>
  );
}

export default Guidecm;


