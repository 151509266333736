import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
const Logino = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [manualPage, setManualPage] = useState(1);
  let navigate = useNavigate();
 
const goaddress = () => {
    //debugger;
    navigate("/companyaddress");
  };
  const manual = [
<div className='row '> 
     <div className='col'>
        <p className='text-dark fw-bold'>Step 1: 
            <p>Add Company<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" class=" ms-4 me-2 bi bi-bank" viewBox="0 0 16 16">
                                                                  <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
                                                              </svg></p></p>
    <img className= "img-fluid notice_img" src={`Images/cmpin.png`} ></img>
         
        </div></div>,
        <div className='row '> 
     <div className='col'>
        <p className='text-dark fw-bold'>Step 2: 
            <p>Add Work Schedule  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock " viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                      </svg></p></p>
    <img className= "img-fluid notice_img" src={`Images/wkcm.png`} ></img>
         
        </div></div>,
        <div className='row '> 
        <div className='col'>
           <p className='text-dark fw-bold'>Step 3: 
               <p>Add Work Location <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
         <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
         <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
       </svg></p></p>
       <img className= "img-fluid notice_img" src={`Images/wkcml.png`} ></img>
            
           </div></div>,
        <div className='row'> 
        <div className='col'>
        <p className='text-dark fw-bold'>Step 4:
        <p> Add Employees <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people  " viewBox="0 0 16 16">
                                                                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                              </svg></p></p>
        <img className= "img-fluid  notice_img" src={`Images/qem.png`} ></img>
           </div></div>,
           <div className='row'> 
           <div className='col'>
              <p className='text-dark fw-bold'>Step 5:
                <p> Give self portal access to employee</p></p>
                <img className= "img-fluid notice_img" src={`Images/ss.png`} ></img>
              </div></div>,
                <div className='row'> 
                 <div className='col'>
                    <p className='text-dark fw-bold'>Step 6:
                      <p>Manage Employees Leave  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentcolor" class="  bi bi-clipboard-minus" viewBox="0 0 16 16">
                                                          <path fill-rule="evenodd" d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
                                                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                      </svg> </p></p>
                      <img className= "img-fluid notice_img" src={`Images/leavemanag.png`} ></img>
                    </div></div>,
                    <div className='row'> 
                    <div className='col'>
                       <p className='text-dark fw-bold'>Step 7:
                         <p>Manage Employee Timesheet  </p></p>
                         <img className= "img-fluid notice_img" src={`Images/timemanag.png`} ></img>
                       </div></div>,
                        <div className='row'> 
                        <div className='col'>
                           <p className='text-dark fw-bold'>Step 8:
                             <p>Run Pay Roll</p></p>
                             <img className= "img-fluid notice_img" src={`Images/qrn.png`} ></img>
                           </div></div>,
               <div className='row'> 
               <div className='col-3'></div>
               <div className='col-6'>  
               <div className='row'><p className='text-dark  text-center fw-bold'>Completed
                    </p></div>
               <div className='row'>
                   <buttton class='btn btn-pri' onClick={goaddress}>Get Started</buttton></div>
                </div>
               <div className='col-2'></div>
               </div>,
      
    
  ];
  
  const nextPage = () => {
    setManualPage(manualPage + 1);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };
  
  return (
    <div className=" bg-white" >
    <Modal show={modalIsOpen} onHide={goaddress} className='' >
      <Modal.Header className='my_mod' closeButton >
        <Modal.Title><p className='text-center'>Quick Guide</p></Modal.Title>
      </Modal.Header>
      <Modal.Body className='my_mod'>{manual[manualPage - 1]}</Modal.Body>
      {manualPage > 7 ? (
        <Modal.Footer className='my_mod'>
         
          <Button variant="" className='btn-pri' onClick={goaddress}>
            Get Started
          </Button>
        </Modal.Footer>
      ) : (
        <>
        <Modal.Footer className='my_mod'>
          <Button variant="secondary" onClick={nextPage}>
            Next
          </Button>
          <Button variant="secondary" onClick={goaddress}>
            Skip
          </Button>
        </Modal.Footer>
        </>
      )}
    </Modal>
  </div>
  
  );

};

export default Logino;
