import React from 'react'
import CryptoJS from 'crypto-js';
import { useEffect, useContext, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { store } from '../../store/user'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useRef } from 'react';
const Sverify = () => {

  const [user, setUserId] = useState([]);

  const [otpverified, setverified] = useState();
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [inputOtp, setInputOtp] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const location = useLocation();
  const { state } = useContext(store);
  const [sndotp, setsndotp] = useState(false);
  console.log('payroll', location?.state?.x[0].loginid);
  const [stdate, startdate] = useState('');
  const [user_details, setuserDetails] = useState([]);




  let navigate = useNavigate();
  const goadh = () => {
    //debugger;
    navigate("/superadmindashboard");
  };
  const goland = () => {
    //debugger;
    navigate("/");
  };

  const globalState = useContext(store);
  const { dispatch } = globalState;
  const login = () => {

    setuserDetails(location?.state?.x);
    localStorage.setItem('user_details', JSON.stringify(location?.state?.x));
    localStorage.setItem('username', location?.state?.x[0].loginid);
    setUserId(location?.state?.x[0].admin_id );
    console.log(location?.state?.x[0].admin_id ,'login_id')
    dispatch({ type: 'ADD_USER', payload: { user: location?.state?.x } }); 
     goadh();
  



  }



  const generateOtp = () => {
    setsndotp(true);

    send_Email();

  };


  useEffect(() => {
    setGeneratedOtp(Math.floor(100000 + Math.random() * 900000).toString());
  }, []);


  const form = useRef();
  const handleChange = (event) => {
    setInputOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputOtp !== generatedOtp) {
      setError('OTP is incorrect');
      setIsValid(false);
    } else {
      setError('');
      login();
      setIsValid(true);

    }
  };



  // send email to back end
  const send_Email = () => {
    const emailData = {
      user_email: `${location?.state?.x[0].loginid}`,
      user_name: `${location?.state?.x[0].first_name}`,
      message: `${generatedOtp}`,
    };
    const secretKey = 'mysecretkey';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

    fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: encryptedData }),
      // body: JSON.stringify(emailData),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }
  return (



    <div class="bg-primary container-fluid sceen_size">
      <div className='row'>

        <div class="circle1img"> <img className='img-fluid' src={`Images/smallcrl.png`} ></img></div>

        <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
          <div className="text-white fw-bold fs-1 mt-4 ms-4 ">
            <img className='img-fluid logo_size' src={`Images/logoq.png`} ></img>
            <img className='img-fluid mt-5' src={`Images/Ellipse12.svg`} ></img>
          </div></div>
        <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
          <div className='row mt-5'>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ms-5'> </div>
            <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-5 ms-4'>
              <img className='img-fluid  float-end' src={`Images/bigcircle.png`} ></img>
              <div className="text-white fw-bold fs-2 text-center mt-5 ms-5">Designed for a <p>global workforce.</p>
              </div>
              <div className='col-2'>

              </div>
            </div>
          </div>




          <div class=""> <img className='img-fluid mobemailimg mobhide' src={`Images/mobemailimg.svg`} ></img></div>
          <button type="button" class="btn btn-secondary  border-0 mobverifybtn mobhide"><p className='text-primary fw-bold mt-1 fs-5'>Verify Email</p></button>
          <div className='row'>

            <div className='col-12'>
              <div class="card email_cardsize_1 mx-auto">

                <div class="card-body">
                  <div className='row'>
                    <div className='col-3 mx-auto mt-1'>
                      <div class=""> <img className='img-fluid' src={`Images/email.png`} ></img></div>
                    </div>
                  </div>
                  <div>
                    <h5 class="card-title text-center mt-4">Hey </h5>

                    <p class="card-text text-center mt-2">Account Verification required  <p>Please Verify Your account Using OTP </p></p>
                    <div className='row'>
                      <div className='col'>

                        <div class="ml-5">

                          <form ref={form} action="#" method="POST">
                            <input type="text " className='hide_all_pc' name="user_name" value={location?.state?.x[0].first_name} />

                            <input type="text " className='hide_all_pc' name="user_email" value={location?.state?.x[0].loginmail} />
                            <input type="text " className='hide_all_pc' name="message" value={generatedOtp} />
                          </form>

                        </div>
                        <br></br>
                        {(() => {
                          if (sndotp == false) {
                            return <div className='row ' >
                              <div className='col-1'></div>
                              <div className='col-6'><button class="btn btn-pri ms-1 mt-2 email_btn" onClick={generateOtp} type='submit' >Verify Email</button>

                              </div>
                              <div className='col-3'></div>

                            </div>

                          }
                          if (sndotp == true) {
                            return <form onSubmit={handleSubmit}>
                              <div className='row ' >
                                <div className='col-3'></div>
                                <div className='col-6'><input type="text" className=' form-control inputcolor' value={inputOtp} onChange={handleChange} />
                                </div>
                                <div className='col-3'></div>

                              </div>
                              <div className='row '>
                                <div className='col-4'></div>
                                <div className='col-3'> {error && <p className=' text-danger'>{error}</p>}
                                  <button type="submit" className=' mt-3 ms-3 btn btn-pri'>Submit</button>
                                  {isValid && <p>OTP is valid</p>}</div>
                                <div className='col-3'></div>

                              </div>

                            </form>

                          }
                        })()}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className='row'>

        <img className=" mx-auto image_email img-fluid d-block" src="./Images/emailback.svg" />

      </div>

    </div>

  );
}

export default Sverify;