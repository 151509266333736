import React, { useCallback, useState, useEffect } from 'react';
import './PersonalDetails.css';
import { useNavigate, useLocation } from "react-router-dom";
import AEHeader from '../Header/AEHeader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';


const PersonalDetails = (props) => {

    const location = useLocation();
    console.log('emp_id: ', location?.state?.x?.emp_id);
    console.log('wagetype: ', location?.state?.x?.wagetype);

    const [countr, setcountryDetails] = useState([]);


    const Getcountry = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getcountry();
        Getvcitylist();
        Getcountryphonecode();
    }, []);


    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const emptax = (x) => {
        let path = `/tax`;
        navigate(path, { state: { x: x } });
    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => employee(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Canceled')
                }
            ]
        })
    }
    const alert = (x) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Data saved succesfully yes to continue to tax',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => emptax(x),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'Exit',
                    onClick: () => employee()
                }
            ]
        })
    }
    const { update, handleSubmit } = useForm();
    const [passport, setpassport] = useState("");
    const [dateofbirth, setdateofbirth] = useState("");
    const [gender, setgender] = useState("");
    const [maritalstatus, setmaritalstatus] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("671");
    const [state, setstate] = useState("");
    const [postalcode, setpostalcode] = useState('');
    const [phone, setphone] = useState('');
    const [loc, setloc] = useState("");
    const [country, setcountry] = useState("");
    const [phcod, setphcode] = useState("+84");

    const onInsert = (empId) => {

        let basic = {
            emp_id: location?.state?.x?.emp_id,
            passport: passport,
            dateofbirth: dateofbirth,
            gender: gender,
            maritalstatus: maritalstatus,
            address: address,
            country: country,
            city: city,
            state: state,
            postalcode: postalcode,
            location: loc,
            phone: phone,
            createdby: 1,
            phncode: phcod
        };


        axios.post(`${process.env.REACT_APP_API_URL}/Employee_personal`, basic).then((res) => {
            console.log('user updated', res.data);

            alert(location?.state?.x);

        }).catch(() => {

        })
    }


    // handle disabled submit
    const isValid = dateofbirth && country && passport && phone.length > '6'

    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    useEffect(() => {
        Getvprovincelist();
    }, [city]);
    const [vprovincelist, setvprovincelist] = useState([]);
    const Getvprovincelist = () => {
        if (city != null) {
            axios.get(`${process.env.REACT_APP_API_URL}/vprovince/${city}`).then((res) => {
                console.log(res.data, 'provi');
                setvprovincelist(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [countrphcode, setcountryDetailsphcode] = useState([]);


    const Getcountryphonecode = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country_phonecode`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetailsphcode(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    return (
        <div className='bg-light container-fluid'>

            <div className='row mt-1'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                </div>

                <div className='col-10 col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 mt-5 tabcl mob_bs'>

                    <div className='row mt-2'>
                        <div className='col-3'></div>

                    </div>
                    <div className='row mt-5 border-bottom scroll-containerbasic'>
                        <div className='col-1  col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'></div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><img className='' src={`Images/base.svg`} ></img></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-secondary text-tabcolor  " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">Basic Details</a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="me-2 bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" class="bi bi-coin  " viewBox="0 0 16 16">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                </svg></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link text-secondary text-tabcolor   " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">Salary Details </a>

                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-2  col-xxl-2  col-md-2 col-lg-2 col-xl-2'><img className='' src={`Images/personal.svg`} ></img></div>
                                <div className='col-8'><a class="nav-item nav-link   border-bottom border-primary border-3 " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">PersonalDetails</a>

                                </div>
                                <div className='col-1 '>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill  " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-2 col-xxl-2 col-md-2 col-lg-2 col-xl-1'></div>
                                <div className='col-3 col-xxl-2 col-md-2 col-lg-2 col-xl-2'> <img className='' src={`Images/tax.svg`} ></img></div>
                                <div className='col-4'> <a class="nav-item nav-link  text-tabcolor text-secondary" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">Tax </a>

                                </div>
                                <div className='col-2 me-3 taxtick'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill me-5 " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3'><img className='img-fluid' src={`Images/pay_1img.svg`} ></img></div>
                                <div className='col-5'><a class="nav-item nav-link  text-tabcolor   text-secondary " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">Payment  </a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                                <div className='col-2'></div>
                            </div>
                        </div>



                    </div>
                    <div className='row bastextmob'>
                        <div className='col-3 col-xxl-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 '></div>
                        <div className=' col-xxl-5 col-sm-5 col-md-7 col-lg-7 col-xl-5 ms-3 '>
                            <div className='row '>
                                <div className='col-6'>
                                    <small className='ms-1 required'>Passport/National ID</small>
                                    <input type="text" minlength="1" maxlength="30" class="form-control inputcolor text_box_size " Value={passport} onChange={(e) => setpassport(e.target.value)} placeholder="Passport/National ID" aria-label="Username" />

                                </div>
                                <div className='col-6'>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput" class="required ms-1">Date of Birth</label>
                                        <input type="date" class="form-control inputcolor  text_box_size " Value={dateofbirth} onChange={(e) => setdateofbirth(e.target.value)} placeholder="Date of Birth *" id="exampleFormControlInput1" />
                                    </div>
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <select class="form-select inputcolor text-start text_box_size" Value={gender} onChange={(e) => setgender(e.target.value)} aria-label=" select Day">
                                        <option selected>Gender </option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Transgender</option>
                                    </select>
                                </div>
                                <div className='col-6'>
                                    <select class="form-select inputcolor text-start   text_box_size" Value={maritalstatus} onChange={(e) => setmaritalstatus(e.target.value)} aria-label=" select Day">
                                        <option selected>Marital Status </option>
                                        <option value="1">Married</option>
                                        <option value="2">Un-Married</option>
                                        <option value="3"></option>
                                    </select>
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col'>
                                    <input type="address" minlength="2" maxlength="250" class="form-control inputcolor text_box_size " placeholder="Address " Value={address} onChange={(e) => setaddress(e.target.value)} id="exampleFormControlInput1" />

                                </div>

                            </div>
                            <div className='row mt-2'>
                                <div className='col-4'>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput" class="required ms-1">Country</label>
                                        <select class="form-select inputcolor text_box_size" aria-label=" select Day" Value={country} onChange={(e) => setcountry(e.target.value)} >
                                            <option >Country</option>
                                            {countr.map((x, index) => {

                                                return (

                                                    <option value={x.country_name} >{x.country_name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-4'>


                                    {(() => {

                                        if (country == "Vietnam ") {
                                            return <div class="">

                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="ms-1">City/Town</label>
                                                    <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                        <option selected>City/Town </option>
                                                        {vcitylist.map((x, index) => {
                                                            return (
                                                                <option value={x.city_id}>{x.city}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>

                                            </div>
                                        } else {
                                            return <div class="">

                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class=" ms-1">City/Town</label>
                                                    <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                        <option selected>City/Town </option>


                                                        <option value="671">Nil</option>

                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    })()}

                                </div>
                                <div className='col-4'>

                                    {vprovincelist.map((x, index) => {
                                        {
                                            return (<div>
                                                {(() => {
                                                    if (city == 671) {
                                                        return <div class="form-group">
                                                            <label for="formGroupExampleInput" class=" ms-1">Province</label>
                                                            <input type="text" class="form-control inputcolor text_box_size" Value={state} onChange={(e) => setstate(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                        </div>
                                                    } else {
                                                        return <div class="form-group">
                                                            <label for="formGroupExampleInput" class=" ms-1">Province</label>
                                                            <input type="text" class="form-control inputcolor text_box_size" value={x.province} Value={state} onChange={(e) => setstate(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                        </div>
                                                    }
                                                })()

                                                }
                                            </div>


                                            )
                                        }
                                    })}
                                </div>



                            </div>
                            <div className='row mt-3'>

                                <div className='col'>
                                    <input type="text" minlength="2" maxlength="15" 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} class="form-control inputcolor  text-start text-secondary text_box_size " placeholder="Postal Code" onPaste={handle_Paste} Value={postalcode} onChange={(e) => setpostalcode(e.target.value)} id="exampleFormControlInput1" />

                                </div>

                            </div>
                            <div className='row mt-3'>
                                <small className='ms-1 required'>Phone Number</small>
                                <div className='col-2'>
                                    <select class="text-start inputcolor text_box_size " aria-label=" select Day" Value={phcod} onChange={(e) => setphcode(e.target.value)}>
                                        <option selected>+84 </option>
                                        {countrphcode.map((x, index) => {

                                            return (

                                                <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                            );
                                        })}

                                    </select>
                                </div>
                                <div className='col-10'>
                                    <input type="tel" onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        minlength="7" maxlength="12" class="form-control inputcolor text_box_size ms-1 " onPaste={handle_Paste} Value={phone} onChange={(e) => setphone(e.target.value)} aria-label="Text input with dropdown button" id="phone" name="phone" pattern="+91[7-9]{2}-[0-9]{3}-[0-9]{4}" placeholder="Phone Number" width={"-10%"} />

                                </div>

                            </div>
                            <div className='row mt-4'>
                                <div className='col-6 d-grid '>    <button class="btn btn-secondary text_box_size " type="button" onClick={alet}>Cancel</button></div>
                                <div className='col-6 '>    <form onSubmit={handleSubmit(onInsert)} className="d-grid" action="#" method="POST">
                                    <button class="btn btn-pri text_box_size  " type="submit" disabled={!isValid} >Next</button>

                                </form> </div>

                            </div>

                        </div>



                    </div>






                    <div className='row  mt-3'>
                        <div className='col-4'></div>

                        <div className='col-3'>
                            <img className="  img-fluid  hide_tab mobsimplytext" src="./Images/basebot.svg" /></div>


                    </div>
                </div>
            </div>


            <AEHeader />
        </div>
    );
}
export default PersonalDetails;
