import React from 'react'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import CryptoJS from 'crypto-js';
const Forpassword = () => {

  const { update, handleSubmit } = useForm();
  const [loginuser, setloginuser] = useState('');
  const [user_details, setuserDetails] = useState('');
  const [password, setPassword] = useState("");
  const [email, setemail] = useState("");
  const generatePassword = () => {

    const randomPassword =
      Math.random().toString(36).slice(2);

    setPassword(randomPassword);

  };
  useEffect(() => {
    generatePassword();
  }, []);

  const sendEmail = (e) => {
    const emailData = {

      user_email: `${email}`,
      // user_name: `${}`,
      message: `${password}`,
    };

    const secretKey = 'mysecretkey';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

    fetch(`${process.env.REACT_APP_API_URL}/sendemailr`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: encryptedData }),
      // body: JSON.stringify(emailData),

    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
    onreset();
  }
  const form = useRef();

  let navigate = useNavigate();
  const Gosignin = () => {
    //debugger;
    navigate("/signup");
  };
  const goland = () => {
    //debugger;
    navigate("/");
  };

  const gohome = () => {
    //debugger;
    navigate("/admindashboard");
  };
  const login = () => {
    let path = `/login`;
    navigate(path);
  }

  const onreset = (data, e) => {
    let bas = {
      loginmail: email,
      password: password,
    };
    axios.put(`${process.env.REACT_APP_API_URL}/resetpasswordmy`, bas).then((res) => {
      console.log('user updated', res.data);

      allert();

    }).catch(() => {

    })
  }
  const onlog = (data, e) => {
    let bas = {
      loginmail: email,

    };


    axios.post(`${process.env.REACT_APP_API_URL}/forgetpass`, bas).then((res) => {
      console.log('valid User', res.data);

      if (res.data.length > 0) {
        alert(" Click ok to send new password");
        sendEmail();
      } else {
        alert("Email ID is not registered with system");
      }
    }).catch(() => {

    })
  }
  const allert = () => {
    confirmAlert({

      message: 'Password sent successfully, please check your registered email.',
      buttons: [
        {
          label: 'OK',
          onClick: () => login(),
          style: {
            backgroundColor: '#F36E35'

          }
        }

      ]
    })
  }
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    setemail(e.target.value);

    // Check email format
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(e.target.value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };
  return (

    <div className='logscr'>
      <div className='row'>
        <div className='col-xxl-7 col-sm-7 col-md-1 col-lg-1 col-xl-7  '>
          <div className='row'>
            <div className='col '>
              <img className='img-fluid loginimg pointer_  tabimgcent' onClick={goland} src={`Images/sygl_img.png`} alt="Logo" />

              <img className='imgbo mobsimplytext hide_tab' src={`/Images/sign_img.png`} alt="SY Image" />
            </div>


          </div>

        </div>
        <div className='col-xxl-5 col-sm-5 col-md-8 col-lg-8 col-xl-5 bg-white inpucent'>
          <div className='row mt-5'></div>
          <div className='row mt-5'></div>
          <div className='row mt-5'></div>
          <div className='row'>
            <div className='row'>
              <div className='col-xxl-3 col-sm-3 col-md-1 col-lg-3 col-xl-3'></div>
              <div className='col-sm-6 col-xxl-6  col-md-8 col-lg-6 col-xl-6 forleft hide_all'> <p className='fw-bold fs-3 text-start fnt-clr'>Forgot <p className=''> Your Password ?</p> </p></div>
              <div className='col-sm-6 mt-5  forleft onlytab hide_tab'> <p className='fw-bold fs-3 text-start fnt-clr'>Forgot  Your Password ? </p></div>
              <div className='col-3'></div>
            </div>

            <div className='col-3 col-xxl-3  col-md-1 col-lg-3 col-xl-3'></div>
            <div className='col-sm-9 col-xxl-9  col-md-9 col-lg-9 col-xl-9'>
              <form ref={form} onSubmit={handleSubmit(onlog)} action="#" method="POST">

                <div class="col-sm-9 col-xxl-9  col-md-9 col-lg-9 col-xl-9 hide_all">

                  <label for="exampleInputEmail1" class="form-label ">Enter Email associated with your account*</label>

                  <input type="email" minlength="2" maxlength="64" name="user_email" class=" form-control inputcolor text_box_size " Value={email} onChange={handleEmailChange} placeholder="Email" id="exampleFormControlInput1" />
                  {emailError && <span className="text-danger">{emailError}</span>}
                  <input type="address " className='hide_all_pc' name="message" value={password} />
                </div>

                <div class="col-9 ms-5 onlytab hide_tab">

                  <label for="exampleInputEmail1" class="form-label ">Enter Email associated with your account*</label>

                  <input type="email" minlength="2" maxlength="64" name="user_email" class=" form-control inputcolor text_box_size " Value={email} onChange={handleEmailChange} placeholder="Email" id="exampleFormControlInput1" />
                  {emailError && <span className="text-danger">{emailError}</span>}
                  <input type="address " className='hide_all_pc' name="message" value={password} />
                </div>
                <div class="row mt-3">
                  <div className='col-2'></div>
                  <div className='col-sm-6 cents'><button type="submit" class="btn btn-pri  ms-3  " >Reset Password</button>
                  </div>
                  <div className='col-3'></div>
                </div>
              </form>

            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-5'></div>
            <div className='col-sm-5 cents signupleft'>
              <div className="">Back to<label className='signup pointer_ ms-2' onClick={login}>Sign In</label></div>

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Forpassword
