import { React, useState, useEffect, useContext } from "react";
import "./Reports.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import ReactPaginate from "react-paginate";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Reportemployee = () => {
    // download 
    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();
    // download 
    const generatePDF = (data) => {

        console.log(data, 'pdf');
        const doc = new jsPDF();
        const cmpname = data[0].company_name;
        // add company logo
        //  doc.addImage(data[0].company_logo, 'JPEG', 40, 5, 20, 20);

        // add company name

        doc.setFontSize(12);
        doc.text('Employee Listing', 15, 16);
        //company address 
        doc.setFontSize(9);
        doc.text(cmpname, 140, 16);

        //  doc.text('Company Address', 140, 16);
        doc.setFontSize(11);
        doc.text(data[0].company_address1, 140, 21);
        doc.text(data[0].company_address2, 140, 26);

        const tableColumn = [
            'No',
            'Name',
            'Designation',
            'Department',
            'Reporting Manager',
            'Work Schedule',
            'Wage',
            'Hire date',
            'End date'
        ];
        const tableRows = [];
        const downl = [`Downloaded On: ${currentDateTime}`];

        const addPageContent = function (data) {
            // get current page number and total number of pages
            const pageCount = doc.internal.getNumberOfPages();
            const pageNumber = data.pageNumber;

            // set font size and style for download message
            doc.setFontSize(8);

            // add download message and page number to bottom of page
            doc.text(downl, data.settings.margin.left, doc.internal.pageSize.height - 10);
            doc.text(`Page ${pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 5);
        };

        // push data to table rows
        data.forEach((item, index) => {
            const dataRow = [
                index + 1,
                item.full_name || '',
                item.job_title || '',
                item.departments || '',
                item.manager_name || '',
                item.workschedule_name || '',
                Math.round(item.amountbasic).toLocaleString('en').replace(/,/g, '.') || 'NOT DEFINED',
                item.hiredate ? moment(item.hiredate).format("DD/MM/YYYY") : '',
                item.active_status ? 'N/A' : moment(item.enddate).format("DD/MM/YYYY")

            ];
            tableRows.push(dataRow);
        });

        // add the table to the PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 35,
            styles: { fontSize: 9 },

            addPageContent: addPageContent

        });

        // save the PDF file
        doc.save('report.pdf');
        // doc.save(`report${query} to ${enddate}.pdf`);

    }
    const generateCSV = (data) => {
        const cmpname = data[0].company_name;

        // define the CSV column headers
        const headers = ['Employee Listing'];
        // const dateRange = [`Report Range: ${query} to ${enddate}`];
        const companyName = [`Company Name: ${cmpname}`];
        const downl = [`Downloaded ON: ${currentDateTime}`];
        const fieldHeaders = ['No',
            'Name',
            'Designation',
            'Department',
            'Reporting Manager',
            'Work Schedule',
            'Wage',
            'Hire date',
            'End date'];

        // create a CSV string from the data
        let csv = '';
        [headers, companyName, downl, fieldHeaders].forEach((row) => {
            csv += row.join(',') + '\n';
        });
        let serialNo = 1;
        data.forEach(item => {
            const dataRow = [
                serialNo,
                item.full_name || '',
                item.job_title || '',
                item.departments || '',
                item.manager_name || '',
                item.workschedule_name || '',
                item.amountbasic || 'NOT DEFINED',
                item.hiredate ? moment(item.hiredate).format("DD/MM/YYYY") : '',
                item.active_status ? 'N/A' : moment(item.enddate).format("DD/MM/YYYY")
            ];
            csv += dataRow.join(',') + '\n';
            serialNo++;
        });

        // initiate file download
        // const filename = 'report.csv';
        const filename = `repor.csv`;
        const blob = new Blob([csv], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };


    const { state } = useContext(store);
    console.log('base', state.user);
    function handleKeyDown(event) {
        const maxDigits = 4; // Maximum number of digits allowed
        const inputLength = event.target.value.length + 1; // Length of input after this key is pressed
        if (inputLength > maxDigits && event.key !== 'Backspace' && event.key !== 'Delete') {
            // If the number of digits entered is greater than the maximum allowed
            event.preventDefault(); // Prevent the key from being entered
        }
    }


    let navigate = useNavigate();
    const payrolldash = () => {
        let path = `/reportsdashboard`;
        navigate(path);
    }
    const [active, setactive] = useState("");

    const [cont, setcont] = useState("");

    const [demolist, setdemolist] = useState([])
    const [editac, seteditact] = useState("back");
    const [department, Setdept] = useState("");
    const [department_details, setdepartment_details] = useState([]);
    const [employee, setempno] = useState("");
    const showactive = (type) => {
        if (type == 'active') {
            seteditact(type);

        }
        if (type == 'back') {
            // seteditact(type);
            window.location.reload();
        }

    }
    const handleDepartmentChange = (event) => {
        const selectedDepartment = event.target.value;

        setactive(selectedDepartment);

    };

    // get departments
    const Getdepartment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/departments/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setdepartment_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [empdetails, setempdetails] = useState([]);
    const Getloc = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');

                setempdetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    useEffect(() => {
        Getdepartment();
        Getloc();
    }, [state]);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;


    const filteredUsers = demolist;
    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(demolist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [datalenght, setdatalenght] = useState();
    // get data for report
    const Getreporttakehome = () => {

        let bas = {
            company_id: state?.user[0]?.company_id,
            department: department,
            labourtype: cont,
            active_status: active,
            worklocation: employee,

        };
        axios.post(`${process.env.REACT_APP_API_URL}/reportemployee`, bas).then((res) => {
            console.log(res.data, 'admin');
            showactive("active");
            setdemolist(res.data);
            console.log(`Number of items in data: ${res.data.length}`);
            setdatalenght(res.data.length);
        }).catch(() => {

        })



    }
    // download 


    // const isValid =  query && enddate 


    // alert  
    const alet = (x) => {
        confirmAlert({

            message: 'Select a format to download Report',
            buttons: [
                {
                    label: 'PDF',
                    className: 'btn-primary btn',
                    onClick: () => generatePDF(x)
                },
                {
                    label: 'EXCEL',
                    className: 'btn-primary btn',
                    onClick: () => generateCSV(x)
                }
            ]
        })
    }
    return (
        <div className='bg-light vh-100 container-fluid '>

            {(() => {
                if (editac == "back") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                        </div>   <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4  tbl'>
                            <p className=" fs-4 ms-sm-5 mt-3 fnt-clr">Reports</p>
                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded">

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-11'>
                                            <p className="fw-bold fs-5 ms-sm-3 text-secondary">Employee Listing</p>
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-3">Department</small>
                                            <select
                                                className="form-select text-start inputcolor text_box_size"
                                                value={department}

                                                onChange={(e) => Setdept(e.target.value)}
                                            ><option value="">All departments</option>
                                                {department_details.map((x) =>
                                                (<option value={x.id}>
                                                    {x.departments}</option>
                                                ))}
                                            </select> </div>
                                        <div className="col-sm-6">

                                            <small className="text-secondary ms-sm-2">Labour Contract</small>
                                            <select
                                                className="form-select text-start inputcolor text_box_size "
                                                value={cont}
                                                onChange={(e) => setcont(e.target.value)}

                                            >
                                                <option value='0'>All Contracts</option>

                                                <option value='1'>Vietnamese(Permanent employee)</option>

                                                <option value='2'>Non-Vietnamese/foreigner(Permanent employee)</option>

                                                <option value='test'>Contract Employee(Vietnamese/Non-Vietnamese)</option>

                                                <option value='-2'>Personal Services Contract</option>

                                            </select>

                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-3">Active Status</small>
                                            <select
                                                className="form-select text-start inputcolor text_box_size"
                                                value={active}
                                                onChange={handleDepartmentChange}
                                            ><option value="">All Status</option>
                                                <option value="true">Active</option>
                                                <option value="false">In-Active</option>

                                            </select> </div>
                                        <div className="col-sm-6">

                                            <small className="text-secondary ms-sm-2">Work location</small>
                                            <select
                                                className="form-select text-start inputcolor text_box_size "
                                                value={employee}
                                                onChange={(e) => setempno(e.target.value)}
                                            >
                                                <option>All Work Locations</option>
                                                {empdetails.map((x) => (
                                                    <option key={x.location_id} value={x.location_id}>{x.worklocation}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-6 d-grid">
                                            <button type="submit" className="btn btn-pri ms-sm-2" onClick={() => Getreporttakehome()}

                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-6 d-grid crdb">
                                            <button type="submit" className="btn btn-secondary" onClick={() => payrolldash()}  >Cancel</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                }
                if (editac == "active") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                        </div>
                        <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4 tbl'>
                            <div className='row '>
                                <div className='col-sm-6 col-xxl-6 col-sm-7 col-md-7 col-lg-6 col-xl-6 '>
                                    <p className="fw-bold fs-4 ms-sm-5 mt-3 fnt-clr">Employee Listing</p>
                                    <p className=" fs-6 ms-sm-5 mt-3">Total No Of Employee :{demolist.length}</p>
                                </div>
                                <div className='col-sm-5 col-xxl-5 col-sm-6 col-md-6 col-lg-5 col-xl-5  hid_tab '>

                                </div>
                            </div>

                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded ">

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>

                                        </div>
                                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid  hid_tab '>
                                            <button type="submit" className="btn btn-secondary ms-sm-2 " onClick={() => showactive("back")}  >Back</button>

                                        </div>
                                        {(() => {
                                            if (datalenght === 0) {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2" disabled>Download</button>
                                                </div>
                                                );
                                            }
                                            else {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2 " onClick={() => alet(demolist)}>Download</button>
                                                </div>);
                                            }
                                        })()}
                                    </div>
                                    {(() => {
                                        if (datalenght == 0) {
                                            return <div className='mt-5'><h5> Data Does Not Exist !</h5>

                                            </div>
                                        } else {
                                            return<>
                                            <div className="row">
                                                    <div className="ms-4 mt-2 onlytab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            <table class="table table-bordered mt-2 year-buttonss">
                                                
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>  <td scope="col"></td>




                                                            <td scope="col" className=' text-center  text-uppercase headfont grey' >No</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey' >Name</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Designation</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Department</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Reporting Manager </td>

                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Work Schedule</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Wage</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>Hire date</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont grey'>End date</td>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {displayUsers.map((x, index) => {
                                                            const actualIndex = pagesVisited + index;
                                                            const serialNumber = actualIndex + 1;
                                                            return (
                                                                <tr>
                                                                    <th scope="row"><div className="ms-sm-3 ">  </div></th>


                                                                    <td className='text-center'>{serialNumber}</td>
                                                                    <td className=' text-center text-capitalize' style={{ paddingTop: '15px' }}>{x.full_name}</td>
                                                                    <td className=' text-center text-capitalize' style={{ paddingTop: '15px' }}>{x.job_title}</td>
                                                                    <td className='  text-center' style={{ paddingTop: '15px' }}>{x.departments}</td>

                                                                    <td className='  text-center' style={{ paddingTop: '15px' }}>{x.manager_name}</td>

                                                                    <td className='  text-center' style={{ paddingTop: '15px' }}>{x.workschedule_name}</td>

                                                                    <td className='  text-center' style={{ paddingTop: '15px' }}>{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='  text-center' style={{ paddingTop: '15px' }}>{moment(x.hiredate).format("DD/MM/YYYY")}</td>


                                                                    {(() => {
                                                                        if (x.active_status == true) {
                                                                            return <td className='  text-center' style={{ paddingTop: '15px' }}>N/A</td>
                                                                        } else {

                                                                            return <td className='  text-center' style={{ paddingTop: '15px' }}>{moment(x.enddate).format("DD/MM/YYYY")}</td>

                                                                        }
                                                                    })()}


                                                                </tr>

                                                            );
                                                        })}




                                                    </tbody>

                                                </table>
                                            </table>
                                            </>
                                        }

                                    })()}
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                }
            })
                ()}



            <Lheader />

        </div>
    )
}
export default Reportemployee