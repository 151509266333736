import React, { useCallback, useState, useContext, useEffect } from 'react';
import './Superadmincss.css';
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
import Mobside from "../Sidebar/Mobside"
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import Password from 'antd/lib/input/Password';

const Superheader = (props) => {
  const { state } = useContext(store);
  let navigate = useNavigate();
  const goadd = () => {
    //debugger;
    navigate("/superadminusers");
  };
  const goLogin = () => {
    navigate("/");

  };
  const reset = () => {
    navigate("/adpassword");

  };

  const [notification, setNotification] = useState({ show: true, message: 'New notification' });

  const handleClose = () => {
    setNotification({ show: false, message: '' });
  };
  const [islogged, setLogged] = useState(true);
  const [User, setUser] = useState(false);
  const logOut = () => {

    if (islogged == true) {
      localStorage.removeItem('user_details');
      setUser(false);
      // goLogin();
      window.location = '/';
    }
    else {
      localStorage.setItem('user_details');
    }
  }
  const current = new Date();
  //const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const date = current.toLocaleDateString('en-US', options);


  return (

    <div className=' '>

      <div className='row border-bottom'>
        <div className='col-2 ms-4'></div>
        <div className='col-2'><p className='fw-bold mt-4 ms-5 grey'>Welcome  {state?.user[0]?.name}</p>
        </div>
        <div className='col-2'><p className='fw-bold mt-4 grey'>{date}</p></div>
        <div className='col-1'></div>

        <div className='col-4 '>

          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid ms-5">



              <ul class="navbar-nav ms-auto loginhead ">
                <li class="nav-item">


                </li>



                <li class="nav-item">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" width="26" height="26" fill="currentColor" class="bi bi-bell-fill belpos mt-1" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                  </svg> */}


                  <li class="nav-item dropdown profile_icon hid_tab">
                    <div className="mt-2 ms-5"> <img src={`Images/grycir.svg`} style={{ height: 40 }} ></img> </div>
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    </a>
                    <ul class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink">


                      {(() => {
                        if (state?.user[0]?.primary == true) {
                          return (<li className='pd'><a class="dropdown-item " onClick={() => goadd()}>Add User</a></li>
                          );
                        } else {
                          return
                        }
                      })()}
                      <li className='pd'><a class="dropdown-item " onClick={() => { reset() }}>Password Reset</a></li>

                      <li className='pd'><small class="dropdown-item " onClick={() => { logOut() }}>Logout</small></li>

                    </ul>
                  </li>
                </li>



              </ul>

            </div>
          </nav>
        </div>
      </div>

    </div>



  );
}



export default Superheader;