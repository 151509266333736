import Sidebar from '../Components/Sidebar/Sidebar'
import EmployeeSidebar from '../Components/EmployeeSidebar/EmployeeSidebar';
import Lheader from '../Components/Lheader/Lheader';
import React, { useState, useContext, useEffect } from 'react';
import { store } from '../store/user';
import Superheader from './Superadmin/Superheader';
import Superside from './Superadmin/Superside';



const Layout = (props) => {

    const [user_details, setuser_details] = useState('');
    const { state, dispatch } = useContext(store);

    console.log('asdfasdfsasfafdsafdsadfasdfasdfa', state.user);
    console.log('asdfasdfsasfafdsafdsadfasdfasdfa', state.user);

    const page = props.page;
    console.log('page', page);



    useEffect(() => {
        const loggedInUser = JSON.parse(localStorage.getItem('user_details'));
        if (loggedInUser != null) {
            dispatch({ type: 'ADD_USER', payload: { user: loggedInUser } });
            //  Gohome();
        }
    }, []);

    return (

        <div>
            {(() => {
                if (state.user != '' && state.user != undefined && state.user[0].admin == true) {
                    return <div>


                        {props.children}
                        {/* <Lheader /> */}
                        <Sidebar />
                    </div>;
                }
                if (state.user != '' && state.user != undefined && (state.user[0].admin == true || state.user[0].admin == false) && (state.user[0].usertypename == 'Admin' || state.user[0].usertypename == 'HR Manager' || state.user[0].usertypename == 'Supervisor')) {
                    return <div>


                        {props.children}
                        {/* <Lheader /> */}
                        <Sidebar />
                    </div>;
                } else if (state.user != '' && state.user != undefined && state.user[0].admin == false && state.user[0].usertypename == 'Employee') {
                    return <div>

                        {props.children}

                        <EmployeeSidebar />
                    </div>;
                }
                else if (state.user != '' && state.user != undefined && state.user[0].primary == false) {
                    return <div>
                        <Superheader />
                        <Superside />
                        {props.children}


                    </div>;
                }
                else if (state.user != '' && state.user != undefined && state.user[0].primary == true) {
                    return <div>
                        <Superheader />
                        <Superside />
                        {props.children}


                    </div>;
                }
                else {
                    return <>
                        {props.children}
                    </>;
                }
            })()}

        </div>

    );
}

export default Layout;