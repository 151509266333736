import axios from 'axios';

// Add a request interceptor
const callInterceptor = () => {
  axios.interceptors.request.use(
    config => {
      // get token from localStorage
      const tokenObj = JSON.parse(localStorage.getItem('user_details'));
      const token = tokenObj ? tokenObj[0]?.accessToken : undefined;

      if (!config?.url.includes('login')) {
        if (token) {
          config.headers['x-access-token'] = `${token}`
        }
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
};

export default callInterceptor;