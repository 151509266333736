import React from 'react';
import './Payroll/Payrolllist.css';



const Loadingscreen = () => {
  return (
    <div className='row vh-100 bg-white vw-100'>
      <div className='col-5'></div>
      <div className='col-3'>
        <h2 className='text-primary loadtxt'>Processing Payroll</h2>
      </div>
    </div>
  );
};

export default Loadingscreen;
