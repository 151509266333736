
import React, { useCallback, useState, useEffect, useContext } from 'react'
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
const Empacc = () => {
  const { state } = useContext(store);
  console.log('profile_cmp', state.user);
  const [password, setPassword] = useState("");
  const [newpassword, setnewPassword] = useState("");
  const { update, handleSubmit } = useForm();
  const [email, setemail] = useState("");
  const [newcon, setcon] = useState("");
  let navigate = useNavigate();

  const gohome = () => {
    navigate("/admindashboard");
  };

  const gohomes = () => {
    //debugger;
    navigate("/supervisordashboard");
  };
  const gohomee = () => {
    navigate("/employeedashboard");
  };


  const onlogin = (data, e) => {

    let bas = {
      loginmail: email,
      password: password,
    };


    axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
      console.log('user updated', res.data);
      // Update();
      // alert();
      //  window.location = "/personaldetails";
    }).catch(() => {

    })
  }
  const alet = () => {
    confirmAlert({
      // title: 'Cancle',
      message: 'Confirm To send password',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onlogin(),
          style:{
            backgroundColor:'#F36E35'
           
       }
        },
        {
          label: 'No',
         

        }
      ]
    })
  }
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hrquipa', 'template_jyjlb0k', form.current, 'CG6sgr1nRYDufVqn2')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };
  const form = useRef();

  const onreset = (data, e) => {
    let bas = {

      loginmail: state?.user[0]?.loginmail,
      password: newcon,
    };


    axios.put(`${process.env.REACT_APP_API_URL}/resetmypassword`, bas).then((res) => {
      console.log('user updated', res.data);

      alert("password saved succesfully");
      if (state?.user[0]?.emp_id == null) {

        gohome();
      }
      if (state?.user[0]?.usertypename == 'HR Manager') {

        gohome();
      }
      if ( state?.user[0]?.usertypename == 'Admin') {

        gohome();
      }
      if (state?.user[0]?.usertypename == 'Supervisor') {

        gohomes();
      }
      if (state?.user[0]?.usertypename == 'Employee') {

        gohomee();
      }
    }).catch(() => {

    })
  }

  const godash = () => {
    if (state?.user[0]?.emp_id == null) {

      gohome();
    }
    if (state?.user[0]?.usertypename == 'HR Manager') {

      gohome();
    }
    if (state?.user[0]?.usertypename == 'Admin') {

      gohome();
    }
    if (state?.user[0]?.usertypename == 'Supervisor') {

      gohomes();
    }
    if (state?.user[0]?.usertypename == 'Employee') {

      gohomee();
    }
  }
  // console.log(state?.user[0]?.loginmail);
  const onlog = (data, e) => {
    let bas = {
      loginmail: state?.user[0]?.loginmail,
      password: password,
    };

    console.log(bas);
    axios.post(`${process.env.REACT_APP_API_URL}/forgetmypass`, bas).then((res) => {
      console.log('valid User', res.data);

      //  Getloginuser () ;
      if (res.data.length > 0) {
        alert(" Click ok to confirm ");
        onreset();
      } else {
        alert("Wrong data please enter a valid data");
      }
    }).catch(() => {

    })
  }
  const { register, watch, formState: { errors } } = useForm({
    mode: 'onTouched'
  });
  const fname = watch('fname')



  // handle disabled submit
  const isValid = fname && password
  const valuepass = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i;


  return (
    <div className='bg-light vh-100 container-fluid'>

      <div className='row mt-5'>


        <div className='col-sm-2 hide_tab'> </div>
        <div className='col-sm-9 col-xxl-9 col-md-11 col-lg-11 col-xl-9 tbl'>

          <div class="card passwordr_card shadow mb-5 p-2 bg-white rounded">
            <p className='fs-5  fnt-clr text-center'>Password Reset</p>
            <div class="card-body">
              <div className='row'>
                <div className='col-sm-1'></div>
                <div className='col-sm-4'>
                  <div className='row'>

                    <form onSubmit={handleSubmit(onlog)} action="#" method="POST">

                      <div className='col'>
                        <div className='row'>


                        </div>
                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'>Current  Password</label>
                            <input type='Password' minlength="2" maxlength="15" name="Password" className='pastxt' Value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'> New Password</label>


                            {/* <input type="text"name="user_name" class="textbox1-work mt-3" placeholder="Joseph George"Value={fname} onChange={(e)=>setfname(e.target.value)} aria-label="Username" /> */}
                            <input
                              // Value={curname} onChange={(e)=>setcurrency(e.target.value)}
                              type='Password'
                              name="user_name"
                              minlength="8" maxlength="15"
                              Value={newpassword} onChange={(e) => setnewPassword(e.target.value)}
                              className={`pastxt  ${errors.fname &&
                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                              {...register("fname", {
                                required: {
                                  value: true,
                                  message: ' *Password is required'
                                },
                                minLength: {
                                  value: 8,
                                  message: "Please enter minimum 8 character",
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    "Maximum allowed length is 15 characters ",
                                },
                                pattern: {
                                  value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i,
                                  message: <small> * Password Should Contain Capital letter ,Small letter, Numeric & special Characters</small>
                                },
                              })}
                            />
                            <div>
                              {errors.fname && <span className=' text-danger'>{errors.fname.message}</span>}
                            </div>
                          </div>
                          {/* <input type="text" name="user_name" /> */}
                        </div>
                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'>Confirm Password</label>
                            <input type='Password' minlength="2" maxlength="15" name="message" className='pastxt' Value={newcon} onChange={(e) => setcon(e.target.value)} />
                          </div>
                        </div>


                        {(() => {
                          if (newcon === fname && fname.match(valuepass)) {
                            // Passwords match, show reset and close buttons
                            return (
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wid mt-3"
                                  value="Reset"
                                  disabled={!isValid}
                                >Reset</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wid ms-3"
                                  onClick={godash}
                                  value="Close"
                                  style={{ fontSize: '13px' }}
                                >Close</button>
                              </div>
                            );
                          } else if (newcon !== "" && newcon !== fname) {
                            // Passwords don't match, show error message, reset button, and close button
                            return (
                              <div className="ms-3">
                                <p className="text-danger">Password does not match</p>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wid mt-3"
                                  value="Reset"
                                  disabled
                                >Reset</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wid ms-3"
                                  onClick={godash}
                                  value="Close"
                                  style={{ fontSize: '13px' }}
                                >Close</button>
                              </div>
                            );
                          } else {
                            // Passwords haven't been entered yet, show empty div with disabled reset and close buttons
                            return (
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wid mt-3"
                                  value="Reset"
                                  disabled
                                >Reset</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wid ms-3"
                                  onClick={godash}
                                  value="Close"
                                  style={{ fontSize: '13px' }}
                                  
                                >Close</button>
                              </div>
                            );
                          }
                        })()}

                      </div>
                    </form>

                  </div>
                </div>
                <div className='col-sm-2'></div>
                <div className='col-sm-5'>
                  {/* <h4 className='fw-bold'>Password Policy</h4> */}
                  <img className='img-fluid imps ms-5 mt-2' src={`Images/pass1.svg`}></img>
                  <p className=' ms-5 fw-bold'>Password Must  Includes </p>

                  <p className=''> - Password Must contain 8 Characters </p>
                  <p className=''> - Password Must contain 1 Capital Letter</p>
                  <p className=''> - Password Must contain 1 Numeric Value</p>
                  <p className=''> - Password Must contain 1 Special Characters</p>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
      <Lheader />

    </div>
  )

};

export default Empacc