import React, { useCallback, useState, useContext, useEffect } from 'react';

import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const MasterTableTax = (integer) => {
    const [tax_details, setTaxDetails] = useState([]);
    const { update, handleSubmit } = useForm();
    const [taxtype, setTaxtype] = useState("");
    const [taxpercent, setTaxpercent] = useState("");
    const [bind_user, setBindUser] = useState({integer});
    const [user_type, setUserType] = useState('');
    const [taxtypes, setTaxtypes] = useState("");
    const [taxpercents, setTaxpercents] = useState("");
    const [taxincome, setTaxincome] = useState("");
    const [taxmincome, setTaxMincome] = useState("");
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const clickdelete = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }
    

    const GetTax = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/tax`).then((res) => {
                console.log(res.data, 'success');
                setTaxDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const onInsert = (data, e) => {
        try {
            let tax = {
                tax_type: taxtype,
                tax_percentage: taxpercent,
                monthlytaxableincome:taxmincome,
            };
            //alert("Click Ok to Add");
            axios.post(`${process.env.REACT_APP_API_URL}/tax`, tax).then((res) => {
                console.log('tax added', res.data);
                save();
                //window.location = "/tabletax";
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const refreshPage = () => {
        window.location.reload();
    }
    useEffect(() => {
        GetTax();
    },
        []);
    const Delete = (tax_id) => {
        try {
            clickdelete();
            axios.delete(`${process.env.REACT_APP_API_URL}/tax/` + tax_id).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/tabletax";
                if (res.data == true) {
                    GetTax();
                }
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const onUpdate = (tax_id, e) => {
    
        let taxs = {
            tax_id: bind_user.tax_id,
            tax_type: taxtypes,
            tax_percentage: taxpercents,
            monthlytaxableincome:taxincome,
           
        };
        //console.log("user", user);
        
        axios.put(`${process.env.REACT_APP_API_URL}/tax/${bind_user.tax_id}` ,taxs).then((res) => {
        console.log('Tax updated',res.data);
        save();
        //window.location = "/tabletax";
        //alert("Click Ok to Update");
        }).catch(() => {
   
        })
       }
       const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
            <div class="card tabeditcard" >
               
                    <div class="card-body">
                    <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST">
                    <div class="input-group">
                            <input type="text" class=" form-control inputcolor" autocomplete="tax_type" Value={bind_user.tax_type}  onChange={(e)=>setTaxtypes(e.target.value)} placeholder="Tax Type" aria-label="Username" />
                        </div>
                        <div class="input-group">
                            <input type="text" class=" form-control inputcolor mt-3" autocomplete="tax_percentage" Value={bind_user.tax_percentage}  onChange={(e)=>setTaxpercents(e.target.value)} placeholder="Tax PercentageS" aria-label="Username" />
                        </div>
                        <div class="input-group">
                            <input type="text" class=" form-control inputcolor mt-3" autocomplete="tax_percentage" Value={bind_user.monthlytaxableincome}  onChange={(e)=>setTaxincome(e.target.value)} placeholder="Monthly taxable Income" aria-label="Username" />
                        </div>
                        <div className='row'>
                        <button  type='sumbit' onSubmit={handleSubmit(onUpdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">Update</button>
                        <button  type='sumbit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn"  onClick={()=>ShowDetails('')}>Cancel</button>
                        </div>
                        </form>
                        
                    </div>
            </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/weekdays`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    return (
        <div className='bg-light vh-100 container-fluid'>
             <div class="row">
                    <div class="col-2">
                        <div >
                            <div className='setting_side'>
                                <p className="ms-5 fs-4 fw-bold mt-3">Settings</p>

                                <ul className='pointer_'>

                                    {/* <div className='' onClick={bank}>Bank </div> */}

                                    <div className='mt-2 ' onClick={loc}>Work Location </div>

                                    <div className='mt-2' onClick={dept}>Department </div>





                                    <div className='mt-2' onClick={job}>Job Title </div>


                                    <div className='mt-2 fw-bold' onClick={tax}>Tax </div>


                                    <div className='mt-2' onClick={national}>Nationality </div>


                                    <div className='mt-2' onClick={salary}>Salary </div>


                                    <div className='mt-2' onClick={holidays}>Holidays </div>
                                    <div className='mt-2' onClick={currency}>Currency </div>
                                    <div className='mt-2' onClick={weekday}>WeekDays </div>
                                    <div className='mt-2' onClick={financial}>Financial year </div>
                                    <div className='mt-2' onClick={shift}>Shift </div>
                                    <div className='mt-2' onClick={Leavetype}>LeaveType </div>
                                    <div className='mt-2' onClick={overtype}>Overtime Rate </div>
                                    <div className='mt-2' onClick={ins}>Insurance </div>
                                    <div className='mt-2' onClick={usrtyp}>User Type </div>
                                    <div className='mt-2' onClick={ipp}>IP White Listing </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 

            <div className='row'>
                <div className='row mt-5'>
                    <div className='col-3'></div>
                    <div className='col-9'>
                        <p className=' mt-5 fs-5 fw-bold create_nam master_text'>  Tax</p>
                    </div>
                </div>

                <div className='row master_text1'>
                    <div className='col-6'>
                        <div class="input-group">
                            <input type="text" class=" form-control inputcolor roles_text ms-3  mt-3 textresp" Value={taxtype} onChange={(e) => setTaxtype(e.target.value)} placeholder="Tax" aria-label="Username" />
                        </div>
                        <div class="input-group mt-5">
                            <input type="text" class=" form-control inputcolor roles_text ms-3  mt-5 textresp" Value={taxpercent} onChange={(e) => setTaxpercent(e.target.value)} placeholder="Tax Percentage" aria-label="Username" />
                        </div>
                        
                        <div class="input-group mt-5">
                            <input type="text" class=" form-control inputcolor roles_text textresp txtbx" Value={taxmincome} onChange={(e) => setTaxMincome(e.target.value)} placeholder="Monthly taxable income" aria-label="Username" />
                        </div>
                    </div>
                    
                    <div className='col-1'></div>
                    <div className='col-3 createbtntop '>
                        <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                            <button class="btn btn-pri createbtn  mt-2 masteradd">Add</button>
                        </form>
                    </div>
                </div>

                <div className='row master_tablesize mt-5'>
                    <div className='col-3'></div>
                    <div className='col-9 rolesalign'>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> Tax Type</td>
                                        <td scope="col" className='fs-6 fw-bold grey'> TaxPercentage</td>
                                        <td scope="col" className='fs-6 fw-bold grey'>MonthlyTaxableIncome</td>
                                        <td scope="col " className='fs-6 fw-bold mobsimplytext grey'>Created Date</td>
                                        
                                        <td scope="col" ></td>
                                        <td scope="col" className='fs-6 fw-bold grey'>Edit </td>
                                        <td scope="col" className='fs-6 fw-bold grey'>DELETE </td>

                                    </tr>
                                </thead>
                                <tbody>

                                {tax_details.map((x, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{x.tax_type}</td>
                                                <td>{x.tax_percentage}</td>
                                                <td>{x.monthlytaxableincome}</td>
                                                <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY, h:mm:ss a')}</td>
                                                
                                                <td></td>
                                                <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></td>
                                                <td><svg onClick={() => Delete(x.tax_id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3" viewBox="0 0 16 16">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                </svg></td>
                                            </tr>
                                        );
                                    })}

                                    {/* <tr>
                                        <td>TDS</td>
                                        <td>10%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <td>Provisional Tax</td>
                                        <td>10%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <td>Professional Tax</td>
                                        <td>15%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <td>Service Tax</td>
                                        <td>6%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <td>Corporate Tax</td>
                                        <td>4%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <td>Value-added Tax</td>
                                        <td>3%</td>
                                        <td className='mobsimplytext'>8/4/20222</td>
                                        <td></td>
                                        <td></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 float-end" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg></td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3 float-end me-5" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg></td>
                                    </tr> */}



                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>
            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />
        </div>
    )
}
export default MasterTableTax