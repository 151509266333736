import React, { useCallback, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';
import { useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
  

const EmpleaveHistory = (props) => {

  const location = useLocation();
  console.log('emp_id: ', location?.state?.emp_id);
  const { state } = useContext(store);
  console.log('leavehistory', state.user);
  const [leavelist, setLeave_list] = useState([])
  const { update, handleSubmit } = useForm();
  let navigate = useNavigate();
  const [tab_type, setTabType] = useState('Attantance');
  const TabChange = (type) => {
    console.log('type', tab_type)
    if (type == 'Attantance') {
      console.log('Attantance', type);
      setTabType(type);
    } else if (type == 'ot') {
      console.log('My Regularization', type);
      setTabType(type);
    }
    else {

      setTabType(type);
    }

  }

  const employee = () => {
    let path = `/employeelist`;
    navigate(path);
  }
  const { emp_id } = useParams();
  const [events, setEvents] = useState([]);

  const Getleavelist = () => {
    if (state.user) {
      axios.get(`${process.env.REACT_APP_API_URL}/leave_emp/${location?.state?.emp_id}`).then((res) => {
        console.log(res.data, 'success');
        setLeave_list(res.data);
        let aa = [];

        res.data.forEach(item => {
          if (item.approved == 'true') {
            const fromdate = moment(item.fromdate).format('YYYY-MM-DD');
            const todate = moment(item.todate).format('YYYY-MM-DD');

            let a = {
              id: item.applyleave_id,
              title: 'Leave',
              allDay: true,
              start: new Date(fromdate),
              end: new Date(todate),
            };
            aa.push(a);
          }

        });
        setEvents(aa);
      }).catch(() => {

      })
    }
  }

  // attendance
  const [time_details, setUserDetails] = useState([]);
  const [eve_nts, set_Events] = useState([]);

  const gettimesheet = (x) => {
    if (state.user)
      axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_all/${location?.state?.emp_id}`).then((res) => {
        setUserDetails(res.data);
        let aa = [];
        res.data.forEach(item => {
          let a = {
            id: item.attendance_id,
            title: item.contents,
            allDay: true,
            // style:item.content === 'Absent' ?'background:#db153d' : 'background:#0080FF',
            // className: item.content === 'Absent' ? 'rbc-event_1' : 'rbc-event_p',
            start: new Date(item.date),
            end: new Date(item.date),
          };
          aa.push(a);
        });
        set_Events(aa);
      }).catch((error) => {
        console.log(error, 'success');
      });
  }
  useEffect(() => { }, [eve_nts])
  useEffect(() => {
    Getleavelist();
    gettimesheet()
  }, [state]);
  const localizer = momentLocalizer(moment);

  const [empget, setemp] = useState([]);
  const [emleave, setemplev] = useState([]);
  const Getemp = (emp_id) => {
    if (state.user) {
      axios.get(`${process.env.REACT_APP_API_URL}/emp_emp/${location?.state?.emp_id}`).then((res) => {
        console.log(res.data, 'success');
        setemp(res.data);
      }).catch(() => {

      })
    }
    // var id = this.props.match.params.emp_id;

  }

  const Getleave_emp = () => {
    if (state.user) {
      axios.get(`${process.env.REACT_APP_API_URL}/leaveemp/${location?.state?.emp_id}`).then((res) => {
        console.log(res.data, 'leaveemp');
        setemplev(res.data);
      }).catch(() => {

      })
    }
   

  }
  useEffect(() => {
    Getemp();
 Getleave_emp ();

  },
    [state]);






  //add timesheet
  const [date, Setdate] = useState("");
  const [totalhr, Settotalhr] = useState("");
  // const [comment, Setcomment] = useState("");
  // const [comment, Setcomment] = useState("");
  const [content, Setcontent] = useState("");
  // const to = watch('to')





  const { register, watch, formState: { errors } } = useForm({
    mode: 'onTouched'
  });


  // const onInsert = (data, e) => {
  //   let totalhr;
  //   if (content === "Paid Leave") {
  //     totalhr = 8;
  //   }else if (content === "Present" ) {
  //     totalhr = 8;
  //   }  else {
  //     totalhr = 0;
  //   }

  //   // Check if record already exists
  //   axios.get(`${process.env.REACT_APP_API_URL}/attget/${location?.state?.emp_id}/${date}`).then((res) => {
  //     if (res.data.length > 0) {
  //       // Update existing record
  //       const attendance_id = res.data[0].attendance_id;
  //       const old_totalhr = res.data[0].totalhr;
  //       let new_totalhr;

  //       console.log(old_totalhr, 'new1');
  //       if (content === "Paid Leave" ) {
  //         new_totalhr = 8;
  //       }else if (content === "Present") {
  //         new_totalhr = 8;
  //       }
  //        else if ((content !== "Paid Leave"|| content !== "Present") && old_totalhr === 8) {
  //         new_totalhr = 0;
  //       } else {
  //         new_totalhr = old_totalhr;
  //       }
  //       console.log(new_totalhr, 'new');
  //       let applyleave = {
  //         emp_id: location?.state?.emp_id,
  //         date: date,
  //         totalhr: new_totalhr,
  //         createdby: 1,
  //         contents: content
  //       };
  //       axios.put(`${process.env.REACT_APP_API_URL}/updateattendance/${attendance_id}`, applyleave).then((res) => {
  //         console.log('Attendance updated', res.data);
  //         alert("Attendance updated successfully");
  //         Setcontent("");
  //         Setdate("");
  //         gettimesheet();
  //       }).catch(() => {
  //         // Handle error
  //       });
  //     } else {
  //       // Add new record
  //       let applyleave = {
  //         emp_id: location?.state?.emp_id,
  //         date: date,
  //         totalhr: totalhr,
  //         createdby: 1,
  //         content: content,
  //       };
  //       axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, applyleave).then((res) => {
  //         console.log('Attendance added', res.data);
  //         alert("Attendance added successfully");
  //         Setcontent("");
  //         Setdate("");
  //         gettimesheet();
  //       }).catch(() => {
  //         // Handle error
  //       });
  //     }
  //   }).catch(() => {
  //     // Handle error
  //   });
  // };






  const onInsert = (data, e) => {
    let totalhr;

    const workSchedule = {}; // Store the employee's work schedule

    // Fetch the employee's work schedule
    axios.get(`${process.env.REACT_APP_API_URL}/get_work_schedule/${location?.state?.emp_id}`)
      .then((res) => {
        if (res.data.length > 0) {
          const schedule = res.data[0];
          workSchedule[1] = parseInt(schedule.monday); // Use 1 for Monday
          workSchedule[2] = parseInt(schedule.tuesday); // Use 2 for Tuesday
          workSchedule[3] = parseInt(schedule.wednesday); // Use 3 for Wednesday
          workSchedule[4] = parseInt(schedule.thursday); // Use 4 for Thursday
          workSchedule[5] = parseInt(schedule.friday); // Use 5 for Friday
          workSchedule[6] = parseInt(schedule.saturday); // Use 6 for Saturday
          workSchedule[0] = parseInt(schedule.sunday); // Use 0 for Sunday
        }
        console.log(workSchedule, "work");
        if (content === "Paid Leave") {
          const dayOfWeek = new Date(date).getDay();
          console.log("dayOfWeek:", dayOfWeek);
          if (workSchedule[dayOfWeek] === 8) {
            totalhr = 4; // Half working day
          } else if (workSchedule[dayOfWeek] === 7) {
            totalhr = 8; // Full working day
          } else {
            totalhr = 0; // weekend working day
          }
        } else if (content === "Present") {
          const dayOfWeek = new Date(date).getDay();
          console.log("dayOfWeek:", dayOfWeek);
          if (workSchedule[dayOfWeek] === 8) {
            totalhr = 4; // Half working day
          } else if (workSchedule[dayOfWeek] === 7) {
            totalhr = 8; // Full working day
          } else {
            totalhr = 0; // weekend working day
          }
        } else {
          totalhr = 0;
        }

        // Check if record already exists
        axios.get(`${process.env.REACT_APP_API_URL}/attget/${location?.state?.emp_id}/${date}`).then((res) => {
          if (res.data.length > 0) {
            // Update existing record
            const attendance_id = res.data[0].attendance_id;
            const old_totalhr = res.data[0].totalhr;
            let new_totalhr;

            console.log(old_totalhr, 'new1');
            if (content === "Paid Leave") {
              if (workSchedule[new Date(date).getDay()] === 8) {
                new_totalhr = 4; // Half working day

              } else if (workSchedule[new Date(date).getDay()] === 7) {
                new_totalhr = 8; // Full working day

              }
              else {
                new_totalhr = 0; // weekend working day

              }
            } else if (content === "Present") {
              if (workSchedule[new Date(date).getDay()] === 8) {
                new_totalhr = 4; // Half working day

              } else if (workSchedule[new Date(date).getDay()] === 7) {
                new_totalhr = 8; // Full working day

              }
              else {
                new_totalhr = 0; // weekend working day

              }
            }
            else if ((content !== "Paid Leave" || content !== "Present") && (old_totalhr === 8 || old_totalhr === 4)) {
              new_totalhr = 0;
            } else {
              new_totalhr = old_totalhr;
            }
            console.log(new_totalhr, 'new');
            let applyleave = {
              emp_id: location?.state?.emp_id,
              date: date,
              totalhr: new_totalhr,
              createdby: 1,
              contents: content
            };
            axios.put(`${process.env.REACT_APP_API_URL}/updateattendance/${attendance_id}`, applyleave).then((res) => {
              console.log('Attendance updated', res.data);
              alert("Attendance updated successfully");
              Setcontent("");
              Setdate("");
              gettimesheet();
            }).catch(() => {
              // Handle error
            });
          } else {
            // Add new record
            let applyleave = {
              emp_id: location?.state?.emp_id,
              date: date,
              totalhr: totalhr,
              createdby: 1,
              content: content,
            };
            axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, applyleave).then((res) => {
              console.log('Attendance added', res.data);
              alert("Attendance added successfully");
              Setcontent("");
              Setdate("");
              gettimesheet();
            }).catch(() => {
              // Handle error
            });
          }
        })

      }).catch(() => {
        // Handle error
      });
  };





  // handle disabled submit
  const isValid = date && content

  const [leavetotal, setLeavetotal] = useState([]);
  const Getleavetotal = (emp_id) => {
    if (state.user) {
      axios.get(`${process.env.REACT_APP_API_URL}/empdashboard_leave/${location?.state?.emp_id}`).then((res) => {
        console.log(res.data, 'leave');

        setLeavetotal(res.data);


      }).catch(() => {

      })
    }
  }
  useEffect(() => {

    Getleavetotal();

  },
    [state]);

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 4;
  const pagesVisited = pageNumber * usersPerPage;

  const displayUsers = leavelist

    .slice(pagesVisited, pagesVisited + usersPerPage)


  const pageCount = Math.ceil(leavelist.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [finance, setfin] = useState([]);
    const Getfinancial = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/financialyear/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'fin');
                setfin(res.data);
                handleSelectChange(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [monthsDiff, setMonthsDiff] = useState(0);
    const handleSelectChange = (financialData) => {
        const currentDate = new Date();
        console.log('Number of months:', financialData[0]?.startmonth);
        const startMonth = new Date(financialData[0]?.startmonth).getMonth(); // Get the start month as a number (January is 0)
     // const startYear = new Date(financialData?.startmonth).getFullYear(); // Get the start year
        const currentMonth = currentDate.getMonth(); // Get the current month as a number (January is 0)
        const currentYear = currentDate.getFullYear(); // Get the current year
       
        
        const newMonthsDiff = (currentMonth - startMonth) ;
       
        setMonthsDiff(newMonthsDiff);
        console.log('Number of months:', monthsDiff);
        // Rest of your code
      };

      
    useEffect(() => {
        Getfinancial();

    }, [state]);



  return (
    <div className="container-fluid ">

      {(() => {
        if (tab_type == "Attantance") {
          return <div class="row">

            <div class="col-sm-2 col-xxl-2 col-md-1 col-lg-1 col-xl-2 ms-sm-3 ">

            </div>
            <div class="col-sm-6  col-xxl-6 col-md-6 col-lg-6 col-xl-6 ms-sm-5 mt-5">

              <ul class="nav nav-tabs nav-justified hide_all" role="tablist">

                <li class="nav-item fw-bold nav-mob">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Attendance</a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link fnt-clr " onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Leave History</a>
                </li>

              </ul>
              <ul class="nav  nav-justified hide_for_pc nav-att tab-nav" role="tablist">

<li class="fw-bold">
  <a class="" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Attendance</a>
</li>
<li class="nav-item  ">
  <a class=" " onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Leave History</a>
</li>

</ul>

              <div class=" mt-5 hide_all caltab" style={{ height: '350pt', width: '500pt', alignItems: 'center' }}>

                <Calendar
                  events={eve_nts}
                  startAccessor="start"
                  endAccessor="end"
                  defaultDate={moment().toDate()}
                  localizer={localizer}
                />

              </div>
              <div class=" mt-3 ms-4 hide_for_pc" style={{ height: '160pt', width: '200pt', alignItems: 'center' }}>

<Calendar
  events={eve_nts}
  startAccessor="start"
  endAccessor="end"
  defaultDate={moment().toDate()}
  localizer={localizer}
/>

</div>
            </div>
            <div class=" col-sm-3 col-xxl-3 col-md-5 col-lg-5 col-xl-3 mt-5 hide_all upattri">

              <div className='row mt-5'>
                <div className='col fw-bold text-center mt-4'>Update Attendance </div>

              </div>
              <div className='row'>

                <div className='col'> </div>
              </div>

              <div className='row mt-4'>
                <div className='col'>

                </div>

              </div>

              <div className='row mt-4'>
                {empget.map((y, index) => {
                  return (
                    <p className="text-capitalize">Employee Name : {y.full_name}</p>
                  )
                })}
                <div className='col'>
                  <small>Select Date</small>

                  <input
                    min={moment().format("YYYY-MM-DD")}
                    type='date'
                    placeholder='To Date'
                    className="txtaddr form-control inputcolor mt-1"
                    value={date} onChange={(e) => Setdate(e.target.value)}
                  />
                </div>

              </div>






              <div className='row'>
                <div className='col'>
                  <textarea type="text " class="form-control inputcolor mt-3" id="inputPassword2" placeholder="Add comment" />

                </div>

              </div>
              <div className='row'>
                <div className='col'>
                  <select class="form-select inputcolor text-start  mt-3" aria-label=" select Day" value={content} onChange={(e) => (Setcontent(e.target.value))} >
                    <option selected>select Type </option>
                    <option value="Unpaid Leave">Unpaid Leave </option>
                    <option value="Paid Leave" >Paid Leave</option>
                    <option value="Present">Present</option>
                  </select>
                </div>


              </div>

              <div className='row mt-3'>
                <div className='col d-grid'>
                  <form className="d-grid" onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                    <button type="submit" class=" btn btn-pri" disabled={!isValid}>Update</button>
                  </form>
                  <button className="btn btn-secondary mt-3" onClick={employee}>Back</button>
                </div>
              </div>
              <div class="form-group">

              </div>



            </div>

            <div class=" col-sm-3  mt-5 onlytab hide_tab">

<div className='row '>
  <div className='col fw-bold text-center mt-2'>Update Attendance </div>

</div>
<div className='row'>

  <div className='col'> </div>
</div>

<div className='row mt-4'>
  <div className='col'>

  </div>

</div>

<div className='row mt-2'>
  {empget.map((y, index) => {
    return (
      <p className="text-capitalize">Employee Name : {y.full_name}</p>
    )
  })}
  <div className='col'>
    <small>Select Date</small>

    <input
      min={moment().format("YYYY-MM-DD")}
      type='date'
      placeholder='To Date'
      className="txtaddr form-control inputcolor mt-1"
      value={date} onChange={(e) => Setdate(e.target.value)}
    />
  </div>

</div>






<div className='row'>
  <div className='col'>
    <textarea type="text " class="form-control inputcolor mt-3" id="inputPassword2" placeholder="Add comment" />

  </div>

</div>
<div className='row'>
  <div className='col'>
    <select class="form-select inputcolor text-start  mt-3" aria-label=" select Day" value={content} onChange={(e) => (Setcontent(e.target.value))} >
      <option selected>select Type </option>
      <option value="Unpaid Leave">Unpaid Leave </option>
      <option value="Paid Leave" >Paid Leave</option>
      <option value="Present">Present</option>
    </select>
  </div>


</div>

<div className='row mt-3'>
  <div className='col d-grid'>
    <form className="d-grid" onSubmit={handleSubmit(onInsert)} action="#" method="POST">
      <button type="submit" class=" btn btn-pri" disabled={!isValid}>Update</button>
    </form>
    <button className="btn btn-secondary mt-3" onClick={employee}>Back</button>
  </div>
</div>
<div class="form-group">

</div>



</div>


          </div>

        } else if (tab_type == "ot") {
          return <div class="row ">

            <div class="col-sm-2 col-xxl-2 col-md-1 col-lg-1 col-xl-2">

            </div>
            <div class="col-sm-6 col-xxl-6 col-md-6 col-lg-6 col-xl-6 ms-sm-5 mt-5">

              <ul class="nav hide_all nav-tabs nav-justified navmobv nav-att tab-nav" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="nav-item  nav-mob">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Attendance</a>
                </li>
                <li class="nav-item  fw-bold">
                  <a class="nav-link fnt-clr " onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Leave History</a>
                </li>

              </ul>
              <ul class="nav hide_for_pc" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="">
                  <a class="" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Attendance</a>
                </li>
                <li class=" fw-bold">
                  <a class=" " onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Leave History</a>
                </li>

              </ul>


              
               <table class="table table-bordered mt-4 leavtablef">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>  <td scope="col"></td>

                                                                <td scope="col" className='headfont  text-center text-uppercase greyh '>Leave Type</td>

                                                                <td scope="col" className='headfont  text-center text-uppercase grey '>Total Leaves</td>
                                                                <td scope="col" className='headfont  text-center text-uppercase grey '>Available Leaves</td>
                                                                <td scope="col" className='headfont  text-center text-uppercase grey '>Leave Taken</td>
                                                               

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                        {emleave.map((y, index) => {
                                                          const  leavedays =  (y.leavepermonth/12)* monthsDiff
                return (
                  
                  
               
<tr>
<th scope="row"><div className="ms-3">  </div></th>
<td className=''><p className='text-center'>{y.leave_type}</p></td>
{/* <td className=''><p className='text-center'>{y.leave_count}</p></td> */}

<td className=''><p className='text-center'>{y.leavepermonth}</p></td>
{(()=>{
  if(y.earned==true){
return(
  <td className=''><p className='text-center'>{y.leave_count}</p></td>
)
  }else{
    return(
      <td className=''><p className='text-center'>{y.leave_count}</p></td>
    )
  }
})()}
{(()=>{
  if(y.earned==true){
return(
  <td className=''><p className='text-center'>{leavedays - y.leave_count}</p></td>
)
  }else{
    return(
      <td className=''><p className='text-center'>{y.leave_count - y.leavepermonth}</p></td>
    )
  }
})()}

                                                               

</tr>
               );
              })}                                             




                                                        </tbody>

                                                    </table>
                                                </table>
            </div>


            <div class="col-sm-2 col-xxl-2 col-md-2 col-lg-2 col-xl-2 mt-5 ms-sm-md-5 hide_all">
              <p className="mt-5 fw-bold">Leave History </p>
              {empget.map((x, index) => {
                  return (
                    <p className="text-capitalize">Employee Name : {x.full_name}</p>
                  )
                })}
             
                {leavetotal.map((a, index) => {
                      return (<div>
                        {(() => {
                          if (a.totalleave == null) {
                            return <p className=" ">
                              Total Leaves Taken:  0
                            </p>
                          } else {
                            return <p className=" ">
                              Total Leaves Taken: {a.totalleave}
                            </p>
                          }
                        })
                          ()}
                      </div>);
                    })}
              {displayUsers.map((x, index) => {
                return (
                  <div key={index} class="frist-tab">

                    <div class="row mt-4">
                      <div class=" fw-bold fs-6">{moment(x.fromdate).format('DD/MM/YY')} - {moment(x.todate).format('DD/MM/YY')}</div>

                    </div>
                    <div class="row">

                      {(() => {
                        if (x.session_1 && x.session_2 == true) {
                          return <td className='hidecoltab mobsimplytext '>Full Day</td>
                        } else {
                          return <td className='hidecoltab mobsimplytext '>Half Day</td>
                        }
                      })()}
                    </div>
                    <div class="row">
                      {(() => {
                        if (x.approved == "true") {
                          return <td className='hidecoltab mobsimplytext text-success'>Leave Approved</td>
                        }
                        if (x.approved == "false") {
                          return <td className='hidecoltab mobsimplytext text-danger'>Leave Rejected</td>
                        } else {
                          return <td className='hidecoltab mobsimplytext text-danger'>Pending</td>
                        }

                      })()}
                    </div>
                  </div>
                );
              })}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              <div className="row mt-4 ms-1">
                <button type="submit" className="btn btn-secondary mt-2" onClick={employee}>Back</button>
              </div>

            </div>
            <div class="col-sm-2 mt-1 ms-sm-5 onlytab hide_tab">
              <p className="mt-1 fw-bold">Leave History </p>
              {empget.map((x, index) => {
                  return (
                    <p className="text-capitalize">Employee Name : {x.full_name}</p>
                  )
                })}
             
                {leavetotal.map((a, index) => {
                      return (<div>
                        {(() => {
                          if (a.totalleave == null) {
                            return <p className=" ">
                              Total Leaves Taken:  0
                            </p>
                          } else {
                            return <p className=" ">
                              Total Leaves Taken: {a.totalleave}
                            </p>
                          }
                        })
                          ()}
                      </div>);
                    })}
              {displayUsers.map((x, index) => {
                return (
                  <div key={index} class="frist-tab">

                    <div class="row mt-4">
                      <div class=" fw-bold fs-6">{moment(x.fromdate).format('DD/MM/YY')} - {moment(x.todate).format('DD/MM/YY')}</div>

                    </div>
                    <div class="row">

                      {(() => {
                        if (x.session_1 && x.session_2 == true) {
                          return <td className='hidecoltab mobsimplytext '>Full Day</td>
                        } else {
                          return <td className='hidecoltab mobsimplytext '>Half Day</td>
                        }
                      })()}
                    </div>
                    <div class="row">
                      {(() => {
                        if (x.approved == "true") {
                          return <td className='hidecoltab mobsimplytext text-success'>Leave Approved</td>
                        }
                        if (x.approved == "false") {
                          return <td className='hidecoltab mobsimplytext text-danger'>Leave Rejected</td>
                        } else {
                          return <td className='hidecoltab mobsimplytext text-danger'>Pending</td>
                        }

                      })()}
                    </div>
                  </div>
                );
              })}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              <div className="row mt-4 ms-1">
                <button type="submit" className="btn btn-secondary mt-2" onClick={employee}>Back</button>
              </div>

            </div>
          </div>


        }

      })()}


      <Lheader />

    </div>
  )
}
export default EmpleaveHistory;