import React, { useCallback, useState, useContext, useEffect } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import './Leavedetails.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
const Leavedetails = (props) => {

    let navigate = useNavigate();
    const letask = () => {
        let path = `/leave`;
        navigate(path);
    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to reject',
            buttons: [
              {
                label: 'Yes',
                onClick : () =>  letask(),
                style:{
                    backgroundColor:'#F36E35'
                   
               }
              },
              {
                label: 'No',
                onClick: () =>  alert('canceled')
              }
            ]
          })
        }
        const alert = () => {
            confirmAlert({
                // title: 'Cancle',
                message: 'Are you sure to approve ',
                buttons: [
                  {
                    label: 'Yes',
                    onClick : () => Getleave(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                  },
                  {
                    label: 'No',
                   
                  }
                ]
              })
        }
        const [leave_details, setUserDetails] = useState([]);
       
        const Getleave = (applyleave_id) => {
            axios.get(`${process.env.REACT_APP_API_URL}/applyleave/3` ).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);
    
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
        useEffect(() => {
            Getleave();
        },
            []);
    return (
        <div className="bg-light container-fluid">

            <div className="row">

                <div className="col-sm-2 hid_tab">

                </div>
                <div className="col-sm-10 mt-5">

                    <div className="card Card_1task mt-4   ">
                        <div className="card-body">
                            <div className="row">

                                <div className="col">
                                    <div className='row'>


                                        <div className='col'>
                                            <p className="fw-bold fs-4"> Leave Details</p>
                                            <table class="table table-bordered mt-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>  <td scope="col"></td>
                                                            <td scope="col" className='fs-6 text-secondary'>Name</td>

                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Date<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-contract ms-3" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M3.646 13.854a.5.5 0 0 0 .708 0L8 10.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zm0-11.708a.5.5 0 0 1 .708 0L8 5.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708z" />
                                                            </svg></td>
                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Session 1 </td>
                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Session 2 </td>
                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Leave Type </td>
                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Department  </td>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {leave_details.map((x, index) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row"><div className="ms-3"> <img src={`Images/u1.svg`} ></img> </div></th>
                                                            <td className=''>{x.fullname}<p></p></td>
                                                            <td className='mobsimplytext'>{moment(x.fromdate).format('MMM Do YYYY')} - {moment(x.todate).format('MMM Do YYYY')}</td>
                                                            <td>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#53B7B7" class="bi bi-check-square-fill ms-3" viewBox="0 0 16 16">
                                                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                                            </svg></td>
                                                            <td> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#53B7B7" class="bi bi-check-square-fill ms-3" viewBox="0 0 16 16">
                                                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                                            </svg></td>
                                                            <td className='mobsimplytext'>{x.leave_type}</td>
                                                            <td className='mobsimplytext'>{x.department}</td>

                                                        </tr>
                                                          );
                                                        })}



                                                    </tbody>

                                                </table>
                                            </table>
                                            <p className="fw-bold fs-5 mt-3">
                                                Reason
                                            </p>
                                            {leave_details.map((x, index) => {
                                                    return (
                                            <p className="fs-6">
                                          {x.reason}
                                           </p>
                                             );
                                            })}
                                        </div>
                                    </div>


                                    <div className="row ">
                                        <button class="btn btn-pri  ms-3 mt-4 appr-mv" type="button" onClick={alert}>Approve</button>
                                        <button class="btn btn-danger ms-4  mt-4 reje-mv" type="button" onClick={alet}>Reject</button>

                                        <div className="row">
                                            <textarea class="form-control inputcolor task_textbox_2  ms-3 mt-3" placeholder="Comments" id="exampleFormControlTextarea1" rows="3"></textarea>

                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <button class="btn btn-secondary  ms-2 mt-3 back-mv" type="button" onClick={letask}>Back</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Lheader />
        </div>
    )


}
export default Leavedetails