import React, { useCallback, useState, useContext,useEffect } from 'react';
import axios from 'axios';
import './Timesheetapp.css';
import moment from 'moment';
import { useNavigate,useLocation } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
const Timesheetapproved = () => {
    const location = useLocation();
    console.log('emp_id: ', location?.state?.emp_id);
    const { state } = useContext(store);
    console.log('profile_cmp', state.user[0].company_id);
    let navigate = useNavigate();
    const empprofile = () => {
        let path = `/timesheetlist`;
        navigate(path);
    }
    const alet = () => {
    confirmAlert({
        // title: 'Cancle',
        message: 'Are you sure to cancel',
        buttons: [
          {
            label: 'Yes',
            onClick : () => empprofile(),
            style:{
                backgroundColor:'#F36E35'
               
           }
          },
          {
            label: 'No',
            onClick: () =>  alert('Canceled')
          }
        ]
      })
    }
// get time sheet
const [time_details, setUserDetails] = useState([]);
const gettimesheet = (x) => {
    if(state.user) {
    
    axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_details`).then((res) => {
        console.log(res.data, 'success');
        setUserDetails(res.data);

    }).catch((error) => {
        console.log(error, 'success');
    });

}
}
useEffect(() => {
    gettimesheet();
 }, [state]);
    return (
        <div className='bg-light vh-100 container-fluid '>

            <div className='row mt-5'>
                <div className='col-2 hid_tab'>

                </div>
                <div className='col-10 mt-1'>
                    <input type="text" class="form-control inputcolor mt-4 searchbox" placeholder="Search by Account, Date, Category or Amount" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <div className='row'>
                        <div className='col-1 '></div>
                        <div className='col-2'>  <input type="date" class="form-control inputcolor dtbx " placeholder=" Date of Birth" aria-label="" />
                       </div>
                         to
                        <div className='col-2 '>  <input type="date" class="form-control inputcolor  dtbx1  " placeholder=" Date of Birth" aria-label="" />
                        </div>
<div className='col-2'></div>
<div className='col-1'> <button className='btn btn-secondary hide_all' onClick={alet}>cancel</button></div>
<div className='col-2 ms-4'>  <button className='btn btn-pri hide_all'>Approve</button></div>
                    </div>
                    <div class="card taskcard shadow  mb-5 bg-white rounded tablcard">

<div class="card-body">
<div className='row text_s'>
    <div className='col-1'><img src={`Images/u1.svg`} ></img>  </div>
    <div className='col-3 ms-2'>  Alexander George<p>Emp Id:130345</p></div>
   
<div className='col-3'></div>
<div className='col-2 '>
    <p className='fw-bold  '>160hrs<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill ms-2" viewBox="0 0 16 16">
                               <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                               <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                           </svg></p>
</div>
<div className='col-2'>
    <p>Pending</p>
</div>

</div>
    <div className='row text_s'>
        <div className='col pointer_'>
            <table class="table table-bordered mt-3">
                <table class="table ">
                    <thead>
                        <tr>
                           <td scope="col" className='fs-6 text_s text-secondary'>Date</td>
                            <td scope="col" className='fs-6 text-secondary'><p className='text_s'> Swipe in</p></td>
                            <td scope="col" className='fs-6 text-secondary text_s'><p className='text_s'> Swipe off</p></td>
                            <td scope="col" className='fs-6 text-secondary  '><p className='text_s'>  Working Time</p></td>
                            <td scope="col" className='fs-6 text-secondary'><p className='text_s'>  Accept <input class=" form-check-input " type="checkbox" value="" id="flexCheckChecked" /></p></td>
                            <td scope="col" className='fs-6 text-secondary '><p className='text_s'>  Total</p> </td>
                            
                            <td scope="col-2" className='fs-6 text-secondary hide_all'> </td>
                            <td scope="col" className='fs-6 text-secondary hide_all'>Comment </td>
                          

                        </tr>
                    </thead>
                    <tbody>
                    {time_details.map((x, index) => {
                                        return (
                        <tr className=''>
                           
                            
                            <td >{moment(x.date).format(' MMM Do YYYY')}</td>
                            <td className=''> <p className='timebox text-center'> {moment(x.intim).format('h:mm:ss A')}</p>
                            <p className='timebox text-center'> {moment(x.out).format('h:mm:ss A')}</p>
                            <p className='timebox text-center'>{x.totalhr}</p>
                            <p className='timebox text-center'> 05.30 PM</p>
                               </td>
                           
                            <td className=''>9 hrs </td>
                          <td> <p className='text-primary'> +1 hrs </p></td>
                         
                           
                        </tr>
                                        )})}

                    </tbody>
                </table>
            </table>
        </div>
    </div>
</div>
</div>
                    
                </div>

            </div>
            <Lheader />
        </div>
    )
}
export default Timesheetapproved