import { React, useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { confirmAlert } from 'react-confirm-alert'; // Import
const Dashboard_suerpvisor = () => {
    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    const { state } = useContext(store);
    console.log('company list', state.user);
    const [company_list, setCompany_list] = useState([])
    const { update, handleSubmit } = useForm();
    const [holiday_title, setHoliday_title] = useState([])
    const [tasklist, setTask_list] = useState([])
    const [timedetails, settimedetails] = useState([])
    const [currency_details, setcurrency_details] = useState([]);
    const [stdate, startdate] = useState('');
    const [trail, gettrail] = useState('');
    const [comp_details, setCompany_data] = useState([]);
    const current = new Date();
    const todaedate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const Getcompanylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_count_supervisor/${state?.user[0]?.company_id}/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }
    const Getholidaylist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/holidayslistcurrentmonth/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setHoliday_title(res.data);

            }).catch(() => {

            })
    }
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });

    const Gettasklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/task_list/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setTask_list(res.data);

            }).catch(() => {

            })

    }
    const Getcurrency = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/currency_1/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/curr/6`).then((result) => {
                        console.log(result.data, 'curr');
                        setcurrency_details(result.data);
                    })
                } else { setcurrency_details(res.data); }

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const Gettimedetails = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet_all/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                settimedetails(res.data);

            }).catch(() => {

            })

    }
    const Getleavedata = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/apply_leave_cmp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setTask_list(res.data);

            }).catch(() => {

            })

    }
    useEffect(() => {
        Getholidaylist();
        Getleavedata();
        Gettimedetails();

    }, [state]);

    useEffect(() => {
        Getcompanylist();
        Getcurrency();

    }, [state]);

    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const todotask = () => {
        let path = `/leave`;
        navigate(path);
    }
    const overtime = () => {
        let path = `/timesheetlist`;
        navigate(path);
    }
    const payslip = (id) => {
        let path = `/emppayslip`;
        navigate(path, { state: { emp_id: id } });
    }
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001', '4/11/2001', '5/11/2001', '6/11/2001'],
                tickAmount: 10,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), 'MMM')
                    }
                }
            },
            title: {
                text: 'Monthly Analyser',
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666',
                    fontFamily: 'Comfortaa'
                }
            },
            fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shade: 'dark',
                //     gradientToColors: [ '#FDD835'],
                //     shadeIntensity: 1,
                //     type: 'horizontal',
                //     opacityFrom: 1,
                //     opacityTo: 1,
                //     stops: [0, 100, 100, 100]
                //   },
                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 30
            }
        },
        series: [{
            name: 'Work',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 17, 1]
        }],

    })
    const [paytotal, setPaytotal] = useState([]);
    const [datedif, setdatediff] = useState('');
    const [leave_details, setUserDetails] = useState([]);


    const goLogin = () => {
        navigate("/");

    };

    const [hrstotal, setHrstotal] = useState([]);
    const Gethrstotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/empdashboard_hrs/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');

                setHrstotal(res.data);


            }).catch(() => {

            })
        }
    }
    const [leavetotal, setLeavetotal] = useState([]);
    const Getleavetotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/empdashboard_leave/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');

                setLeavetotal(res.data);


            }).catch(() => {

            })
        }
    }
    //   const [paytotal, setPaytotal] = useState([]);
    const Getpaytotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_pay/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
                        console.log(result.data, 'pay');
                        setPaytotal(result.data);
                    })
                } else { setPaytotal(res.data); }
            }).catch(() => {

            })
        }
    }
    useEffect(() => {

        Getcurrency();
        Getpaytotal();
        Getleavetotal();
        Gethrstotal();
    },
        [state]);

        const emped = () => {
            let path = `/EmpProfileedit`;
            navigate(path);
        }
        const [empdata, setempdata] = useState([]);
    
        const Getempdata = (emp_id) => {
            if (state.user) {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/emp_data/${state?.user[0]?.emp_id}`)
                    .then((res) => {
                        console.log(res.data, "empdata");
                        setempdata(res.data);
                    })
                    .catch((err) => {
                        console.error(err.message);
                        ale_rt(); // Show the error popup
                    });
            }
        };
        useEffect(() => {
           
            if (state.user && state.user[0]?.emp_id) {
                Getempdata();
            }
        },
    
            [state]);
        const ale_rt = () => {
            confirmAlert({
                // title: 'Cancle',
                message: 'Please complete the profile data ',
                buttons: [
                    {
                        label: 'Complete',
                        onClick: () => emped(),
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    }
    
                ]
            })
        }

    return (
        <div className="bg-light vh-100 container-fluid">

            <div className="row mt-4">


                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab ">


                </div>
                <div className="  col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 bg-light">


                    <div className="lefdash">
                        <div class="card empviewdetailssup ms-5 mt-5">

                            <div class="card-body">
                                <blockquote class="blockquotemb-0">

                                    <div className="row">


                                        <div className="col-sm-12">
                                            {company_list.map((x, index) => {
                                                return (<p className="mt-2 fw-bold grey supertxtmob"> Total number of employees: {x.case}</p>

                                                );
                                            })}
                                        </div>


                                    </div>

                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="ms-4 mt-2 onlytab hide_tab">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                    {hrstotal.map((h, index) => {
                        return (<div>
                            {leavetotal.map((a, index) => {
                                return (<div>
                                    {currency_details.map((y, index) => {
                                        return (<div>
                                            {paytotal.map((x, index) => {
                                                return (
                                                    <div className="row scroll-container">
                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4  pointer_ lefdash">
                                                            <div class="card card_1 ms-5 mt-3" onClick={() => payslip(x.emp_id)}  >

                                                                <div class="card-body">
                                                                    <img className="mx-auto d-block" src="./Images/card_1.svg"></img>
                                                                    {(() => {
                                                                        if (x.payroll_amount == null) {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-1 fs-5 text-lowercase">

                                                                                {x.payroll_amount.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')}  {y.symbol}

                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}
                                                                    <p className="text-white text-center mt-3">
                                                                        Pay Slip
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4">
                                                            <div class="card card_3 ms-4  mt-3 " >


                                                                <div class="card-body ">
                                                                    <img className="mx-auto d-block" src="./Images/card_2emp.svg"></img>

                                                                    {(() => {
                                                                        if (h.totalhrssum == null) {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                {h.totalhrssum.toFixed(1)} Hours


                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}
                                                                    <p className="text-white text-center mt-3">
                                                                        Working Hours
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4">
                                                            <div class="card card_2 ms-4 mt-3" >

                                                                <div class="card-body">

                                                                    <img className="mx-auto d-block" src="./Images/card_3emp.svg"></img>

                                                                    {(() => {
                                                                        if (a.totalleave == null) {
                                                                            return <p className="text-white text-center mt-2 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-2 fs-5">
                                                                                {a.totalleave} Days
                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}
                                                                    <p className="text-white text-center mt-3">
                                                                        Leave
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                                );
                            })}
                        </div>
                        );
                    })}
                    <div className="row mt-3">
                        <div className="col-sm-6 col-xxl-6 col-md-6 col-lg-6 col-xl-6 lefdash grlf">
                            <div class="card card_overtime  ms-5 mt-3 graphcardwidthmob" >

                                <div class="card-body">
                                    < p className=" fw-bold fs-6 text-center grey"> Pending Overtime Approvals </p>
                                    {tasklist.length > 0 ? (
                                        timedetails.map((x, index) => {
                                            return (
                                                <p className="text_small_mid text-center">
                                                    <p className="fw-bold text-capitalize">Employee Name: <small className="fw-normal">{x.full_name}</small>,   OT Date: <small className="fw-normal">{moment(x.date).format('DD-MM-YYYY')}</small>, OT Hours: <small className="fw-normal">{x.overtimehr.toFixed(1)}</small></p>
                                                    <p className="border-bottom"></p>

                                                </p>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center">
                                            There are no pending approvals today
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-9">

                                        </div>

                                        <div className="col-3 mt-2">
                                            <a className="text-primary _text  pointer_" onClick={overtime}> view all</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-sm-3 col-xxl-3 col-md-6 col-lg-6 col-xl-3 pendcard">
                            <div class="card card_leave ms-1 mt-3 " >

                                <div class="card-body">
                                    < p className=" fw-bold text-center grey">Pending  Leave Approvals </p>
                                    {tasklist.length > 0 ? (
                                        tasklist.map((x, index) => {
                                            return (
                                                <p className="text_small">

                                                    <p className="fw-bold text-center text-capitalize">Employee Name: {x.full_name}
                                                        <p className="text-center fw-normal mt-1">Leave Date: {moment(x.fromdate).format('DD-MM-YYYY')}-{moment(x.todate).format('DD-MM-YYYY')}</p></p>
                                                    <p className="border-bottom"></p>
                                                </p>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center">
                                            There are no pending approvals today
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-8">

                                        </div>

                                        <div className="col-4">
                                            <a className="text-primary _text  pointer_" onClick={todotask}> view all</a>

                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xxl-3 col-md-6 col-lg-6 col-xl-3 holcard">
                            <div class="card card_holidaysuper mt-3 graphcardwidthmob" >

                                <div class="card-body _text">
                                    < p className=" fw-bold fs-6 grey text-center">Holidays in {month}</p>
                                    {holiday_title.length > 0 ? (
                                        holiday_title.map((x, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className="text_small">
                                                        <p className="fw-bold  text-center">{moment(x.holidaydate).format('DD MMMM,dddd')}
                                                            <p className="text-center fw-normal mt-1">{x.holiday_name}</p></p>
                                                        <p className="border-bottom"></p>
                                                    </p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center greyh">
                                            There are no holidays in this month
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-8">

                                        </div>


                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">

                                    </div>

                                    <div className="col-4">
                                        <a href="" className="text-primary _text  hide_all_pc" > view all</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Lheader />

        </div>
    )
}
export default Dashboard_suerpvisor