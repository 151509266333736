import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import './Timesheetapp.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';
import moment from 'moment';
const Timesheetlist = () => {
    const { state } = useContext(store);
    console.log('profile', state.user);
    let navigate = useNavigate();
    const getclock = () => {
        // <Timesheetlist />

    }
    const [tab_type, setTabType] = useState("");
    const TabChange = (type) => {
        console.log('type', tab_type)
        if (type == 'ot') {

            setTabType(type);
        }
        else {

            setTabType(type);
        }

    }

    const { update, handleSubmit } = useForm();
    const gettimesheet = (x) => {

        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/overtimeall/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.usertypename && state?.user[0]?.usertypename == 'Supervisor') {
                    datalist = datalist.filter(x => x?.manager == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setUserDetails(datalist);

            }).catch(() => {

            })

    }
    // night shift update  

    // const gettimesheet = () => {
    //     if (state.user) {
    //         axios.get(`${process.env.REACT_APP_API_URL}/overtimeall/${state?.user[0]?.company_id}`).then((res) => {
    //             let temp = [];
    //             res?.data?.forEach(item => {
    //                 item['overtimeemp_id'] = item?.emp_id;
    //                 item['overtimedate'] = item?.date;
    //                 temp.push(item);
    //                 //onupdatenightstatus(item?.emp_id, item?.date);
    //                 //onupdatenightstatusrej(item?.emp_id, item?.date);

    //                 console.log(`Emp ID: ${item?.emp_id}, Date: ${item?.date}`);
    //             });
    //             console.log(temp, 'time');
    //             setUserDetails(temp);
    //         }).catch(() => {

    //         })
    //     }
    // }

    // const onupdatenightstatus = (emp_id, date) => {
    //     console.log('updateot in nightshift');
    //     try {
    //       let pay = {
    //         emp_id: emp_id,
    //         date: moment(date).format('YYYY MM DD'),
    //         nightstatus: 'Approved',
    //       };

    //       axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
    //           console.log('outupdate', res.data);
    //         });
    //     } catch (err) {
    //       console.log(err.message);
    //     }
    //   };

    //   const onupdatenightstatusrej = (emp_id, date) => {
    //     console.log('updateot in nightshift');
    //     try {
    //       let pay = {
    //         emp_id: emp_id,
    //         date: moment(date).format('YYYY MM DD'),
    //         nightstatus: 'Rejected',
    //       };

    //       axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
    //           console.log('outupdate', res.data);
    //         });
    //     } catch (err) {
    //       console.log(err.message);
    //     }
    //   };    

    const [time_details, setUserDetails] = useState([]);
    // const [id, set] = useState([]);
    useEffect(() => {
        gettimesheet();
        getalltimesheet();

    }, [state]);

    const onapprove = (data, e) => {
        try {
            let pay = {
                otstatus: "Approved",
                over_id: bind_user.over_id,
                otcmnt: otcmnt
            };

            axios.put(`${process.env.REACT_APP_API_URL}/emp_ot`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                // save();
                try {
                    let pay = {
                        emp_id: bind_user.emp_id,
                        date: moment(bind_user.date).format('YYYY MM DD'),
                        nightstatus: 'Approved',
                    };

                    // axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
                    //     console.log('outupdate', res.data);
                    // });
                } catch (err) {
                    console.log(err.message);
                }
                ShowDetails('');
                gettimesheet();
                getalltimesheet();
                window.location = '/timesheetlist';

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onrej = (data, e) => {
        try {
            let pay = {

                otstatus: "Rejected",
                over_id: bind_user.over_id,
                otcmnt: otcmnt
            };

            axios.put(`${process.env.REACT_APP_API_URL}/emp_ot`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                try {
                    let pay = {
                        emp_id: bind_user.emp_id,
                        date: moment(bind_user.date).format('YYYY MM DD'),
                        nightstatus: 'Rejected',
                    };

                    // axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
                    //     console.log('outupdate', res.data);
                    // });
                } catch (err) {
                    console.log(err.message);
                }
                // save();
                //onupdatenightstatusrej();
                ShowDetails('');
                gettimesheet();
                getalltimesheet();
                window.location = '/timesheetlist';
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    // edit ott status
    const onrej_edit = (data, e) => {
        try {
            let assigne;
            if (state?.user[0]?.emp_id === null) {
                assigne = state.user[0].user_id;
            } else {
                assigne = state.user[0].emp_id;
            }
            let pay = {

                otstatus: "Rejected",
                over_id: bind_user.over_id,
                finalcmnt: finalcmnt,
                edited_by: assigne,
                afterprocess: false,
            };

            axios.put(`${process.env.REACT_APP_API_URL}/ot_edit`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                try {
                    let pay = {
                        emp_id: bind_user.emp_id,
                        date: moment(bind_user.date).format('YYYY MM DD'),
                        nightstatus: 'Rejected',
                    };

                    // axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
                    //     console.log('outupdate', res.data);
                    // });
                } catch (err) {
                    console.log(err.message);
                }
                // save();

                ShowDetails('');
                gettimesheet();
                getalltimesheet();
                window.location = '/timesheetlist';
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onapp_edit = (data, e) => {
        try {
            let assigne;
            if (state?.user[0]?.emp_id === null) {
                assigne = state.user[0].user_id;
            } else {
                assigne = state.user[0].emp_id;
            }
            let pay = {

                otstatus: "Approved",
                over_id: bind_user.over_id,
                finalcmnt: finalcmnt,
                edited_by: assigne,
                afterprocess: true,
            };

            axios.put(`${process.env.REACT_APP_API_URL}/ot_edit`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                try {
                    let pay = {
                        emp_id: bind_user.emp_id,
                        date: moment(bind_user.date).format('YYYY MM DD'),
                        nightstatus: 'Approved',
                    };

                    // axios.put(`${process.env.REACT_APP_API_URL}/nightshiftstatus`, pay).then((res) => {
                    //     console.log('outupdate', res.data);
                    // });
                } catch (err) {
                    console.log(err.message);
                }
                // save();
                ShowDetails('');
                gettimesheet();
                getalltimesheet();
                window.location = '/timesheetlist';
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [bind_user, setBindUser] = useState({});

    const [user_type, setUserType] = useState('');
    const RenderEdit = () => {
        console.log('bind', bind_user);

        confirmAlert({
            // title: 'Leave Details',

            message: <div className='row'>


                <div class="modal-content">
                    <p className='text-black'> Confirm to approve overtime of {bind_user.full_name} </p>

                    <div className='row'>

                    </div>
                </div>


            </div>,
            buttons: [
                {
                    className: 'bgclr',
                    label: 'Submit',
                    onClick: () => onapprove()
                },

                {
                    label: 'Close'
                },


            ]
        })

    }
    const [query, SetQuery] = useState("");
    const RenderEdit1 = () => {
        console.log('bind', bind_user);

        confirmAlert({
            // title: 'Leave Details',

            message: <div className='row'>


                <div class="modal-content">
                    <p className='text-black'> Confirm to reject overtime Of {bind_user.full_name} </p>
                    <div className='row'>

                    </div>
                </div>


            </div>,
            buttons: [
                {
                    className: 'bglr',
                    label: 'Submit',
                    onClick: () => onrej()
                },
                {
                    label: 'Close'
                },


            ]
        })

    }
    const [time_det, settimeDetails] = useState([]);
    const gettimesheet_det = () => {
        if (bind_user)
            axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_details/${bind_user.emp_id}/${bind_user.date}`).then((res) => {
                console.log(res.data, 'time');
                settimeDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });


    }
    const [otedit, setotedit] = useState([]);
    const getotedit = () => {
        if (bind_user)
            axios.get(`${process.env.REACT_APP_API_URL}/overedit/${bind_user.over_id}`).then((res) => {
                console.log(res.data, 'time');
                settimeDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });


    }
    const [timesheet, setalltimesheet] = useState([]);
    const getalltimesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/getemptimesheet_all/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.usertypename && state?.user[0]?.usertypename == 'Supervisor') {
                    datalist = datalist.filter(x => x?.manager == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setalltimesheet(datalist);
            }).catch((error) => {
                console.log(error, 'success');
            });

    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to go back',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ShowDetails(''),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Canceled')
                }
            ]
        })
    }


    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    const finalcmnt = watch('finalcmnt')
    const isValid = finalcmnt

    //   const femail = watch('email')


    const [final_cmnt, set_final] = useState("");
    const [otcmnt, set_otcmnt] = useState("");
    const Renderview = () => {
        console.log('bind', bind_user);

        gettimesheet_det(bind_user);

        return (

            <div className="container-fluid edit_ren">

                <div className='row mt-5'>
                    <div className='col-sm-2  hid_tab'>

                    </div>
                    <div className='col-sm-10 col-xxl-10  col-md-12 col-lg-12 col-xl-10 bg-light mt-3 tbl'>




                        <div class=" ms-sm-5   mb-5 ">

                            <div class=""><p className=' fs-4 ms-2 fnt-clr'>Overtime Details</p>

                                <div className='row text_s'>
                                    <div className='col-sm-6 '> <p className='ms-2' >Total Overtime Hours Worked :{bind_user.overtimehr.toLocaleString(undefined, { maximumFractionDigits: 2 })} </p></div>
                                    {(() => {
                                        if (bind_user.approvalstatus == "Pending") {
                                            return <div className='row'>

                                                <div className='row hide_all'>
                                                    <div className='col-3  col-xxl-3 col-sm-3 col-md-1 col-lg-3 col-xl-3'>
                                                        <button className='btn btn-secondary timeapp ms-2 mt-4' onClick={() => ShowDetails('')}> Back </button>

                                                    </div>
                                                    <div className=' col-2  col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-1'>

                                                    </div>
                                                    <div className='col-3  tbl col-xxl-3 col-sm-3 col-md-7 col-lg-3 col-xl-4'>

                                                        <button className='btn btn-pri timeapp mt-4  ms-5' type='submit' onClick={onapprove}> Approve </button>




                                                        <button className='btn btn-danger  btnh timeapp mt-4  ms-3' style={{ fontSize: '10px' }} type='submit' onClick={onrej}> Reject </button>

                                                    </div>
                                                    <div className='col-3 '>
                                                        <textarea className=' mt-4 ms-3 form-control inputcolor' minlength="2" maxlength="65" Value={otcmnt} onChange={(e) => set_otcmnt(e.target.value)} placeholder='Add Comment'></textarea>

                                                    </div>

                                                </div>

                                                <div className='row hide_for_pc'>
                                                    <div className='col-sm-6 '>
                                                        <button className='btn btn-secondary timeapp ms-sm-2 mt-4' onClick={() => ShowDetails('')}> Back </button>


                                                        <button className='btn btn-pri timeapp mt-4  ms-5' type='submit' onClick={onapprove}> Approve </button>





                                                    </div>
                                                    <div className='col-sm-6 '>
                                                        <button className='btn btn-danger  btnh timeapp mt-4  ms-sm-3' style={{ fontSize: '10px' }} type='submit' onClick={onrej}> Reject </button>

                                                        <textarea className=' mt-4 ms-sm-3 form-control inputcolor' minlength="2" maxlength="65" Value={otcmnt} onChange={(e) => set_otcmnt(e.target.value)} placeholder='Add Comment'></textarea>

                                                    </div>

                                                </div>





                                            </div>

                                        }

                                        if (bind_user.afterprocess == null && bind_user.approvalstatus != "Pending") {

                                            return <div className=''>
                                                <div className='row hide_all'>
                                                    <div className='col-5'><button className='btn btn-pri timeapp ms-sm-2 mt-2' disabled type='submit' onClick={onapprove}> Approve </button>
                                                        <button className='btn btn-danger timeapp ms-sm-1 btnh mt-2' type='submit' disabled onClick={onrej} > Reject </button>


                                                        <button className='btn btn-pri timeapp ms-sm-1 mt-2' type='submit' data-bs-toggle="modal" data-bs-target="#exampleModal2" > Edit  </button>


                                                        <button className='btn btn-secondary timeapp ms-sm-1 mt-2 btnh' onClick={() => ShowDetails('')}> Back </button>
                                                    </div></div>
                                                <div className='row hide_for_pc '>
                                                    <div className='col-sm-6'><button className='btn btn-pri timeapp ms-sm-2 mt-2' disabled type='submit' onClick={onapprove}> Approve </button>
                                                        <button className='btn btn-danger timeapp ms-1 btnh mt-2' type='submit' disabled onClick={onrej} > Reject </button>
                                                    </div>
                                                    <div className='col-sm-6'> <button className='btn btn-pri timeapp ms-sm-1 mt-2' type='submit' data-bs-toggle="modal" data-bs-target="#exampleModal2" > Edit  </button>


                                                        <button className='btn btn-secondary timeapp ms-1 mt-2 btnh' onClick={() => ShowDetails('')}> Back </button>
                                                    </div>




                                                </div>

                                            </div>



                                        }
                                        else {
                                            return <div className='row'>
                                                <div className='col-5'><button className='btn btn-pri timeapp ms-2' disabled type='submit' onClick={onapprove}> Approve </button>
                                                    <button className='btn btn-danger timeapp ms-1' type='submit' onClick={onrej} disabled> Reject </button>
                                                    <button className='btn btn-secondary timeapp ms-1' onClick={() => ShowDetails('')}> Back </button>
                                                </div>

                                            </div>




                                        }
                                    })()}
                                    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-scrollable ">
                                            <div class="modal-content ">
                                                <div class="modal-header">
                                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Edit Over time</h7>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>

                                                <div class="modal-body ">

                                                    <div className='row'>

                                                        <input

                                                            type='text'
                                                            Value={finalcmnt} onChange={(e) => set_final(e.target.value)}
                                                            placeholder='Enter your comments here'
                                                            minlength="2" maxlength="65"
                                                            className={` form-control inputcolor  `}
                                                            {...register("finalcmnt", {
                                                                required: {
                                                                    value: true,
                                                                    message: '*Comment  is required'
                                                                },

                                                            })}
                                                        />
                                                        <div>
                                                            {errors.finalcmnt && <span className='text-danger'>{errors.finalcmnt.message}</span>}
                                                        </div>
                                                        {(() => {
                                                            if (bind_user.approvalstatus == "Rejected") {
                                                                return <div className='col-2'>
                                                                    <button type='submit' className='btn btn-pri mt-2' onClick={() => onapp_edit()} disabled={!isValid}>Approve</button>
                                                                </div>
                                                            }
                                                            if (bind_user.approvalstatus == "Approved") {
                                                                return <div className='col-2'>
                                                                    <button type='submit' className='btn btn-danger mt-2 btnh ' disabled={!isValid} onClick={() => onrej_edit()} >Reject</button>
                                                                </div>
                                                            }
                                                        })()}


                                                    </div>





                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm'>

                                            <div className="row">
                                                <div className="ms-4 mt-2 onlytab hide_tab">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <table class="table table-bordered mt-3 year-buttonss">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>




                                                            <td scope="col" className='headfont text-dark '></td>

                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Date</td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Clock-IN</td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Clock-OUT</td>

                                                            <td scope="col" className='headfont  text-center text-uppercase grey '>Clocked Hours</td>
                                                            <td scope="col" className='headfont  text-center text-uppercase grey '>Comments</td>

                                                        </tr>
                                                    </thead>

                                                    <tbody>{time_det.map((x, index) => {
                                                        return (
                                                            <tr className='timecardrow'>


                                                                <td className=''> </td>

                                                                <td className=''><p className='text-center greyh'> {moment(x.date).format(' MMM Do YYYY')} </p></td>
                                                                <td className=''><p className='text-center greyh'>{moment(x.intim).format('h:mm:ss A')}</p> </td>
                                                                <td className=''><p className='text-center greyh'>{moment(x.out).format('h:mm:ss A')} </p></td>
                                                                <td className=''><p className=' ms-2 text-center greyh'> {x.totalhr} Hrs </p></td>
                                                                <td className=' text-center greyh'> {x.time_comment}</td>





                                                            </tr>
                                                        )
                                                    })}


                                                    </tbody>
                                                </table>
                                            </table>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            {(() => {
                                                if (bind_user.afterprocess != null) {
                                                    return <table class="table table-bordered mt-3">
                                                        <table class="table table-striped">
                                                            <thead>
                                                                <tr>




                                                                    <td className='headfont text-dark  text-center'>Edited Date</td>

                                                                    <td scope="col" className='headfont text-dark hide_all text-center'>Edited Comment</td>
                                                                    <td scope="col" className='headfont text-dark hide_all text-center'>Edited By</td>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr className='timecardrow'>




                                                                    <td className=''><p className='text-center greyh'> {moment(bind_user.finaltime).format(' MMM Do YYYY')} </p></td>
                                                                    <td className=''><p className='text-capitalize text-center greyh'>{bind_user.finalcmnt} </p></td>
                                                                    <td className=''><p className='text-capitalize text-center greyh'> {bind_user.emp_nam ? bind_user.emp_nam : bind_user.adminname}</p></td>






                                                                </tr>



                                                            </tbody>
                                                        </table>
                                                    </table>

                                                }

                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    }
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }
    const [query_date, SetdateQuery] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 7;
    const usersPerPageo = 12;
    const pagesVisited = pageNumber * usersPerPage;
    const filteredUsers = time_details.filter((x) => x.approvalstatus.toLowerCase().includes(query) || x.full_name.toLowerCase().includes(query.toLowerCase()));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);




    const pageCount = Math.ceil(time_details.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const filtere_dUsers = timesheet.filter((x) => { console.log(moment(x.date).format('YYYY-MM-DD'), query_date); return (moment(x.date).format('YYYY-MM-DD')).includes(query_date) || x.full_name.toLowerCase().includes(query_date.toLowerCase()) });

    const display_Users = filtere_dUsers.slice(pagesVisited, pagesVisited + usersPerPageo);



    const page_Count = Math.ceil(timesheet.length / usersPerPage);



    return (
        <div className='bg-light vh-100 container-fluid'>

            <div className='row mt-4'>
                <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-2 col-lg-2 col-xl-2 hid_tab'>

                </div>
                <div className='col-sm-10 col-xxl-10 col-md-12 col-lg-12 col-xl-10 bg-light mt-4 tbl'>
                    <input type="text" onChange={(e) => {
                        const value = e.target.value;
                        SetQuery(value);
                        SetdateQuery(value);
                    }} class="form-control grey mt-4 ms-sm-5 searchbox " placeholder="Search by Employee Name" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon " viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    {(() => {
                        if (tab_type == "ot") {
                            return <div class="card  shadow  mb-5 bg-white rounded ms-sm-5 ">
                                <ul class="nav hide_all nav-tabs nav-justified navmobv nav-att tab-nav ms-1" role="tablist">
                                    <div class="slider hid_tab"></div>
                                    <li class="nav-item ms-2 ">
                                        <a class="nav-link" style={{ color: '#717372' }} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Overtime</a>

                                    </li>
                                    <li class="  nav-item  ">
                                        <a class="nav-link" style={{ color: '#4EB4E6' }} onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Timesheets</a>

                                    </li>

                                </ul>
                                <ul class="nav hide_for_pc  nav-tabs nav-justified navmobv  tab-nav ms-1" role="tablist">

                                    <li class=" ">
                                        <a class="nav-link" style={{ color: '#717372' }} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Overtime</a>

                                    </li>
                                    <li class="    ">
                                        <a class="nav-link att-tab" style={{ color: '#4EB4E6' }} onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Timesheets</a>

                                    </li>

                                </ul>
                                <div class="card-body">

                                    <div className='row'>
                                        <div className='col-sm-3'>

                                            <input type="date" class="form-control inputcolor  mt-1 pointer_" placeholder=" Month" aria-label="" onChange={(e) => { console.log(e.target.value); SetdateQuery(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <div className="row">
                                                <div className="ms-4 mt-2 onlytab hide_tab">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <table class="table table-bordered mt-3 year-buttonss">


                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>


                                                            <td scope="col" className='headfont  text-center text-uppercase grey'>Name </td>


                                                            <td scope="col" className='headfont   text-center text-uppercase grey'>Date</td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Clock-IN</td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Clock-OUT</td>

                                                            <td scope="col" className='headfont  text-center text-uppercase grey'>Clocked Hours</td>
                                                            <td scope="col" className='headfont text-dark '></td>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {display_Users.filter((x) => { console.log(moment(x.date).format('YYYY-MM-DD'), query_date); return (moment(x.date).format('YYYY-MM-DD')).includes(query_date) || x.full_name.toLowerCase().includes(query_date.toLowerCase()) }).map((x, index) => {
                                                            return (
                                                                <tr className='timecardrow'>


                                                                    <td className='text-center greyh'>{x.full_name}</td>


                                                                    <td className='text-center greyh'> {moment(x.date).format('DD-MM-YYYY')} </td>
                                                                    <td className='text-center greyh '>{moment(x.intim).format('h:mm:ss A')}</td>
                                                                    <td className='text-center greyh '>{moment(x.out).format('h:mm:ss A')}</td>
                                                                    <td className='text-center greyh'>{x.totalhr} Hrs</td>


                                                                    <td>



                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={page_Count}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } else {
                            return <div class="card  shadow  mb-5 bg-white rounded ms-sm-5">
                                <ul class="hide_all nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1" role="tablist">

                                    <li class="nav-item ">
                                        <a class="nav-link  " style={{ color: '#4EB4E6' }} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Overtime</a>

                                    </li>
                                    <li class="nav-item ">
                                        <a class="nav-link   " style={{ color: '#717372' }} onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Timesheets</a>

                                    </li>

                                </ul>
                                <ul class=" nav hide_for_pc nav-tabs nav-justified navmobv  tab-nav ms-1" role="tablist">

                                    <li class=" ">
                                        <a class="nav-link  " style={{ color: '#4EB4E6' }} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Overtime</a>

                                    </li>
                                    <li class=" ">
                                        <a class="nav-link   " style={{ color: '#717372' }} onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Timesheets</a>

                                    </li>

                                </ul>
                                <div class="card-body">

                                    <div className='row'>
                                        <div className='col-11'>
                                            <p className=' fs-4 fnt-clr'> Overtime Management</p>
                                        </div>
                                        <div className='col-1 mobfilter pointer_'>
                                            <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 " viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col '>
                                            <div className="row">
                                                <div className="ms-4 mt-2 onlytab hide_tab">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <table class="table table-bordered mt-3 year-buttonss">

                                                <table class="table">
                                                    <thead>
                                                        <tr>


                                                            <td></td>
                                                            <td scope="col" className='headfont  text-center text-uppercase fnt-clr '>Name </td>
                                                            <td scope="col" className='headfont text-dark hide_all text-center'></td>
                                                            <td scope="col" className='headfont text-dark hide_all text-center'> </td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey '>Date</td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey'>Overtime </td>
                                                            <td scope="col" className='headfont text-dark hide_all text-center'> </td>
                                                            <td scope="col" className='headfont text-center text-uppercase grey onlytab hide_tab'>View </td>
                                                            <td scope="col" className='headfont text-dark hide_all text-center'> </td>
                                                            <td scope="col" className='headfont  text-center text-uppercase grey '>Approval Status </td>
                                                            <td scope="col" className='headfont  text-center text-uppercase grey '>Action  </td>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {displayUsers.filter((x) => x.approvalstatus.toLowerCase().includes(query) || x.full_name.toLowerCase().includes(query.toLowerCase())).map((x, index) => {
                                                            return (
                                                                <tr className=''>

                                                                    <td ><div className="ms-3">  </div></td>
                                                                    <td className=''><p className=' text-center greyh'>{x.full_name}</p></td>
                                                                    <td className='hide_all'> </td>
                                                                    <td className='hide_all'></td>
                                                                    <td className=' greyh'><p className='text-center'> {moment(x.date).format(' MMM Do YYYY')} </p></td>
                                                                    <td className=' greyh'><p className=' text-center'> {x.overtimehr.toLocaleString(undefined, { maximumFractionDigits: 2 })} Hours </p></td>
                                                                    <td className='text-center ms-1 pointer_'><svg xmlns="http://www.w3.org/2000/svg" onClick={() => ShowDetails(x, 'view')} width="20" height="20" fill="#4EB4E6" class="bi bi-eye-fill " viewBox="0 0 16 16">
                                                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                    </svg>   </td>
                                                                    <td className='hide_all'></td>
                                                                    <td className='text-danger'>{(() => {
                                                                        if (x.approvalstatus == "Rejected") {
                                                                            return <p className="text-danger text-center">{x.approvalstatus}</p>
                                                                        }
                                                                        if (x.approvalstatus == "Approved") {
                                                                            return <p className="text-success  text-center">{x.approvalstatus}</p>
                                                                        }

                                                                        else {
                                                                            return <p className="text-warning  text-center">{x.approvalstatus}</p>


                                                                        }
                                                                    })()} </td>
                                                                    <td className='hide_tab'>

                                                                        {(() => {
                                                                            if (x.approvalstatus == "Pending") {
                                                                                return <div className='text-center'><button className='btn btn-pri  text-center' onClick={() => ShowDetails(x, 'edit')}> Approve </button>
                                                                                    <button className='btn btn-danger ms-sm-2 btnh text-center' onClick={() => ShowDetails(x, 'delete')}> Reject </button></div>
                                                                            }


                                                                            else {
                                                                                return <div className='text-center'><button className='btn btn-pri  text-center' disabled > Approve </button>
                                                                                    <button className='btn btn-danger ms-sm-2  btnh text-center' disabled > Reject </button></div>




                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td className='onlytab '>

                                                                        {(() => {
                                                                            if (x.approvalstatus == "Pending") {
                                                                                return <div className='text-center'><button className='btn btn-pri  text-center' onClick={() => ShowDetails(x, 'edit')}> Approve </button>
                                                                                    <button className='btn btn-danger ms-sm-2  mt-1 btnh text-center' onClick={() => ShowDetails(x, 'delete')}> Reject </button></div>
                                                                            }


                                                                            else {
                                                                                return <div className='text-center'><button className='btn btn-pri  text-center' disabled > Approve </button>
                                                                                    <button className='btn btn-danger ms-sm-2 mt-1  btnh text-center' disabled > Reject </button></div>




                                                                            }
                                                                        })()}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    })()}
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <p class=" fs-6 ms-4 fw-bold">Approval Status</p>
                                            <div className='row'>
                                                <div className='col-6'>

                                                    <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => SetQuery(e.target.value)}>
                                                        <option value="">ALL</option>

                                                        <option value="approved">Approved</option>
                                                        <option value="rejected">Rejected</option>
                                                        <option value="pending">Pending</option>

                                                    </select>


                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                <div class="s">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>{(() => {
                    switch (user_type) {
                        case "view": return Renderview();
                        case "edit": return RenderEdit();
                        case "delete": return RenderEdit1();
                        default: return "";
                    }
                })()}</div>

            </div>
            <Lheader />
        </div>
    )
}
export default Timesheetlist