import { React, useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import Superheader from "./Superheader";
import { useNavigate, useLocation } from "react-router-dom";
import Superside from "./Superside";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';

import CryptoJS from 'crypto-js';
const SuperPayments = () => {
    const location = useLocation();
    const { state } = useContext(store);
    console.log('payroll', state.user);
    console.log('emp_id: ', location?.state?.x.user_id);
    console.log('emp__id: ', location?.state?.x.userid);
    const tax = location?.state?.x.amount / 10;
    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const [mon, setMon] = useState("");
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setUserType(type);
    }
    const [tab_type, setTabType] = useState("");
    const TabChange = (type) => {
        console.log('type', tab_type)
        if (type == 'ot') {

            setTabType(type);
        }
        else {

            setTabType(type);
        }

    }

    // get all payments
    const [allpayment, setallpayment] = useState([])
    const Getallpayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getallpaymentofuser/${location?.state?.x.userid}`).then((res) => {
                console.log(res.data, 'allpay');
                setallpayment(res.data);

            }).catch(() => {

            })
        }

    }
    const [userdetails, getuserdetails] = useState([])
    const Getall_payment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/singleuser/${location?.state?.x.user_id}`).then((res) => {
                console.log(res.data, 'userdetils');
                getuserdetails(res.data);
                setemailid(res.data[0].email);
                setdataemail(res.data[0].first_name);
                setpaydate(res.data[0].company_name);
            }).catch(() => {

            })
        }

    }
    const [penpayment, setpenpayment] = useState([])
    const [datalenght, setdatalenght] = useState();
    const Getpen_payment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getpenpaymentofuser/${location?.state?.x.user_id}`).then((res) => {
                console.log(res.data, 'allpay');
                setpenpayment(res.data);
                setdatalenght(res.data.length);

            }).catch(() => {

            })
        }

    }
    const [payments, setpayment] = useState([])
    const Getpayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/singleuser/${location?.state?.x.userid}`).then((res) => {
                console.log(res.data, 'success');
                setpayment(res.data);

            }).catch(() => {

            })
        }

    }
    const [paycheck, setcheckdata] = useState([])
    const Getcheckpay = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getpaycheck/${location?.state?.x.userid}`).then((res) => {
                console.log(res.data, 'allpay');
                setcheckdata(res.data);

            }).catch(() => {

            })
        }

    }
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    console.log(firstDayOfMonth);

    const givenDate = new Date(firstDayOfMonth);
    const currentDate = new Date();
    const differenceInDays = Math.floor((currentDate - givenDate) / (1000 * 60 * 60 * 24));


    const Get_payment = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/singleuser/${location?.state?.x.user_id}`).then((res) => {
            console.log(res.data, 'singleuser');
            setpayment(res.data);

        }).catch(() => {

        })


    }


    useEffect(() => {
        if (location?.state?.x.userid != null) {

            Getpayment();
            Getallpayment();
            Getcheckpay();

        } if (location?.state?.x.user_id != null) {

            Get_payment();
            // Getall_payment();
            Getpen_payment();
        }

    }, [state]);



    useEffect(() => {
       
            Getall_payment();
           

    }, [state]);

    let navigate = useNavigate();
    const custom = () => {
        let path = `/superadmincustomers`;
        navigate(path);
    }
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [storedMonth, setStoredMonth] = useState();
    const handleChange = (event) => {
        if (!storedMonth) {
            setStoredMonth(event.target.value);
            setMon(event.target.value);
        } else {
            const selectedIndex = months.indexOf(event.target.value);
            const storedIndex = months.indexOf(storedMonth);

            if (selectedIndex === storedIndex + 1) {
                setMon(event.target.value);
                // save selectedMonth to database or local storage here
            } else {
                alert('Please select the months in sequence');
            }
        }
    };
    // add payment
    const [payment_date, setpayment_date] = useState("");
    const [st_date, setst_date] = useState("");
    const [end_date, setend_date] = useState("");
    const [amount, setamount] = useState('2500000');
    const [taxam, settax] = useState('250000');
    const [pay_id, setmonth] = useState("");
    const [emailid, setemailid] = useState("");
    const [dataemail, setdataemail] = useState("");
    const [paydate, setpaydate] = useState("");
    const [stotal, setotal] = useState(amount);
    const [paymonth, setpaymonth] = useState("");

    const checkifValidDate = (inputDate) => {
        let isValid = true;
        paycheck.forEach(item => {
            const paymentDate = moment(item.payment_date).format('YYYY-MM-DD');
            if (moment(paymentDate).isSame(inputDate, 'month') && moment(paymentDate).isSame(inputDate, 'year')) {
                isValid = false;
            }
        });
        return isValid;
    };
    
    const onInsertpay = (data, e) => {
        const startmonth = moment(st_date);
        const endmonth = moment(end_date);

      if(checkifValidDate(st_date) && checkifValidDate(end_date)){
 
        let stotal = 0;
            while (startmonth.isSameOrBefore(endmonth, 'month')) {
               
                const demo = {
                  userid: location?.state?.x.user_id,
                  payment_date: startmonth.startOf('month').format('YYYY-MM-DD'),
                  tax:taxam,
                  amount: amount,
                  created_by:state?.user[0]?.name
                };
                
                axios.post(`${process.env.REACT_APP_API_URL}/add_payment`, demo)
                  .then((res) => {
                    console.log('user updated', res.data);
                    // alert("payment updated successfully");
                    stotal =stotal+amount;
                    console.log('stotal', stotal);
                    Getpen_payment();
                    Getallpayment();
                    
                  })
                  .catch(() => {});
                  
                startmonth.add(1, 'month');
               
              }
              sendEmail(st_date,end_date);
              Getallpayment();
            }

             
        
       
        else {
            confirmAlert({
                message: 'Please select the start date or end date which has no existing Payment ',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    },
                ]
            });
        }
      };
    const onupdatepay = (data, e) => {

        let demo = {
            Payment_id: pay_id,
            payment_date: payment_date,
            tax:taxam,
            amount: amount,
            created_by:state?.user[0]?.name
        };
        axios.put(`${process.env.REACT_APP_API_URL}/update_payment/${pay_id}`, demo).then((res) => {
            console.log('userpayment', res.data);
             alert("Payment updated succesfully");
           
            Getpen_payment();
            Getallpayment();
            send_Email(res.data);
           

        }).catch(() => {

        })
    }

    // send email to costumer
    
    const sendEmail = (x,y) => {
        
        
        const emailData = {

            user_email: `${emailid}`,
            user_name: `${dataemail}`,
            message: `${paydate}`,
            tamount:`${stotal}`,
            month:`${moment(x).format('MMM-YYYY')}To ${moment(y).format('MMM-YYYY')}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailpayment`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
            window.location.reload();
    }

    // send email to update
    const send_Email = (x) => {
        console.log('user_payment', x.paymentDate);
        const emailData = {

            user_email: `${emailid}`,
            user_name: `${dataemail}`,
            message: `${paydate}`,
            tamount:`${stotal}`,
            month: `${moment(x.paymentDate).format('MMM-YYYY')}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailpayment`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
            window.location.reload();
    }

      // handle disabled submit
      const isValide = (st_date && end_date) || (payment_date && amount && taxam)
    return (<div>
        <div className="bg-light vh-100 row container-fluid">
            <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">

            </div>
            <div className="col-10">
                {(() => {
                    if (tab_type == "ot") {
                        return <div class="card  shadow  mb-5 bg-white rounded ms-5 ">
                            <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1" role="tablist">
                                <div class="slider hid_tab"></div>
                                <li class="nav-item ms-2 nav-mob">
                                    <a class="nav-link text-dark " onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Pending</a>

                                </li>
                                <li class="nav-item  fw-bold">
                                    <a class="nav-link text-dark att-tab" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>History</a>

                                </li>

                            </ul>
                            <div className="col">
                                
                                <div className="row ms-1">
                                    <div className=" Card_1task  ms-3">
                                        <div className="row mt-3">

                                            <div className="col-3 d-grid " >
                                            <p className=" fw-bold mt-2 ms-3 ">Payments History</p>
                                            </div>
                                            <div className="col-5 d-grid" >
                                            </div>
                                            <div className="col-2 d-grid" >
                                            </div>
                                            <div className="col-2 " >

                                            </div>
                                        </div>

                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col">
                                                    <div className='row'>

                                                        <div className='col'>

                                                            <table class="table table-bordered mt-2">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>  <td scope="col"></td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary'>Payment-Month</td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary'>Payment-ID</td>

                                                                            <td scope="col" className='fs-6 text-center text-secondary mobsimplytext'>Customer Name</td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary mobsimplytext'>Amount Paid </td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary mobsimplytext'>Paid Date</td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary mobsimplytext'>Tax</td>
                                                                            <td scope="col" className='fs-6 text-center text-secondary mobsimplytext'>Total Paid</td>


                                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Pending amount</td>
                                                                            <td scope="col" className='fs-6 text-secondary mobsimplytext'>Recorded By</td>




                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {allpayment.map((x, index) => {
                                                                            return (
                                                                                <tr>
                                                                                    <th scope="row"></th>
                                                                                    <td className=''><p className="text-center">{moment(x.payment_date).format('MMM-YYYY')}</p></td>
                                                                                    <td className=''><p className="text-center">{x.payment_id}</p></td>
                                                                                    <td className='pointer_'><p className="text-center">{location?.state?.x.first_name}</p></td>
                                                                                    <td className=''><p className="text-center">{Math.round(x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                                                                    <td className=''>{(()=>{
if(x.paymentdate!=null){
    return<p className="text-center">
    {moment(x.paymentdate).format('MMM-DD-YYYY')}</p>

}else{
    return<p className="text-center">
    {moment(x.payment_date).format('MMM-DD-YYYY')}</p>
}
                                                                                    })()}
                                                                                    </td>
                                                                                    <td className=''><p className="text-center">{Math.round(x.tax).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                                                                    <td className=''><p className="text-center">{Math.round(x.amount + tax).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                                                                    <td className=''><p className="text-center">{Math.round(2500000 - x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                                                                    <td className=''><p className="text-center">{x.created_by}</p></td>

                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>

                                                                </table>
                                                            </table>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*pay*/}
                                    


                                </div>

                               
                            </div>
                        </div>
                    } else {
                        return <div class="card  shadow  mb-5 bg-white rounded ms-5">
                            <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1" role="tablist">
                                <div class="slider hid_tab"></div>
                                <li class="nav-item  fw-bold nav-mob">
                                    <a class="nav-link text-dark  " onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>Pending</a>

                                </li>
                                <li class="nav-item   ">
                                    <a class="nav-link text-dark att-tab" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>History</a>

                                </li>

                            </ul>
                            <div className="col">
                                
                                <div className="row ms-1">
                                    <div className=" Card_1task  ms-3">
                                        <div className="row mt-3">

                                            <div className="col-3 d-grid "  >
                                            <p className=" fw-bold mt-2 ms-3">Payment Is Pending</p>
                                            </div>
                                            <div className="col-5 d-grid" >
                                            </div>
                                            
                                            <div className="col-2 " >{(()=>{
if(datalenght==0){
   return<button className="btn btn-pri mt-2" data-bs-toggle="modal" data-bs-target="#exampleModal2" >New Payment</button>
 
}else{
    return<button className="btn btn-pri mt-2" disabled data-bs-toggle="modal" data-bs-target="#exampleModal2" >New Payment</button>
 
}
                                            })()}
                                            
                                            
                                            </div>
                                           
                                             
                                            <div className="col-2 " >{(()=>{
if(datalenght==0){
   return<button className="btn btn-pri mt-2" disabled data-bs-toggle="modal" data-bs-target="#exampleModal" >Update  Payment</button>

}else{
    return<button className="btn btn-pri mt-2"  data-bs-toggle="modal" data-bs-target="#exampleModal" >Update  Payment</button>

}
                                            })()}
                                            
                                            
                                            </div>
                                           
                                            
                                        </div>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col">
                                                    <div className='row'>

                                                        <div className='col'>

                                                            <table class="table table-bordered mt-2">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>  <td scope="col"></td>
                                                                            <td scope="col" className='fs-6 text-secondary'>Pending Month</td>


                                                                            <td scope="col" className='fs-6 text-secondary text-center mobsimplytext'>Customer Name</td>
                                                                            <td scope="col" className='fs-6 text-secondary text-center mobsimplytext'>Pending Amount</td>
                                                                            <td scope="col" className='fs-6 text-secondary text-center mobsimplytext'>Due-Date</td>
                                                                            <td scope="col" className='fs-6 text-secondary text-center mobsimplytext'>Total-Amount</td>
                                                                            <td scope="col" className='fs-6 text-secondary text-center mobsimplytext'>Over-Due Days</td>

                                                                        </tr>
                                                                    </thead>

                                                                    {penpayment.map((x, index) => {
                                                                        return (<tbody>


                                                                            <tr>
                                                                                <th scope="row"></th>
                                                                                <td className=''><p className="text-center" >{moment(x.payment_date).format('MMM-YYYY')}</p></td>
                                                                                <td className=''><p className="text-center">{location?.state?.x.first_name}</p></td>

                                                                                <td className=''><p className="text-center">{Math.round(2500000 - x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"} </p></td>
                                                                                <td className=''><p className="text-center">{moment(x.payment_date).format('MMM-DD-YYYY')}</p></td>

                                                                                <td className=''><p className="text-center">{Math.round((2500000 - x.amount) + ((2500000 - x.amount) / 10)).toLocaleString('en-US').replace(/,/g, '.') + "đ"} </p></td>
                                                                                <td className='text-danger'><p className="text-center">{Math.floor(moment.duration(moment().diff(moment(x.payment_date))).asDays())} days</p></td>
                                                                            </tr>


                                                                        </tbody>
                                                                        )
                                                                    })}



                                                                </table>
                                                            </table>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Filter */}
                                    <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                                            <div class="modal-content ">
                                                <div class="modal-header">
                                                    <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body ">
                                                    <p className="text-secondary">All</p>
                                                    <p className="text-secondary">Overdue Days</p>
                                                    <div className='row mt-4'>

                                                        <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                                            <button type="button" class="btn btn-pri">Filter</button>
                                                        </div>
                                                        <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* pending payment */}
                                <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-scrollable">
                                        <div class="modal-content ">
                                            <div class="">
                                                <div className="row mt-4">
                                                    <div className="col-2"></div>
                                                    <div className="col-8">
                                                        <div class=" text-center text-primary fs-4 " id="exampleModalLabel">Update Pending Payment</div>
                                                    </div>
                                                    <div className="col-1">
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="modal-body ">

                                                <div className='row'>
                                                    <div className="col-2"></div>
                                                    <div className='col-8 '>
                                                        <small className=' text-center'>Pending Month</small>
                                                        <select class="form-select inputcolor text-start text_box_size " value={pay_id} onChange={(e) => setmonth(e.target.value)} onClick={(e) => setpaymonth(e.target.Value)}>
                                                            <option value="">Select a month</option>
                                                            {penpayment.map((x) => (
                                                                <option className="text-black" value={x.payment_id} >
                                                                    {moment(x.payment_date).format('MMM YYYY')}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="col-2"></div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-2"></div>
                                                    <div className='col-8 '>
                                                        <small className=' text-center'>Amount</small>
                                                        <div class="input-group">
                                                            <input type="text" class="  form-control inputcolor text_box_size  " Value={amount} onChange={(e) => setamount(e.target.value)} placeholder="2500000" aria-label="Username" />
                                                        </div>
                                                        <div className="col-2"></div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-2"></div>
                                                    <div className='col-8 '>
                                                        <small className=' text-center'>Tax Amount</small>
                                                        <div class="input-group">
                                                            <input type="text" class="  form-control inputcolor text_box_size  " Value={amount/10}  onChange={(e) => settax(e.target.value)} placeholder="" aria-label="Username" />
                                                        </div>
                                                        <div className="col-2"></div>
                                                    </div>

                                                </div>
                                               
                                                <div className='row'>
                                                <div className="col-2"></div>
                                                    <div className='col-8'>
                                                        <small className=' text-center'>Payment date</small>
                                                        <div class="input-group">

                                                            <input type="date" class="  form-control inputcolor text_box_size " placeholder="preferred Demo Date" onChange={(e) => setpayment_date(e.target.value)} aria-label="Username" />
                                                        </div>
                                                        <div className="col-2"></div>
                                                    </div>

                                                </div>




                                                <div className='row mt-4'>
                                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    </div>
                                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                                        <button type="button" class="btn btn-pri text_box_size" aria-label="Close" onClick={onupdatepay} disabled={!isValide}>Update</button>
                                                    </div>
                                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade mt-5" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-scrollable">
                                            <div class="modal-content ">
                                                <div class="">
                                                    <div className="row mt-4">
                                                        <div className="col-2"></div>
                                                        <div className="col-8">
                                                            <div class=" text-center text-primary fs-4 " id="exampleModalLabel">Update New Payment</div>
                                                        </div>
                                                        <div className="col-1">
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                                    </div>
                                                    </div>

                                                </div>
                                                <div class="modal-body ">
                                                    

                                                    <div className='row'>
                                                        <div className="col-2"></div>
                                                        <div className='col-8 '>
                                                            <small className=' text-center'>Amount</small>
                                                            <div class="input-group">
                                                                <input type="text" class="  form-control inputcolor text_box_size  " onChange={(e) => setamount(e.target.value)} placeholder="" aria-label="Username" />
                                                            </div>
                                                            <div className="col-2"></div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-2"></div>
                                                        <div className='col-8 '>
                                                            <small className=' text-center'>Tax Amount</small>
                                                            <div class="input-group">
                                                            <input type="text" class="  form-control inputcolor text_box_size  " Value={amount/10}  onChange={(e) => settax(e.target.value)} placeholder="" aria-label="Username" />
                                                        </div>
                                                            <div className="col-2"></div>
                                                        </div>

                                                    </div>
                                                   
                                                    <div className='row'>
                                                        <div className="col-2"></div>
                                                        <div className='col-4'>
                                                            <small className=' text-center'>Start date</small>
                                                            <div class="input-group">

                                                                <input type="date" class="  form-control inputcolor text_box_size " placeholder="preferred Demo Date" onChange={(e) => setst_date(e.target.value)} aria-label="Username" />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className='col-4'>
                                                            <small className=' text-center'>end date</small>
                                                            <div class="input-group">

                                                                <input type="date" class="  form-control inputcolor text_box_size " placeholder="preferred Demo Date" onChange={(e) => setend_date(e.target.value)} aria-label="Username" />
                                                            </div>
                                                            
                                                        </div>
                                                    </div>




                                                    <div className='row mt-4'>
                                                        <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                        </div>
                                                        <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                                            <button type="button" class="btn btn-pri text_box_size " data-bs-dismiss="modal" aria-label="Close" onClick={onInsertpay} disabled={!isValide}>Submit</button>
                                                        </div>
                                                        <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>

                    }
                })()}
            </div>













        </div>
        <div>{(() => {
            switch (user_type) {

                default: return "";
            }
        })()}</div>

    </div>

    )
}
export default SuperPayments