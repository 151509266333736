import React, { useCallback, useState, useContext } from 'react';

import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import Bank from './AddBank';


const Masterpage = () => {
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/addlanguage`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
return(
    <div className='bg-light vh-100 container-fluid'>
           
            <div className='row mt-5'>
                <div className='col-2 hid_tab'>

                </div>
                <div className='col-10 mt-1'>
                    <input type="text" class="form-control inputcolor mt-4 searchbox" placeholder="Search by Account, Date, Category or Amount" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                   <p className='fw-bold text-center'>
                   ADD DATA
                   </p>
                
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g" onClick={bank}>Bank</button>
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={dept}>Department</button>
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={lang}>Language</button>
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={job}>Job Title</button>
                 </div>
                 <div className='text-center'>
                 {/* <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={tax}>Tax</button> */}
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={national}>Nationality</button>
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={salary}>Salary</button>
                 </div>
                 <div className='text-center'>
                 <button type="button" class="btn btn-pri btn-l_g mt-3"onClick={holidays}>Holidays</button>
                 </div>
                </div>

            </div>
            <Lheader />
        </div>
)
}
export default Masterpage