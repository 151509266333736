import React, { useCallback, useState, useContext, useEffect } from 'react';
import './Payrolllist.css';
import { useNavigate, useLocation } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { EXCEL_FILE_BASE64 } from '../constant';
import FileSaver from 'file-saver';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Input } from 'antd';
import ReactPaginate from "react-paginate";
import * as XLSX from 'xlsx';
import { store } from '../../store/user';
import Loadingscreen from '../Loadingscreen';
import { Regular } from '../constant';
import { Holiday } from '../constant';
import { Rest } from '../constant';

const PayroleCycle = () => {
    const { state } = useContext(store);
    console.log('payroll', state.user);
    const { update, handleSubmit } = useForm();
    const [paylist, setPay_list] = useState([])
    const [tab_type, setTabType] = useState('Basic_Profile');

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPayrollStatus, setSelectedPayrollStatus] = useState("");
    const [total_days, settotaldays] = useState();
    const location = useLocation();
    console.log('payroll_id: ', location?.state?.payroll_id);
    console.log('start date: ', location?.state?.startdate);
    console.log('end date: ', location?.state?.enddate);
    console.log('month: ', location?.state?.month);


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(paylist.map(li => li.emp_id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const handleClick = e => {
        const { emp_id, checked } = e.target;
        setIsCheck([...isCheck, emp_id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== emp_id));
        }
    };

    const [activeTab, setActiveTab] = useState("Existing");

    const TabChangepop = (type) => {
        console.log('type', tab_type)
        if (type == 'Basic_Profile') {
            console.log('Basic_Profile', type);
            setTabType(type);
            setActiveTab("Existing");
        } else {
            console.log('Job_Profile', type);
            setTabType(type);
            setActiveTab("New");
        }

    }
    //get method of payrollcycle
    const [palist, setPayList] = useState([]);
    const Getpaylist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payrollcycles_att/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'payid');
                setPayList(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    //salary component to show exsting component 
    const Getsalarydata = (empid) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_salarycomponent/${empid}/${location?.state?.payroll_id}/${state?.user[0]?.company_id}`).then((res) => {
                let temp = [];
                res?.data?.forEach(item => {
                    item['salCompTypes'] = item?.salarycomponent_types?.split(',');
                    item['salCompAmounts'] = item?.amounts?.split(',')?.map(i => parseInt(i, 10));
                    item['salCompIds'] = item?.salaryids?.split(',')?.map(i => parseInt(i, 10));
                    item['salComponents'] = item?.salarycomponents?.split(',');


                    let date = new Date();
                    let start = new Date(location?.state?.startdate);
                    let end = new Date(location?.state?.enddate);
                    let totalWorkingDays = 0;
                    while (start <= end) {
                        let dayOfWeek = start.getDay();
                        let workingHours = 0;
                        switch (dayOfWeek) {
                            case 0: // Sunday
                                workingHours = item?.sunday;
                                break;
                            case 1: // Monday
                                workingHours = item?.monday;
                                break;
                            case 2: // Tuesday
                                workingHours = item?.tuesday;
                                break;
                            case 3: // Wednesday
                                workingHours = item?.wednesday;
                                break;
                            case 4: // Thursday
                                workingHours = item?.thursday;
                                break;
                            case 5: // Friday
                                workingHours = item?.friday;
                                break;
                            case 6: // Saturday
                                workingHours = item?.saturday;
                                break;
                            default:
                                console.log(`Invalid day of week: ${dayOfWeek}`);
                                break;
                        }
                        if (workingHours === 7) {
                            totalWorkingDays++;
                        } else if (workingHours === 8) {
                            totalWorkingDays += 0.5;
                        } else if (workingHours >= 0 && workingHours <= 6) {
                            // Week off
                        } else {
                            console.log(`Invalid working hours for day ${start}`);
                        }
                        start.setDate(start.getDate() + 1);
                    }
                    console.log(`Total working days: ${totalWorkingDays}`);
                    setwork(totalWorkingDays)

                    let totalWorkingHoursWeek = 0;

                    // Check if Monday to Sunday is stored as 7 or 8, and calculate the total hours
                    if (item?.monday === 7 || item?.monday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.monstime, item?.monetime);
                    }
                    if (item?.tuesday === 7 || item?.tuesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.tuestime, item?.tueetime);
                    }
                    if (item?.wednesday === 7 || item?.wednesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.wedstime, item?.wedetime);
                    }
                    if (item?.thursday === 7 || item?.thursday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.thustime, item?.thuetime);
                    }
                    if (item?.friday === 7 || item?.friday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.fristime, item?.frietime);
                    }
                    if (item?.saturday === 7 || item?.saturday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.satstime, item?.satetime);
                    }
                    if (item?.sunday === 7 || item?.sunday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.sunstime, item?.sunetime);
                    }

                    console.log(`Total working hours: ${totalWorkingHoursWeek}`);

                    // Helper function to calculate the working hours based on start time and end time
                    function calculateWorkingHours(startTime, endTime) {
                        const start = parseFloat(startTime);
                        const end = parseFloat(endTime);
                        if (!isNaN(start) && !isNaN(end) && end >= start) {
                            return end - start;
                        } else {
                            console.log(`Invalid working hours: start=${startTime}, end=${endTime}`);
                            return 0;
                        }
                    }



                    item['overTimeHrsList'] = item?.overtimehrslist?.split(',')?.map(i => parseFloat(i));
                    item['overTimePerList'] = item?.overtimepercentagelist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeTypesList'] = item?.overtimetypeslist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeDatesList'] = item?.overtimedatelist?.split(',');

                    let regularRate = item?.amountbasic / (totalWorkingHoursWeek * 52 / 12);
                    let overtimeAmt = 0;
                    let regularAmt = 0;
                    let overtimeHrsCount = 0;

                    item['overTimeHrsList']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['overTimeDatesList'][i])) {
                            overtimeAmt = overtimeAmt + (((regularRate / 100) * item['overTimePerList'][i]) * hrs);
                            overtimeHrsCount = overtimeHrsCount + hrs;
                            regularAmt = regularAmt + (regularRate * hrs);
                        }
                    });
                    item['regularRate'] = regularRate;
                    item['overtimeAmt'] = overtimeAmt;
                    item['overtimeHrsCount'] = overtimeHrsCount;
                    item['regularAmt'] = regularAmt;


                    item['timeHrsCount'] = item?.timeHrsCount?.split(',')?.map(i => parseFloat(i));
                    item['totalhours'] = item?.totalhours?.split(',')?.map(i => parseFloat(i));
                    item['timesheetdate'] = item?.timesheetdate?.split(',');

                    item['totaldays'] = item?.totaldays?.split(',')?.map(i => parseInt(i, 10));
                    let timeHrsCount = 0;

                    let totaldays = 0;
                    item['totalhours']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['timesheetdate'][i])) {
                            console.log()
                            timeHrsCount = timeHrsCount + hrs;
                            totaldays = totaldays + 1
                            console.log(totaldays, 'count');
                        }
                    });
                    item['timeHrsCount'] = timeHrsCount;
                    item['totaldays'] = totaldays;
                    item['totalhours'] = item?.totalhours;
                    item['timesheetdate'] = item?.timesheetdate;


                    item['applyDate'] = item?.applydate?.split(',');

                    let leaveCount = 0;

                    item['applyDate']?.forEach((x, i) => {
                        console.log("testleave")
                        if (checkifValidDate(item['applyDate'][i])) {
                            leaveCount = leaveCount + 1
                            console.log(leaveCount, 'pleave');
                        }
                    });

                    item['leaveCount'] = leaveCount;

                    temp.push(item);
                });
                console.log(res.data, 'bnd');
                ShowDetails(res.data[0], "edit");

            }).catch(() => {

            })
        }
    }
    //salary component to show exsting component to show after added to add more
    const Get_salarydata = (empid) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_salarycomponent/${empid}/${location?.state?.payroll_id}/${state?.user[0]?.company_id}`).then((res) => {
                let temp = [];
                res?.data?.forEach(item => {
                    item['salCompTypes'] = item?.salarycomponent_types?.split(',');
                    item['salCompAmounts'] = item?.amounts?.split(',')?.map(i => parseInt(i, 10));
                    item['salCompIds'] = item?.salaryids?.split(',')?.map(i => parseInt(i, 10));
                    item['salComponents'] = item?.salarycomponents?.split(',');



                    let date = new Date();
                    let start = new Date(location?.state?.startdate);
                    let end = new Date(location?.state?.enddate);
                    let totalWorkingDays = 0;
                    while (start <= end) {
                        let dayOfWeek = start.getDay();
                        let workingHours = 0;
                        switch (dayOfWeek) {
                            case 0: // Sunday
                                workingHours = item?.sunday;
                                break;
                            case 1: // Monday
                                workingHours = item?.monday;
                                break;
                            case 2: // Tuesday
                                workingHours = item?.tuesday;
                                break;
                            case 3: // Wednesday
                                workingHours = item?.wednesday;
                                break;
                            case 4: // Thursday
                                workingHours = item?.thursday;
                                break;
                            case 5: // Friday
                                workingHours = item?.friday;
                                break;
                            case 6: // Saturday
                                workingHours = item?.saturday;
                                break;
                            default:
                                console.log(`Invalid day of week: ${dayOfWeek}`);
                                break;
                        }
                        if (workingHours === 7) {
                            totalWorkingDays++;
                        } else if (workingHours === 8) {
                            totalWorkingDays += 0.5;
                        } else if (workingHours >= 0 && workingHours <= 6) {
                            // Week off
                        } else {
                            console.log(`Invalid working hours for day ${start}`);
                        }
                        start.setDate(start.getDate() + 1);
                    }
                    console.log(`Total working days: ${totalWorkingDays}`);
                    setwork(totalWorkingDays)

                    let totalWorkingHoursWeek = 0;

                    // Check if Monday to Sunday is stored as 7 or 8, and calculate the total hours
                    if (item?.monday === 7 || item?.monday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.monstime, item?.monetime);
                    }
                    if (item?.tuesday === 7 || item?.tuesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.tuestime, item?.tueetime);
                    }
                    if (item?.wednesday === 7 || item?.wednesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.wedstime, item?.wedetime);
                    }
                    if (item?.thursday === 7 || item?.thursday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.thustime, item?.thuetime);
                    }
                    if (item?.friday === 7 || item?.friday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.fristime, item?.frietime);
                    }
                    if (item?.saturday === 7 || item?.saturday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.satstime, item?.satetime);
                    }
                    if (item?.sunday === 7 || item?.sunday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.sunstime, item?.sunetime);
                    }

                    console.log(`Total working hours: ${totalWorkingHoursWeek}`);

                    // Helper function to calculate the working hours based on start time and end time
                    function calculateWorkingHours(startTime, endTime) {
                        const start = parseFloat(startTime);
                        const end = parseFloat(endTime);
                        if (!isNaN(start) && !isNaN(end) && end >= start) {
                            return end - start;
                        } else {
                            console.log(`Invalid working hours: start=${startTime}, end=${endTime}`);
                            return 0;
                        }
                    }

                    item['overTimeHrsList'] = item?.overtimehrslist?.split(',')?.map(i => parseFloat(i));
                    item['overTimePerList'] = item?.overtimepercentagelist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeTypesList'] = item?.overtimetypeslist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeDatesList'] = item?.overtimedatelist?.split(',');

                    let regularRate = item?.amountbasic / (totalWorkingHoursWeek * 52 / 12);
                    let overtimeAmt = 0;
                    let regularAmt = 0;
                    let overtimeHrsCount = 0;

                    item['overTimeHrsList']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['overTimeDatesList'][i])) {
                            overtimeAmt = overtimeAmt + (((regularRate / 100) * item['overTimePerList'][i]) * hrs);
                            overtimeHrsCount = overtimeHrsCount + hrs;
                            regularAmt = regularAmt + (regularRate * hrs);
                        }
                    });
                    item['regularRate'] = regularRate;
                    item['overtimeAmt'] = overtimeAmt;
                    item['overtimeHrsCount'] = overtimeHrsCount;
                    item['regularAmt'] = regularAmt;


                    item['timeHrsCount'] = item?.timeHrsCount?.split(',')?.map(i => parseFloat(i));
                    item['totalhours'] = item?.totalhours?.split(',')?.map(i => parseFloat(i));
                    item['timesheetdate'] = item?.timesheetdate?.split(',');

                    item['totaldays'] = item?.totaldays?.split(',')?.map(i => parseInt(i, 10));
                    let timeHrsCount = 0;

                    let totaldays = 0;
                    item['totalhours']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['timesheetdate'][i])) {
                            console.log()
                            timeHrsCount = timeHrsCount + hrs;
                            totaldays = totaldays + 1
                            console.log(totaldays, 'count');
                        }
                    });
                    item['timeHrsCount'] = timeHrsCount;
                    item['totaldays'] = totaldays;
                    item['totalhours'] = item?.totalhours;
                    item['timesheetdate'] = item?.timesheetdate;


                    item['applyDate'] = item?.applydate?.split(',');

                    let leaveCount = 0;

                    item['applyDate']?.forEach((x, i) => {
                        console.log("testleave")
                        if (checkifValidDate(item['applyDate'][i])) {
                            leaveCount = leaveCount + 1
                            console.log(leaveCount, 'pleave');
                        }
                    });

                    item['leaveCount'] = leaveCount;

                    temp.push(item);
                });
                console.log(temp, 'bnds');
                setecheck(temp);

            }).catch(() => {

            })
        }
    }

    // excel download
    const handleexcelDownload = () => {
        let dataBlob = EXCEL_FILE_BASE64;
        let sliceSize = 1024;
        let byteCharacters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(
            new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
            "payroll excel.xlsx"
        );
    };
    const [salary_details, setSalaryDetails] = useState([]);

    const GetSalary = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'payrollsuccess');
                setSalaryDetails(res.data);

            }).catch(() => {

            })
        }
    }

    const [bind_user, setBindUser] = useState([]);
    const [salarytypes, setSalarytypes] = useState("");
    const [headtypes, setHeadtypes] = useState("");
    const [sali, setsali] = useState("");

    const onUpdate = (id) => {

        let salaries = {
            empsalid: id,
            amount: salarytypes,
            //amount_detuction:headtypes,
            //head_type: headtypes,
            //scope: scopetypes,

        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/employeesalary/${id}`, salaries).then((res) => {
            console.log('employee_salary updated', res.data);

            // window.location = "/payrollcycle";
            alert("Updated successfully");
        }).catch(() => {

        })
    }
    const onUpdatestatus = () => {

        let paystatus = {

            payrollstatus: "true",
        };


        axios.put(`${process.env.REACT_APP_API_URL}/payrollstatus/${location?.state?.payroll_id}`, paystatus).then((res) => {
            console.log('employee_salary updated', res.data);

            setIsLoading(false); // Hide loading screen when API call is complete
            payrlist();
        }).catch(() => {

        })
    }
    const [salar_id, setSalaryId] = useState([]);


    const handleUpload = (e) => {
        e.preventDefault();

        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            console.log(dataParse);
        };
        reader.readAsBinaryString(f);
    }

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const savebasic = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdatesal(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }

    // Main Get method of payroll
    const GetPaylist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp/${state?.user[0]?.company_id}/${location?.state?.payroll_id}`).then((res) => {
                let temp = [];
                res?.data?.forEach(item => {
                    item['salCompTypes'] = item?.salarycomponent_types?.split(',');
                    item['salCompAmounts'] = item?.amounts?.split(',')?.map(i => parseInt(i, 10));
                    item['salCompIds'] = item?.salaryids?.split(',')?.map(i => parseInt(i, 10));
                    item['salComponents'] = item?.salarycomponents?.split(',');

                    // let date = new Date();
                    // let start = new Date(location?.state?.startdate);
                    // let end = new Date(location?.state?.enddate);
                    // let holidays = 0; console.log(start);
                    // console.log(end);
                    // while (start <= end) {
                    //     let day = start.getDay();
                    //     if (day !== item?.monday && day !== item?.tuesday && day !== item?.wednesday && day !== item?.thursday && day !== item?.friday && day !== item?.saturday && day !== item?.sunday) {
                    //         holidays++;
                    //     }
                    //     console.log(holidays, 'working');
                    //     start.setDate(start.getDate() + 1);
                    // }
                    // setwork(holidays)


                    let date = new Date();
                    let start = new Date(location?.state?.startdate);
                    let end = new Date(location?.state?.enddate);
                    let totalWorkingDays = 0;
                    while (start <= end) {
                        let dayOfWeek = start.getDay();
                        let workingHours = 0;
                        switch (dayOfWeek) {
                            case 0: // Sunday
                                workingHours = item?.sunday;
                                break;
                            case 1: // Monday
                                workingHours = item?.monday;
                                break;
                            case 2: // Tuesday
                                workingHours = item?.tuesday;
                                break;
                            case 3: // Wednesday
                                workingHours = item?.wednesday;
                                break;
                            case 4: // Thursday
                                workingHours = item?.thursday;
                                break;
                            case 5: // Friday
                                workingHours = item?.friday;
                                break;
                            case 6: // Saturday
                                workingHours = item?.saturday;
                                break;
                            default:
                                console.log(`Invalid day of week: ${dayOfWeek}`);
                                break;
                        }
                        if (workingHours === 7) {
                            totalWorkingDays++;
                        } else if (workingHours === 8) {
                            totalWorkingDays += 0.5;
                        } else if (workingHours >= 0 && workingHours <= 6) {
                            // Week off
                        } else {
                            console.log(`Invalid working hours for day ${start}`);
                        }
                        start.setDate(start.getDate() + 1);
                    }
                    console.log(`Total working days: ${totalWorkingDays}`);
                    setwork(totalWorkingDays)

                    let totalWorkingHoursWeek = 0;

                    // Check if Monday to Sunday is stored as 7 or 8, and calculate the total hours
                    if (item?.monday === 7 || item?.monday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.monstime, item?.monetime);
                    }
                    if (item?.tuesday === 7 || item?.tuesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.tuestime, item?.tueetime);
                    }
                    if (item?.wednesday === 7 || item?.wednesday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.wedstime, item?.wedetime);
                    }
                    if (item?.thursday === 7 || item?.thursday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.thustime, item?.thuetime);
                    }
                    if (item?.friday === 7 || item?.friday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.fristime, item?.frietime);
                    }
                    if (item?.saturday === 7 || item?.saturday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.satstime, item?.satetime);
                    }
                    if (item?.sunday === 7 || item?.sunday === 8) {
                        totalWorkingHoursWeek += calculateWorkingHours(item?.sunstime, item?.sunetime);
                    }

                    console.log(`Total working hours: ${totalWorkingHoursWeek}`);

                    // Helper function to calculate the working hours based on start time and end time
                    function calculateWorkingHours(startTime, endTime) {
                        const start = parseFloat(startTime);
                        const end = parseFloat(endTime);
                        if (!isNaN(start) && !isNaN(end) && end >= start) {
                            return end - start;
                        } else {
                            console.log(`Invalid working hours: start=${startTime}, end=${endTime}`);
                            return 0;
                        }
                    }


                    item['overTimeHrsList'] = item?.overtimehrslist?.split(',')?.map(i => parseFloat(i));
                    item['overTimePerList'] = item?.overtimepercentagelist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeTypesList'] = item?.overtimetypeslist?.split(',')?.map(i => parseInt(i, 10));
                    item['overTimeDatesList'] = item?.overtimedatelist?.split(',');

                    let regularRate = item?.amountbasic / (totalWorkingHoursWeek * 52 / 12);
                    let overtimeAmt = 0;
                    let regularAmt = 0;
                    let overtimeHrsCount = 0;

                    item['overTimeHrsList']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['overTimeDatesList'][i])) {
                            overtimeAmt = overtimeAmt + (((regularRate / 100) * item['overTimePerList'][i]) * hrs);
                            overtimeHrsCount = overtimeHrsCount + hrs;
                            regularAmt = regularAmt + (regularRate * hrs);
                        }
                    });
                    item['regularRate'] = regularRate;
                    item['overtimeAmt'] = overtimeAmt;
                    item['overtimeHrsCount'] = overtimeHrsCount;
                    item['regularAmt'] = regularAmt;


                    item['timeHrsCount'] = item?.timeHrsCount?.split(',')?.map(i => parseFloat(i));
                    item['totalhours'] = item?.totalhours?.split(',')?.map(i => parseFloat(i));
                    item['timesheetdate'] = item?.timesheetdate?.split(',');

                    item['totaldays'] = item?.totaldays?.split(',')?.map(i => parseInt(i, 10));
                    let timeHrsCount = 0;

                    let totaldays = 0;
                    item['totalhours']?.forEach((hrs, i) => {
                        if (checkifValidDate(item['timesheetdate'][i])) {
                            console.log()
                            timeHrsCount = timeHrsCount + hrs;
                            totaldays = totaldays + 1
                            console.log(totaldays, 'count');
                        }
                    });
                    item['timeHrsCount'] = timeHrsCount;
                    item['totaldays'] = totaldays;
                    item['totalhours'] = item?.totalhours;
                    item['timesheetdate'] = item?.timesheetdate;


                    item['applyDate'] = item?.applydate?.split(',');

                    let leaveCount = 0;

                    item['applyDate']?.forEach((x, i) => {
                        console.log("testleave")
                        if (checkifValidDate(item['applyDate'][i])) {
                            leaveCount = leaveCount + 1
                            console.log(leaveCount, 'pleave');
                        }
                    });

                    item['leaveCount'] = leaveCount;

                    temp.push(item);
                });
                console.log(temp, 'time');
                setPay_list(temp);
            }).catch(() => {

            })
        }
    }

    const [amount, setAmounttypes] = useState("");
    const [component, setComponenttypes] = useState("");

    const onUpdatesal = (emp_id, e) => {

        let emp = {
            emp_id: bind_user.emp_id,
            amountbasic: amount,
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/employeeupbasic/${bind_user.emp_id}`, emp).then((res) => {
            console.log('Tax updated', res.data);
            onInsertsalhistory(amount)

            //window.location = "/addshift";
            //alert("Click Ok to Update");
        }).catch(() => {

        })
    }


    const onInsertsalhistory = (amount) => {

        let history = {
            emp_id: bind_user.emp_id,
            grossamount: amount,
            updateby: state?.user[0]?.first_name
        };


        axios.post(`${process.env.REACT_APP_API_URL}/grosshistory`, history).then((res) => {

            console.log('user updated', res.data);


        }).catch(() => {

        })
    }

    useEffect(() => {
        GetTax();
    }, []);

    useEffect(() => {
        GetSalary();
        GetPaylist();
        Getbasicwage();
        Getpaylist();
    }, [state]);

    useEffect(() => {
        const salty = () => {

            let salaries = {
                salary_id: bind_user.sal1,
                // amount: salarytypes,
                //amount_detuction:headtypes,
                //head_type: headtypes,
                //scope: scopetypes,

            };
            //console.log("user", user);

            axios.post(`${process.env.REACT_APP_API_URL}/getsaltype`, salaries).then((res) => {
                console.log('employee_salary updated', res.data);
                console.log(res.data, 'testings');

                setSalaryId(res.data);

                // window.location = "/payrollcycle";
                //alert("Click ");
            }).catch(() => {

            }, 1000);
            salty();
        }
    }, []);

    const alet = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to go back',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => newpayrol(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to cancel')
                }
            ]
        })
    }
    const [startDate, setStartDate] = useState(new Date());

    let handleColor = (time) => {
        return time.getHours() > 12 ? "text-success" : "text-error";
    }

    let navigate = useNavigate();
    const emppayrole = () => {
        let path = `/emppay`;
        navigate(path);
    }

    const newpayrol = () => {
        let path = `/newpayroll`;
        navigate(path);
    }
    const payrlist = () => {
        let path = `/payrolllist`;
        navigate(path, { state: { payroll_id: location?.state?.payroll_id, startdate: location?.state?.startdate, enddate: location?.state?.enddate, month: location?.state?.month } });
    }
    const [user_type, setUserType] = useState('');

    const ShowDetails = (data, type) => {
        // salty();
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
        // setCurrentId(data.job_id);

    }



    const [type_address, setTypeAddress] = useState('Address_update');
    const [type_tax, setTypeTax] = useState('tax_update');
    const [type_bank, setTypeBank] = useState('bank_update');

    const TabChange = (type) => {
        console.log('type', type_address)
        if (type == 'Address_edit') {
            console.log('Address_edit', type);
            setTypeAddress(type);
        } else {
            console.log('Address_update', type);
            setTypeAddress(type);
        }
    }
    const TabChange1 = (type) => {
        console.log('type', type_address)
        if (type == 'tax_edit') {
            console.log('tax_edit', type);
            setTypeTax(type);
        } else {
            console.log('tax_update', type);
            setTypeTax(type);
        }
    }
    const TabChange2 = (type) => {
        console.log('type', type_address)
        if (type == 'bank_edit') {
            console.log('bank_edit', type);
            setTypeBank(type);
        } else {
            console.log('bank_update', type);
            setTypeBank(type);
        }
    }

    const [tax_details, setTaxDetails] = useState([]);

    const GetTax = (x) => {
        let payroll_cycle_id = location?.state?.payroll_id;
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/payrolllimit/${payroll_cycle_id}`).then((res) => {
                console.log(res.data, 'success');
                setTaxDetails(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    //Page no  
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;


    const filteredUsers = paylist.filter((x) =>
        x.full_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedPayrollStatus === "" ||
            (selectedPayrollStatus === "Paid" && x.paidstatus) ||
            (selectedPayrollStatus === "Pending" && !x.paidstatus && x.paytextstatus === null) ||
            (selectedPayrollStatus === "Processed" && x.paytextstatus === 'Completed' && !x.paidstatus && x.paytextstatus !== null) ||
            (selectedPayrollStatus === "Skipped" && x.paytextstatus === 'Skipped'))
    );

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(paylist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const getOvertimeHrs = (typeArr, dateArr, hrsArr, type) => {
        if (typeArr && Array.isArray(typeArr) && hrsArr && Array.isArray(hrsArr)) {
            let total = 0;
            typeArr?.forEach((typeItem, i) => {
                if (checkifValidDate(dateArr[i]) && typeItem == type) {
                    total = total + hrsArr[i];
                }
            });
            return total
        } else {
            return 0
        }
    }

    const RenderView = () => {
        console.log('bind', bind_user);
        return <div className='bg-light  container-fluid comedi1'>

            <div className="row  mt-5 ">
                <div className="col-sm-3 hid_tab "></div>
                <div className="col-sm-9  ">
                    <div className="row">
                        <div className="col-4">
                            <div className="row mt-5">
                                <div className="col-6 ">

                                    <p className="profilename"> {bind_user.full_name}
                                        <p>Emp Id:{('000' + bind_user.employeeid).slice(-3)}
                                        </p></p>
                                </div>


                            </div>

                            <div className="row mt-5">

                                <p className="text-secondary  fw-bold mt-4">Regular Hours </p>
                                <p>{bind_user.timeHrsCount}</p>
                                <p className="text-secondary  fw-bold">Overtime</p>
                                <p>Regular Overtime</p>
                                <p>{bind_user.overTimeTypesList && bind_user.overTimeTypesList.length > 0 ? (getOvertimeHrs(bind_user.overTimeTypesList, bind_user.overTimeDatesList, bind_user.overTimeHrsList, bind_user.overTimeTypesList[0]).toLocaleString(undefined, { maximumFractionDigits: 2 })) : (
                                    0
                                )}</p>
                                <p>Rest Day/Weekend </p>
                                <p>{bind_user.overTimeTypesList && bind_user.overTimeTypesList.length > 0 ? (getOvertimeHrs(bind_user.overTimeTypesList, bind_user.overTimeDatesList, bind_user.overTimeHrsList, bind_user.overTimeTypesList[1]).toLocaleString(undefined, { maximumFractionDigits: 2 })) : (
                                    0
                                )}</p>
                                <p>Holiday Overtime </p>
                                <p>{bind_user.overTimeTypesList && bind_user.overTimeTypesList.length > 0 ? (getOvertimeHrs(bind_user.overTimeTypesList, bind_user.overTimeDatesList, bind_user.overTimeHrsList, bind_user.overTimeTypesList[2]).toLocaleString(undefined, { maximumFractionDigits: 2 })) : (
                                    0
                                )}</p>

                            </div>
                            <div className='row'>
                                <div className="col">

                                    <button className="btn btn-secondary" onClick={() => ShowDetails("")}>Close</button>
                                </div>
                            </div>




                        </div>
                        <div className="col-8 ">
                            <Tabs
                                defaultActiveKey="address"
                                id="uncontrolled-tab-example"
                                className="mb-3 neg2">



                            </Tabs>
                        </div>
                    </div>
                </div>


            </div>

            <Lheader />
        </div>;


    }
    const [timedate, settimedate] = useState("");


    const RenderLoad = () => {
        console.log('bind', bind_user);
        return <div className='bg-light  container-fluid comedit'>
            <div className='row vh-100 bg-light vw-100'>
                <div className='col-3'></div>
                <div className='col-3'>
                    <h1 className='text-center'>Processing Payroll...</h1>
                </div>
            </div>
        </div>;


    }

    const updateSal = (id) => {
        setsali(id);
        onUpdate(id);
        GetPaylist();
    }
    const checkifValidcomp = (component) => {
        console.log(component, 'salcomp');
        let isValid = true;
        console.log(chk, 'paylist');
        chk.forEach(item => {
            console.log(item, 'item');
            console.log(item.salarycomponents, 'item.salarycomponent');
            if (item.salarycomponents && item.salarycomponents.match(component)) {
                isValid = false;
            }
        });
        return isValid
    }

    const [chk, setecheck] = useState([]);


    const [accno, setaccno] = useState("");
    const [sal, setsal] = useState("");
    const onInsert = (data, e) => {
        // Get_salarydata(bind_user.emp_id);
        if (sal != "") {
            if (checkifValidcomp(sal)) {
                try {
                    let bnk = {
                        emp_id: bind_user.emp_id,
                        salarycomponent: sal,
                        amount: accno,

                        createdby: 1,
                        salarycomponent_type: salary_details.filter(item => item?.head_name == sal)[0]?.scope,
                        ispayrollcomponent: true,
                        payroll_id: location?.state?.payroll_id,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/Employee_salary`, bnk).then((res) => {
                        console.log('user updated', res.data);
                        //save();
                        //window.location = "/payrolecycle";
                        Getsalarydata(bind_user.emp_id);
                        TabChangepop("Basic_Profile");
                        Get_salarydata(bind_user.emp_id);
                        GetPaylist();
                    })

                } catch (err) {
                    console.log(err.message);
                }
            } else {

                alert('Selected component already exists')
            }

        } else {
            confirmAlert({
                message: 'Please fill all data',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }



    const Deletecomponent = (salCompIds) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/Employeedelsalary/` + salCompIds).then((res) => {
                console.log(res.data, 'success');
                Getsalarydata(bind_user.emp_id);
                Get_salarydata(bind_user.emp_id);
                GetPaylist();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const clickdelete = (salCompIds) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Deletecomponent(salCompIds),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }


    const onInsertpayroll = (data, e) => {

        let pay = {

            emp_id: bind_user.emp_id,
            payroll_cycle_id: location?.state?.payroll_id,
            fullname: bind_user.full_name,
            workeddays: bind_user.workeddays,
            paid: bind_user.paid,
            unpaid: bind_user.unpaid,
            timesheet: bind_user.time_sheet,
            amountbasic: bind_user.amountbasic,
            salarycomponent: bind_user.sal1,
            salarycomponent2: bind_user.sal2,
            amount: bind_user.am1,
            amount2: bind_user.am2,
            amount3: bind_user.am3,
            overtimehrs: bind_user.overtimehr,
            overtimert: bind_user.overtime_percentage,
            totalemppart: bind_user.emppart1 + bind_user.emppart2 + bind_user.emppart3,
            resident: bind_user.residentalstatus,
            hire_date: bind_user.hiredate,
            paytextstatus: 'pending',
            amtded4: bind_user.am4,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_Payroll`, pay).then((res) => {
            console.log('user updated', res.data);
            // save();
            window.location = "/payrolecycle";
            //setButtonText(false);
        }).catch(() => {

        })
    }
    const [isLoading, setIsLoading] = useState(false);

    // const callInsertPayrollMultiple = () => {
    //     confirmAlert({
    //         message: 'Are you sure want to Process?',
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: () => saverunpay()
    //             },
    //             {
    //                 label: 'No',
    //                 onClick: () => { }
    //             }
    //         ]
    //     });
    // }
    const callInsertPayrollMultiple = () => {
        confirmAlert({
            message: 'Are you sure want to process?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsLoading(true); // Show loading screen
                        saverunpay();
                    },
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => { },

                },
            ],
        });
    };
    const [basicwage, setBasicwage] = useState([])
    const Getbasicwage = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/minwagebasic/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'basicwage');

                setBasicwage(res.data[0].minimumwage, 'social');

            }).catch(() => {

            })
        }
    }
    const saverunpay = () => {
        // let a = paylist.filter(item => item?.select === true);
        const a = paylist.filter(item => item.select);
        onInsertpayrollForMUser(a);
    }


    const getTotalAmount = (type, typeList, amountList) => {
        if (typeList && amountList) {
            let amountToAdd = 0;
            typeList.forEach((typeItem, i) => {
                if (typeItem == type) {
                    amountToAdd = amountToAdd + amountList[i]
                }
            });
            return amountToAdd
        } else {
            return 0
        }
    }

    // const checkifValidDate = (input) => {
    //     let inputDate = moment(input);
    //     if (moment(location?.state?.startdate).isSameOrBefore(inputDate) && moment(location?.state?.enddate).isSameOrAfter(inputDate)) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    const checkifValidDate = (input) => {
        const inputDate = moment(input);
        const startDate = moment(location?.state?.startdate).startOf('day');
        const endDate = moment(location?.state?.enddate).endOf('day');
        return inputDate.isBetween(startDate, endDate, null, '[]');
    }


    const [work, setwork] = useState();

    // Post method of processing payroll   
    const onInsertpayrollForMUser = (users, e) => {
        let finallist = {
            inputList: users.map(user => {
                // let date = new Date();
                // let start = new Date(location?.state?.startdate);
                // let end = new Date(location?.state?.enddate);
                // let holidays = 0; console.log(start);
                // console.log(end);
                // while (start <= end) {
                //     let day = start.getDay();
                //     if (day !== user?.monday && day !== user?.tuesday && day !== user?.wednesday && day !== user?.thursday && day !== user?.friday && day !== user?.saturday && day !== user?.sunday) {
                //         holidays++;
                //     }
                //     console.log(holidays, 'working');
                //     start.setDate(start.getDate() + 1);
                // }
                // setwork(holidays)

                let date = new Date();
                let start = new Date(location?.state?.startdate);
                let end = new Date(location?.state?.enddate);
                let totalWorkingDays = 0;
                while (start <= end) {
                    let dayOfWeek = start.getDay();
                    let workingHours = 0;
                    switch (dayOfWeek) {
                        case 0: // Sunday
                            workingHours = user?.sunday;
                            break;
                        case 1: // Monday
                            workingHours = user?.monday;
                            break;
                        case 2: // Tuesday
                            workingHours = user?.tuesday;
                            break;
                        case 3: // Wednesday
                            workingHours = user?.wednesday;
                            break;
                        case 4: // Thursday
                            workingHours = user?.thursday;
                            break;
                        case 5: // Friday
                            workingHours = user?.friday;
                            break;
                        case 6: // Saturday
                            workingHours = user?.saturday;
                            break;
                        default:
                            console.log(`Invalid day of week: ${dayOfWeek}`);
                            break;
                    }
                    if (workingHours === 7) {
                        totalWorkingDays++;
                    } else if (workingHours === 8) {
                        totalWorkingDays += 0.5;
                    } else if (workingHours >= 0 && workingHours <= 6) {
                        // Week off
                    } else {
                        console.log(`Invalid working hours for day ${start}`);
                    }
                    start.setDate(start.getDate() + 1);
                }
                console.log(`Total working days: ${totalWorkingDays}`);
                setwork(totalWorkingDays)

                function getNumberOfWeeks(year) {
                    const firstDayOfYear = new Date(year, 0, 1); // January 1st of the specified year
                    const lastDayOfYear = new Date(year, 11, 31); // December 31st of the specified year

                    // Find the first Thursday of the year
                    const firstThursday = new Date(firstDayOfYear.getTime());
                    firstThursday.setDate(firstDayOfYear.getDate() + ((4 - (firstDayOfYear.getDay() + 6) % 7) + 7) % 7);

                    // Find the last Thursday of the year
                    const lastThursday = new Date(lastDayOfYear.getTime());
                    lastThursday.setDate(lastDayOfYear.getDate() + ((4 - (lastDayOfYear.getDay() + 6) % 7) + 7) % 7);

                    // Calculate the number of weeks between the first and last Thursday
                    const weekCount = Math.floor((lastThursday.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1;

                    return weekCount;
                }

                // Usage:
                const currentYear = new Date().getFullYear();
                const numberOfWeeks = getNumberOfWeeks(currentYear);
                console.log(`Number of weeks in ${currentYear}: ${numberOfWeeks}`);


                let totalWorkingHoursWeek = 0;

                // Check if Monday to Sunday is stored as 7 or 8, and calculate the total hours
                if (user.monday === 7 || user.monday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.monstime, user.monetime);
                }
                if (user.tuesday === 7 || user.tuesday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.tuestime, user.tueetime);
                }
                if (user.wednesday === 7 || user.wednesday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.wedstime, user.wedetime);
                }
                if (user.thursday === 7 || user.thursday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.thustime, user.thuetime);
                }
                if (user.friday === 7 || user.friday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.fristime, user.frietime);
                }
                if (user.saturday === 7 || user.saturday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.satstime, user.satetime);
                }
                if (user.sunday === 7 || user.sunday === 8) {
                    totalWorkingHoursWeek += calculateWorkingHours(user.sunstime, user.sunetime);
                }

                console.log(`Total working hours: ${totalWorkingHoursWeek}`);

                // Helper function to calculate the working hours based on start time and end time
                function calculateWorkingHours(startTime, endTime) {
                    const start = parseFloat(startTime);
                    const end = parseFloat(endTime);
                    if (!isNaN(start) && !isNaN(end) && end >= start) {
                        return end - start;
                    } else {
                        console.log(`Invalid working hours: start=${startTime}, end=${endTime}`);
                        return 0;
                    }
                }


                let overTimeHrsList = user?.overtimehrslist?.split(',')?.map(i => parseFloat(i));
                let overTimePerList = user?.overtimepercentagelist?.split(',')?.map(i => parseInt(i, 10));
                let overTimeDatesList = user?.overtimedatelist?.split(',');

                let regularRate = user?.amountbasic / (totalWorkingHoursWeek * 52 / 12);
                console.log(regularRate, "rate")
                console.log(totalWorkingHoursWeek * 52 / 12, "hrrate")
                let overtimeAmt = 0;
                let regularAmt = 0;

                if (overTimeHrsList) {
                    overTimeHrsList.forEach((hrs, i) => {
                        if (checkifValidDate(overTimeDatesList[i])) {
                            console.log(overTimePerList[i], hrs, 'hrs and per');
                            overtimeAmt = overtimeAmt + (((regularRate / 100) * overTimePerList[i]) * hrs);
                            console.log(overtimeAmt, 'overtime')
                            regularAmt = regularAmt + (regularRate * hrs);
                        }
                    });
                }

                let nightHrsList = user?.nighthrslist?.split(',')?.map(i => parseFloat(i));
                //let nightMinList = user?.nightminlist?.split(',')?.map(i => parseFloat(i));
                //let nightPerList = user?.nightpercentagelist?.split(',')?.map(i => parseInt(i, 10));
                //let nightDatesList = user?.otdatelist?.split(',');
                let nightDatesList = user?.otdatelist?.split(',');

                let nightregularRate = user?.amountbasic / (totalWorkingDays * 8);
                let nightAmt = 0;
                let RegularRate = 0;

                if (nightHrsList) {

                    nightHrsList.forEach((hrs, i) => {
                        if (checkifValidDate(nightDatesList[i])) {
                            console.log(nightDatesList[i], hrs, 'ntm1');
                            //alert( nightPerList[i])
                            //RegularRate = regularRate * (hrs);
                            nightAmt = nightAmt + ((nightregularRate * 0.3) * hrs);
                            console.log(nightAmt, 'ntm');
                            //alert( nightAmt)

                        }
                    });
                }

                let totalHours = user?.totalhours;
                let timEsheetdate = user?.timesheetdate;
                let timeHrsCount = 0;
                let totaldays = 0;
                if (totalHours) {
                    totalHours.forEach((hrs, i) => {
                        if (checkifValidDate(timEsheetdate[i])) {
                            console.log(timeHrsCount, hrs)
                            timeHrsCount = timeHrsCount + hrs;
                            totaldays = totaldays + 1
                            console.log(totaldays, 'count');
                        }
                    });
                }

                let totalWorkingDaysWeek = 0;
                let currentDate = new Date();
                for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                    let workingHoursWeek = 0;
                    switch (dayOfWeek) {
                        case 0: // Sunday
                            workingHoursWeek = user?.sunday;
                            break;
                        case 1: // Monday
                            workingHoursWeek = user?.monday;
                            break;
                        case 2: // Tuesday
                            workingHoursWeek = user?.tuesday;
                            break;
                        case 3: // Wednesday
                            workingHoursWeek = user?.wednesday;
                            break;
                        case 4: // Thursday
                            workingHoursWeek = user?.thursday;
                            break;
                        case 5: // Friday
                            workingHoursWeek = user?.friday;
                            break;
                        case 6: // Saturday
                            workingHoursWeek = user?.saturday;
                            break;
                        default:
                            console.log(`Invalid day of week: ${dayOfWeek}`);
                            break;
                    }
                    if (workingHoursWeek === 7) {
                        totalWorkingDaysWeek++;
                    } else if (workingHoursWeek === 8) {
                        totalWorkingDaysWeek += 0.5;
                    } else if (workingHoursWeek >= 0 && workingHoursWeek <= 6) {
                        // Week off
                    } else {
                        console.log(`Invalid working hours for day ${dayOfWeek}`);
                    }
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                console.log(`Total working days: ${totalWorkingDaysWeek}`);

                let totalWorkingDaysPerMonth = Math.round(totalWorkingHoursWeek / 8 * 52 / 12);
                console.log(`Total working days per month: ${totalWorkingDaysPerMonth}`);
                //totalWorkingDaysPerMonth();
                //let grossBasic = user?.amountbasic / totalWorkingDays * ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 }));
                let grossBasic;
                if ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 }) < totalWorkingDays) {

                    let unpaiddays = totalWorkingDays - ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 }))

                    console.log(unpaiddays, "unpaiddays");

                    if (totalWorkingDays > totalWorkingDaysPerMonth && totalWorkingDaysPerMonth == unpaiddays) {

                        let unpaidday = totalWorkingDaysPerMonth - ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 }))

                        let normalgross = user?.amountbasic / totalWorkingDaysPerMonth;

                        console.log(normalgross, "normalgross")

                        grossBasic = user?.amountbasic - (normalgross * unpaidday);

                    } else {

                        let normalgross = user?.amountbasic / totalWorkingDaysPerMonth;

                        console.log(normalgross, "normalgross")

                        grossBasic = user?.amountbasic - (normalgross * unpaiddays);

                    }



                } else {

                    grossBasic = user?.amountbasic / totalWorkingDays * ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 }));

                }
                let OTPremium = 0
                if (overtimeAmt == 0) {
                    OTPremium = nightAmt - regularAmt;
                } else {
                    OTPremium = overtimeAmt - regularAmt;
                }

                return {
                    emp_id: user.emp_id,
                    payroll_cycle_id: location?.state?.payroll_id,
                    fullname: user.full_name,
                    workeddays: ((timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 })),
                    grossbasic: grossBasic,
                    paid: user.paid,
                    unpaid: user.unpaid,
                    timesheet: user.time_sheet,
                    amountbasic: user.amountbasic,
                    regularrate: regularRate,
                    overtimeamt: overtimeAmt,
                    otpremium: OTPremium,
                    earningsTotal: getTotalAmount('Earning', user.salCompTypes, user.salCompAmounts),
                    deductionsTotal: getTotalAmount('Deduction', user.salCompTypes, user.salCompAmounts),
                    totalemppart: user.emppart1 + user.emppart2 + user.emppart3,
                    resident: user.residentalstatus,
                    hire_date: user.hiredate,
                    paytextstatus: 'pending',
                    workingdays: totalWorkingDays,
                    socialper: user.emppart1,
                    healthper: user.emppart2,
                    unempper: user.emppart3,
                    zone: user.zone,
                    nightamt: nightAmt,
                    minimumwage: user.minimumwage,
                    basicwage: basicwage,
                    compsocialper: user.emlyrpart1,
                    comphealthper: user.emlyrpart2,
                    compunempper: user.emlyrpart3,
                    dependents: user.dependance,
                    labourtype: user.labourtype,
                    totalnontaxable_earning: getTotalAmount('Non taxable Earning', user.salCompTypes, user.salCompAmounts),
                    totalnontaxable_deduction: getTotalAmount('Non taxable Deduction', user.salCompTypes, user.salCompAmounts),
                }
            })
        }

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_payroll_multiple`, finallist).then((res) => {
            console.log('user updated', res.data);

            onUpdatestatus();
        }).catch(() => {
            onUpdatestatus();
        });
    }

    const onInsertpayrollForUser = (user, e) => {

        let pay = {
            user_id: state.user[0].user_id,
            emp_id: user.emp_id,
            payroll_cycle_id: location?.state?.payroll_id,
            fullname: user.full_name,
            workeddays: user.workeddays,
            paid: user.paid,
            unpaid: user.unpaid,
            timesheet: user.time_sheet,
            amountbasic: user.amountbasic,
            salarycomponent: user.sal1,
            salarycomponent2: user.sal2,
            amount: user.am1,
            amount2: user.am2,
            amount3: user.am3,
            overtimehrs: user.overtimehr,
            overtimert: user.overtime_percentage,
            totalemppart: user.emppart1 + user.emppart2 + user.emppart3,
            resident: user.residentalstatus,
            hire_date: user.hiredate,
            paytextstatus: 'pending',
            amtded4: user.am4,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_Payroll`, pay).then((res) => {
            console.log('user updated', res.data);
            // save();
            // window.location = "/payrolecycle";
            //setButtonText(false);
        }).catch(() => {

        })
    }

    const [buttonText, setButtonText] = useState(true);

    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className=' card comedii cardmob cardtab'>
            <nav className=''>
                <div class="nav mt-3 mx-sm-5  " id="nav-tab" role="tablist">
                    {/* <a class="nav-item nav-link   text-tabcolor text-center  Pointer_" id="nav-home-tab" data-toggle="tab" onClick={() => { TabChangepop("Basic_Profile") }} role="tab" aria-controls="nav-home" aria-selected="true">Existing components</a>
                        <a class="nav-item nav-link  text-tabcolor   ms-3 Pointer_" id="nav-profile-tab" data-toggle="tab" role="tab" onClick={() => { TabChangepop("Job_Profile") }} aria-controls="nav-profile" aria-selected="false">Add new components </a> */}

                    <a class={`nav-item nav-link  text-tabcolor text-center  pointer_ ${activeTab === 'Existing' ? 'active' : ''}`}
                        id="nav-home-tab"
                        data-toggle="tab"
                        onClick={() => { setActiveTab('Existing'); TabChangepop('Basic_Profile'); }}
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected={activeTab === 'Existing'}>
                        Existing components
                    </a>

                    <a class={`nav-item nav-link text-tabcolor text-center  ms-sm-3 pointer_ ${activeTab === 'New' ? 'active' : ''}`}
                        id="nav-profile-tab"
                        data-toggle="tab"
                        onClick={() => { setActiveTab('New'); TabChangepop('Job_Profile'); }}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected={activeTab === 'New'}>
                        Add new components
                    </a>

                    <button type="button" class="btn-close ms-sm-auto" data-bs-dismiss="modal" onClick={() => ShowDetails("")} aria-label="Close"></button>
                </div>
            </nav>
            {(() => {
                if (tab_type == "Basic_Profile") {
                    return <div>
                        <div class="modal-content ">
                            <div class="modal-header ms-sm-3">
                                <h7 class="modal-title fw-bold ms-sm-5 text-primary text-capitalize mt-3" id="exampleModalLabel">{bind_user.full_name}</h7>
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button> */}
                            </div>
                            <div className='ms-sm-3'>
                                <p className='fw-bold ms-sm-5'>Salary Components</p>
                            </div>

                            <div class="modal-body ">
                                {/* <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST"> */}
                                <div className='row ms-sm-1'>
                                    <small className=' mt-1 text-capitalize ms-sm-5 '>Monthly Wage</small>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <p class=" ms-sm-5 fw-bold text-lowercase">{Math.round(bind_user.amountbasic).toLocaleString('en-US').replace(/,/g, '.')} đ</p>
                                            {/* <input type="text" minlength="1" maxlength="30" class=" ms-5  " Value={bind_user.amountbasic} onChange={(e) => setAmounttypes(e.target.value)} placeholder="" aria-label="Username" /> */}

                                        </div>
                                        {/* <div className='col-6'>
                                            <form onSubmit={handleSubmit(savebasic)} action="#" method="POST">
                                                <button type="submit" class="btn btn-pri ms-3 salupdate"><p className='upbtn'>Update</p></button>
                                            </form>
                                        </div> */}
                                    </div>

                                    {(() => {
                                        if (bind_user.salCompTypes == null || bind_user.salCompTypes == undefined) {
                                            return <div></div>
                                        } else {
                                            return bind_user.salCompTypes.map((sal, i) => {
                                                return <div><small className=' text-capitalize ms-sm-5 '>{bind_user.salComponents[i]}</small>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <input type="text" onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }} minlength="1" maxlength="30" class=" ms-sm-5  " Value={bind_user.salCompAmounts[i]} onChange={(e) => setSalarytypes(e.target.value)} placeholder="" aria-label="Username" />
                                                        </div>
                                                        <div className='col-3'>
                                                            <form onSubmit={handleSubmit(() => updateSal(bind_user.salCompIds[i]))} action="#" method="POST">
                                                                <button type="submit" class="btn btn-pri ms-1 salupdate"><p className=''>Update</p></button>
                                                            </form>
                                                        </div>
                                                        <div className='col-1  '>
                                                            <svg onClick={() => clickdelete(bind_user.salCompIds[i])} xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            });
                                        }
                                    })()}


                                </div>

                                <div className='row'>

                                    <div className='col'>
                                        {/* <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST">
                                            <button onSubmit={handleSubmit(onUpdate)} type="submit" class="btn btn-pri  ">Update</button>
                                        </form> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                } else {
                    return <div>
                        <div class="modal-content ">
                            <div class="modal-header ms-sm-3 mt-3">
                                <h7 class="modal-title fw-bold ms-sm-5 text-primary text-capitalize" id="exampleModalLabel">{bind_user.full_name}</h7>

                            </div>
                            <div className='ms-sm-3'>
                                <p className='fw-bold ms-sm-5 '>Salary Components</p>
                            </div>

                            <div class="modal-body ">
                                {/* <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST"> */}
                                <div className='row'>


                                    <form className="App" autoComplete="off" onSubmit={handleSubmit(save)} action="#" method="POST">
                                        <div className='row ms-sm-1'>
                                            <div className='col-sm-6'>

                                                <select name="head_name" class="form-select text-start inputcolor ms-sm-5 drpsz " id="service" aria-label=" select Day"
                                                    // value={singleService.service}
                                                    onChange={(e) => setsal(e.target.value)}
                                                    //onChange={(e) => handleServiceChange(e, index)}

                                                    required>
                                                    <option>Salary Components</option>
                                                    {salary_details.map((x, index) => {

                                                        return (

                                                            <option value={x.head_name}>{x.head_name}</option>
                                                        );
                                                    })}

                                                </select>

                                            </div>

                                            <div className='col-sm-5'>
                                                <div class="input-group hide_all">
                                                    <input type="text" class="form-control ms-sm-2 inputcolor" onChange={(e) => setaccno(e.target.value)} placeholder="Amount" aria-label="Username" />
                                                </div>

                                                <div class="input-group onlytab hide_tab">
                                                    <input type="text" class="form-control ms-sm-2 inputcolor mt-2" onChange={(e) => setaccno(e.target.value)} placeholder="Amount" aria-label="Username" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-9'></div>
                                            <div className='col-sm-3'>
                                                <button type="submit" class="btn btn-pri mt-2 ms-sm-2" onSubmit={handleSubmit(save)}>Add   </button>
                                            </div>

                                        </div>



                                    </form>


                                </div>
                                <div className='row'>

                                    <div className='col'>
                                        {/* <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST">
                                            <button onSubmit={handleSubmit(onUpdate)} type="submit" class="btn btn-pri  ">Update</button>
                                        </form> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            })()}




        </div>;




    }

    const RenderEdit2 = () => {

        console.log('bind', bind_user);
        confirmAlert({
            title: '',

            message: <div className='row'>


                <button onClick={handleSubmit(onInsertpayroll)} type="submit" className='btn btn-pri'>add</button>


            </div>,
            buttons: [
                {
                    label: 'Close',
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },



            ]
        })

    }
    const areAnyCheckboxesChecked = paylist.some(item => item.select === true);

    const InsertPayrollskip = (x) => {
        confirmAlert({
            message: 'Are you sure want to skip?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        onInsertpayrollForSkippedUser(x);
                    },
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };



    const onInsertpayrollForSkippedUser = (user) => {
        let pay = {
            emp_id: user.emp_id,
            payroll_cycle_id: location?.state?.payroll_id,
            fullname: user.full_name,
            workeddays: user.workeddays,
            paid: user.paid,
            unpaid: user.unpaid,
            timesheet: user.time_sheet,
            amountbasic: user.amountbasic,
            resident: user.residentalstatus,
            hire_date: user.hiredate,
            paytextstatus: 'Skipped',
            payrollstatus: true,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_Payroll_Skip`, pay).then((res) => {
            console.log('user updated', res.data);
            GetPaylist();
        }).catch(() => {

        })
    }

    const clickdeleteskip = (emp_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to undo',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(emp_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => { },
                }
            ]
        })
    }
    const Delete = (emp_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/payroll_unskip/` + emp_id).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/addjobtitle";
                if (res.data == true) {

                }
                GetPaylist();
            })
        } catch (err) {
            console.log(err.message);
        }
    }




    return (
        <div className='bg-light  container-fluid'>
            {isLoading ? (
                <Loadingscreen />
            ) : (
                <div className='row mt-4'>
                    <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-12 col-lg-2 col-xl-2 hid_tab'>

                    </div>
                    <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light  mt-4 tbl'>
                        <input type="text" class="form-control grey mt-4 ms-sm-5 searchbox" placeholder="Search by Employee Name"
                            onChange={(event) => { setSearchTerm(event.target.value); }} id="" aria-label="Username" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon srhicn" viewBox="0 0 20 20">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                        <div className='row'>
                            <div className='col-sm-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 ms-3'>
                                <p className='ms-5 mt-2 pytextali fnt-clr'>Pay role Cycle: {location?.state?.month}</p>
                            </div>
                            <div className='col-sm-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'>
                                <div class="">
                                    {/* <select class="text-start upsele text-secondary " aria-label=" select Day">
                                    <option selected>Upload Time Sheet</option>
                                    <option value="1">Upload Attendance</option>
                                    <option value="2">Upload Salary Details</option>
                                   
                                </select> */}
                                    {/* <button class="btn btn-light border flbtn text-secondary" >Upload</button>
                                <input class=" fileupld" type="file" placeholder="Upload Time Sheet" id="formFileMultiple" onChange={handleUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <button class="btn btn-secondary payrolebtn" onClick={alet}>Back</button>
                            </div>
                            <form onSubmit={handleSubmit(callInsertPayrollMultiple)} action="#" method="POST">
                                {palist.map((x, index) => {
                                    return (
                                        <div className='col-sm-2'>
                                            {/* <button type='submit' disabled={!areAnyCheckboxesChecked}
                                                class="btn btn-secondary payrolebtn payrolebtn1">Process</button> */}
                                            {(() => {

                                                console.log(x.payroll_id, location?.state?.payroll_id, 'te')
                                                if (x.payroll_id == location?.state?.payroll_id) {
                                                    return (
                                                        <button
                                                            type='submit'
                                                            disabled={!areAnyCheckboxesChecked}
                                                            className="btn btn-pri payrolebtn payrolebtn1"
                                                        >
                                                            Process
                                                        </button>
                                                    );
                                                } else {
                                                    return (
                                                        <button
                                                            disabled
                                                            className="btn btn-secondary payrolebtn payrolebtn1"
                                                        >
                                                            Process
                                                        </button>
                                                    );
                                                }

                                            })()}

                                            {/* {isLoading && <Loadingscreen />}  */}
                                            {/* Render LoadingScreen when isLoading is true */}

                                        </div>
                                    );
                                })}
                                {/* {tax_details.map((x, index) => {
                                return (
                                    onClick={() => ShowDetails('load')}
                                    <div className='col-2'>
                                        {(() => {
                                            // if (x.payroll_amount == null) {
                                                return <button type='submit' class="btn btn-secondary payrolebtn payrolebtn1">Run Payroll</button>
                                            // } else {
                                            //     return <button  class="btn btn-secondary payrolebtn payrolebtn1" disabled>Run Payroll</button>
                                            // }
                                        })()}


                                    </div>
                                );
                            })} */}
                            </form>
                        </div>


                        <div class="card ms-sm-5 shadow   rounded  cardb cardbt viewtop">

                            <div class="card-body ">
                                <div className='row'>

                                    <div className='col-sm-3'>
                                        {/* <p className='fw-bold mt-2 dwndtxttab'>Download the Template</p> */}
                                    </div>
                                    <div className='col-sm-3'>
                                        {/* <button class="btn btn-pri dwnbtn" onClick={handleexcelDownload}>Download</button> */}
                                    </div>

                                    <div className='col-sm-6 align-button'>

                                        <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 pointer_" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                        </svg>
                                        {/* <button class="btn btn-pri dwnbtn" onClick={callInsertPayrollMultiple}>Add Selected</button> */}
                                        <button class="btn btn-pri ms-4" onClick={payrlist}>View Details</button>
                                    </div>

                                </div>

                                <div className='row'>

                                    <div className='col-sm'>
                                        <div className="row">
                                            <div className="ms-4 mt-2 onlytab">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <table class="table table-bordered mt-4 year-buttonss">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr className=''>
                                                        <td className='pointer_'>  <input type="checkbox"
                                                            className='ms-sm-4'
                                                            onChange={e => {
                                                                const value = e.target.checked;
                                                                setPay_list(prevList => {
                                                                    return prevList.map(item => {
                                                                        if (
                                                                            item.timesheet === null &&
                                                                            item.paytextstatus == null &&
                                                                            (item.amountbasic != null && item.amountbasic != undefined) // Updated condition
                                                                            && (item.leaveCount != 0 || item.timeHrsCount / 8 != 0) &&
                                                                            (new Date(item.contractduedate) > new Date() || item.contractduedate == null)
                                                                            && (item.workschedule != null && item.workschedule != undefined) && (item.nationality_id != null && item.nationality_id != undefined)
                                                                            && (item.residentalstatus != null && item.residentalstatus != undefined) &&
                                                                            (item.hiredate != null && item.hiredate != undefined) && (item.dependance != null && item.dependance != undefined) && (item.pitcode != null && item.pitcode != undefined && item.pit_code != "")
                                                                            && (item.sibook != null && item.sibook != undefined && item.si_book != "")

                                                                        ) {
                                                                            item.select = value;
                                                                        } else {
                                                                            item.select = false; // Deselect if conditions are not met
                                                                        }
                                                                        console.log(item, 'prevListt');
                                                                        return item;
                                                                    });
                                                                });
                                                            }}



                                                        /></td>




                                                        <td scope="col" className='headfont  text-center text-uppercase greyh'>Name </td>
                                                        <td scope="col" className='headfont  text-center text-uppercase greyh'>Profile Status </td>
                                                        <td scope="col" className='headfont  text-center text-uppercase grey'>Paid Days</td>
                                                        <td scope="col" className='headfont   hidecoltab text-center text-uppercase grey'>Paid Leave</td>

                                                        <td scope="col" className='headfont  text-center text-uppercase grey'>Regular Hours </td>
                                                        <td scope="col" className='headfont  text-center text-uppercase grey'>Overtime </td>
                                                        <td></td>
                                                        <td scope="col" className='headfont  text-center text-uppercase grey'>Wage </td>
                                                        <td></td>
                                                        <td scope="col" className='headfont  text-center text-uppercase greyh'>Payroll Status </td>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayUsers.filter((x) =>
                                                        x.full_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                                                        (selectedPayrollStatus === "" ||
                                                            (selectedPayrollStatus === "Paid" && x.paidstatus) ||
                                                            (selectedPayrollStatus === "Pending" && !x.paidstatus && x.paytextstatus === null) ||
                                                            (selectedPayrollStatus === "Processed" && x.paytextstatus === 'Completed' && !x.paidstatus && x.paytextstatus !== null) ||
                                                            (selectedPayrollStatus === "Skipped" && x.paytextstatus === 'Skipped'))
                                                    ).map((x, index) => {
                                                        return (
                                                            <>


                                                                {(() => {
                                                                    if (x.payrollstatus == true) {
                                                                        return <tr className=''>
                                                                            <td className=''>
                                                                                <form className="App" autoComplete="off" action="#" method="POST">

                                                                                    <div class="form-check ">
                                                                                        {(() => {
                                                                                            if (x.paytextstatus == "Completed" || x.paytextstatus == "Skipped") {
                                                                                                return <input
                                                                                                    type="checkbox"

                                                                                                    disabled

                                                                                                />
                                                                                            } else {
                                                                                                return <input
                                                                                                    type="checkbox"

                                                                                                    checked={x.select}
                                                                                                    onChange={e => {
                                                                                                        let value = e.target.checked;
                                                                                                        setPay_list(
                                                                                                            paylist.map(sd => {
                                                                                                                if (sd.emp_id === x.emp_id) {
                                                                                                                    sd.select = value;
                                                                                                                }
                                                                                                                return sd;
                                                                                                            })
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        })()}



                                                                                    </div>
                                                                                </form>

                                                                            </td>
                                                                            <td className='text-center text-capitalize'>{x.full_name}.{x.last_name}</td>
                                                                            {(() => {
                                                                                if (x.timesheet != null || (x.amountbasic == null || x.amountbasic == undefined) || (x.contractduedate && new Date(x.contractduedate) < new Date()) ||
                                                                                    (x.workschedule == null || x.workschedule == undefined) || (x.nationality_id == null || x.nationality_id == undefined) || (x.residentalstatus == null || x.residentalstatus == undefined) ||
                                                                                    (x.hiredate == null || x.hiredate == undefined) || (x.dependance == null || x.dependance == undefined) || (x.pitcode == null || x.pitcode == undefined || x.pit_code == "") || (x.sibook == null || x.sibook == undefined || x.si_book == "")) {
                                                                                    return <td className='text-danger text-center'>Incomplete</td>
                                                                                } else {

                                                                                    return <td className='text-success text-center'>Complete</td>;
                                                                                }

                                                                            })()}
                                                                            <td className='calicn  text-center'>{(x.timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 })} </td>
                                                                            <td className='calicn  text-center'>{x.leaveCount} </td>


                                                                            <td className=' text-center'>{x.timeHrsCount.toLocaleString(undefined, { maximumFractionDigits: 2 })} Hours</td>
                                                                            <td className='text-center '>{x.overtimeHrsCount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                                            <td className='text-center pointer_'><svg onClick={() => ShowDetails(x, 'view')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="fnt-clr" class="bi bi-eye-fill eyeicn" viewBox="0 0 16 16">
                                                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                            </svg></td>
                                                                            <td className=' text-center text-lowercase'>{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.')} đ</td>
                                                                            <td className='pointer_'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                                            </svg></td>

                                                                            {(() => {
                                                                                if (x.paidstatus == true) {
                                                                                    return <td className='text-success text-center'>Paid</td>;
                                                                                } else if (x.paytextstatus == null) {
                                                                                    return <td className='text-center'>Pending</td>;
                                                                                } else if (x.paytextstatus == 'pending') {
                                                                                    return <td className='text-capitalize text-center'>{x.paytextstatus}</td>;
                                                                                } else if (x.paytextstatus == 'Skipped') {
                                                                                    return <td className='text-warning text-capitalize text-center'>{x.paytextstatus}</td>;
                                                                                } else {
                                                                                    return <td className='text-primary text-center'>Processed</td>;
                                                                                }
                                                                            })()}

                                                                            {(() => {
                                                                                if (x.paytextstatus == 'Skipped') {
                                                                                    return <td className='' ><svg id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                                    </svg>
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li>
                                                                                                <a onClick={() => clickdeleteskip(x.emp_id)} className="dropdown-item">Undo</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </td>;
                                                                                } else {
                                                                                    return <td className='' ><svg id="dropdownMenuButton1" disabled data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                                    </svg>
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        </ul>
                                                                                    </td>;
                                                                                }
                                                                            })()}


                                                                        </tr>
                                                                    } else {
                                                                        return <tr className=''>
                                                                            <td className=''>
                                                                                <form className="App" autoComplete="off" onSubmit={handleSubmit(onInsertpayroll)} action="#" method="POST">

                                                                                    <div class="form-check ">
                                                                                        {(() => {
                                                                                            if (x.timesheet != null || (x.amountbasic == null || x.amountbasic == undefined) || (x.leaveCount == 0 && x.timeHrsCount / 8 == 0) || (x.contractduedate && new Date(x.contractduedate) < new Date()) ||
                                                                                                (x.workschedule == null || x.workschedule == undefined) || (x.nationality_id == null || x.nationality_id == undefined) || (x.residentalstatus == null || x.residentalstatus == undefined) ||
                                                                                                (x.hiredate == null || x.hiredate == undefined) || (x.dependance == null || x.dependance == undefined) || (x.pitcode == null || x.pitcode == undefined || x.pit_code == "") || (x.sibook == null || x.sibook == undefined || x.si_book == "")) {
                                                                                                return <input
                                                                                                    type="checkbox"

                                                                                                    disabled

                                                                                                />

                                                                                            } else {
                                                                                                return <input
                                                                                                    type="checkbox"

                                                                                                    checked={x.select}
                                                                                                    onChange={e => {
                                                                                                        let value = e.target.checked;
                                                                                                        setPay_list(
                                                                                                            paylist.map(sd => {
                                                                                                                if (sd.emp_id === x.emp_id) {
                                                                                                                    sd.select = value;
                                                                                                                }
                                                                                                                return sd;
                                                                                                            })
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        })()}


                                                                                    </div>
                                                                                </form>

                                                                            </td>
                                                                            <td className='text-center text-capitalize'>{x.full_name}.{x.last_name}</td>
                                                                            {(() => {
                                                                                if (x.timesheet != null || (x.amountbasic == null || x.amountbasic == undefined) || (x.contractduedate && new Date(x.contractduedate) < new Date()) ||
                                                                                    (x.workschedule == null || x.workschedule == undefined) || (x.nationality_id == null || x.nationality_id == undefined) || (x.residentalstatus == null || x.residentalstatus == undefined) ||
                                                                                    (x.hiredate == null || x.hiredate == undefined) || (x.dependance == null || x.dependance == undefined) || (x.pitcode == null || x.pitcode == undefined || x.pit_code == "") || (x.sibook == null || x.sibook == undefined || x.si_book == "")) {
                                                                                    return <td className='text-danger text-center'>Incomplete</td>
                                                                                } else {

                                                                                    return <td className='text-success text-center'>Complete</td>;
                                                                                }

                                                                            })()}
                                                                            <td className='calicn  text-center'>{(x.timeHrsCount / 8).toLocaleString(undefined, { maximumFractionDigits: 1 })} </td>

                                                                            <td className='calicn  text-center'>{x.leaveCount} </td>


                                                                            <td className=' text-center'>{x.timeHrsCount.toLocaleString(undefined, { maximumFractionDigits: 2 })} Hours</td>
                                                                            <td className='text-center'>{x.overtimeHrsCount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                                            <td className='text-center pointer_'><svg onClick={() => ShowDetails(x, 'view')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#4EB4E6" class="bi bi-eye-fill  eyeicn" viewBox="0 0 16 16">
                                                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                            </svg></td>
                                                                            {(() => {
                                                                                if ((x.amountbasic == null || x.amountbasic == undefined)) {
                                                                                    return <td className='text-danger text-center'>Wage Required</td>
                                                                                } else {
                                                                                    return <td className=' text-center text-lowercase'>{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.')} đ</td>
                                                                                }
                                                                            })()}

                                                                            <td className='pointer_'><svg onClick={() => (ShowDetails(x, 'edit'), Get_salarydata(x.emp_id))} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                                            </svg></td>

                                                                            {(() => {
                                                                                if (x.paytextstatus == null) {
                                                                                    return <td className='text-center'>Pending </td>
                                                                                }
                                                                                if (x.paytextstatus == 'pending') {
                                                                                    return <td className='text-capitalize text-center'>{x.paytextstatus} </td>
                                                                                } else {
                                                                                    return <td className='text-success text-center'>{x.paytextstatus} </td>
                                                                                }
                                                                            })()}

                                                                            <td className='pointer_'><svg id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                            </svg>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                                                    <li>
                                                                                        <form onSubmit={handleSubmit(InsertPayrollskip)} action="#" method="POST">
                                                                                            <a onClick={() => InsertPayrollskip(x)} className="dropdown-item">Skip</a>
                                                                                        </form>
                                                                                    </li>
                                                                                </ul>


                                                                            </td>

                                                                        </tr>
                                                                    }
                                                                })()}
                                                            </>
                                                        );
                                                    })}




                                                </tbody>

                                            </table>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <p class=" fs-6 ms-sm-4 fw-bold">Payroll status</p>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <select class="form-select inputcolor popupdrop ms-sm-4" value={selectedPayrollStatus} onChange={(e) => setSelectedPayrollStatus(e.target.value)} aria-label=" select Day">
                                                        <option value="">All</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Processed">Processed</option>
                                                        <option value="Paid">Paid</option>
                                                        <option value="Skipped">Skipped</option>
                                                    </select>


                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                <div class="s">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();
                    case "edit": return RenderEdit();
                    case "edit2": return RenderEdit2();
                    case "load": return RenderLoad();
                    default: return "";
                }
            })()}</div>
            <Lheader />
        </div>
    );
}
export default PayroleCycle;