import React, { useCallback, useState, useEffect, useContext } from 'react';
import './LandingPage/LandingPage.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useNavigate } from "react-router-dom";
import { store } from '../store/user';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
const LandingPage = (props) => {

    const { update, handleSubmit } = useForm();
    const [user_details, setuserDetails] = useState('');
    let navigate = useNavigate();
    const login = () => {
        let path = `/login`;
        navigate(path);
    }
    const signup = () => {
        let path = `/signup`;
        navigate(path);
    }
    const employeedashboard = () => {
        let path = `/employeedashboard`;
        navigate(path);
    }
    const symp = () => {
        window.location = 'https://www.Symplefy.Com/'; // Include the protocol (https://) and correct capitalization

    }


    const gohome = () => {
        //debugger;
        navigate("/admindashboard");
    };

    const Getloginuser = () => {
        // console.log(type, 'success');
        setuserDetails('');
        localStorage.setItem('user_details', JSON.stringify('Employee'));
        dispatch({ type: 'ADD_USER', payload: { user: 'Employee' } });//context api updated.  
        gohome();

    }
    const globalState = useContext(store);
    const { dispatch } = globalState;

    useEffect(() => {
        const loggedInUser = localStorage.getItem('user_details');
        console.log('loggedInUser', loggedInUser);
        if (loggedInUser != null) {
            gohome();
        }
    }, []);

    const [demoname, setDemoname] = useState("");
    const [demodate, setDemodate] = useState("");
    const [emaill, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [designation, setDesignation] = useState("");
    const [reason, setReason] = useState("");
    const onInsert = (data, e) => {

        let demo = {

            name: demo_name,
            demodate: demodate,
            email: emaill,
            phoneno: phonee,
            designation: desig_nation,
            reason: reason,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/demo`, demo).then((res) => {
            console.log('user updated', res.data);
            alert("Demo booked succesfully");
            window.location = '/';
        }).catch(() => {

        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    const phonee = watch('phonee')
    const demo_name = watch('demo_name')
    // const emaill = watch('emaill')
    const desig_nation = watch('desig_nation')

    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);

        // Check email format
        const regex = /\S+@\S+\.\S+/;
        if (!regex.test(e.target.value)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };


    // const isValid = ((phonee ?? '').length >= 8 && (emaill ?? '').length >= 4 && demodate && (demo_name ?? '').length >= 4 && desig_nation && reason) && ((phonee ?? '').length <= 12 && (emaill ?? '').length <= 64 && demodate && (demo_name ?? '').length <= 25 && (reason ?? '').length <= 255) ;

    const isValid = demo_name && demodate && emaill && phonee.length > "7" && desig_nation && reason && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emaill)

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only alphabetical characters
        if (!/^[a-zA-Z]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };


    return (

        <div className=''>
            <img className="curveimg hide_tab" src={`Images/curve.svg`} ></img>
            <div className='row'>
                <div className='row'>
                    <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'></div>
                    <div className='col-sm-2 col-xxl-2  col-md-4 col-lg-4 col-xl-2'> <img className='img-fluid ' src={`Images/logoql.png`} ></img></div>
                    <div className='col-7 col-xxl-7 col-sm-4 col-md-4 col-lg-7 col-xl-7'></div>
                    <div className='col-sm-2 col-xxl-2  col-md-3 col-lg-3 col-xl-2 landingloginbtn d-grid  ' > <button className='text-center btn border_null  text-primary fs-5 fw-bold ms-4' onClick={login}> Login </button></div>

                </div>
                <div className='row'>
                    <div className='col-sm-12 col-xxl-12  col-md-12 col-lg-12 col-xl-12 bg-primary'>
                        <div className='row'>
                            <div className='col-sm-4 col-xxl-4  col-md-10 col-lg-4 col-xl-4'>
                                <div className='row mt-5'></div>
                                <div className='row mt-5'>
                                    <p className='fw-bold text-white fs-2 ms-sm-5 cents'>
                                        The Modern Platform for Time,
                                        Attendance and Payroll
                                    </p>
                                </div>

                                <div className='row mt-3'>
                                    <p className='fw-bold text-white  ms-sm-5 cents'>
                                        <p>A well-supported team is the key to a successful business. With Symplefy's easy-to-use time, attendance and payroll platform,you can empower your people and push your business forward,
                                            Symplefy's all-in-one platform puts the tools you need to track time, pay, and manage your team at your fingertips.
                                        </p>  </p>
                                </div>
                                <div className='row mt-1'></div>
                                <div className='row'>
                                    <div className='col-1 col-xxl-1 col-sm-1 col-md-4 col-lg-1 col-xl-1'>

                                    </div>
                                    <div className='col-5 col-xxl-5  col-md-5 col-lg-5 col-xl-5  ms-sm-4 bg-white d-grid bdradius Clockt10'>
                                        <button className='btn  text-primary fw-bold border-white ' onClick={signup}> Get Started</button>
                                    </div>
                                    <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>

                                    </div>
                                    <div className='row mt-3'></div>
                                </div>
                            </div>

                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 mobsimplytext hide_tab'>
                                <div className='row mt-5'></div>
                                <div className='row'>
                                    <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                        <div className='row bg-white mt-5 ms-5 bdradius'>
                                            <div className='row'>


                                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    <img className='img-fluid mt-2 ' src={`Images/cardimg1.svg`} ></img>
                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                                                    <p className='text-primary fw-bold fs-6 mt-3'>
                                                        Add Work Schedule
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                                    <label for="exampleInputEmail1" class="form-label ms-3 text_small text-primary">Work Shedule Name</label>
                                                    <input type="text" class="form-control inputcolor  border-primary bg-white" value="Day Shift" id="exampleInputEmail1" disabled />

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>
                                            <div className='row mt-3'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'></div>
                                </div>
                                <div className='row mt-5'></div>
                                <div className='row'>
                                    <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'></div>
                                    <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                        <div className='row bg-white mt-3 ms-5 bdradius'>
                                            <div className='row'>


                                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    <img className='img-fluid mt-3 ' src={`Images/cardimg3.svg`} ></img>
                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                                                    <p className='text-primary fw-bold mt-3'>
                                                        <small> Company-Information</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                                    <label for="exampleInputEmail1" class="form-label ms-3 text_small text-primary"> Name</label>
                                                    <input type="text" class="form-control inputcolor  border-primary bg-white" value="Symplefy" id="exampleInputEmail1" disabled />

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>
                                            <div className='row mt-3'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-2'></div>
                            </div>
                            <div className='col-sm-1 col-xxl-1  col-md-1 col-lg-1 col-xl-1 '>
                                <div className='row mt-5'>

                                </div>
                                <div className='row mt-5'></div>

                                <img className=' mt-5 mobsimplytext hide_tab' src={`Images/landlady.svg`} ></img>

                            </div>
                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 mobsimplytext hide_tab'>

                                <div className='row mt-5'></div>
                                <div className='row'>
                                    <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'></div>
                                    <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                        <div className='row bg-white mt-5 ms-5 bdradius'>
                                            <div className='row'>


                                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    <img className='img-fluid mt-2 ' src={`Images/cardimg3.svg`} ></img>
                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                                                    <p className='text-primary fw-bold fs-6 mt-3'>
                                                        Add Employee
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                                    <label for="exampleInputEmail1" class="form-label ms-3 text_small text-primary">Employee Name</label>
                                                    <input type="text" class="form-control inputcolor  border-primary bg-white" value="Joy" id="exampleInputEmail1" disabled />

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>
                                            <div className='row mt-3'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-5'></div>
                                <div className='row'>
                                    <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'></div>
                                    <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                        <div className='row bg-white  ms-5 bdradius'>
                                            <div className='row'>


                                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                    <img className='img-fluid mt-3 ' src={`Images/cardimg3.svg`} ></img>
                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                                                    <p className='text-primary fw-bold mt-3'>
                                                        <small> Run Payroll</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                                    <label for="exampleInputEmail1" class="form-label ms-3 text_small text-primary"> Enter Month</label>
                                                    <input type="text" class="form-control inputcolor  border-primary bg-white" value="Jan 2022" id="exampleInputEmail1" disabled />

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>
                                            <div className='row mt-3'>


                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                                <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>

                                                </div>
                                                <div className='col-1 col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'></div>
                                </div>
                                <div className='row mt-2'></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-xxl-12  col-md-12 col-lg-12 col-xl-12'>
                    <img className='image-fluid imglnd hide_all' src={`Images/dp.png`} ></img> 
                    <img className='image-fluid imglnd onlytab hide_tab mt-2' src={`Images/dpmon.png`} ></img> 

                </div>

            </div>
            <div className='row mt-4'>
                <div className='row ms-sm-5'>
                    <div className='row'> <p className="text-primary text-center fs-1 fw-bold tbl">Benefits of Symplefy</p>
                    </div>
                    <div className='row mt-2 mobsimplytext hide_tab'>
                        <div className='col-sm-3 col-xxl-3  col-md-3 col-lg-3 col-xl-3'>


                            <div className=''>
                                <div class="d-flex align-items-start">
                                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button class="nav-link active text-primary btn_size" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><p className='fw-bold fs-5 text-start mt-2'>Simplify complexity</p></button>
                                        <button class="nav-link text-primary btn_size " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2 '>Stay ahead, stay safe</p></button>
                                        <button class="nav-link text-primary btn_size" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2 '>Feel relaxed</p></button>
                                        <button class="nav-link text-primary btn_size" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2 '>Save money, get agile</p></button>
                                        <button class="nav-link text-primary btn_size" id="v-pills-nationality-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nationality" type="button" role="tab" aria-controls="v-pills-nationality" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2 '>Optimize resources</p> </button>
                                        <button class="nav-link text-primary btn_size" id="v-pills-accessible-tab" data-bs-toggle="pill" data-bs-target="#v-pills-accessible" type="button" role="tab" aria-controls="v-pills-accessible" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2'>Be accessible to staff</p></button>
                                        <button class="nav-link text-primary btn_size" id="v-pills-support-tab" data-bs-toggle="pill" data-bs-target="#v-pills-support" type="button" role="tab" aria-controls="v-pills-support" aria-selected="false"><p className='fw-bold fs-5 text-start mt-2'>Support</p></button>
                                    </div>


                                </div>


                            </div>

                        </div>
                        <div className='col-sm-9 col-xxl-9  col-md-9 col-lg-9 col-xl-9'>


                            <div className=''>
                                <div class="">


                                    <div class="tab-content " id="v-pills-tabContent">

                                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <img className='img_hei image-fluid imgimg hide_tab' src={`Images/ln2.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Pay employees accurately and on time to boost their confidence in the company.</p></div>
                                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><img className='image-fluid img_hei imgimg ' src={`Images/ld1.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Stay constantly updated on the cloud with Symplefy. Confirm to statutory guidelines around payroll.</p></div>
                                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld2.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Updates, course corrections, and all you need for smooth payroll processing.</p></div>
                                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld3.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>A cloud-based subscription model means no upfront bulk costs, lower TCO. Updates are rolled out without </p><p className='fs-5 text-black mt-3 imgtxt '> added maintenance costs. And you scale up or down per need.</p></div>
                                        <div class="tab-pane fade" id="v-pills-nationality" role="tabpanel" aria-labelledby="v-pills-nationality-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld4.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Enable online workflows with Symplefy. Save on printing costs, labor, and time with automated online processes.</p></div>
                                        <div class="tab-pane fade" id="v-pills-accessible" role="tabpanel" aria-labelledby="v-pills-accessible-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld6.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Employees, HR and Payroll departments can access portal & work from anywhere, anytime,on any device. </p><p className='fs-5 text-black mt-3 imgtxt '> </p></div>
                                        <div class="tab-pane fade" id="v-pills-support" role="tabpanel" aria-labelledby="v-pills-support-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld7.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt'>Have a problem with payroll? Get in touch and we'll handle it for you. We're trusted partners, not providers.</p></div>
                                    </div></div>


                            </div>

                        </div>
                    </div>

                    {/* mobile view of benifits */}
                    <div className='row mt-2 onlytab benifits'>
                        <div className='col-sm-12 '>


                            <div className=''>
                                <div class="d-flex align-items-center">
                                    <div class="nav flex-column nav-pills ms-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <div class=" active text-primary" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="text" role="tab" aria-controls="v-pills-home" aria-selected="true"><p className='fw-bold fs-5 text-center mt-2'>Simplify complexity</p></div>
                                        <div class=" text-primary " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="text" role="tab" aria-controls="v-pills-profile" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2 '>Stay ahead, stay safe</p></div>
                                        <div class=" text-primary" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="text" role="tab" aria-controls="v-pills-messages" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2 '>Feel relaxed</p></div>
                                        <div class=" text-primary" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="text" role="tab" aria-controls="v-pills-settings" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2 '>Save money, get agile</p></div>
                                        <div class=" text-primary" id="v-pills-nationality-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nationality" type="text" role="tab" aria-controls="v-pills-nationality" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2 '>Optimize resources</p> </div>
                                        <div class=" text-primary" id="v-pills-accessible-tab" data-bs-toggle="pill" data-bs-target="#v-pills-accessible" type="text" role="tab" aria-controls="v-pills-accessible" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2'>Be accessible to staff</p></div>
                                        <div class=" text-primary" id="v-pills-support-tab" data-bs-toggle="pill" data-bs-target="#v-pills-support" type="text" role="tab" aria-controls="v-pills-support" aria-selected="false"><p className='fw-bold fs-5 text-center mt-2'>Support</p></div>
                                    </div>


                                </div>


                            </div>

                        </div>
                        {/* <div className='col-sm-9 col-xxl-9  col-md-9 col-lg-9 col-xl-9'>


                            <div className=''>
                                <div class="">


                                    <div class="tab-content " id="v-pills-tabContent">

                                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <img className='img_hei image-fluid imgimg' src={`Images/ln2.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Pay employees accurately and on time to boost their confidence in the company.</p></div>
                                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><img className='image-fluid img_hei imgimg ' src={`Images/ld1.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Stay constantly updated on the cloud with Symplefy. Confirm to statutory guidelines around payroll.</p></div>
                                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld2.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Updates, course corrections, and all you need for smooth payroll processing.</p></div>
                                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld3.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>A cloud-based subscription model means no upfront bulk costs, lower TCO. Updates are rolled out without </p><p className='fs-5 text-black mt-3 imgtxt '> added maintenance costs. And you scale up or down per need.</p></div>
                                        <div class="tab-pane fade" id="v-pills-nationality" role="tabpanel" aria-labelledby="v-pills-nationality-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld4.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Enable online workflows with Symplefy. Save on printing costs, labor, and time with automated online processes.</p></div>
                                        <div class="tab-pane fade" id="v-pills-accessible" role="tabpanel" aria-labelledby="v-pills-accessible-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld6.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt '>Employees, HR and Payroll departments can access portal & work from anywhere, anytime,on any device. </p><p className='fs-5 text-black mt-3 imgtxt '> </p></div>
                                        <div class="tab-pane fade" id="v-pills-support" role="tabpanel" aria-labelledby="v-pills-support-tab"><img className='image-fluid img_hei imgimg' src={`Images/ld7.jpg`} ></img>
                                            <p className='fs-5 text-black mt-3 imgtxt'>Have a problem with payroll? Get in touch and we'll handle it for you. We're trusted partners, not providers.</p></div>
                                    </div></div>


                            </div>

                        </div> */}
                    </div>

                </div>

            </div>
            <div className='row mt-3'>
                <div className='row'>
                    <div className='card landseccard '>
                        <div className='curimg mobsimplytext hide_tab' ></div>
                        <p className='text-white fw-bold fs-2 wanttxt'>Want to experience the future of payroll?</p>

                        <button type='button' className='btn btn-pri bookleft border-0 requestbtn fs-3 fw-bold' data-bs-toggle="modal" data-bs-target="#exampleModal">BOOK A DEMO</button>
                    </div>

                </div>


                <div className='row mt-5'>
                    <div className='col'>
                        <p className="text-black text-center fs-2 fw-bold"> Contact Us
                            <br></br>
                            <a
                                onclick={() => symp()}
                                className='fs-6 pointer_ text-primary text-center'
                                href='https://www.Symplefy.Com/'
                                style={{ textTransform: 'uppercase' }} // Add this inline style
                            >
                                www.symplefy.com
                            </a>

                        </p>
                    </div>

                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'> <p className='text-white '> © copyrights @2022</p> </div>
                </div>

            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable demoalign">
                    <div class="modal-content demosize">
                        <div class="modal-header">
                            <h7 class="modal-title fw-bold text-primary text-center fs-3 reqtext" id="exampleModalLabel">Book a Demo</h7>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <div className='row'>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">
                                        <input type="text" minlength="3" maxlength="35" id="exampleInputEmail1" Value={demo_name} onChange={(e) => setDemoname(e.target.value)} aria-describedby="emailHelp"

                                            onPaste={handlePaste}
                                            placeholder="Username"
                                            onKeyPress={(event) => {
                                                if (
                                                    demo_name.length === 0 && // Check if input is empty
                                                    !/[a-zA-Z]/.test(event.key) // Allow only alphabet for the first character
                                                ) {
                                                    event.preventDefault();
                                                }
                                                if (
                                                    /[0-9]/.test(event.key) // Disallow numbers
                                                ) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onBlur={(event) => {
                                                if (!/^[a-zA-Z\s]*$/.test(event.target.value)) {
                                                    event.target.value = ''; // Clear the input if it doesn't match the pattern
                                                    setDemoname(''); // Also clear the state variable
                                                }
                                            }}
                                            className={`mt-3 form-control inputcolor text_box_size ${errors.demo_name &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("demo_name", {
                                                required: {
                                                    value: true,
                                                    message: '*Name is required'
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "Please enter minimum 4 character",
                                                },
                                                maxLength: {
                                                    value: 25,
                                                    message:
                                                        "Maximum allowed length is 25 characters ",
                                                },
                                                pattern: {
                                                    value: /^(?=[a-z A-Z]){4,25}/,
                                                    message: <smaller className=''>Name should contain only Characters</smaller>
                                                },
                                            })}
                                        />
                                    </div>
                                    <p className='demo_text text-center'>Name</p>
                                    <div>
                                        {errors.demo_name && <span className='text-danger ms-3'>{errors.demo_name.message}</span>}
                                    </div>
                                </div>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">
                                        <input type="date" min={moment().format("YYYY-MM-DD")} class=" ms-3 form-control inputcolor text_box_size mt-3" Value={demodate} onChange={(e) => setDemodate(e.target.value)} placeholder="preferred Demo Date" aria-label="Username" />
                                    </div>
                                    <p className='demo_txt text-center'>Preferred Demo Date</p>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">
                                        <input type="email" id="exampleInputEmail1" Value={emaill} onChange={handleEmailChange} minLength="4" maxLength="64" aria-describedby="emailHelp"


                                            placeholder="Business Email*" className="form-control inputcolor text_box_size"


                                        />

                                    </div>
                                    {emailError && <span className="text-danger">{emailError}</span>}

                                </div>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">

                                        <input type="text"
                                            onPaste={handle_Paste}
                                            id="exampleInputEmail1"
                                            value={phonee}
                                            onChange={(e) => setPhone(e.target.value)}
                                            aria-describedby="emailHelp"
                                            placeholder="Phone Number*"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minlength="8" maxlength="12"
                                            className={`ms-3 form-control inputcolor text_box_size ${errors.phonee && " focus:border-red-500 focus:ring-red-500 border-red-500"
                                                }`}
                                            {...register("phonee", {
                                                required: {
                                                    value: true,
                                                    message: '*Phone Number is required'
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Please enter minimum 8 characters",
                                                },
                                                maxLength: {
                                                    value: 12,
                                                    message: "Maximum allowed length is 12 characters",
                                                },
                                                pattern: {
                                                    value: /^[0-9]{8,12}$/,
                                                    message: "Phone number should contain only numeric characters",
                                                },
                                            })}
                                        />


                                    </div>
                                    <div>
                                        {errors.phonee && <span className='text-danger ms-3'>{errors.phonee.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">
                                        <input type="text" minlength="2" maxlength="30" id="exampleInputEmail1" Value={desig_nation} onChange={(e) => setDesignation(e.target.value)} aria-describedby="emailHelp"


                                            placeholder="Designation*"

                                            className={`mt-3 form-control inputcolor text_box_size ${errors.desig_nation &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("desig_nation", {
                                                required: {
                                                    value: true,
                                                    message: '*Designation is required'
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message: "Please enter minimum 2 character",
                                                },
                                                maxLength: {
                                                    value: 25,
                                                    message:
                                                        "Maximum allowed length is 25 characters ",
                                                },
                                                pattern: {
                                                    value: /^(?=[a-z A-Z]){2,25}/,
                                                    message: <smaller className=''>Designation should contain  Characters</smaller>
                                                },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        {errors.desig_nation && <span className='text-danger ms-3'>{errors.desig_nation.message}</span>}
                                    </div>
                                </div>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                    <div class="input-group">
                                        <textarea type="text" minlength="5" maxlength="200" class=" ms-3 form-control inputcolor " Value={reason} onChange={(e) => setReason(e.target.value)} placeholder="What would you be using symplefy for*" aria-label="Username" />
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                </div>
                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                    <form onSubmit={handleSubmit(onInsert)} className="d-grid" action="#" method="POST">
                                        <button type="submit" class="btn btn-pri text_box_size" disabled={!isValid}>Book</button>
                                    </form>
                                </div>
                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              

        </div>


    );
}
export default LandingPage;
