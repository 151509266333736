import { React, useState, useEffect, useContext } from "react";
import Superheader from "./Superheader";
import Superside from "./Superside";
import Header from '../Header/Header'
import Chart from "react-apexcharts";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Mobside from "../Sidebar/Mobside"
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
const Superdashboard = () => {

    const [count_u, setcount] = useState([])
    const [demolist, setdemolist] = useState([])
    const [leadlist, set_leadlist] = useState([])
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const Getdemo = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/demotop`).then((res) => {
            console.log(res.data, 'success');
            setdemolist(res.data);

        }).catch(() => {

        })

    }
    const Get_count = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/countuser`).then((res) => {
            console.log(res.data, 'success');
            setcount(res.data);

        }).catch(() => {

        })

    }
    const Getlead_count = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/leadcount`).then((res) => {
            console.log(res.data, 'success');
            set_leadlist(res.data);

        }).catch(() => {

        })

    }
    const [pay, set_paylist] = useState([]);
    const paymentcount = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/userpayment`).then((res) => {
            console.log(res.data, 'success');
            set_paylist(res.data);

        }).catch(() => {

        })

    }

    const [company_list, setCompany_list] = useState([]);
    const [paygraph, setpaygraph] = useState([]);




    const Getcompanylist = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/alluserm`).then((res) => {
            console.log(res.data, 'mnt');
            setCompany_list(res.data);

        }).catch(() => {

        })
    }
    const Getpaygraph = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/paymentgraph`).then((res) => {
            console.log(res.data, 'mnt');
            setpaygraph(res.data);

        }).catch(() => {

        })
    }
    useEffect(() => {
        const cont = paygraph.map((entry) => (entry.total_amount));

        setstate((prevState) => ({
            ...prevState,

            series: [
                {
                    name: 'revenue',
                    data: cont,
                },

            ],
        }));
    }, [company_list]);

    useEffect(() => {
        Getdemo();
        Getpaygraph()
        Get_count();
        Getlead_count();
        paymentcount();
        Getcompanylist();
    }, []);

    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const golead = () => {
        let path = `/superleads`;
        navigate(path);
    }
    const godemo = () => {
        let path = `/superdemo`;
        navigate(path);
    }

    const gopay = () => {
        let path = `/superadmincustomers`;
        navigate(path);
    }
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {

                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],


            },
            title: {
                text: 'Revenue Analizer',
                align: 'left',
                style: {
                    fontSize: "14px",
                    color: '#717372',
                    fontFamily: 'Comfortaa'
                }
            },
            fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shade: 'dark',
                //     gradientToColors: [ '#FDD835'],
                //     shadeIntensity: 1,
                //     type: 'horizontal',
                //     opacityFrom: 1,
                //     opacityTo: 1,
                //     stops: [0, 100, 100, 100]
                //   },
                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 2500000,
                max: 300000000
            }
        },
        series: [{
            name: 'Revenue',
            data: []
        }],

    })
    const [state_bar, setstatebar] = useState({
        options: {
            chart: {
                type: 'bar',
                height: 150,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {

                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],


            },
            title: {
                text: 'Customer Analizer',
                align: 'left',
                style: {
                    fontSize: "14px",
                    fontFamily: 'Comfortaa',
                    
                    color: '#717372'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: ['#FDD835'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 30
            }
        },
        series: [{
            name: 'count',
            data: []
        }],

    })
    useEffect(() => {
        const cont = company_list.map((entry) => (entry.count));

        setstatebar((prevState) => ({
            ...prevState,

            series: [
                {
                    name: 'count',
                    data: cont,
                },

            ],
        }));
    }, [company_list]);
    return (
        <div className="bg-light vh-100 row container-fluid">




            <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2">


            </div>
            <div className="col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 bg-light ">

                <div className="row ms-4 mt-5">
                    <div className="col-9">
                        <div className="row slider" >
                            <div className="col ">
                                <div class="card card_1_super ms-3 mt-3" >


                                    <div class="card-body">
                                        <div className="row">
                                            <p className="text-white text-center fw-bold fs-5">Subscription  Details</p>

                                        </div>
                                        <div className="row">
                                            <div className="col-4 ">

                                            </div>
                                            <div className="col-4  "><p className="text-white text-center fw-bold">Paid Customer</p>

                                                {count_u.map((x, index) => {
                                                    return <p className="text-white text-center">{x.count}</p>

                                                })}</div>
                                            <div className="col-4">

                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-6">  <div class="card  ms-3 mt-3" >

                                <div class="card-body">

                                    <Chart

                                        options={state_bar.options}
                                        series={state_bar.series}
                                        type="bar"
                                        height="320px"
                                    />
                                </div>
                            </div></div>
                            <div className="col-6">
                                <div class="card  ms-3 mt-3" >

                                    <div class="card-body">
                                        <Chart

                                            options={stat_e.options}
                                            series={stat_e.series}
                                            type="line"
                                            height="320px"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-3 ">
                        <div className="row ">
                            <div className="col mt-3">
                                <div className="card card_super_lead">
                                    <p className="text-center text-white fw-bold mt-3">Leads </p>
                                    <div className="row ">


                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-funnel align-center " viewBox="0 0 16 16">
                                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                                        </svg>

                                    </div>
                                    {leadlist.map((x, index) => {
                                        return (
                                            <div className="row  pointer_" onClick={golead}>



                                                <p className="fw-bold text-center mt-3 fs-5 text-white">{x.count}</p>

                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <div className="card card_super card_super_demo">
                                    <p className="text-center text-white fw-bold mt-3">Up-Coming Demos </p>
                                    <div className="row">
                                        <div className="col-4"></div>
                                        <div className="col-5">
                                            <img className="mx-auto   img-fluid ms-1" style={{ height: 55, width: 55 }} src="./Images/demo1.png"></img>

                                        </div>
                                        <div className="col-3"></div>
                                    </div>
                                    {demolist.map((x, index) => {
                                        return (
                                            <div className="row ms-2 pointer_" onClick={godemo}>

                                                <div className="row">
                                                    <div className="col-4"></div>
                                                    <div className="col-5">

                                                        <p className="fw-bold ms-4 mt-3 fs-5 text-white">{x.count}</p>
                                                    </div>
                                                    <div className="col-3"></div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <div className="card card_super_lead">
                                    <p className="text-center text-white fw-bold mt-3">Payment Dues</p>
                                    {pay.map((x, index) => {
                                        return (
                                            <div className="row  pointer_" onClick={gopay}>


                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi align-center bi-credit-card-fill " viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z" />
                                                </svg>
                                                <p className="fw-bold text-center mt-3 fs-5 text-white">{x.count}</p>


                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div>




                </div>



            </div>
        </div>



    )
}
export default Superdashboard