import React, { useCallback, useState, useContext, useEffect, useRef } from 'react';
import './EmployeeList.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { store } from '../../store/user';
import Chart from "react-apexcharts";
import Empleave from './Empleave';
import ReactPaginate from "react-paginate";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Select from 'react-select'
import CryptoJS from 'crypto-js';

const EmployeeList = (props) => {
    const { state } = useContext(store);
    console.log('employee list', state.user);

    const [edit_basic, setbasic] = useState("back");
    const [edit_job, setjob] = useState("back");
    const [edit_personal, setpersonal] = useState("back");
    const [edit_tax, settax] = useState("back");
    const [edit_acc, setacc] = useState("back");
    const [edit_sal, setsal] = useState("back");
    const [middleName, SetMiddlename] = useState("");
    const [lastname, SetLstname] = useState("");
    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const { update, handleSubmit } = useForm();
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, { service: "" }]);
    };
    const showactive = (type) => {
        if (type == 'active') {
            seteditact(type);

        }
        if (type == 'back') {
            seteditact(type);
        }

    }
    const showbasic = (type) => {
        if (type == 'basic') {
            setbasic(type);

        }
        if (type == 'back') {
            setbasic(type);
        }

    }
    const showjob = (type) => {
        if (type == 'job') {
            setjob(type);
        }
        if (type == 'back') {
            setjob(type);
        }

    }
    const showpersonal = (type) => {
        if (type == "personal") {
            console.log("data")
            setpersonal(type);
        }
        if (type == "back") {

            setpersonal(type);
        }

    }
    const showtax = (type) => {
        if (type == 'tax') {
            settax(type);
        }
        if (type == 'back') {
            settax(type);
        }

    }
    const showacc = (type) => {
        if (type == 'acc') {
            setacc(type);
        }
        if (type == 'back') {
            setacc(type);
        }

    }
    const showsal = (type) => {
        if (type == 'sal') {
            setsal(type);
        }
        if (type == 'back') {
            setsal(type);
        }

    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ShowDetails(''),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('canceled'),

                }
            ]
        })
    }
    const alert = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to update ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ShowDetails(''),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',


                }
            ]
        })
    }
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001', '4/11/2001', '5/11/2001', '6/11/2001'],
                tickAmount: 10,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), ' MMM')
                    }
                }
            },
            title: {
                text: 'Monthly Analyser',
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666'
                }
            },
            fill: {


                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 30
            }
        },
        series: [{
            name: 'Sales',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 17, 1]
        }],

    })
    let navigate = useNavigate();
    const goemp = () => {
        navigate("/employeelist");

    };
    const empedit = () => {
        navigate("/EmpProfileedit");

    };
    const empleave = (id) => {
        let path = `/empleavehistory `
        navigate(path, { state: { emp_id: id } });
    };

    const empprofile = () => {
        let path = `/baseprofile`;
        navigate(path);
    }
    const salrevision = (id) => {
        let path = `/salrevision`;
        navigate(path, { state: { emp_id: id } });;
    }
    const [bind_user, setBindUser] = useState([]);
    const [leave_details, setUserDetails] = useState([]);
    const [emp_leav, setemp_leave] = useState([]);
    const [alertVisibility, setAlertVisibility] = useState(false);
    const [visibility, setVisibility] = useState('');

    const Getleave = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/employee_basic/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                let temp = [];
                res?.data?.forEach(item => {
                    if (item.hiredate) {
                        const currentDate = new Date();
                        const hireDate = new Date(item.hiredate);
                        const diffInDays = (currentDate - hireDate) / (1000 * 3600 * 24);
                        console.log(diffInDays, "hirealert")
                        if (diffInDays >= 85 && diffInDays <= 100) {

                        }
                    }

                    temp.push(item);

                });

                setUserDetails(temp);

            }).catch(() => {

            })
        }
    }
    const Getemp = (emp_id) => {

        axios
            .get(`${process.env.REACT_APP_API_URL}/emp_emp/${emp_id}`)
            .then((res) => {
                console.log(res.data, "bindemp");
                ShowDetails(res.data[0], 'view');
            })
            .catch((err) => {
                console.error(err.message);

            });

    };
    const [employeedt, setemployeedt] = useState("");
    // date validator
    const Getemployeedt = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/usercompany`).then((res) => {

                setemployeedt(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const checkifValiddate = (empdate) => {
        console.log(empdate, 'dept');
        let isValid = true;
        employeedt.forEach(item => {
            console.log(employeedt, 'dept');
            if (item.date.match(empdate)) {
                isValid = false;
            }
        });
        return isValid
    }


    const [shift_details, setShiftDetails] = useState([]);
    const GetShift = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/shift/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setShiftDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [work_list, setWork_list] = useState([])
    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setWork_list(res.data);

            }).catch(() => {

            })
    }
    const [bank_details, setbankdetail] = useState([]);
    const Getbank = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/bank/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setbankdetail(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [managerlist, setManagerlist] = useState([])
    const Getmanagerlist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/employeereportingmanager/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setManagerlist(res.data);

            }).catch(() => {

            })
    }
    useEffect(() => {
        Getbank();
    }, [state]);
    const [query, SetQuery] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedWorkMode, setSelectedWorkMode] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedContract, setSelectedContract] = useState("");
    const [query1, SetQuery1] = useState("");
    console.log(leave_details.filter(x => x.full_name.toLowerCase().includes(query)));
    useEffect(() => {
        Getleave();
        GetShift();
        Getworklist();
        Getmanagerlist();
    }, [state]);


    const [selectedfile, setSelectedfile] = useState([]);

    const [image64, setImage64] = useState("");
    const image = (e) => {
        setSelectedfile(e.target.files);
        console.log(e.target.files[0]);
        console.log(e.target.files[0].name);
        console.log(e.target.files[0].size);
        console.log(e.target.files[0].type);
    };
    const ConvertImageToBase64 = (event) => {
        // console.log('event', event.target.files[0]);
        let file = event.target.files[0];
        var reader = new FileReader();
        console.log('file upload');
        let base64;
        reader.readAsDataURL(file);
        reader.onload = () => {
            base64 = reader.result;
            console.log('base64', base64);
            setImage64(base64);
        };
        reader.onerror = (error) => {
            console.log('error :', error);
        };
    };
    // update basic
    const [fname, setfname] = useState("");
    const [hire, sethire] = useState("");
    const [email, setemail] = useState("");
    const [res, setres] = useState("");
    const [lang, setlang] = useState("");
    const [nat, setNat] = useState("");

    const onInsert = (emp_id, e) => {
        if (!fname || !email) {
            confirmAlert({
                message: 'First name and Email is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let base = {

            fullname: fname,
            profileimg: image64,
            nationality_id: nat,
            hiredate: hire,
            email: email,
            residentalstatus: res,
            emailpayslip: "true",
            language: lang,
            middlename: middleName,
            lastname: lastname,


        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_basic_update/${bind_user.emp_id}`, base).then((res) => {
            console.log('user_updated', res.data);
            console.log(res.data, "bindemp");
            Getemp(bind_user.emp_id);
            showbasic("back");

        }).catch(() => {

        })
    }

    // update job profile
    const [jbtitle, setjbtitle] = useState("");
    const [managr, setmanagr] = useState("");
    const [lbcon, setlbcon] = useState("");
    const [dep, setdep] = useState("");
    const [shif, setshif] = useState("");
    const [lbtyp, setlbtyp] = useState("");
    const [wgtyp, setwgtyp] = useState("");
    const [work, setwork] = useState("");
    const [time, settime] = useState("");
    const [workloc, setworkloc] = useState("");
    const [usert, SetUserT] = useState("");
    const [wfo, setwfo] = useState('');
    const [contractduedate, setcontractduedate] = useState('');
    const onInsertjob = (emp_id, e) => {
        console.log("testjobupdate")
        let job = {
            jobtitle: jbtitle,
            manager: managr,
            labourcontract: lbcon,
            department: dep,
            shift: shif,
            wagetype: wgtyp,
            labourtype: lbtyp,
            workschedule: work,
            timesheet: time,
            worklocation: workloc,
            usertype: usert,
            wfo: wfo,
            contractduedate: contractduedate,
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/employee_job/${bind_user.emp_id}`, job).then((res) => {
            console.log('user updated', res.data);
            //Update();
            Getemp(bind_user.emp_id);
            showjob('back');
            Getleave();
            if (res.data.timesheet === 2) {
                console.log(res.data.timesheet, "timetest")
                onInsertaddtime(res?.data);
            }
        }).catch(() => {

        })
    }

    const onInsertaddtime = (x) => {
        const currentDate = moment(); // Current date
        let startDate; // Start date for attendance
        const endDate = moment(); // Today's date
        let content;

        const workSchedule = {}; // Store the employee's work schedule

        // Fetch the employee's work schedule
        axios
            .get(`${process.env.REACT_APP_API_URL}/get_work_schedule/${bind_user.emp_id}`)
            .then((res) => {
                if (res.data.length > 0) {
                    const schedule = res.data[0];
                    workSchedule.monday = schedule.monday;
                    workSchedule.tuesday = schedule.tuesday;
                    workSchedule.wednesday = schedule.wednesday;
                    workSchedule.thursday = schedule.thursday;
                    workSchedule.friday = schedule.friday;
                    workSchedule.saturday = schedule.saturday;
                    workSchedule.sunday = schedule.sunday;
                }
                console.log(workSchedule, "work")
                // Check if hire date is within the current month and timesheet is 2
                if (
                    moment(x.hiredate).isSameOrBefore(currentDate.endOf('month')) &&
                    moment(x.hiredate).isSameOrAfter(currentDate.startOf('month')) &&
                    x.timesheet === 2
                ) {
                    startDate = moment(x.hiredate); // Start from the hire date

                    while (startDate <= endDate) {
                        const currentDateFormatted = startDate.format('YYYY-MM-DD');
                        let totalhr;

                        if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                            totalhr = 4; // Half working day
                            content = 'Present';
                        } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                            totalhr = 8; // Full working day
                            content = 'Present';
                        } else {
                            totalhr = 0; // weekend working day
                            content = 'Weekoff';
                        }

                        let time = {
                            emp_id: bind_user.emp_id,
                            date: currentDateFormatted,
                            totalhr: totalhr,
                            content: content,
                        };

                        axios
                            .get(`${process.env.REACT_APP_API_URL}/attget/${bind_user.emp_id}/${currentDateFormatted}`)
                            .then((res) => {
                                if (res.data.length === 0) {
                                    axios
                                        .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                                        .then((res) => {
                                            console.log('User attendance added', res.data);
                                        })
                                        .catch(() => { });
                                } else {
                                    console.log(`Attendance already exists for ${currentDateFormatted}`);
                                }
                            })
                            .catch(() => { });

                        startDate.add(1, 'day');
                    }
                } else {
                    // Original code for adding attendance from the first day of the current month to the current date
                    if (state.user) {
                        axios
                            .get(`${process.env.REACT_APP_API_URL}/payrollcycles_att/${state.user[0].company_id}`)
                            .then((res) => {
                                if (res.data.length > 0) {
                                    const payrollCycleEndDate = moment(res.data[0].enddate);
                                    const endOfMonth = moment().endOf('month');

                                    if (payrollCycleEndDate.isBefore(endOfMonth)) {
                                        startDate = moment.max(moment(payrollCycleEndDate).add(1, 'day'), moment(x.hiredate)); // Start from the maximum date between next day after payroll cycle end date and hire date
                                    } else {
                                        startDate = moment.max(moment().startOf('month'), moment(x.hiredate)); // Start from the maximum date between first day of the current month and hire date
                                    }
                                } else {
                                    startDate = moment().startOf('month'); // Start from the first day of the current month
                                }

                                while (startDate <= endDate) {
                                    const currentDateFormatted = startDate.format('YYYY-MM-DD');
                                    let totalhr;

                                    if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                                        totalhr = 4; // Half working day
                                        content = 'Present';
                                    } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                                        totalhr = 8; // Full working day
                                        content = 'Present';
                                    } else {
                                        totalhr = 0; // weekend working day
                                        content = 'Weekoff';
                                    }

                                    let time = {
                                        emp_id: bind_user.emp_id,
                                        date: currentDateFormatted,
                                        totalhr: totalhr,
                                        content: content,
                                    };

                                    axios
                                        .get(`${process.env.REACT_APP_API_URL}/attget/${bind_user.emp_id}/${currentDateFormatted}`)
                                        .then((res) => {
                                            if (res.data.length === 0) {
                                                axios
                                                    .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                                                    .then((res) => {
                                                        console.log('User attendance added', res.data);
                                                    })
                                                    .catch(() => { });
                                            } else {
                                                console.log(`Attendance already exists for ${currentDateFormatted}`);
                                            }
                                        })
                                        .catch(() => { });

                                    startDate.add(1, 'day');
                                }
                            })
                            .catch(() => { });
                    } else {
                        startDate = moment().startOf('month'); // Start from the first day of the current month

                        while (startDate <= endDate) {
                            const currentDateFormatted = startDate.format('YYYY-MM-DD');
                            let totalhr;

                            if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                                totalhr = 4; // Half working day
                                content = 'Present';
                            } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                                totalhr = 8; // Full working day
                                content = 'Present';
                            } else {
                                totalhr = 0; // weekend working day
                                content = 'Weekoff';
                            }

                            let time = {
                                emp_id: bind_user.emp_id,
                                date: currentDateFormatted,
                                totalhr: totalhr,
                                content: content,
                            };

                            axios
                                .get(`${process.env.REACT_APP_API_URL}/attget/${bind_user.emp_id}/${currentDateFormatted}`)
                                .then((res) => {
                                    if (res.data.length === 0) {
                                        axios
                                            .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                                            .then((res) => {
                                                console.log('User attendance added', res.data);
                                            })
                                            .catch(() => { });
                                    } else {
                                        console.log(`Attendance already exists for ${currentDateFormatted}`);
                                    }
                                })
                                .catch(() => { });

                            startDate.add(1, 'day');
                        }
                    }
                }
            })
            .catch(() => { });
    };


    const [password, setPassword] = useState("");

    const generatePassword = () => {

        const randomPassword =
            Math.random().toString(36).slice(2);

        setPassword(randomPassword);

        // if (navigator.clipboard.writeText(randomPassword)) {

        //     console.log(randomPassword);
        // }
    };
    useEffect(() => {
        generatePassword();
    }, []);
    const onreset = (data, e) => {
        let bas = {

            loginmail: bind_user.email,
            password: password,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/resetpasswordmy`, bas).then((res) => {
            console.log('user updated', res.data);
            //allert();
            sendEmail();
        }).catch(() => {

        })
    }

    const sendEmail = (x) => {
        const emailData = {

            user_email: `${bind_user.email}`,
            user_name: `${bind_user.full_name}`,
            message: `${password}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailemp`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }




    // update personal
    const [pass, setpass] = useState("");
    const [gen, setgen] = useState("");
    const [marital, setmarital] = useState("");
    const [adre, setadre] = useState("");
    const [city, setcity] = useState("671");
    const [stat, setstat] = useState("");
    const [loc, setloc] = useState("");
    const [phn, setphn] = useState();
    const [con, setcoun] = useState("");
    const [pin, setpin] = useState("");
    const [dbo, setdbo] = useState("");
    const [phcod, setphcode] = useState("+84");

    const onInsertper = (emp_id, e) => {
        if (!phn || phn.lenght > '6') {
            confirmAlert({
                message: 'Phone No is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let pers = {
            passport: pass,
            gender: gen,
            maritalstatus: marital,
            address: adre,
            city: city,
            state: stat,
            location: loc,
            phone: phn,
            country: con,
            pincode: pin,
            dateofbirth: dbo,
            phncode: phcod
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_perso/${bind_user.emp_id}`, pers).then((res) => {
            console.log('user updated', res.data);
            //Update();
            Getemp(bind_user.emp_id);
            showpersonal('back');
            Getleave();
        }).catch(() => {

        })
    }
    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    // update tax 
    const [pitcode, setpitcode] = useState("");
    const [sibook, setsibook] = useState("");
    const [depen, setdepen] = useState("");
    const onInserttax = (emp_id, e) => {
        if (!pitcode || !sibook || !depen) {
            confirmAlert({
                message: 'Pit code, Si book and Dependents is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let tax = {
            pitcode: pitcode,
            sibook: sibook,
            dependance: depen
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_etax/${bind_user.emp_id}`, tax).then((res) => {

            console.log('user updated', res.data);
            //Update();
            Getemp(bind_user.emp_id);
            showtax('back');
            // Getleave();
        }).catch(() => {

        })
    }
    // update bank 
    const [bnk, setbnk] = useState("");
    const [accno, setaccno] = useState("");

    const onInsertbank = (emp_id, e) => {
        if (!accno) {
            confirmAlert({
                message: 'Account No. is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let bank = {
            bank: bnk,
            accountno: accno,

        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_Bank/${bind_user.emp_id}`, bank).then((res) => {

            console.log('user updated', res.data);
            //Update();
            Getemp(bind_user.emp_id);
            showacc('back');
            Getleave();
        }).catch(() => {

        })
    }
    // update basic salary 
    const [bassalary, setbassalary] = useState("");
    const onInsersal = (emp_id, e) => {
        if (!bassalary) {
            confirmAlert({
                message: 'Gross Pay is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let bank = {
            amountbasic: bassalary,


        };


        axios.put(`${process.env.REACT_APP_API_URL}/Employee_basicsal/${bind_user.emp_id}`, bank).then((res) => {

            console.log('user updated', res.data);
            onInsertsalhistory(bassalary);
            Getemp(bind_user.emp_id);
            showsal('back');
            Getleave();

        }).catch(() => {

        })
    }
    // new insert to grosshistory tble on update of gross
    const onInsertsalhistory = (bassalary) => {

        let history = {
            emp_id: bind_user.emp_id,
            grossamount: bassalary,
            updateby: state?.user[0]?.first_name
        };


        axios.post(`${process.env.REACT_APP_API_URL}/grosshistory`, history).then((res) => {

            console.log('user updated', res.data);
            Getgrosshistory(bind_user.emp_id);

        }).catch(() => {

        })
    }

    const onInsertsalhistoryrevision = (amountrev, revdate) => {

        let history = {
            emp_id: bind_user.emp_id,
            grossamount: amountrev,
            updateby: state?.user[0]?.first_name,
            updatedon: revdate,
            revision: "(Revision)",
        };


        axios.post(`${process.env.REACT_APP_API_URL}/grosshistoryrevisi`, history).then((res) => {

            console.log('user updated', res.data);
            Getgrosshistory(bind_user.emp_id);

        }).catch(() => {

        })
    }

    // update salary 
    const [salamount, setsalarycomponent] = useState("");
    const onInsersalarycomponent = (id) => {

        let salcomp = {
            amount: salamount,


        };


        axios.put(`${process.env.REACT_APP_API_URL}/Employee_allsal/${id}`, salcomp).then((res) => {

            console.log('user updated', res.data);

            // showsal('back');
            Getsalcomp(bind_user.emp_id);
            GetSalary()
            Getleave();

        }).catch(() => {

        })
    }


    // update salary revision
    const [salaryrev, setsalaryrev] = useState("");
    const [salrevpercent, setsalrevpercent] = useState("");
    const [salaryrevam, setsalaryrevam] = useState();
    const [revdate, setrevdate] = useState("");
    const inputRef = useRef(null);

    // handle disabled submit
    const isValidrev = revdate



    const onUpdaterevision = (emp_id, e) => {
        let amountbasic = Number(bind_user.amountbasic);
        let salaryrevamt = salaryrevam ? Number(salaryrevam) : ((bind_user.amountbasic) * salrevpercent) / 100;
        let amountrev = amountbasic + salaryrevamt;
        if (bind_user.revisiondate == null) {

            let bank = {
                amountbasic: amountrev,
                revisiondate: revdate,
            };

            axios.put(`${process.env.REACT_APP_API_URL}/Employee_basicsalrevision/${bind_user.emp_id}`, bank).then((res) => {
                console.log('user updated', res.data);
                setsalrevpercent("");
                setsalaryrevam("");
                setrevdate("");
                inputRef.current.value = '';
                onInsertsalhistoryrevision(amountrev, revdate);
                Getemp(bind_user.emp_id);
                showsal('back');
                Getleave();
            }).catch(() => {

            })
        } else {
            if (bind_user.paiddate == null || bind_user.paiddate < bind_user.revisiondate) {
                confirmAlert({
                    message: 'Cannot revise salary. At least one payroll should be processed before salary revision with the existing salary.',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
                return;
            }
            // Calculate the date one month after the revision date
            const oneMonthAfterRevDate = moment(bind_user.revisiondate).add(1, 'month');

            // Check if the current date is less than one month after the revision date
            if (moment() < oneMonthAfterRevDate) {
                confirmAlert({
                    message: 'Salary revision can only be updated one month after the revision date.',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
                return;
            }
        }


    }


    const [phcode, setphncode] = useState("+84");
    // new personal
    const onInsertnewpersonal = (empId) => {

        let basic = {
            emp_id: bind_user.emp_id,
            passport: pass,
            gender: gen,
            maritalstatus: marital,
            address: adre,
            city: city,
            state: stat,
            location: loc,
            phone: phn,
            country: con,
            postalcode: pin,
            dateofbirth: dbo,
            createdby: state?.user[0]?.company_id,
            phncode: phcode

        };


        axios.post(`${process.env.REACT_APP_API_URL}/Employee_personal`, basic).then((res) => {
            console.log('user updated', res.data);
            Getemp(bind_user.emp_id);
            showpersonal('back');
            Getleave();
        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValid = dbo && con && pass && phn.length > '6'

    // new tax
    const onInsert_newtax = (empId) => {

        let tax = {
            emp_id: bind_user.emp_id,
            pitcode: pitcode,
            sibook: sibook,
            dependance: depen,
            createdby: state?.user[0]?.company_id

        };


        axios.post(`${process.env.REACT_APP_API_URL}/Employee_tax`, tax).then((res) => {
            console.log('user updated', res.data);
            Getemp(bind_user.emp_id);
            showtax('back');
            Getleave();
        }).catch(() => {

        })
    }
    function handleKeyDown(event) {
        const maxDigits = 20; // Maximum number of digits allowed
        const inputLength = event.target.value.length + 1; // Length of input after this key is pressed
        if (inputLength > maxDigits && event.key !== 'Backspace' && event.key !== 'Delete') {
            // If the number of digits entered is greater than the maximum allowed
            event.preventDefault(); // Prevent the key from being entered
        }
    }

    // handle disabled submit
    const isValids = pitcode && sibook && depen
    // new bank
    const onInsertnewbank = (empId) => {

        let bank = {
            emp_id: bind_user.emp_id,
            bank: bnk,
            accountno: accno,
            createdby: 1

        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_bank`, bank).then((res) => {
            console.log('user updated', res.data);
            Getemp(bind_user.emp_id);
            showacc('back');
            Getleave();


        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValide = bnk && accno

    // new salary
    const onInsertgross = (data, e) => {
        let amountbasic;
        if (wgtyp === "Annually") {
            amountbasic = bassalary / 12;
        } else {
            amountbasic = bassalary;
        }
        let bnk = {
            emp_id: bind_user.emp_id,

            amountbasic: amountbasic,

            createdby: 1

        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employeebasicsalary`, bnk).then((res) => {
            console.log('user updated', res.data);
            onUpdatewage();
            onInsertsalhistory(amountbasic)
            Getemp(bind_user.emp_id);
            showsal('back');
            Getleave();
            Getgrosshistory(bind_user.emp_id);

        }).catch(() => {

        })
    }
    const onUpdatewage = (job_id, e) => {

        let wage = {
            emp_id: bind_user.emp_id,
            wagetype: wgtyp,

        };

        axios.put(`${process.env.REACT_APP_API_URL}/updatewagetype/${bind_user.emp_id}`, wage).then((res) => {
            console.log('wage updated', res.data);
        }).catch(() => {

        })
    }

    const wagetypeupdate = (job_id, e) => {
        if (!wgtyp) {
            confirmAlert({
                message: 'Wage type is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let wage = {
            emp_id: bind_user.emp_id,
            wagetype: wgtyp,

        };

        axios.put(`${process.env.REACT_APP_API_URL}/updatewagetype/${bind_user.emp_id}`, wage).then((res) => {
            console.log('wage updated', res.data);
            Getemp(bind_user.emp_id);
            showsal('back');
            Getleave();
        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValidt = bassalary

    // new sal comp
    const checkifValidcomp = (component) => {
        console.log(component, 'salcomp');
        let isValid = true;
        salary_list.forEach(item => {
            console.log(salary_list, 'salcomp');
            if (item.salarycomponent.match(component)) {
                isValid = false;
            }
        });
        return isValid
    }

    const [acc_no, set_accno] = useState("");
    const [accno_2, setaccno_2] = useState("");
    const [saltype, setsaltype] = useState([]);
    const onInsertnewsal = (data, e) => {
        if (saltype != "") {
            if (checkifValidcomp(saltype)) {
                try {
                    let bnk = {
                        emp_id: bind_user.emp_id,
                        salarycomponent: saltype,
                        amount: acc_no,
                        createdby: 1,
                        salarycomponent_type: salary_details.filter(item => item?.head_name == saltype)[0]?.scope,
                        ispayrollcomponent: false,
                        payroll_id: 0,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/Employee_salary`, bnk).then((res) => {
                        console.log('user updated', res.data);
                        Getemp(bind_user.emp_id);
                        Getsalcomp(bind_user.emp_id);
                        set_accno("");
                        setsaltype("");

                    })

                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Selected component already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: 'Please fill all data',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                    },
                ]
            });
        }
    }
    const onInsertnewsal1 = (data, e) => {

        let bnk = {
            emp_id: bind_user.emp_id,
            salarycomponent: saltype,
            amount: acc_no,
            createdby: 1,
            salarycomponent_type: salary_details.filter(item => item?.head_name == saltype)[0]?.scope,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_salary`, bnk).then((res) => {
            console.log('user updated', res.data);
            Getemp(bind_user.emp_id);
            Getsalcomp(bind_user.emp_id);

        }).catch(() => {

        })
    }
    const [salary_cdetails, setSalarycDetails] = useState([]);
    const GetSalaryc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setSalarycDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [salary_list, setsal_list] = useState([]);
    const [emid, setemid] = useState();
    const Getsalcomp = (slid) => {

        axios.get(`${process.env.REACT_APP_API_URL}/Employee_salary/${slid}`).then((res) => {
            console.log(res.data, 'success');
            setsal_list(res.data);
            Getgrosshistory(slid);
        }).catch((error) => {
            console.log(error, 'success');
        });

    }
    const [countr, setcountryDetails] = useState([]);
    const Getcountry = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [countrphcode, setcountryDetailsphcode] = useState([]);


    const Getcountryphonecode = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country_phonecode`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetailsphcode(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    useEffect(() => {
        Getvcitylist();
        GetSalary();
        Getcountry();
        Getvbanklist();
        Getcountryphonecode();
    }, []);
    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    useEffect(() => {
        Getvprovincelist();
    }, [city]);
    const [vprovincelist, setvprovincelist] = useState([]);
    const Getvprovincelist = () => {
        if (city != null) {
            axios.get(`${process.env.REACT_APP_API_URL}/vprovince/${city}`).then((res) => {
                console.log(res.data, 'provi');
                setvprovincelist(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [nationality_details, setNationalityDetails] = useState([]);
    const GetNationality = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/nationality`).then((res) => {
                console.log(res.data, 'success');
                setNationalityDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [grossdetails, setGrossDetails] = useState([]);
    const Getgrosshistory = (slid) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/grosshistory/${slid}`).then((res) => {
                console.log(res.data, 'grosshistory');
                setGrossDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        GetNationality();
        Getdepartment();
        Getjobtitle();
        Getloc();
        GetSalaryc();

    }, [state]);

    // leave history
    const [leavelist, setLeave_list] = useState([])
    const [events, setEvents] = useState([]);
    const localizer = momentLocalizer(moment);

    const Getleavelist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leave_emp/${bind_user.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setLeave_list(res.data);
                let aa = [];

                res.data.forEach(item => {
                    if (item.approved == 'true') {
                        let a = {
                            id: item.applyleave_id,
                            title: 'Leave',
                            allDay: true,
                            start: new Date(item.fromdate),
                            end: new Date(item.todate),
                        };
                        aa.push(a);
                    }

                });
                setEvents(aa);
            }).catch(() => {

            })
        }
    }
    const [salary_details, setSalaryDetails] = useState([]);
    const GetSalary = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setSalaryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [department_details, setdepartment_details] = useState([]);

    const Getdepartment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/departments/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setdepartment_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [job_title, setJob_title] = useState([])
    const Getjobtitle = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/job_title/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');

                setJob_title(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [complocation, setcomploc] = useState([])
    const Getloc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setcomploc(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [vbanklist, setvbanklist] = useState([]);
    const [editac, seteditact] = useState("back");
    const [actop, setoption] = useState("");
    const Getvbanklist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vbank`).then((res) => {
                console.log(res.data, 'success');
                setvbanklist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    const clickdelete = (empsalid) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(empsalid)
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to Delete')
                }
            ]
        })
    }
    const current_Date = new Date().toISOString().split('T')[0];
    const Delete = (empsalid) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/Employeedelsalary/` + empsalid).then((res) => {
                console.log(res.data, 'success');

                showsal('back');
                Getsalcomp(bind_user.emp_id);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    document.addEventListener('DOMContentLoaded', function () {
        var elements = document.querySelectorAll('.auto-resize');

        elements.forEach(function (element) {
            var numDigits = element.textContent.replace(/[^0-9]/g, '').length;
            var fontSize = 12; // Initial font size

            if (numDigits > 6) {
                fontSize -= (numDigits - 6); // Decrease font size for more than 6 digits
            }

            element.style.fontSize = fontSize + 'px';
        });
    });

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const RenderView = () => {
        // console.log(bind_user.emp_id, 'check id');

        // Getsalcomp(bind_user.emp_id);

        return (
            <div className={`container-fluid edit_ren ${isAccordionOpen ? "edit_ren-expanded" : ""}`}>
                <div className="row ">
                    <div className="    col-sm-2  hid_tab"></div>

                    <div className="ms-sm-5 col-sm-3 col-xxl-3 col-md-5 col-lg-4 col-xl-3 htborder border-end">
                        <div className="row mt-5  ">

                            <div className="col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 ">

                                <div className='row'>

                                    <div className='col-4'>
                                        <img className='' src={bind_user.profileimg} style={{ height: 65, width: 65 }} ></img>
                                    </div>
                                    <div className='col-8 '>
                                        <p className=" mt-2 ">{bind_user.full_name}
                                            <p> EMP ID :{('000' + bind_user.employeeid).slice(-3)}
                                            </p></p>
                                    </div>
                                </div>


                            </div>
                            <div className="col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                <div className="row">
                                    <div className='col'>
                                        <p className="mt-2 ">Status
                                            {(() => {
                                                if (bind_user.active_status == true) {
                                                    return <p className='text-success fw-bold'>Active</p>


                                                } if (bind_user.active_status == false) {
                                                    return <p className='text-danger fw-bold'>In-Active</p>

                                                }
                                                else {
                                                    return
                                                }
                                            })()}</p>
                                    </div>

                                </div>



                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div class="accordion  me-4" id="accordionExample">

                                        <div class="accordion-item">

                                            <h2 class="accordion-header " id="headingOne" >

                                                <button class="accordion-button  reviseclr" style={{ height: "50px" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">

                                                    {(() => {
                                                        if (bind_user.active_status == true) {
                                                            return (
                                                                <div className='mt-3'>
                                                                    Employment History
                                                                    <p className='text-dark fw-bold mt-1 ' style={{ fontSize: '12px' }}>
                                                                        From <small className='text-dark'>{moment(bind_user.hiredate).format('DD/MM/YYYY')}</small> to <small className='text-dark'>Present</small>
                                                                    </p>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div className='mt-3'>
                                                                    Employment History
                                                                    <p className='text-dark fw-bold  mt-1' style={{ fontSize: '12px' }}>
                                                                        From <small className='text-dark'>{moment(bind_user.hiredate).format('DD/MM/YYYY')}</small> to <small className='text-dark' >{moment(bind_user.enddate).format('DD/MM/YYYY')}</small>
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                </button>


                                            </h2>







                                            <div id="collapsetwo" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                {(() => {
                                                    if (editac == "active") {
                                                        return <div class="accordion-body" style={{ backgroundColor: " #F4F3F3" }}>
                                                            <div className='row'>

                                                                <small className='text-secondary '>Start date</small>
                                                                <input className='form-control inputcolor' value={moment(bind_user.hiredate).format('DD/MM/YYYY')} disabled></input>
                                                                <small className='text-secondary'>End date</small>


                                                                <input
                                                                    className='form-control inputcolor'
                                                                    type="date"
                                                                    value={enddate}
                                                                    onChange={(e) => setenddate(e.target.value)}
                                                                    max={current_Date}
                                                                />
                                                                <small className='text-secondary'>Reason</small>
                                                                <select className='form-select inputcolor' type="text" Value={activereason} onChange={(e) => setactivereason(e.target.value)}>
                                                                    <option selected>Select Option</option>
                                                                    <option value="Resigned">Resigned</option>
                                                                    <option value="Terminated">Terminated</option>
                                                                    <option value="Long Leave">Long Leave</option>
                                                                </select>
                                                                <small className='text-secondary' >Comments</small>
                                                                <textarea className='form-control inputcolor' Value={accmnt} onChange={(e) => setaccmnt(e.target.value)} type="text"></textarea>

                                                            </div>

                                                            <div className='row '>

                                                                <button className='btn btn-pri mt-3' disabled={!isValidac} onClick={() => (onupdatestatusf(bind_user.emp_id))} >Update</button>
                                                            </div>

                                                        </div>
                                                    }
                                                    if (editac == "back") {
                                                        return <div class="accordion-body" style={{ backgroundColor: " #F4F3F3" }}>



                                                            <div className='row'>



                                                                <small className='text-dark '>Current status </small>

                                                                {(() => {
                                                                    if (bind_user.active_status == true) {
                                                                        return <small className='text-success fw-bold'>Active</small>


                                                                    } if (bind_user.active_status == false) {
                                                                        return <p className='text-danger fw-bold'>In-Active</p>

                                                                    }

                                                                })()}

                                                                {(() => {
                                                                    if (bind_user.active_status == false) {
                                                                        return <div><small className='text-secondary'>Comments : </small>
                                                                            <small className='text-dark'>{bind_user.activecmnt}</small></div>
                                                                    } else {

                                                                        return
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.active_status == false) {
                                                                        return <div><small className='text-secondary'>Reason : </small>
                                                                            <small className='text-dark'>{bind_user.actreason}</small></div>
                                                                    } else {

                                                                        return
                                                                    }
                                                                })()}
                                                            </div>

                                                            <div className='row mt-4'>

                                                                {(() => {
                                                                    if (bind_user.active_status == true) {
                                                                        return <button className='btn btn-pri' onClick={() => showactive("active")} >Edit</button>

                                                                    } else {

                                                                        return <button className='btn btn-pri ' onClick={() => (setenddate(bind_user.enddate), onupdatestatust(bind_user.emp_id))} >Update</button>

                                                                    }
                                                                })()}



                                                            </div>

                                                        </div>
                                                    }
                                                })
                                                    ()}
                                            </div>

                                        </div>





                                    </div>
                                </div>


                            </div>

                            <div className="row mt-3">
                                <div className="row">
                                    <div className="col-6">
                                        <p className="text-primary fw-bold">Email</p>
                                        <p className='text-lowercase text_small_mid'>{bind_user.email}</p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p className="text-primary text-end  fw-bold" >Phone</p>
                                        <p className=" text-end  text_small_mid">{bind_user.phon}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p className="text-primary fw-bold">Department</p>
                                        <p className='text_small_mid'>{bind_user.departments}</p>
                                    </div>

                                    <div className="col-6 text-end">
                                        <p className="text-primary fw-bold">Designation</p>
                                        <p className='text-end text_small_mid'>{bind_user.job_title}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p className="text-primary fw-bold">Manager</p>
                                        <p className='text_small_mid'>{bind_user.manager_name} {bind_user.lastmanager_name}</p>
                                    </div>
                                    <div className="col-5 text-end">

                                    </div>
                                </div>






                            </div>



                            <div className="row">
                                <div className="col-6">
                                    <p className="text-secondary " >
                                        Wage </p>
                                    <small className='fw-bold text-lowercase text_small_mid'
                                    >{Math.round(bind_user.amountbasic).toLocaleString('en-US').replace(/,/g, '.')} đ </small>
                                </div>
                                <div className="col-5">
                                    <p className="text-secondary text-end">
                                        Deductions</p>
                                    <p className='fw-bold text-lowercase text_small_mid text-end'
                                    >{Math.round(bind_user.grossded).toLocaleString('en-US').replace(/,/g, '.')} đ</p></div>


                            </div>

                            <div className="row mt-2">
                                <div className="col-6">
                                    <p className="text-secondary ">
                                        Take Home</p>
                                    <small className='fw-bold text-lowercase text_small_mid'
                                    >{Math.round(bind_user.takehomepay).toLocaleString('en-US').replace(/,/g, '.')} đ</small>
                                </div>
                                <div className="col-6">

                                    <div className='row'>

                                        <div className='col-4'></div>
                                        <div className='col-4'><svg xmlns="http://www.w3.org/2000/svg" onClick={() => empleave(bind_user.emp_id)} width="16" height="16" fill="currentColor" class="bi bi-calendar-event fnt-clr pointer_" viewBox="0 0 16 16">
                                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg></div>
                                        <div className='col-4'></div>
                                    </div>
                                    <p className='fw-bold ms-3 text_small_mid mt-3' onClick={() => empleave(bind_user.emp_id)}
                                    >View attendance</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="col-sm-6  border-start ">
                        <Tabs
                            defaultActiveKey="basic"
                            id="uncontrolled-tab-example"
                            className="mb-3 tab_pos negw mt-2"
                        >
                            <Tab eventKey="basic" className="" title="Basic Profile">
                                {(() => {
                                    if (edit_basic == "basic") {


                                        return <form onSubmit={handleSubmit(onInsert)} action="#" method="POST"> <div className="row">
                                            <div className='row'>
                                                <div className='col-sm-2'>
                                                    <img className='img-fluid mt-3 ms-3' src={image64} ></img>

                                                </div>

                                                <div className='col-sm-8 col-xxl-8 col-md-10 col-lg-10 col-xl-8'>

                                                    <div className='row mt-3'>
                                                        <div className='col'>
                                                            <small className='text-secondary'>First Name</small>
                                                            <input type="text" minlength="2" maxlength="40" class="form-control inputcolor  text_box_size " defaultValue={bind_user.full_name} onChange={(e) => setfname(e.target.value)} placeholder="" />


                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>MiddleName</small>
                                                            <input type="text" minlength="1" maxlength="40" class=" text-start form-control inputcolor  text_box_size  " Value={bind_user.middle_name} onChange={(e) => SetMiddlename(e.target.value)} placeholder=" Middle Name" aria-label="" />


                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>LastName</small>
                                                            <input type="text" minlength="1" maxlength="40" class=" text-start form-control inputcolor  text_box_size  " Value={bind_user.last_name} onChange={(e) => SetLstname(e.target.value)} placeholder=" Last Name" aria-label="" />


                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Hiredate</small>
                                                            <input type="date" class=" text-start form-control inputcolor  text_box_size  " Value={bind_user.hiredate} onChange={(e) => sethire(e.target.value)} placeholder=" Hire date *" aria-label="" />


                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Nationality</small>
                                                            <select class=" text-start   form-control inputcolor  text_box_size" Value={bind_user.nationality} onChange={(e) => setNat(e.target.value)} aria-label=" select Day">
                                                                <option selected disabled>{bind_user.nationality}</option>
                                                                {nationality_details.map((x, index) => {
                                                                    return (<option value={x.nationality_id}>{x.nationality}</option>);
                                                                })}

                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col'>
                                                            <small className='text-secondary'>Email</small>

                                                            <input type="email" minlength="1" maxlength="64" class="form-control inputcolor text-lowercase  text_box_size mt-2" defaultValue={bind_user.email} onChange={(e) => setemail(e.target.value)} placeholder="Email" aria-label="Username" />

                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col'>
                                                            <small className='text-secondary'>Tax Resident Status</small>
                                                            <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.residentalstatus} onChange={(e) => setres(e.target.value)} aria-label=" select Day">

                                                                {(() => {
                                                                    if (bind_user.residentalstatus == 1) {
                                                                        return <option className='ms-2' selected disabled>Resident</option>
                                                                    }
                                                                    if (bind_user.residentalstatus == 2) {
                                                                        return <option className='ms-2' selected disabled>Non-Resident</option>
                                                                    }
                                                                    else {
                                                                        return <option className='ms-2' selected disabled>Not Defined</option>
                                                                    }
                                                                })
                                                                    ()}

                                                                <option value='1'>Resident</option>

                                                                <option value='2'>Non-resident</option>
                                                            </select>

                                                        </div>
                                                    </div>

                                                    <div className='row mt-3' >
                                                        <div className='col'>
                                                            <small className='text-secondary'>Profile Image</small>
                                                            <input type="file" class=" mt-2 form-control inputcolor  " onChange={ConvertImageToBase64} placeholder="Profile Image" aria-label="Username" />
                                                            <span className=' text-danger text_box_size'>*Less than 620*420 pixel</span>

                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col'>
                                                            <small className='text-secondary'>Language</small>

                                                            <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.language} onChange={(e) => setlang(e.target.value)} aria-label=" select Day">
                                                                <option selected disabled>{bind_user.language}</option>


                                                                <option value='English'>English</option>

                                                                <option value='Vietnamese'>Vietnamese</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className='row mt-5'>
                                                        <div className="col-6 d-grid">
                                                            <button class="btn btn-secondary text_box_size " type="button" onClick={() => { showbasic("back") }}>Cancel</button>
                                                        </div>

                                                        <div className="col-6 d-grid">

                                                            <button class="btn btn-pri text_box_size " type="submit" onClick={onInsert} >Update</button>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        </form>
                                    }
                                    if (edit_basic == "back") {
                                        return <div className="row">
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2  ">Full Name</p>
                                                    <p className=" ms-2">{bind_user.full_name}</p>
                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2  " >Hire Date</p>
                                                    <p className=" ms-2">{moment(bind_user.hiredate).format('MMM Do YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2 ">Nationality</p>
                                                    <p className=" ms-2"> {bind_user.nationality}
                                                    </p>
                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2 ">Email</p>
                                                    <p className="text-lowercase  ms-2">{bind_user.email}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2 ">Tax Resident Status *</p>
                                                    <div>
                                                        {(() => {
                                                            if (bind_user.residentalstatus == 1) {
                                                                return <p className='ms-2'>Resident</p>
                                                            }
                                                            if (bind_user.residentalstatus == 2) {
                                                                return <p className='ms-2'>Non-Resident</p>
                                                            }
                                                            else {
                                                                return <p className='ms-2'>Not Defined</p>
                                                            }
                                                        })
                                                            ()}
                                                    </div>
                                                </div>
                                                <div className='col-5'>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext   ms-2">Language Preference</p>
                                                    <p className=" ms-2">{bind_user.language}</p>
                                                </div>
                                                <div className='col-5'></div>
                                            </div>



                                            <div className="row">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary  " type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-pri   " onClick={() => { showbasic("basic") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>


                                    }
                                })
                                    ()}
                            </Tab>
                            <Tab eventKey="jobprofile" title="Job Profile">
                                {(() => {
                                    if (edit_job == "job") {


                                        return <form onSubmit={handleSubmit(onInsertjob)} action="#" method="POST" >
                                            <div className='row'>
                                                <div className='col-sm-2 hide_tab'>

                                                </div>

                                                <div className='col-sm-8 col-xxl-8 col-md-12 col-lg-12 col-xl-8'>

                                                    <div className='row mt-2'>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Jobtitle</small>

                                                            <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.job_title} onChange={(e) => setjbtitle(e.target.value)} aria-label=" select Day" placeholder="Job Title" >
                                                                <option selected disabled>{bind_user.job_title}</option>
                                                                {job_title.map((x, index) => {
                                                                    return (<option value={x.job_id}>{x.job_title}</option>);
                                                                })}

                                                            </select>

                                                        </div>
                                                        <div className='col-6'>
                                                            <div class="form-group">
                                                                <small className='text-secondary'>User Type</small>
                                                                <select class=" text-start form-select inputcolor  text_box_size   " Value={bind_user.usertype} onChange={(e) => SetUserT(e.target.value)} aria-label=" select Day">
                                                                    {(() => {
                                                                        if (bind_user.usertype == '9') {
                                                                            return <option selected disabled>Admin</option>
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (bind_user.usertype == '3') {
                                                                            return <option selected disabled>Hr Manager</option>
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (bind_user.usertype == '2') {
                                                                            return <option selected disabled>Supervisor</option>
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (bind_user.usertype == '10') {
                                                                            return <option selected disabled>Employee</option>
                                                                        }
                                                                    })()}

                                                                    <option value='9'>Admin</option>
                                                                    <option value='3'>Hr Manager</option>
                                                                    <option value='2'>Supervisor</option>
                                                                    <option value='10'>Employee</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>

                                                        <div className='col-6'>
                                                            <div class="form-group">
                                                                <small className='text-secondary'>Reporting Manager</small>

                                                                <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.manager} onChange={(e) => setmanagr(e.target.value)} aria-label=" select Day" placeholder="Job Title" >
                                                                    <option selected disabled>{bind_user.manager_name}</option>
                                                                    {managerlist.map((x, index) => {
                                                                        return (<option value={x.emp_id}>{x.manager_name} {x.manager_l_name}</option>);
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Department</small>

                                                            <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.departments} onChange={(e) => setdep(e.target.value)} aria-label=" select Day" placeholder=" Department" >
                                                                <option selected disabled>{bind_user.departments}</option>
                                                                {department_details.map((x, index) => {
                                                                    return (<option value={x.id}>{x.departments}</option>);
                                                                })}

                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Labour contract Number</small>
                                                            <input type="text" minlength="2" maxlength="30" class=" text-start form-control inputcolor  text_box_size  " Value={bind_user.labourcontract} onChange={(e) => setlbcon(e.target.value)} placeholder=" Labor Contract" aria-label="" />



                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Labour Type</small>

                                                            <select class=" text-start form-select inputcolor  text_box_size  " Value={bind_user.labourtype} onChange={(e) => setlbtyp(e.target.value)} aria-label=" select Day">
                                                                {(() => {
                                                                    if (bind_user.labourtype == null || bind_user.labourtype == '-1') {
                                                                        return <option selected disabled>Contract Employee(Vietnamese/Non-Vietnamese)</option>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.labourtype == '-2') {
                                                                        return <option selected disabled>Personal Services Contract</option>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.labourtype == '1') {
                                                                        return <option selected disabled>Vietnamese(Permanent employee)</option>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.labourtype == '2') {
                                                                        return <option selected disabled>Non-Vietnamese/foreigner(Permanent employee)</option>
                                                                    }
                                                                })()}

                                                                <option value="1">Vietnamese(Permanent employee)</option>
                                                                <option value="2">Non-Vietnamese/foreigner(Permanent employee)</option>
                                                                <option value='-1'>Contract Employee(Vietnamese/Non-Vietnamese)</option>
                                                                <option value='-2'>Personal Services Contract</option>

                                                            </select>


                                                        </div>

                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col-6'>

                                                            <small className='text-secondary'>Work Mode</small>
                                                            <select class=" text-start form-select inputcolor text_box_size" Value={bind_user.wfo} onChange={(e) => setwfo(e.target.value)} aria-label=" select Day">

                                                                {(() => {
                                                                    if (bind_user.wfo == true) { return <option className='ms-2' selected disabled>Work from office</option> }
                                                                    if (bind_user.wfo == false) { return <option className='ms-2' selected disabled>Work from home</option> }
                                                                    else { return <option className='ms-2' disabled>Not Defined</option> }
                                                                })()}
                                                                <option value='1'>work form office</option>
                                                                <option value='0'>work form home</option>
                                                            </select>




                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>TimeSheet</small>
                                                            <select class=" text-start   form-select inputcolor  text_box_size " Value={bind_user.timesheet} onChange={(e) => settime(e.target.value)} aria-label=" select Day">
                                                                {(() => {
                                                                    if (bind_user.timesheet == null) {
                                                                        return <option selected disabled>TimeSheet</option>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.timesheet == '1') {
                                                                        return <option selected disabled>TimeSheet Required</option>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (bind_user.timesheet == '2') {
                                                                        return <option selected disabled>TimeSheet Not-Required</option>
                                                                    }
                                                                })()}
                                                                <option value='1'>TimeSheet Required</option>
                                                                <option value='2'>TimeSheet Not-Required</option>
                                                            </select>
                                                        </div>

                                                        <div className='text-danger text_small'>Please note when editing a timesheet from "Timesheet required" to "Timesheet not required," please exercise caution as it will have an impact on attendance.</div>
                                                    </div>


                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Workschedule</small>
                                                            <select class=" text-start   form-select inputcolor  text_box_size" Value={bind_user.workschedule} onChange={(e) => setwork(e.target.value)} aria-label=" select Day" placeholder=" Shift" >
                                                                <option selected disabled>{bind_user.workschedule_name}</option>
                                                                {work_list.map((x, index) => {
                                                                    return (<option value={x.workschedule_id}>{x.workschedule_name}</option>);
                                                                })}

                                                            </select>

                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-secondary'>Work Location</small>
                                                            <select class=" text-start   form-select inputcolor  text_box_size " Value={bind_user.worklocation} onChange={(e) => setworkloc(e.target.value)} aria-label=" select Day">
                                                                <option selected disabled>{bind_user.worklocation}</option>
                                                                {complocation.map((x, index) => {
                                                                    return (<option value={x.location_id}>{x.worklocation}</option>);
                                                                })}

                                                            </select>

                                                        </div>

                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col'>
                                                            <small className='text-secondary'>Contract Expiry Date</small>
                                                            <input type='date' class=" text-start form-control inputcolor text_box_size" Value={bind_user.contractduedate} onChange={(e) => setcontractduedate(e.target.value)}>
                                                            </input>
                                                        </div>



                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col'>
                                                            <div class="form-check">
                                                                <input class="form-check-input  " type="checkbox" value="" id="flexCheckDefault" onChange={onreset} />
                                                                <label class="form-check-label  " for="flexCheckDefault">

                                                                    Send employee self service portal access*
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <input type="email" name="user_email" class="form-control inputcolor hide_all_pc " placeholder="Email" />
                                                        <input type="address" className='hide_all_pc' name="message" />

                                                    </div>

                                                    <div className='row mt-5'>
                                                        <div className="col-6 d-grid">
                                                            <button class="btn btn-secondary   text_box_size" type="button" onClick={() => { showjob("back") }}>Cancel</button>
                                                        </div>

                                                        <div className="col-6 d-grid">

                                                            <button class="btn btn-pri   text_box_size " type="submit" >Update</button>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>


                                        </form>

                                    }
                                    if (edit_job == "back") {
                                        return <div className="row">
                                            <div className='row'>
                                                <div className='col-5'>

                                                </div>
                                                <div className='col-5'>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2 ">Job profile</p>
                                                    <p className=" ms-2">{bind_user.job_title}</p>
                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext  ms-2" >Reporting Manager</p>
                                                    <p className="ms-2">{bind_user.manager_name} {bind_user.lastmanager_name}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2" >User Type</p>
                                                    {(() => {
                                                        if (bind_user.usertype == '9') {
                                                            return <option selected className='ms-2'>Admin</option>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.usertype == '3') {
                                                            return <option selected className='ms-2'>Hr Manager</option>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.usertype == '2') {
                                                            return <option selected className='ms-2'>Supervisor</option>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.usertype == '10') {
                                                            return <option selected className='ms-2'>Employee</option>
                                                        }
                                                    })()}
                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2">Department</p>
                                                    <p className="ms-2">{bind_user.departments}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext  ms-2">Labour Type</p>
                                                    {(() => {
                                                        if (bind_user.labourtype == null || bind_user.labourtype == '-1') {
                                                            return <p className='ms-2'>Contract Employee(Vietnamese/Non-Vietnamese)</p>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.labourtype == '-2') {
                                                            return <p className='ms-2'>Personal Services Contract</p>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.labourtype == '1') {
                                                            return <p className='ms-2'>Vietnamese(Permanent employee)</p>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.labourtype == '2') {
                                                            return <p className='ms-2'>Non-Vietnamese/foreigner(Permanent employee)</p>
                                                        }
                                                    })()}

                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2">Labour Contract Number</p>
                                                    <p className="ms-2">{bind_user.labourcontract}</p>
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className='col-5'>

                                                    <p className="text-secondary profiletext ms-2 ">Work Mode</p><div>
                                                        {(() => {
                                                            if (bind_user.wfo == true) { return <p className='ms-2'>Work from office</p> }
                                                            if (bind_user.wfo == false) { return <p className='ms-2'>Work from home</p> }
                                                            else { return <p className='ms-2'>Not Defined</p> }
                                                        })()}
                                                    </div>


                                                </div>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2">TimeSheet</p>
                                                    {(() => {
                                                        if (bind_user.timesheet == '1') {
                                                            return <p className="ms-2">TimeSheet Required</p>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (bind_user.timesheet == '2') {
                                                            return <p className="ms-2">TimeSheet Not-Required</p>
                                                        }
                                                    })()}
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2">WorkSchedule</p>
                                                    <p className="ms-2">{bind_user.workschedule_name}</p>
                                                </div>
                                                <div className='col-5'>

                                                    <p className="text-secondary profiletext ms-2">Work Location </p>
                                                    <p className="ms-2">{bind_user.worklocation}</p>
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className='col-5'>
                                                    <p className="text-secondary profiletext ms-2 ">Contract Expiry Date</p><div>
                                                        {(() => {
                                                            if (bind_user.contractduedate == null) { return <p className='ms-2'></p> }
                                                            else { return <p className='ms-2'>{moment(bind_user.contractduedate).format("DD/MM/YYYY")}</p> }
                                                        })()}
                                                    </div>


                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary ms-1 " type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-pri ms-4  " onClick={() => { showjob("job") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })
                                    ()}
                            </Tab>
                            <Tab eventKey="Salary" title="Salary " >


                                {(() => {
                                    if (edit_sal == "sal") {


                                        return <div>

                                            {(() => {
                                                if (bind_user.basic_id == null) {
                                                    return <div className="row">
                                                        <div className='col-sm-2 hide_tab'>

                                                        </div>

                                                        <div className="col-sm-10 col-xxl-10 col-md-10 col-lg-10 col-xl-10">
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <small className='text-secondary required'>Wage Type</small>
                                                                    <select class=" text-start form-select inputcolor  text_box_size  " Value={wgtyp} onChange={(e) => setwgtyp(e.target.value)} aria-label=" select Day">
                                                                        <option selected>Wage Type</option>
                                                                        <option value='Monthly'>Monthly</option>
                                                                        <option value='Annually'>Annually</option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <small className='required ms-1'>Gross Pay</small>
                                                                <div className='col-6 '>

                                                                    <input type="text" class=" form-control inputcolor text_box_size" Value=" Gross Pay" disabled="disabled" aria-label="Username" />



                                                                </div>
                                                                <div className='col-6'>

                                                                    <input type="text" onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                        minlength="1" maxlength="30" class="form-control inputcolor text_box_size " placeholder="Amount" Value={bind_user.amountbasic} onChange={(e) => setbassalary(e.target.value)} aria-label="Username" />


                                                                </div>


                                                            </div>

                                                            <div className='row mt-3'>
                                                                <div className="col-5 ms-3 d-grid">

                                                                    <button class="btn btn-secondary text_box_size ms-4 " type="button" onClick={() => showsal('back')}>Cancel</button>

                                                                </div>

                                                                <div className="col-5 d-grid">

                                                                    <button class="btn btn-pri text_box_size ms-4 " type="button" onClick={onInsertgross} disabled={!isValidt}>Add</button>

                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                } else {
                                                    return <div className="row">
                                                        <div className='col-2 hide_tab'>

                                                        </div>
                                                        <div className="col-10 col-xxl-10 col-md-10 col-lg-10 col-xl-10">
                                                            <div className='row'>
                                                                <div className='col-10'>
                                                                    <small className='text-secondary'>Wage Type</small>
                                                                    <select class=" text-start form-select inputcolor  text_box_size  " Value={wgtyp} onChange={(e) => setwgtyp(e.target.value)} aria-label=" select Day">

                                                                        {(() => {
                                                                            if (bind_user.wagetype == "Monthly") {
                                                                                return <option selected className='ms-2' disabled>Monthly</option>
                                                                            }
                                                                            if (bind_user.wagetype == "Annually") {
                                                                                return <option selected className='ms-2' disabled>Annually</option>
                                                                            }

                                                                            else {
                                                                                return <option selected className='ms-2' disabled>Not Defined</option>
                                                                            }
                                                                        })()}
                                                                        <option value='Monthly'>Monthly</option>
                                                                        <option value='Annually'>Annually</option>

                                                                    </select>
                                                                </div>
                                                                <div className='col-1 mt-4'>

                                                                    <button class="btn btn-pri mt-2  " type="button" onClick={wagetypeupdate}>Update</button>


                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-5 mt-5 '>

                                                                    <input type="text" class=" form-control inputcolor text_box_size" Value="Monthly Gross Pay" disabled="disabled" aria-label="Username" />



                                                                </div>
                                                                <div className='col-5 mt-5 '>

                                                                    <input type="text" onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                        minlength="1" maxlength="30" class="form-control inputcolor text_box_size " placeholder="Amount" Value={Math.round(bind_user.amountbasic)} onChange={(e) => setbassalary(e.target.value)} aria-label="Username" />


                                                                </div>
                                                                <div className='col-1 mt-5 '>

                                                                    <button class="btn btn-pri mt-2  " type="button" onClick={onInsersal}>Update</button>


                                                                </div>

                                                            </div>
                                                            {salary_list.map((x, index) => {
                                                                return (
                                                                    <div className='row mt-3'>
                                                                        <div className='col-5  '>

                                                                            <input type="text" class=" form-control inputcolor text_box_size" Value={x.salarycomponent} disabled="disabled" aria-label="Username" />



                                                                        </div>
                                                                        <div className='col-5  '>

                                                                            <input type="text" onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                                minlength="1" maxlength="30" class="form-control inputcolor text_box_size " placeholder="Amount" Value={x.amount} onChange={(e) => setsalarycomponent(e.target.value)} aria-label="Username" />


                                                                        </div>
                                                                        <div className='col-1  '>

                                                                            <button class="btn btn-pri mt-2 " type="button" onClick={(e) => { onInsersalarycomponent(x.empsalid) }} >Update</button>


                                                                        </div>
                                                                        <div className='col-1  '>

                                                                            <svg onClick={() => clickdelete(x.empsalid)} xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="red" class="bi bi-trash3 ms-5 mt-3" viewBox="0 0 16 16">

                                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                                                                            </svg>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })}
                                                            <form className="App" autoComplete="off" onSubmit={handleSubmit(onInsertnewsal)} action="#" method="POST">

                                                                <div className='row mt-5'>
                                                                    <div className='col-5'>

                                                                        <select name="head_name" class=" text-start  form-select inputcolor text_box_size  " id="service" aria-label=" select Day"
                                                                            value={saltype}
                                                                            onChange={(e) => setsaltype(e.target.value)}


                                                                            required>
                                                                            <option>Select Salary Component</option>
                                                                            {salary_cdetails.map((x, index) => {

                                                                                return (

                                                                                    <option value={x.head_name}>{x.head_name}</option>
                                                                                );
                                                                            })}

                                                                        </select>

                                                                    </div>
                                                                    <div className='col-5  '>

                                                                        <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                            minlength="1" maxlength="30" class=" form-control inputcolor text_box_size  " value={acc_no} onChange={(e) => set_accno(e.target.value)} placeholder="Amount" name='amount' aria-label="Username" />


                                                                    </div>
                                                                    <div className='col-1'>
                                                                        <button className='btn btn-pri mt-2' type='submit'>ADD</button>
                                                                    </div>
                                                                </div>





                                                            </form>
                                                            <div className='row mt-3'>
                                                                <div className="col-3 d-grid">


                                                                </div>
                                                                <div className="col-5 ms-3 d-grid">

                                                                    <button class="btn btn-secondary text_box_size ms-2 " type="button" onClick={() => showsal('back')}>Back</button>

                                                                </div>

                                                                <div className="col-5 d-grid">


                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                }
                                            })()}
                                        </div>
                                    }
                                    if (edit_sal == "back") {

                                        return <div className="row">
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className="text-secondary profiletext  ms-2">Wage Type</p>

                                                    {(() => {
                                                        if (bind_user.wagetype == "Monthly") {
                                                            return <p className='ms-2'>Monthly</p>
                                                        }
                                                        if (bind_user.wagetype == "Annually") {
                                                            return <p className='ms-2'>Annually</p>
                                                        }

                                                        else {
                                                            return <p className='ms-2'>Not Defined</p>
                                                        }
                                                    })()}
                                                </div>
                                                <div className='col-4'>
                                                    <p className="ms-2 text-secondary profiletext">Monthly Wage </p>

                                                    {(() => {
                                                        if (bind_user.amountbasic == null) {
                                                            return <p className="ms-2">0.00</p>
                                                        } else {
                                                            return <p className="ms-2 text-lowercase" >{Math.round(bind_user.amountbasic).toLocaleString('en-US').replace(/,/g, '.')}đ</p>
                                                        }
                                                    })()}
                                                </div>
                                                <div className='col-4'>
                                                    <p className="ms-2 text-secondary profiletext">Annual Gross Pay</p>

                                                    {(() => {
                                                        if (bind_user.amountbasic == null) {
                                                            return <p className="ms-2">0.00</p>
                                                        } else {
                                                            return <p className="ms-2 text-lowercase" >{Math.round(bind_user.amountbasic * 12).toLocaleString('en-US').replace(/,/g, '.')}đ</p>
                                                        }
                                                    })()}
                                                </div>
                                            </div>

                                            <div className='row'>
                                                {salary_list.map((x, index) => {
                                                    return (

                                                        <div className='col-3  '>

                                                            <p className="ms-2 text-secondary profiletext">{x.salarycomponent}</p>
                                                            <p className="ms-2 ">{Math.round(x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                                                        </div>




                                                    );
                                                })}
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary" type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-pri" onClick={() => { showsal("sal"); Getsalcomp(bind_user.emp_id) }} type="button">Edit</button>
                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-sm-10 col-xxl-10 col-md-12 col-lg-11 col-xl-10'>
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <button class="accordion-button fw-bold reviseclr" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                                                                    Salary Revision
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body" style={{ backgroundColor: " #F4F3F3" }}>
                                                                    <div className='row no-gutters'>
                                                                        <div className='col-4 px-0'>
                                                                            <small className='text-primary  ms-2 required'>Monthly Wage</small>
                                                                            <input type="text" onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                                minlength="1" maxlength="30" class="form-control inputcolor text1bx mt-2" placeholder="wage" value={Math.round(bind_user.amountbasic)} aria-label="Username" />
                                                                        </div>
                                                                        <div className='col-4  px-0'>

                                                                            <input
                                                                                type="text"
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                minlength="1"
                                                                                maxlength="30"
                                                                                class="form-control inputcolor text2bx mt-4"
                                                                                value={salrevpercent}
                                                                                onChange={(e) => {
                                                                                    setsalrevpercent(e.target.value);
                                                                                    setsalaryrevam((bind_user.amountbasic * e.target.value) / 100 || "");
                                                                                }}
                                                                                placeholder="* Enter %"
                                                                                aria-label="Username"
                                                                            /> </div>
                                                                        <div className='col-4 mt-4 px-0'>

                                                                            <input
                                                                                type="text"
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                minlength="1"
                                                                                maxlength="30"
                                                                                class="form-control inputcolor text3bx "
                                                                                ref={inputRef}
                                                                                value={salaryrevam}
                                                                                onChange={(e) => setsalaryrevam(e.target.value)}
                                                                                placeholder="Enter Amount"
                                                                                aria-label="Username"
                                                                            /> </div>
                                                                    </div>
                                                                    <div className='row mt-2'>
                                                                        <small className='text-primary required'>Effective From</small>
                                                                        <input type="date" min={moment().format("YYYY-MM-DD")} className='form-control inputcolor text_box_size' value={revdate} onChange={(e) => setrevdate(e.target.value)} placeholder='Effective From'></input>
                                                                    </div>
                                                                    <div className='row mt-2'>
                                                                        <div className='col-6'>
                                                                            <small className=" text-secondary profiletext">Monthly Wage</small>

                                                                            {(() => {
                                                                                if (bind_user.amountbasic == null) {
                                                                                    return <p className="">0.00</p>
                                                                                } else {
                                                                                    return <p className="text-lowercase" >{Math.round(bind_user.amountbasic).toLocaleString('en-US').replace(/,/g, '.')} đ</p>
                                                                                }
                                                                            })()}

                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <small className=" text-secondary profiletext">Effective Date</small>

                                                                            {(() => {
                                                                                if (bind_user.revisiondate == null) {
                                                                                    return <p className="">Not Revised</p>
                                                                                } else {
                                                                                    return <p className="" >{moment(bind_user.revisiondate).format("DD-MM-YYYY")}</p>
                                                                                }
                                                                            })()}

                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-4'>
                                                                        <div className='col-10'></div>
                                                                        <div className='col-sm-2 col-xxl-2 col-md-4 col-lg-3 col-xl-2'>
                                                                            <button className='btn btn-pri w-100' type='submit' onClick={onUpdaterevision} disabled={!isValidrev}>Save</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='row'>
                                                    <div className='col-9'>
                                                        <p className='fw-bold '>Salary History</p>
                                                    </div>

                                                </div>
                                                <div className='col-10'>
                                                    <table class="table table-bordered mt-3">

                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <td scope="col" className='fs-6 fw-bold grey'>Sl.No</td>
                                                                    <td scope="col" className='fs-6 fw-bold grey' >Monthly Wage</td>

                                                                    <td scope="col" className='fs-6 fw-bold grey'>Modified By</td>
                                                                    <td scope="col" className='fs-6 fw-bold grey'>Modified Date</td>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {grossdetails.map((x, index) => {
                                                                    return (
                                                                        <tr key={index}>

                                                                            <td>{index + 1}</td>
                                                                            <td className='text-lowercase'>{Math.round(x.grossamount).toLocaleString('en-US').replace(/,/g, '.')} đ</td>


                                                                            <td className='text-capitalize'>{x.updateby}</td>

                                                                            <td>{moment(x.updatedon).format('DD /MM/ YYYY')} {x.revision}</td>




                                                                        </tr>

                                                                    );
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })
                                    ()}
                            </Tab>
                            <Tab eventKey="personal" title="Personal" >
                                {(() => {
                                    if (edit_personal == "personal") {


                                        return <div>
                                            {(() => {
                                                if (bind_user.emp_perid == null) {
                                                    return <form onSubmit={handleSubmit(onInsertnewpersonal)} action="#" method="POST" >

                                                        <div className='row'>
                                                            <div className='col-sm-2 hide_tab'>

                                                            </div>

                                                            <div className='col-sm-8 col-xxl-8 col-md-12 col-lg-12 col-xl-8'>


                                                                <div className='row mt-3'>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary required'>National/Passport ID</small>
                                                                        <input type="text" minlength="2" maxlength="40" class=" text-start  form-control inputcolor  text_box_size " Value={bind_user.pass_port} onChange={(e) => setpass(e.target.value)} placeholder=" passport" aria-label="" />




                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary required'>Date Of Birth</small>
                                                                        <input type="date" class=" text-start  form-control inputcolor  text_box_size " Value={bind_user.dateof_birth} onChange={(e) => setdbo(e.target.value)} placeholder=" Date of Birth" aria-label="" />

                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>Gender</small>
                                                                        <select class=" text-start form-select inputcolor  text_box_size   " Value={bind_user.gender} onChange={(e) => setgen(e.target.value)} aria-label=" select Day">
                                                                            <option selected>Gender</option>
                                                                            <option value="1">Male</option>
                                                                            <option value="2">Female</option>


                                                                        </select>


                                                                    </div>

                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>Marital status</small>
                                                                        <select class=" text-start form-select inputcolor  text_box_size   " Value={bind_user.maritalstatus} onChange={(e) => setmarital(e.target.value)} aria-label=" select Day">
                                                                            <option selected>Marital status</option>
                                                                            <option value="1">Married</option>
                                                                            <option value="2">Single</option>


                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary'>Address</small>
                                                                        <input type="text" minlength="2" maxlength="250" class="form-control inputcolor  text_box_size " placeholder="Address 1" Value={bind_user.addres} onChange={(e) => setadre(e.target.value)} aria-label="Username" />




                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col-5'>
                                                                        <small className='text-secondary required'>Country</small>

                                                                        <select class=" text-start   form-control inputcolor  text_box_size  " aria-label=" select Day" Value={con} onChange={(e) => setcoun(e.target.value)}>
                                                                            <option  >Country</option>
                                                                            {countr.map((x, index) => {

                                                                                return (

                                                                                    <option value={x.country_name} >{x.country_name}</option>
                                                                                );
                                                                            })}



                                                                        </select>



                                                                    </div>

                                                                    <div className='col-7'>
                                                                        <small className='text-secondary required'>Phone Number</small>
                                                                        <div className='row'>
                                                                            <div className='col-4'>
                                                                                <select class="text-start inputcolor text_box_size " aria-label=" select Day" Value={phcode} onChange={(e) => setphncode(e.target.value)}>
                                                                                    <option>+84 </option>
                                                                                    {countrphcode.map((x, index) => {

                                                                                        return (

                                                                                            <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                        );
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <div className='col-8'>
                                                                                <input type="text" onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                    minlength="7" maxlength="12" class=" text-start form-control inputcolor  text_box_size" placeholder=" Phone" onPaste={handle_Paste} Value={bind_user.phon} onChange={(e) => setphn(e.target.value)} aria-label="" />
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>City</small>

                                                                        {(() => {

                                                                            if (con == "Vietnam ") {
                                                                                return <div class="">

                                                                                    <div class="form-group">

                                                                                        <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                            <option selected>City/Town </option>
                                                                                            {vcitylist.map((x, index) => {
                                                                                                return (
                                                                                                    <option value={x.city_id}>{x.city}</option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>

                                                                                </div>
                                                                            } else {
                                                                                return <div class="">

                                                                                    <div class="form-group">

                                                                                        <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                            <option selected>City/Town </option>


                                                                                            <option value="671">Nil</option>

                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })()}




                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>Province</small>

                                                                        {vprovincelist.map((x, index) => {
                                                                            {
                                                                                return (<div>
                                                                                    {(() => {
                                                                                        if (city == 671) {
                                                                                            return <div class="form-group">

                                                                                                <input type="text" class="form-control inputcolor text_box_size" Value={stat} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                            </div>
                                                                                        } else {
                                                                                            return <div class="form-group">

                                                                                                <input type="text" class="form-control inputcolor text_box_size" value={x.province} Value={stat} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                            </div>
                                                                                        }
                                                                                    })()

                                                                                    }
                                                                                </div>


                                                                                )
                                                                            }
                                                                        })}

                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>Postalcode</small>
                                                                        <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                            minlength="1" maxlength="12" class=" text-start form-control inputcolor  text_box_size  " placeholder=" Postalcode" Value={bind_user.postalcode} onChange={(e) => setpin(e.target.value)} aria-label="" />

                                                                    </div>

                                                                </div>





                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size " type="button" onClick={() => { showpersonal("back") }}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri text_box_size " type="Submit" disabled={!isValid}>ADD</button>


                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>

                                                    </form>

                                                }
                                                else {
                                                    return <form onSubmit={handleSubmit(onInsertper)} action="#" method="POST" >

                                                        <div className='row'>
                                                            <div className='col-sm-2 hide_tab'>

                                                            </div>

                                                            <div className='col-sm-10 col-xxl-10 col-md-12 col-lg-12 col-xl-10'>


                                                                <div className='row mt-3'>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>National/Passport Id</small>
                                                                        <input type="text" minlength="1" maxlength="40" class=" text-start  form-control inputcolor  text_box_size " Value={bind_user.pass_port} onChange={(e) => setpass(e.target.value)} placeholder=" passport" aria-label="" />




                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>Date Of Birth</small>
                                                                        <input type="date" class=" text-start  form-control inputcolor  text_box_size " Value={bind_user.dateof_birth} onChange={(e) => setdbo(e.target.value)} placeholder=" Date of Birth" aria-label="" />

                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>Gender</small>
                                                                        <select class=" text-start form-select inputcolor  text_box_size   " Value={bind_user.gender} onChange={(e) => setgen(e.target.value)} aria-label=" select Day">

                                                                            {(() => {
                                                                                if (bind_user.gender == 1) {
                                                                                    return <option className="ms-2" selected disabled>Male</option>
                                                                                } if (bind_user.gender == 2) {
                                                                                    return <option className="ms-2" selected disabled>Female</option>
                                                                                }

                                                                            })()}
                                                                            <option value="1">Male</option>
                                                                            <option value="2">Female</option>


                                                                        </select>


                                                                    </div>

                                                                    <div className='col-6'>
                                                                        <small className='text-secondary'>Marital status</small>
                                                                        <select class=" text-start form-select inputcolor  text_box_size   " Value={bind_user.maritalstatus} onChange={(e) => setmarital(e.target.value)} aria-label=" select Day">

                                                                            {(() => {
                                                                                if (bind_user.maritalstatus == 1) {
                                                                                    return <option className="ms-2" selected disabled>Married</option>
                                                                                } if (bind_user.maritalstatus == 2) {
                                                                                    return <option className="ms-2" selected disabled>Single</option>
                                                                                }

                                                                            })()}
                                                                            <option value="1">Married</option>
                                                                            <option value="2">Single</option>


                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary'>Address</small>
                                                                        <input type="text" minlength="2" maxlength="250" class="form-control inputcolor  text_box_size " placeholder="Address 1" Value={bind_user.addres} onChange={(e) => setadre(e.target.value)} aria-label="Username" />




                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col-5'>
                                                                        <small className='text-secondary'>Country</small>

                                                                        <select class=" text-start   form-select inputcolor  text_box_size  " aria-label=" select Day" Value={bind_user.country} onChange={(e) => setcoun(e.target.value)}>
                                                                            <option selected disabled>{bind_user.country}</option>
                                                                            {countr.map((x, index) => {

                                                                                return (

                                                                                    <option value={x.country_name} >{x.country_name}</option>
                                                                                );
                                                                            })}



                                                                        </select>



                                                                    </div>

                                                                    <div className='col-7'>
                                                                        <small className='text-secondary'>Phone Number</small>
                                                                        <div className='row'>
                                                                            <div className='col-4'>
                                                                                <select class="text-start inputcolor text_box_size " aria-label=" select Day" onPaste={handle_Paste} Value={bind_user.phncode} onChange={(e) => setphcode(e.target.value)}>
                                                                                    <option selected disabled>{bind_user.phncode} </option>
                                                                                    {countrphcode.map((x, index) => {

                                                                                        return (

                                                                                            <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                        );
                                                                                    })}

                                                                                </select>
                                                                            </div>

                                                                            <div className='col-8'>
                                                                                <input type="text" onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                    minlength="7" maxlength="12" class=" text-start form-control inputcolor  text_box_size" placeholder=" Phone" Value={bind_user.phon} onChange={(e) => setphn(e.target.value)} aria-label="" />

                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>





                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className='row mt-1'>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>City</small>

                                                                        {(() => {

                                                                            if (bind_user.country == "Vietnam ") {
                                                                                return <div class="">

                                                                                    <div class="form-group">

                                                                                        <select class="form-control inputcolor text_box_size" Value={bind_user.city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                            <option selected disabled>{bind_user.city} </option>
                                                                                            {vcitylist.map((x, index) => {
                                                                                                return (
                                                                                                    <option value={x.city_id}>{x.city}</option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>

                                                                                </div>
                                                                            } else {
                                                                                return <div class="">

                                                                                    <div class="form-group">

                                                                                        <select class="form-control inputcolor text_box_size" Value={bind_user.city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                            <option selected>City/Town </option>


                                                                                            <option value="671">Nil</option>

                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })()}




                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>Province</small>
                                                                        <input type="text" class=" text-start form-control inputcolor  text_box_size   " value={bind_user.state} onChange={(e) => setstat(e.target.value)} placeholder=" State" aria-label="" />


                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <small className='text-secondary'>Postalcode</small>
                                                                        <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                            minlength="1" maxlength="12" class=" text-start form-control inputcolor  text_box_size  " placeholder=" Postalcode" Value={bind_user.postalcode} onChange={(e) => setpin(e.target.value)} aria-label="" />

                                                                    </div>

                                                                </div>





                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size " type="button" onClick={() => { showpersonal("back") }}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri text_box_size " type="Submit">Update</button>


                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>

                                                    </form>

                                                }
                                            }
                                            )()}
                                        </div>



                                    }
                                    if (edit_personal == "back") {
                                        return <div className="row">
                                            <div className="row">
                                                <div class="col-5">
                                                    <p className="text-secondary profiletext ms-2">  Passport/National ID</p>
                                                    <p className="ms-2">{bind_user.pass_port}</p>
                                                    <p className="text-secondary profiletext ms-2" >Gender</p>
                                                    {(() => {
                                                        if (bind_user.gender == 1) {
                                                            return <p className="ms-2">Male</p>
                                                        } if (bind_user.gender == 2) {
                                                            return <p className="ms-2">Female</p>
                                                        }

                                                    })()}

                                                    <p className="ms-2 text-secondary profiletext">Marital Status</p>

                                                    {(() => {
                                                        if (bind_user.maritalstatus == 1) {
                                                            return <p className="ms-2">Married</p>
                                                        } if (bind_user.maritalstatus == 2) {
                                                            return <p className="ms-2">Single</p>
                                                        }

                                                    })()}

                                                    <p className="ms-2 text-secondary profiletext">Address </p>
                                                    <p className="ms-2">{bind_user.addres}</p>


                                                    <p className="ms-2 text-secondary profiletext">City</p>
                                                    <p className="ms-2">{bind_user.city}</p>

                                                </div>
                                                <div class="col-4">

                                                    <p className="ms-2 text-secondary profiletext">
                                                        Country</p>
                                                    <p className="ms-2">{bind_user.country}</p>
                                                    <p className="ms-2 text-secondary profiletext">
                                                        State/Province</p>
                                                    <p className="ms-2">{bind_user.province}</p>
                                                    <p className="text-secondary ms-2 profiletext">
                                                        Phone Number</p>
                                                    <p className="ms-2">{bind_user.phncode} {bind_user.phon}</p>
                                                    <p className="text-secondary ms-2 profiletext">
                                                        Postal Code</p>
                                                    <p className="ms-2">{bind_user.postalcode}</p>
                                                    <p className="text-secondary ms-2 profiletext">
                                                        Date of Birth</p>
                                                    {(() => {
                                                        if (bind_user.dateof_birth === null || bind_user.dateof_birth === '') {
                                                            return <p className="ms-2"> </p>
                                                        } else {
                                                            return <p className="ms-2">{moment(bind_user.dateof_birth).format('MMM Do YYYY')}</p>
                                                        }

                                                    })()}


                                                </div>
                                            </div>



                                            <div className="row">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary ms-1" type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-pri ms-4" onClick={() => { showpersonal("personal") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })
                                    ()}

                            </Tab>

                            <Tab eventKey="tax" title="Tax" >
                                {(() => {
                                    if (edit_tax == "tax") {


                                        return <div>
                                            {(() => {
                                                if (bind_user.emptax_id == null) {
                                                    return <form onSubmit={handleSubmit(onInsert_newtax)} action="#" method="POST" >
                                                        <div className='row'>
                                                            <div className='col-2'>

                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary required'>Pit Code</small>
                                                                        <input type="text" minlength="1" maxlength="30" class=" form-control inputcolor  text_box_size" onChange={(e) => setpitcode(e.target.value)} placeholder=" Pit Code" aria-label="Username" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary required'>SI book</small>
                                                                        <input type="text" minlength="1" maxlength="30" class=" text-left  form-control inputcolor  text_box_size " onChange={(e) => setsibook(e.target.value)} placeholder=" SI Book" aria-label="" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary required'># of Dependents</small>
                                                                        <input type="number" minlength="1" maxlength="30" class=" text-left form-control inputcolor  text_box_size  " onChange={(e) => setdepen(e.target.value)} onKeyDown={handleKeyDown} placeholder=" # of Dependents" aria-label="" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size " type="button" onClick={() => showtax("back")}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri  text_box_size" type="submit" disabled={!isValids}>Add</button>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>



                                                    </form>
                                                }
                                                else {
                                                    return <form onSubmit={handleSubmit(onInserttax)} action="#" method="POST" >
                                                        <div className='row'>
                                                            <div className='col-2'>

                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary '>Pit Code</small>
                                                                        <input type="text" minlength="1" maxlength="30" class=" form-control inputcolor  text_box_size" placeholder="" Value={bind_user.pit_code} onChange={(e) => setpitcode(e.target.value)} aria-label="Username" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary'>SI Book</small>
                                                                        <input type="text" minlength="1" maxlength="30" class=" text-left  form-control inputcolor  text_box_size " Value={bind_user.si_book} onChange={(e) => setsibook(e.target.value)} placeholder=" SI Book" aria-label="" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary '># of Dependents</small>
                                                                        <input type="number" class=" text-left form-control inputcolor  text_box_size  " Value={bind_user.dependance} onChange={(e) => setdepen(e.target.value)} onKeyDown={handleKeyDown} placeholder=" # of Dependents" aria-label="" />
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size " type="button" onClick={() => showtax("back")}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri  text_box_size" type="submit" >Update</button>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>



                                                    </form>
                                                }
                                            }
                                            )()}


                                        </div>

                                    }
                                    if (edit_tax == "back") {

                                        return <div className="row">
                                            <p className="text-secondary ms-2 profiletext">PIT Code</p>
                                            <p className="ms-2">{bind_user.pit_code}</p>
                                            <p className="text-secondary ms-2 profiletext" >
                                                SI Book</p>
                                            <p className="ms-2">{bind_user.si_book}</p>
                                            <p className="ms-2 text-secondary profiletext"># of Dependents</p>
                                            <p className="ms-2">{bind_user.dependance}
                                            </p>

                                            <div className="row">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary ms-1" type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">

                                                    <button class="btn btn-pri ms-4" onClick={() => showtax("tax")} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })
                                    ()}
                            </Tab>
                            <Tab eventKey="account" title="Bank" >
                                {(() => {
                                    if (edit_acc == "acc") {


                                        return <div>
                                            {(() => {
                                                if (bind_user.empbank_id == null) {
                                                    return <form onSubmit={handleSubmit(onInsertnewbank)} action="#" method="POST" >
                                                        <div className='row'>
                                                            <div className='col-2'>

                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>

                                                                        <div class="form-group">
                                                                            <label for="formGroupExampleInput" class="required ms-1">Bank Name</label>
                                                                            <select class=" form-select inputcolor text_box_size" Value={bind_user.bank} onChange={(e) => setbnk(e.target.value)} aria-label=" select Day">
                                                                                <option selected>Bank Name </option>
                                                                                {vbanklist.map((x, index) => {
                                                                                    return (
                                                                                        <option value={x.vbankid}>{x.vbankname}</option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary required'>Acccount No</small>
                                                                        <input onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }} type="text" minlength="1" maxlength="30" class=" text-left  form-control inputcolor  text_box_size " placeholder="Acccount No" Value={bind_user.account_no} onChange={(e) => setaccno(e.target.value)} aria-label="" />
                                                                    </div>

                                                                </div>

                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size  " type="button" onClick={() => showacc("back")}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri text_box_size " type="submit" disabled={!isValide} >ADD</button>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>




                                                    </form>
                                                } else {
                                                    return <form onSubmit={handleSubmit(onInsertbank)} action="#" method="POST" >
                                                        <div className='row'>
                                                            <div className='col-2'>

                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <label for="formGroupExampleInput" class=" ms-1 text-secondary">Bank Name</label>
                                                                        <select class="form-select inputcolor text-start  bankaccountbox" Value={bind_user.bank} onChange={(e) => setbnk(e.target.value)} aria-label=" select Day">
                                                                            <option selected disabled>{bind_user.vbankname}</option>
                                                                            {vbanklist.map((x, index) => {
                                                                                return (
                                                                                    <option value={x.vbankid} onChange={(e) => setbnk(e.target.value)} >{x.vbankname}</option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div className='row mt-3'>
                                                                    <div className='col'>
                                                                        <small className='text-secondary'>Account No</small>
                                                                        <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }} minlength="1" maxlength="30" class=" text-left  form-control inputcolor  text_box_size " placeholder="Account No " Value={bind_user.account_no} onChange={(e) => setaccno(e.target.value)} aria-label="" />
                                                                    </div>

                                                                </div>

                                                                <div className='row mt-5'>
                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-secondary text_box_size  " type="button" onClick={() => showacc("back")}>Cancel</button>
                                                                    </div>

                                                                    <div className="col-6 d-grid">
                                                                        <button class="btn btn-pri text_box_size " type="button" onClick={onInsertbank}>Update</button>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>




                                                    </form>
                                                }
                                            })()}
                                        </div>


                                    }
                                    if (edit_acc == "back") {

                                        return <div className="row">
                                            <p className="ms-2 text-secondary profiletext">Bank</p>
                                            <p className="ms-2">{bind_user.vbankname}</p>
                                            <p className="ms-2 text-secondary profiletext" >
                                                Account Number</p>
                                            <p className="ms-2">{bind_user.account_no}</p>


                                            <div className="row">
                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-secondary ms-1" type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                </div>

                                                <div className="col-5 d-grid">
                                                    <button class="btn btn-pri ms-4" onClick={() => showacc("acc")} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })
                                    ()}
                            </Tab>

                        </Tabs>
                    </div>
                </div>


            </div>
        )

    }


    const [user_type, setUserType] = useState('');
    const ShowDetails = (data, type) => {
        Getleave();
        console.log('data', data);
        setBindUser(data);
        setfname(data.full_name);
        SetMiddlename(data.middle_name);
        SetLstname(data.last_name);
        sethire(data.hiredate);
        setemail(data.email);
        setwfo(data.wfo);
        setNat(data.nationality_id);
        setres(data.residentalstatus);
        setlang(data.language);
        setjbtitle(data.jobtitle);
        setmanagr(data.manager);
        SetUserT(data.usertype);
        setdep(data.department);
        setlbcon(data.labourcontract);
        setshif(data.shift);
        setwork(data.workschedule);
        settime(data.timesheet);
        setcontractduedate(data.contractduedate);
        setworkloc(data.location_id);
        setwgtyp(data.wagetype);
        setImage64(data.profileimg);
        setlbtyp(data.labourtype);
        setgen(data.gender);
        setpass(data.pass_port);
        setadre(data.addres);
        setcity(data.city_id);
        setstat(data.province);
        setphcode(data.phncode);
        setpin(data.postalcode);
        setdbo(data.dateof_birth);
        setcoun(data.country);
        setphn(data.phon);
        setmarital(data.maritalstatus);
        setloc(data.location);
        setpitcode(data.pit_code);
        setsibook(data.si_book);
        setdepen(data.dependance);
        setbnk(data.bank);
        setaccno(data.account_no);
        setenddate(data.enddate);

        setUserType(type);


    }
    // const base64String = btoa(String.fromCharCode(...new Uint8Array(bind_user.profileimg)));
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 8;
    const pagesVisited = pageNumber * usersPerPage;



    const filteredUsers = leave_details.filter((x) =>
        x.full_name.toLowerCase().includes(query.toLowerCase()) &&
        (selectedDepartment === "" || x.departments === selectedDepartment) &&

        (selectedWorkMode === "" || (x.wfo === true ? "WFO" : (x.wfo === false ? "WFH" : "")) === selectedWorkMode) &&

        (selectedStatus === "" || (x.active_status ? "Active" : "In-Active") === selectedStatus) &&
        (selectedContract === "" || (x.contractduedate &&
            new Date(x.contractduedate) < new Date()) &&
            selectedContract === "Contract Expired"));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);

    const pageCount = Math.ceil(leave_details.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    // updated disable

    const [active_status, setactive_status] = useState();
    const [enddate, setenddate] = useState('');
    const [activereason, setactivereason] = useState("");
    const isValidac = activereason && enddate

    const [accmnt, setaccmnt] = useState("");
    const onupdatestatust = (id) => {
        setactive_status(true);
        let active = {

            active_status: "true"
        };


        axios.put(`${process.env.REACT_APP_API_URL}/update_active/${id}`, active).then((res) => {
            console.log('user enddate', enddate);
            console.log('user updated', res.data);

            onupdatestatus_empt(id);
        }).catch(() => {

        })
    }
    const onupdatestatusf = (id) => {
        setactive_status(false);
        let active = {

            active_status: "false"
        };


        axios.put(`${process.env.REACT_APP_API_URL}/update_active/${id}`, active).then((res) => {
            console.log('user enddate', enddate);
            console.log('user updated', res.data);

            onupdatestatus_emp(id);
        }).catch(() => {

        })
    }

    const onupdatestatus_emp = (id) => {

        let active = {
            active_status: "false",
            enddate: enddate,
            reason: activereason,
            activecmnt: accmnt,

        };


        axios.put(`${process.env.REACT_APP_API_URL}/update_active_emp/${id}`, active).then((res) => {

            console.log('user updated', res.data);


            Getleave();

            showactive("back");
            window.location.reload();
        }).catch(() => {

        })
    }
    const onupdatestatus_empt = (id) => {

        let active = {
            active_status: "true",
            enddate: enddate,
            reason: activereason,
            activecmnt: accmnt,

        };


        axios.put(`${process.env.REACT_APP_API_URL}/update_active_emp/${id}`, active).then((res) => {

            console.log('user updated', res.data);


            Getleave();

            showactive("back");
            window.location.reload();
        }).catch(() => {

        })
    }

    // Initialize your states
    const [selectedManager, setSelectedManager] = useState("");
    const [selectedWorkSchedule, setSelectedWorkSchedule] = useState("");
    const [selectedWorkLocation, setSelectedWorkLocation] = useState("");

    // The function to handle the Assign All button click
    const assignAll = () => {
        // Get the emp_ids of selected employees
        const selectedEmpIds = leave_details
            .filter(item => item.select && item.emp_id !== null)
            .map(item => item.emp_id);

        // Call the update function for each selected employee
        selectedEmpIds.forEach(emp_id => {
            onupdateassign(emp_id, selectedManager, selectedWorkSchedule, selectedWorkLocation);
        });
    };

    const onupdateassign = (emp_id, manager, workschedule_id, location_id) => {
        const data = {
            manager: manager,
            workschedule_id: workschedule_id,
            location_id: location_id
        };

        axios.put(`${process.env.REACT_APP_API_URL}/updateassign/${emp_id}`, data)
            .then((res) => {
                console.log('User updated', res.data);
                // Do something after the update
                window.location.reload();
            })
            .catch((error) => {
                console.error('Error updating user', error);
            });
    };


    const areAnyCheckboxesChecked = leave_details.some(item => item.select === true);
    return (
        <>

            <div className='vh-100 container-fluid'>

                <div className='row mt-4'>
                    <div className='col-sm-2 col-xxl-2  col-md-2 col-lg-2 col-xl-2 hid_tab '>

                    </div>
                    <div className='col-sm-10 col-xxl-10  col-md-12 col-lg-12 col-xl-10   bg-light  mt-4 tbl'>
                        <input type="text" class=" form-control grey mt-4 ms-sm-5 searchbox grey" onChange={(e) => SetQuery(e.target.value)} placeholder="Search by Employee Name" id="" aria-label="Username" />

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                        <div class="card ms-sm-5 shadow mb-5 p-2 bg-white rounded">

                            <div class="card-body">
                                <div className='row'>
                                    <div className='col-sm-3 col-xxl-3 col-md-3 col-lg-3 col-xl-3'>
                                        <button class="btn btn-pri mt-2" onClick={empprofile}>Add Employee</button>
                                    </div>
                                    <div className='col-sm-6 col-xxl-6 col-md-5 col-lg-5 col-xl-6'></div>
                                    <div className='col-sm-2 col-xxl-2 col-md-3 col-lg-3 col-xl-2'>
                                        <button class="btn btn-pri mt-2" data-bs-toggle="modal" disabled={!areAnyCheckboxesChecked} data-bs-target="#exampleModal1">Bulk Assign</button>
                                    </div>
                                    <div className='col-sm-1 pointer_ hide_all'>

                                        <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-sm-3 mt-2  Clockt8 " viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                        </svg>
                                    </div>
                                    <div className='col-sm-1 pointer_ onlytab hide_tab'>

<svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-sm-3   Clockt8 " viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
</svg>
</div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm'>
                                       
                                        <div className="row">
                                            <div className="ms-4 mt-2 onlytab">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <table class="table table-bordered mt-4 year-buttonss">
                                            <table class="table table-striped ">
                                                <thead className='text-_small'>
                                                    <tr>
                                                        <td scope="col">
                                                            <input type="checkbox"
                                                                className='ms-3'
                                                                onChange={e => {
                                                                    const value = e.target.checked;

                                                                    setUserDetails(leave_details => {
                                                                        return leave_details.map(item => {

                                                                            if (
                                                                                item.emp_id != null && item.emp_id != state?.user[0]?.emp_id

                                                                            ) {
                                                                                item.select = value;
                                                                            } else {
                                                                                item.select = false; // Deselect if conditions are not met
                                                                            }
                                                                            console.log(item, 'prevListt');
                                                                            return item;
                                                                        });
                                                                    });
                                                                }} />
                                                        </td>
                                                        <td scope="col"></td>
                                                        <td scope="col" className='  text-center text-uppercase headfont grey' >Name</td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Profile Status</td>

                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Wage</td>

                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Attendance</td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Department </td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Work-Mode </td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Status </td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>Take Home (Last Processed) </td>
                                                    </tr>
                                                </thead>
                                                <tbody>



                                                    {displayUsers.filter((x) =>
                                                        x.full_name.toLowerCase().includes(query.toLowerCase()) &&
                                                        (selectedDepartment === "" || x.departments === selectedDepartment) &&
                                                        (selectedWorkMode === "" || (x.wfo === true ? "WFO" : (x.wfo === false ? "WFH" : "")) === selectedWorkMode) &&
                                                        (selectedStatus === "" || (x.active_status ? "Active" : "In-Active") === selectedStatus) &&
                                                        (selectedContract === "" || (x.contractduedate &&
                                                            new Date(x.contractduedate) < new Date()) &&
                                                            selectedContract === "Contract Expired")
                                                    ).map((x, index) => {

                                                        const contractDueDate = new Date(x.contractduedate);
                                                        const currentDate = new Date();
                                                        const diffTime = Math.abs(contractDueDate - currentDate);
                                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                                        console.log(diffDays, 'mapempname');
                                                        return (

                                                            <tr key={index} className=' '>
                                                                <td scope="col">
                                                                    {(() => {
                                                                        if (x.emp_id != state?.user[0]?.emp_id) {

                                                                            return <input
                                                                                type="checkbox" className='ms-3'
                                                                                checked={x.select}
                                                                                onChange={e => {
                                                                                    let value = e.target.checked;
                                                                                    setUserDetails(
                                                                                        leave_details.map(sd => {
                                                                                            if (sd.emp_id === x.emp_id) {
                                                                                                sd.select = value;
                                                                                            }
                                                                                            return sd;
                                                                                        })
                                                                                    );
                                                                                }}
                                                                            />
                                                                        } else {
                                                                            return <input
                                                                                type="checkbox"
                                                                                className='ms-3'
                                                                                disabled

                                                                            />
                                                                        }
                                                                    })()}

                                                                </td>

                                                                {(() => {
                                                                    if (x.profileimg == null || x.profileimg == '') {
                                                                        return <td scope="row"><div className="ms-3" > <img className='img-fluid hide_tab' src={`Images/grycir.svg`} style={{ height: 45, width: 45 }} ></img> </div></td>


                                                                    } else {
                                                                        return <td scope="row"><div className="ms-3"> <img className='img-fluid im_g hide_tab' src={x.profileimg} style={{ height: 45, width: 45 }}></img> </div></td>

                                                                    }
                                                                })()}

                                                                <td className=' text-center text-capitalize pointer_  greyh' onClick={() => { ShowDetails(x, 'view'); Getsalcomp(x.emp_id) }} style={{ paddingTop: '15px' }}>{x.full_name}</td>
                                                                <td className='' style={{ paddingTop: '15px' }}>{(() => {
                                                                    if (x.amountbasic == null || x.account_no == null || x.account_no == "" || x.full_name == null ||
                                                                        x.full_name == "" || x.hiredate == null || x.hiredate == "" ||
                                                                        x.nationality == null || x.nationality == "" || x.email == null || x.email == "" ||
                                                                        x.residentalstatus == null || x.residentalstatus == "" ||
                                                                        x.usertype == null || x.usertype == "" || x.workschedule == null || x.workschedule == "" ||
                                                                        x.phon == null || x.phon == "" || x.pass_port == null || x.pass_port == "" ||
                                                                        x.pit_code == null || x.pit_code == '' || x.si_book == null || x.si_book == "" ||
                                                                        x.dependance == null || x.dependance == "" || x.timesheet == null || x.timesheet == "") {
                                                                        return <p className=' text-center greyh'>Incomplete</p>
                                                                    } else {
                                                                        return <p className=' text-center greyh'>Complete</p>


                                                                    }
                                                                })()}
                                                                    {
                                                                        (x.contractduedate && new Date(x.contractduedate) < new Date()) ? (
                                                                            <small className='text-center' style={{ color: "#F36E35", fontSize: '11px' }}>
                                                                                <div role="alert text-warning">
                                                                                    Contract expired
                                                                                </div>
                                                                            </small>
                                                                        ) : null
                                                                    }
                                                                </td>

                                                                <td className='  text-center text-lowercase greyh' style={{ paddingTop: '15px' }}>{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.')}đ</td>

                                                                <td className='  text-center pointer_ greyh' style={{ paddingTop: '15px' }}><svg xmlns="http://www.w3.org/2000/svg" onClick={() => empleave(x.emp_id)} width="16" height="16" fill="#4EB4E6" class="bi bi-calendar-event " viewBox="0 0 16 16">
                                                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                </svg></td>
                                                                <td className='  text-center greyh' style={{ paddingTop: '15px' }}>{x.departments}</td>
                                                                {(() => {
                                                                    if (x.wfo == true) { return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>WFO</td> }
                                                                    if (x.wfo == false) { return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>WFH</td> }
                                                                    else { return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>Not defined</td> }
                                                                })()}
                                                                {(() => {
                                                                    if (x.active_status == true) {
                                                                        return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>Active</td>
                                                                    } else {
                                                                        return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>In-Active</td>


                                                                    }
                                                                })()}

                                                                {(() => {
                                                                    if (x.takehomepay == null) {
                                                                        return <td className=' text-center greyh' style={{ paddingTop: '15px' }}>Yet-To-Process</td>
                                                                    } else {
                                                                        return <td className=' text-center greyh text-lowercase' style={{ paddingTop: '15px' }}>{Math.round(x.takehomepay).toLocaleString('en-US').replace(/,/g, '.')} đ</td>
                                                                    }
                                                                })()}
                                                                <td className='' style={{ paddingTop: '15px' }}><svg id="dropdownMenuButton1 " data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical " viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />

                                                                </svg>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                                        <li><a className="dropdown-item" onClick={() => { ShowDetails(x, 'view'); Getsalcomp(x.emp_id) }} >View</a></li>
                                                                    </ul>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Filter code */}
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <p class="fs-6 ms-4 fw-bold">Departments</p>
                                            <select class="form-select inputcolor popupdrop ms-4" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)} aria-label="select departments status">
                                                <option value="">All</option>
                                                {department_details.map((x, index) => {
                                                    return (<option value={x.departments}>{x.departments}</option>);
                                                })}
                                            </select>
                                        </div>

                                        <div className='col-sm-6'>
                                            <p class=" fs-6 ms-4 fw-bold">Work Mode</p>
                                            <select class="form-select inputcolor popupdrop ms-4" value={selectedWorkMode} onChange={(e) => setSelectedWorkMode(e.target.value)} aria-label=" select Day">
                                                <option value="">All</option>
                                                <option value="WFO">WFO</option>
                                                <option value="WFH">WFH</option>
                                            </select>


                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-sm-6'>
                                            <p class=" fs-6 ms-4 fw-bold">Status</p>
                                            <select class="form-select inputcolor popupdrop ms-4" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} aria-label=" select Day">
                                                <option value="">All</option>
                                                <option value="Active">Active</option>
                                                <option value="In-Active">In-Active</option>
                                            </select>


                                        </div>

                                        <div className='col-sm-6'>
                                            <p class=" fs-6 ms-4 fw-bold">Contract Status</p>
                                            <select class="form-select inputcolor popupdrop ms-4" value={selectedContract} onChange={(e) => setSelectedContract(e.target.value)} aria-label=" select Day">
                                                <option value="">All</option>
                                                <option value="Contract Expired">Contract Expired</option>
                                            </select>


                                        </div>

                                    </div>
                                </div>
                                <div class="s">


                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable ">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Assign Employee Details</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row mt-2'>
                                        <div className='col-2'>

                                        </div>
                                        <div className='col-8'>
                                            <select class=" text-start   form-select inputcolor  text_box_size" onChange={(e) => setSelectedManager(e.target.value)} aria-label=" select Day" placeholder="Job Title" >
                                                <option selected disabled>Manager</option>
                                                {managerlist.map((x, index) => {
                                                    return (<option value={x.emp_id}>{x.manager_name} {x.manager_l_name}</option>);
                                                })}

                                            </select>
                                        </div>
                                        {/* <div className='col-6'>
                                      <button class="btn btn-pri mt-2" >Assign Manager</button>
                                 
                                      </div> */}

                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-2'>

                                        </div>
                                        <div className='col-8'>
                                            <select class=" text-start   form-select inputcolor  text_box_size" onChange={(e) => setSelectedWorkSchedule(e.target.value)} aria-label=" select Day" placeholder=" Shift" >
                                                <option selected disabled>Work schedule</option>
                                                {work_list.map((x, index) => {
                                                    return (<option value={x.workschedule_id}>{x.workschedule_name}</option>);
                                                })}

                                            </select>
                                        </div>
                                        {/* <div className='col-6'>
                                      <button class="btn btn-pri mt-2" >Assign Workschedule</button>
                                 
                                      </div> */}

                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-2'>

                                        </div>
                                        <div className='col-8'>
                                            <select class=" text-start   form-select inputcolor  text_box_size " onChange={(e) => setSelectedWorkLocation(e.target.value)} aria-label=" select Day">
                                                <option selected disabled>Work Location</option>
                                                {complocation.map((x, index) => {
                                                    return (<option value={x.location_id}>{x.worklocation}</option>);
                                                })}

                                            </select>
                                        </div>
                                        {/* <div className='col-6'>
                                      <button class="btn btn-pri mt-2" >Assign Worklocation</button>

                                      </div>
                                      */}
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col d-flex justify-content-center">
                                            <button className="btn btn-pri" onClick={assignAll}>
                                                Save
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div>{(() => {
                    switch (user_type) {
                        case "view": return RenderView();
                        default: return "";
                    }
                })()}</div>
                <Lheader />
            </div>
        </>
    );
}
export default EmployeeList;