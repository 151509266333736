import React, { useState, useEffect } from 'react';

const GeoFencingClockIn = () => {

  const [currentLocation, setCurrentLocation] = useState(null);
  const [long, setlong] = useState();

  const [lat, setlat] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation([latitude, longitude]);
        setlong(longitude);
        setlat(latitude);
      },
      (error) => console.log(error)
    );
  }, []);
   // State to store IP
   const [ip, setIp] = React.useState("");
 
   // Fetch IP when component mounts
   useEffect(() => {
     fetch("https://api.ipify.org?format=json")
       .then(res => res.json())
       .then(res => setIp(res.ip));
   }, []);
 
  return (
    <div className='row'>
      <div className='col-3'>

      </div>
      <div className='col-5'>
        {currentLocation && (
          <p className='fw-bold'>
            You are currently at {currentLocation[0]}, {currentLocation[1]}

          </p>

        )}
        <p>
        Your IP is: {ip} 
        </p>
      </div>

      {(() => {
        if ( ip == "103.43.200.157" ) {
          return <button


            className={`timer-controller-btn btn-pri btn pausebtn `}
          >
            Clock In
          </button>
        }

        else {
          return <button


            className={`timer-controller-btn btn-pri btn pausebtn `}
            disabled  >
            Clock In
          </button>


        }
      })()}
    </div>
  );
};

export default GeoFencingClockIn;