import React, { useCallback, useState, useEffect, useContext } from 'react';
import AEHeader from '../Header/AEHeader'
import "./empPayment.css"
import { useNavigate, useLocation } from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { store } from '../../store/user';

const AddempPayment = () => {
    const { state } = useContext(store);
    console.log('payment', state.user);
    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const salary = (x) => {
        let path = `/salarydetails`;
        navigate(path, { state: { x: x, } });
    }
    const location = useLocation();
    console.log('emp_id: ', location?.state?.x?.emp_id);
    console.log('wagetype: ', location?.state?.x?.wagetype);

    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => employee(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('Canceled')
                }
            ]
        })
    }
    const alert = (x) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Data saved succesfully yes to continue',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => salary(x),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'Exit',
                    onClick: () => employee()
                }
            ]
        })
    }
    useEffect(() => {
        Getbank();
    },
        []);

    useEffect(() => {
        Getcompanyaddress();
        Getvbanklist();
    }, [state]);

    const [bank_details, setUserDetails] = useState([]);
    const { update, handleSubmit } = useForm();
    const [accno, setaccno] = useState("");
    const [bankno, setbankno] = useState("");

    const onInsert = (empId) => {

        let bnk = {
            emp_id: location?.state?.x?.emp_id,
            bank: bankno,
            accountno: accno,
            createdby: 1

        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_bank`, bnk).then((res) => {
            console.log('user updated', res.data);
            //Update();
            //alert(location?.state?.x)
            employee();
        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValid = bankno && accno 

    const Getbank = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/bank/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [company_list, setCompany_list] = useState([])
    const Getcompanyaddress = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/addempvbank/${location?.state?.x?.emp_id}`).then((res) => {
                console.log(res.data, 'addempbank');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }
    const [vbanklist, setvbanklist] = useState([]);

    const Getvbanklist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vbank`).then((res) => {
                console.log(res.data, 'success');
                setvbanklist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };
    
    return (

        <div className='container-fluid'>

            {/* <a class="nav-link text-primary fw-bold hide_all_pc " aria-current="page" href="#"><img className="hide_all_pc" src="./Images/Cpyinfo.png"></img>Company Information</a>
            <a class="nav-link text-primary fw-bold hide_all_pc text" href="#"><img className="hide_all_pc" src="./Images/Work.png"></img>Work Schedules</a>
            <a class="nav-link fw-bold  text-primary hide_all_pc" href="#"><img className="hide_all_pc" src="./Images/Account.png"></img>Add Employes</a> */}

            <div className='row '>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                </div>

                <div className='col-10 col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 mt-5 tabcl mob_bs'>

                    <div className='row mt-2'>
                        <div className='col-3'></div>
                        
                    </div>
                    <div className='row mt-5 border-bottom scroll-containerbasic'>
                        <div className='col-1  col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'></div>
                        <div className='col-2  mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><img className='' src={`Images/base.svg`} ></img></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-secondary text-tabcolor  " id="nav-home-tab" data-toggle="tab"  role="tab" aria-controls="nav-home" aria-selected="true">Basic Details</a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="me-2 bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" class="bi bi-coin  " viewBox="0 0 16 16">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                </svg></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link text-secondary  text-tabcolor   " id="nav-profile-tab" data-toggle="tab"  role="tab" aria-controls="nav-profile" aria-selected="false">Salary Details </a>

                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-2  col-xxl-2  col-md-2 col-lg-2 col-xl-2'><img className='' src={`Images/personal.svg`} ></img></div>
                                <div className='col-8'><a class="nav-item nav-link  text-secondary   " id="nav-home-tab" data-toggle="tab"  role="tab" aria-controls="nav-home" aria-selected="true">PersonalDetails</a>

                                </div>
                                <div className='col-1 '>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#53B7B7" class="bi bi-check-circle-fill  " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-2 col-xxl-2  col-md-2 col-lg-2 col-xl-1'></div>
                                <div className='col-3 col-xxl-2  col-md-2 col-lg-2 col-xl-2'> <img className='' src={`Images/tax.svg`} ></img></div>
                                <div className='col-4'> <a class="nav-item nav-link text-secondary  text-tabcolor " id="nav-profile-tab" data-toggle="tab"  role="tab" aria-controls="" aria-selected="false">Tax </a>

                                </div>
                                <div className='col-2 me-3'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="bi bi-check-circle-fill me-5 " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid1'>
                            <div className='row'>
                                <div className='col-3'><img className='img-fluid' src={`Images/pay_1img.svg`} ></img></div>
                                <div className='col-5'><a class="nav-item nav-link  text-tabcolor   border-bottom border-primary border-3 " id="nav-profile-tab" data-toggle="tab"  role="tab" aria-controls="" aria-selected="false">Payment  </a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                                <div className='col-2'></div>
                            </div>
                        </div>
                        


                    </div>
                    <div className='row bastextmob'>
                        <div className='col-3 col-xxl-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 '></div>
                        <div className=' col-xxl-5 col-sm-5 col-md-7 col-lg-7 col-xl-5 ms-3 '>


                            <div className='row mt-3'>
                                <div className='col'>
                                   
                                    {company_list.map((b, index) => {
                                        return (<div>
                                            {(() => {
                                                console.log(b.country, 'check');
                                                if (b.country == "Vietnam ") {
                                                    return <div class="form-group">
                                                        <label for="formGroupExampleInput" class="required ms-1">Bank Name</label>
                                                        <select class=" form-select inputcolor text_box_size" Value={bankno} onChange={(e) => setbankno(e.target.value)} aria-label=" select Day">
                                                            <option selected>Bank Name </option>
                                                            {vbanklist.map((x, index) => {
                                                                return (
                                                                    <option value={x.vbankid}>{x.vbankname}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                } else {
                                                    return <div class="form-group">
                                                        <label for="formGroupExampleInput" class="required ms-1">Bank Name</label>
                                                        <select class=" form-select inputcolor text_box_size" Value={bankno} onChange={(e) => setbankno(e.target.value)} aria-label=" select Day">
                                                            <option selected>Bank Name </option>

                                                            <option value="1">Nil</option>

                                                        </select>
                                                    </div>
                                                }
                                            })()}
                                        </div>
                                        );
                                    })}
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col'>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput" class="required ms-1">Acccount Number</label>
                                        <input type="text"  minlength="1" maxlength="40"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}  class=" form-control inputcolor text_box_size  " onPaste={handle_Paste} Value={accno} onChange={(e) => setaccno(e.target.value)} placeholder="Account Number" aria-label="Username" />

                                    </div>
                                </div>

                            </div>

                            <div className='row mt-4'>
                                <div className='col-6 d-grid '>   <button class="btn btn-secondary  text_box_size  " type="button" onClick={alet}>Cancel</button>
                                </div>
                                <div className='col-6 d-grid'>    <form onSubmit={handleSubmit(onInsert)} className="d-grid" action="#" method="POST">
                                    <button class="btn btn-pri  text_box_size" type="submit" disabled={!isValid}>Save</button>
                                </form>
                                </div>

                            </div>

                        </div>
                        <div className='row  mt-3'>
                            <div className='col-4'></div>

                            <div className='col-4'>

                                <img className="   img-fluid hide_all mobsimplytext hide_tab" src="./Images/tax_foot.png" /></div>


                        </div>
                    </div>





                </div></div>
            <AEHeader />
        </div>
    )
}
export default AddempPayment 