// import React, { Component } from 'react';
// import { render } from 'react-dom';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import moment from 'moment';

// import 'react-big-calendar/lib/css/react-big-calendar.css';

// const localizer = momentLocalizer(moment);

// class Empcal extends Component {
//   constructor() {
//     super();
//     const now = new Date();
//     const events = [
//       {
//           id: 0,
//           title: 'All Day Event very long title',
//           allDay: true,
//           start: new Date(2015, 3, 0),
//           end: new Date(2015, 3, 1),
//       },
//       {
//           id: 1,
//           title: 'Long Event',
//           start: new Date(2015, 3, 7),
//           end: new Date(2015, 3, 10),
//       },

//       {
//           id: 2,
//           title: 'DTS STARTS',
//           start: new Date(2016, 2, 13, 0, 0, 0),
//           end: new Date(2016, 2, 20, 0, 0, 0),
//       },

//       {
//           id: 3,
//           title: 'DTS ENDS',
//           start: new Date(2016, 10, 6, 0, 0, 0),
//           end: new Date(2016, 10, 13, 0, 0, 0),
//       },

//       {
//           id: 4,
//           title: 'Some Event',
//           start: new Date(2015, 3, 9, 0, 0, 0),
//           end: new Date(2015, 3, 10, 0, 0, 0),
//       },
//       {
//           id: 5,
//           title: 'Conference',
//           start: new Date(2015, 3, 11),
//           end: new Date(2015, 3, 13),
//           desc: 'Big conference for important people',
//       },
//       {
//           id: 6,
//           title: 'Meeting',
//           start: new Date(2015, 3, 12, 10, 30, 0, 0),
//           end: new Date(2015, 3, 12, 12, 30, 0, 0),
//           desc: 'Pre-meeting meeting, to prepare for the meeting',
//       },
//       {
//           id: 7,
//           title: 'Lunch',
//           start: new Date(2015, 3, 12, 12, 0, 0, 0),
//           end: new Date(2015, 3, 12, 13, 0, 0, 0),
//           desc: 'Power lunch',
//       },
//       {
//           id: 8,
//           title: 'Meeting',
//           start: new Date(2015, 3, 12, 14, 0, 0, 0),
//           end: new Date(2015, 3, 12, 15, 0, 0, 0),
//       },
//       {
//           id: 9,
//           title: 'Happy Hour',
//           start: new Date(2015, 3, 12, 17, 0, 0, 0),
//           end: new Date(2015, 3, 12, 17, 30, 0, 0),
//           desc: 'Most important meal of the day',
//       },
//       {
//           id: 10,
//           title: 'Dinner',
//           start: new Date(2015, 3, 12, 20, 0, 0, 0),
//           end: new Date(2015, 3, 12, 21, 0, 0, 0),
//       },
//       {
//           id: 11,
//           title: 'Birthday Party',
//           start: new Date(2015, 3, 13, 7, 0, 0),
//           end: new Date(2015, 3, 13, 10, 30, 0),
//       },
//       {
//           id: 12,
//           title: 'Late Night Event',
//           start: new Date(2015, 3, 17, 19, 30, 0),
//           end: new Date(2015, 3, 18, 2, 0, 0),
//       },
//       {
//           id: 12.5,
//           title: 'Late Same Night Event',
//           start: new Date(2015, 3, 17, 19, 30, 0),
//           end: new Date(2015, 3, 17, 23, 30, 0),
//       },
//       {
//           id: 13,
//           title: 'P',
//           start: new Date(2022, 9, 1, 9, 30, 0),
//           end: new Date(2022, 9, 10, 5, 0, 0),
//       },
//       {
//         id: 14,
//         title: 'CL',
//         start: new Date(2022, 9, 11, 9, 30, 0),
//         end: new Date(2022, 9, 11, 5, 0, 0),
//     },
//     {
//         id: 15,
//         title: 'P',
//         start: new Date(2022, 9, 12, 9, 30, 0),
//         end: new Date(2022, 9, 14, 5, 0, 0),
//     },


//     ]
//     this.state = {
//       name: 'React',
//       events
//     };
//   }

//   render() {
//     return (
//       <div>

//         <div class="center_cal mt-5" style={{ height: '400pt', width:'400pt' ,alignItems:'center'}}>
//           <Calendar
//             events={this.state.events}
//             startAccessor="start"
//             endAccessor="end"
//             defaultDate={moment().toDate()}
//             localizer={localizer}
//           />
//         </div>
//       </div>
//     );
//   }
// }

// export default Empcal


import React, { useState } from 'react';

function Empcal() {
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [inputOtp, setInputOtp] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const generateOtp = () => {
    setGeneratedOtp(Math.floor(100000 + Math.random() * 900000).toString());
  };

  const handleChange = (event) => {
    setInputOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputOtp !== generatedOtp) {
      setError('OTP is incorrect');
      setIsValid(false);
    } else {
      setError('');
      setIsValid(true);
    }
  };

  return (
    <div>
      <button onClick={generateOtp}>Generate OTP</button>
      <p>Generated OTP: {generatedOtp}</p>
      <form onSubmit={handleSubmit}>
        <input type="text" value={inputOtp} onChange={handleChange} />
        {error && <p>{error}</p>}
        <button type="submit">Submit</button>
        {isValid && <p>OTP is valid</p>}
      </form>
    </div>
  );
}

export default Empcal;
