import axios from 'axios';

const resInterceptor = () => {
  axios.interceptors.response.use((res) => {
    if (!res?.config?.url.includes('login') && !res?.config?.url.includes('refreshToken') && res.status === 200) {
      refreshToken();
    }
    return res
  }, (err) => {
    if(err?.response?.status === 401) {
      window.location = '/login';
    }
  })
}

function refreshToken() {
  const tokenObj = JSON.parse(localStorage.getItem('user_details'));
  const refreshToken = tokenObj ? tokenObj[0]?.refreshToken : undefined;

  const tokenObjPayload = { refreshToken };

  axios.post(`${process.env.REACT_APP_API_URL}/refreshToken`, tokenObjPayload).then((res) => {
    tokenObj[0]['accessToken'] = res?.accessToken;
  });
}

export default resInterceptor;
