import logo from './logo.svg';
import Layout from './Components/Layout';
import './App.css';
// import "./Login/Login.css"
import { useEffect, useContext, useState } from 'react'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import EmpLeave from './Components/EmployeeList/Empleave';
import Login from './Components/Login';
import Signup from './Components/Signup/Signup';
import Header from './Components/Header/Header';
import Workshedule from './Components/Workshedule/Workshedule';
import Companyaccount from './Components/Company_account/Companyaccount';
import Addcompany from './Components/Company_account/Addcompany';
import Worksheduleupdate from './Components/Workshedule/Worksheduleupdate';
import Sidebar from './Components/Sidebar/Sidebar'
import EmployeeSidebar from './Components/EmployeeSidebar/EmployeeSidebar'
import EmailVerification from './Components/EmailVerification/EmailVerification';
import CompanyAddress from './Components/CompanyAddress/CompanyAddress';
import CompanyTax from './Components/CompanyTax/CompanyTax';
import BaseProfile from './Components/BaseProfile/BaseProfile';
import PersonalDetails from './Components/PersonalDetails/PersonalDetails';
import Lheader from './Components/Lheader/Lheader';
import Todotask from './Components/Todotask/Todotask';
import EmployeeList from './Components/EmployeeList/EmployeeList';
import CompanyList from './Components/CompanyList/CompanyList';
import CompanyDetails from './Components/CompanyDetails/CompanyDetails';
import LeaveManagement from './Components/LeaveManagement/LeaveManagement';
import LandingPage from './Components/LandingPage';
import TimeSheet from './Components/TimeSheet/TimeSheet';
import CreateRoles from './Components/CreateRoles/CreateRoles';
import Dashboard_admin from './Components/Company_dashboard/Dashboard';
import Addtask from './Components/Company_dashboard/Taskstatus';
import Emp_Profileedit_admin from './Components/Employe_profileedit/Emp_profile_edit';
import Dashboard_Employee from './Components/Employee/Employeedashboard';
import YourWork from './Components/Employee_work/Your_work';
import Tax from './Components/tax/tax';
import AddempPayment from './Components/tax/Payments';
import PayrollList from './Components/Payroll/Payrolllist';
import ApplyLeave from './Components/ApplyLeave/ApplyLeave';
import LeaveHistory from './Components/Leave History/LeaveHistory';
import HolidayCalendar from './Components/HolidayCalendar/HolidayCalendar';
import Attantance from './Components/Attantance/Attantance';
import Mobside from './Components/Sidebar/Mobside'
import Newpayroll from './Components/Payroll/Newpayroll';
import Leavedetails from './Components/LeaveManagement/Leavedetails';
import Report from './Components/Payroll/Report';
import Bank from './Components/Master/AddBank';
import Language from './Components/Master/Language';
import AddNationality from './Components/Master/AddNationality';
import Jobtitle from './Components/Master/JobTitle';
import MasterTableTax from './Components/Master/MasterTableTax';
import Adddepartment from './Components/Master/AddDepartment';
import Masterpage from './Components/Master/Masterpage';
import Salary from './Components/Master/Salary';
import MasterHolidays from './Components/Master/MasterHolidays';
import Shift from './Components/Master/Shift';
import Financial from './Components/Master/Addfinancial';
import Weekdays from './Components/Master/Addweekdays';
import PayroleCycle from './Components/Payroll/PayroleCycle';
import SalaryDetails from './Components/PersonalDetails/SalaryDetails';
import Emppayroll from './Components/Payroll/Emp_payroll';
import Timesheetlist from './Components/Timesheetapproval/Timesheetlist';
import Currency from './Components/Master/Currency';
import Timesheetapproved from './Components/Timesheetapproval/Timesheetapproval';
import EHeader from './Components/Header/EHeader';
import AEHeader from './Components/Header/AEHeader';
import Loadingscreen from './Components/Loadingscreen';
import Overtimerate from './Components/Master/Overtimerate';
import Insurance from './Components/Master/insurance';
import Usertype from './Components/Master/Usertype';
import Otpverify from './Components/Login/Verifyacc';
import Dashboard_suerpvisor from './Components/Company_dashboard/Supervisordash';
import ReportsDashboard from './Components/Reports/ReportsDashboard';
import Incometax from './Components/Reports/Incometax';
import Remainder from './Components/Company_dashboard/Reminder';
import Insurancedetails from './Components/Reports/Insurancedetails';
import Payrollcost from './Components/Reports/Payrollcost';
import {

  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  useNavigate,
  Navigate
} from "react-router-dom";

import Leavetype from './Components/Master/Leave_type';
import Empacc from './Components/EmployeeList/Employeeaccount';
import Password from './Components/Password';
import Empcal from './Components/EmployeeList/empcal';
import Forpassword from './Components/Login/forgetpassword';
import Laboutcontract from './Components/Master/laboutcontract';
import GeoFencingClockIn from './Components/TimeSheet/timgeo';
import Ip from './Components/Master/Ip';
import Adminprofile from './Components/Employe_profileedit/Adminprofile';
import Superdashboard from './Components/Superadmin/Superadmindashboard'
import EmpleaveHistory from './Components/EmployeeList/emp_leavehistory';
import Superlead from './Components/Superadmin/Superadminleads';
import Leaddetails from './Components/Superadmin/Leaddetails';
import Companylocation from './Components/Master/Companylocation';
import Superadmincustomers from './Components/Superadmin/Superadmincustomers';
import Superheader from './Components/Superadmin/Superheader';
import Superdemo from './Components/Superadmin/Superdemo';
import Superside from './Components/Superadmin/Superside';
import Superadmindues from './Components/Superadmin/Superadmindues';
import Superlogin from './Components/Superadmin/Superadminlogin';
import Superadminusers from './Components/Superadmin/Adminadd';
import Logino from './Components/Superadmin/Lg';
import Adminpass from './Components/Superadmin/Adminpassword';
import SuperPayments from './Components/Superadmin/Payments';
import Minimumwage from './Components/Master/Minimumwage';
import Worklocation from './Components/Workshedule/Worklocation';
import Sverify from './Components/Superadmin/Superverify';
import Guidecm from './Components/Quickguide/Guidecomp';
import MyTeams from './Components/EmployeeList/MyTeams';
import Billing from './Components/Reports/Billing';
import Loginact from './Components/Superadmin/Loginactivity';
import Payrolltakehome from './Components/Reports/Reporttakehome';
import Reportemployee from './Components/Reports/Reportemp';
import Passwordchange from './Components/EmployeeList/Passworddefault';
import Allocation from './Components/Master/Leaveallocation';
import Empquick from './Components/Quickguide/Empquick';
import Warning from './Components/Company_dashboard/Warning';
import Reportencash from './Components/Reports/Encashreport';
function App() {

  let loggedInUser;
  // let isAdmin = true;
  // let isHR;
  // let isSupervisor;

  useEffect(() => {
    // loggedInUser = JSON.parse(localStorage.getItem('user_details'));
    // console.log('loggedInUser', loggedInUser);
    // if (!(!loggedInUser || loggedInUser == null || loggedInUser == '')) {
    //   if(loggedInUser[0]['admin'] == true || loggedInUser[0]['admin'] == 'true') {
    //     isAdmin = true;
    //   } else {
    //     isAdmin = false;
    //   }
    // }
  }, []);

  const isAllowed = (inputType) => {
    let isAdmin = false;
    let isHR = false;
    let isSupervisor = false;
    let superadmin = false;
    loggedInUser = JSON.parse(localStorage.getItem('user_details'));

    if (!(!loggedInUser || loggedInUser == null || loggedInUser == '')) {
      if (loggedInUser[0]['admin'] && (loggedInUser[0]['admin'] == true || loggedInUser[0]['admin'] == 'true')) {
        isAdmin = true
      } else {
        if (loggedInUser[0]['usertypename'] && loggedInUser[0]['usertypename'] == 'Admin') {
          isAdmin = true;
        }
        if (loggedInUser[0]['usertypename'] && loggedInUser[0]['usertypename'] == 'Supervisor') {
          isSupervisor = true;
        } else if (loggedInUser[0]['usertypename'] && loggedInUser[0]['usertypename'] == 'HR Manager') {
          isHR = true;
        }else if (loggedInUser[0]['admin_id'] && loggedInUser[0]['admin_id'] != null) {
          superadmin = true;
        }

      }
    } else {
      return false
    }

    if(inputType.indexOf('emp') != -1) {
      return true
    }

    if(inputType.indexOf('admin') != -1 && inputType.indexOf('hr') != -1 && inputType.indexOf('supervisor') != -1 &&inputType.indexOf('super') == -1) {
      return isAdmin || isHR || isSupervisor
    } else if (inputType.indexOf('admin') != -1 && inputType.indexOf('hr') != -1 && inputType.indexOf('supervisor') == -1 &&inputType.indexOf('super') == -1) {
      return isAdmin || isHR
    } else if (inputType.indexOf('admin') != -1 && inputType.indexOf('hr') == -1 && inputType.indexOf('supervisor') != -1 &&inputType.indexOf('super') == -1) {
      return isAdmin || isSupervisor
    } else if (inputType.indexOf('admin') == -1 && inputType.indexOf('hr') != -1 && inputType.indexOf('supervisor') != -1 &&inputType.indexOf('super') == -1) {
      return isHR || isSupervisor
    } else if (inputType.indexOf('admin') != -1 && inputType.indexOf('hr') == -1 && inputType.indexOf('supervisor') == -1 &&inputType.indexOf('super') == -1) {
      return isAdmin
    } else if (inputType.indexOf('admin') == -1 && inputType.indexOf('hr') != -1 && inputType.indexOf('supervisor') == -1 &&inputType.indexOf('super') == -1) {
      return isHR
    } else if (inputType.indexOf('admin') == -1 && inputType.indexOf('hr') == -1 && inputType.indexOf('supervisor') != -1 &&inputType.indexOf('super') == -1) {
      return isSupervisor
    }
      else if (inputType.indexOf('admin') == -1 && inputType.indexOf('hr') == -1 && inputType.indexOf('supervisor') == -1 && inputType.indexOf('super') != 1) {
        return superadmin
    } else {
      return false
    }
  }

  let routes = useRoutes([
    { path: "/", element: < LandingPage /> },
    // { path: "/landingpage", element: <LandingPage />  },
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/emailverification", element: <EmailVerification /> },
    { path: "/companyaddress", element: <CompanyAddress /> },
    { path: "/companytax", element: <CompanyTax /> },
    { path: "/baseprofile", element: isAllowed(['admin', 'hr']) ? <BaseProfile /> : <Navigate to='/login' />},
    { path: "/personaldetails", element: isAllowed(['admin', 'hr']) ? <PersonalDetails /> : <Navigate to='/login' />},
    { path: "/todotask", element: isAllowed(['admin', 'hr', 'supervisor']) ? <Todotask />  : <Navigate to='/login' />},
    { path: "/employeelist", element: isAllowed(['admin', 'hr']) ? <EmployeeList /> : <Navigate to='/login' /> },
    { path: "/companylist", element: isAllowed(['admin']) ? <CompanyList /> : <Navigate to='/login' />},
    { path: "/companydetails", element: isAllowed(['admin']) ? <CompanyDetails /> : <Navigate to='/login' />},
    { path: "/leave", element: isAllowed(['admin', 'hr', 'supervisor']) ? <LeaveManagement /> : <Navigate to='/login' /> },
    { path: "/timesheet", element: isAllowed(['emp']) ? <TimeSheet /> : <Navigate to='/login' />},
    { path: "/createroles", element: <CreateRoles /> },
    { path: "/EmpProfileedit", element: isAllowed(['emp']) ? <Emp_Profileedit_admin /> : <Navigate to='/login' />},
    { path: "/admindashboard", element: isAllowed(['admin', 'hr']) ? <Dashboard_admin /> : <Navigate to='/login' />},
    { path: "/companyaccount", element: <Companyaccount /> },
    { path: "/employeedashboard", element: isAllowed(['emp']) ? <Dashboard_Employee /> : <Navigate to='/login' />},
    { path: "/workshedule", element: <Workshedule /> },
    { path: "/worksheduleupdate", element: <Worksheduleupdate /> },
    { path: "/tax", element: isAllowed(['admin', 'hr']) ? <Tax /> : <Navigate to='/login' />},
    { path: "/addtask", element: isAllowed(['admin', 'hr', 'supervisor']) ? <Addtask />: <Navigate to='/login' /> },
    { path: "/addemppayment", element: isAllowed(['admin', 'hr']) ? <AddempPayment /> : <Navigate to='/login' />},
    { path: "/addcompany", element: isAllowed(['admin']) ? <Addcompany /> : <Navigate to='/login' />
  },
    { path: "/yourwork", element: isAllowed(['emp']) ?<YourWork /> : <Navigate to='/login' />},
    { path: "/superleads", element: <Superlead />},
    { path: "/attantance", element: isAllowed(['emp']) ?<Attantance /> : <Navigate to='/login' />},
    { path: "/applyleave", element: isAllowed(['emp']) ?<ApplyLeave /> : <Navigate to='/login' />},
    { path: "/leavehistory", element: isAllowed(['emp']) ?<LeaveHistory /> : <Navigate to='/login' />},
    { path: "/holidaycalendar", element: isAllowed(['emp']) ?<HolidayCalendar /> : <Navigate to='/login' />},
    { path: "/employeesidebar", element: isAllowed(['emp']) ?<EmployeeSidebar /> : <Navigate to='/login' />},
    { path: "/payrolllist", element: isAllowed(['admin', 'hr']) ? <PayrollList /> : <Navigate to='/login' />},
    { path: "/newpayroll", element: isAllowed(['admin', 'hr']) ? <Newpayroll /> : <Navigate to='/login' />},
    { path: "/report", element: <Report /> },
    { path: "/Leavedetails", element: <Leavedetails /> },
    { path: "/masterbank", element: isAllowed(['admin']) ? < Bank /> : <Navigate to='/login' />},
    { path: "/addlanguage", element: < Language /> },
    { path: "/addjobtitle", element: isAllowed(['admin']) ? < Jobtitle /> : <Navigate to='/login' />},
    { path: "/addnationality", element: < AddNationality /> },
    { path: "/adddepartment", element: isAllowed(['admin']) ? < Adddepartment /> : <Navigate to='/login' />},
    { path: "/tabletax", element: < MasterTableTax /> },
    { path: "/masterpage", element: < Masterpage /> },
    { path: "/addsalary", element: isAllowed(['admin']) ? < Salary /> : <Navigate to='/login' />},
    { path: "/addholidays", element: isAllowed(['admin']) ? < MasterHolidays /> : <Navigate to='/login' />},
    { path: "/addshift", element: < Shift /> },
    { path: "/financial", element: isAllowed(['admin']) ? < Financial /> : <Navigate to='/login' />},
    { path: "/weekdays", element: isAllowed(['admin']) ? < Weekdays /> : <Navigate to='/login' />},
    { path: "/insurance", element: isAllowed(['admin']) ? < Insurance />  : <Navigate to='/login' />},
    { path: "/currency", element: isAllowed(['admin']) ? < Currency /> : <Navigate to='/login' />},
    { path: "/payrolecycle", element: isAllowed(['admin', 'hr']) ? < PayroleCycle /> : <Navigate to='/login' />},
    { path: "/salarydetails", element: isAllowed(['admin', 'hr']) ? < SalaryDetails /> : <Navigate to='/login' />},
    { path: "/emppay", element: < Emppayroll /> },
    { path: "/timesheetlist", element: isAllowed(['admin', 'hr', 'supervisor']) ? < Timesheetlist /> : <Navigate to='/login' />},
    { path: "/timesheetapprove", element: isAllowed(['admin', 'hr', 'supervisor']) ? <  Timesheetapproved />: <Navigate to='/login' /> },
    { path: "/leavetype", element: isAllowed(['admin']) ? <  Leavetype /> : <Navigate to='/login' />},
    { path: "/emppayslip", element: isAllowed(['emp']) ? <  EmpLeave /> : <Navigate to='/login' />},
    { path: "/adminprofile", element: <  Adminprofile /> },
    { path: "/addminimumwage", element: isAllowed(['admin']) ? <Minimumwage  /> : <Navigate to='/login' />},
    { path: "/worklocation", element: isAllowed(['admin']) ? <Worklocation  /> : <Navigate to='/login' />},

    { path: "/employeequide", element: <  Empquick /> },
    { path: "/account", element: <  Empacc /> },
    { path: "/overtimerate", element: isAllowed(['admin']) ? <Overtimerate />  : <Navigate to='/login' />},
    { path: "/passwordreset", element: <  Password /> },
    { path: "/empcal", element: <  Empcal /> },
    { path: "/forgetpassword", element: <  Forpassword /> },
    { path: "/labourtype", element: <  Laboutcontract /> },
    { path: "/geo", element: <  GeoFencingClockIn /> },
    { path: "/usertype", element: < Usertype /> },
    { path: "/ip", element: isAllowed(['admin']) ? < Ip /> : <Navigate to='/login' />},
    { path: "/Superadmindashboard", element: isAllowed(['super']) ? <Superdashboard /> : <Navigate to='/login' />},
    { path: "/empleavehistory", element: isAllowed(['admin', 'hr', 'supervisor']) ? < EmpleaveHistory />: <Navigate to='/login' /> },
    { path: "/leaddetails", element: <Leaddetails /> },
    { path: "/Companylocation", element: isAllowed(['admin']) ? <Companylocation /> : <Navigate to='/login' />},
    { path: "/superadmincustomers", element:isAllowed(['super']) ? <Superadmincustomers /> : <Navigate to='/login' />},
    { path: "/superdemo", element:isAllowed(['super']) ? <Superdemo /> : <Navigate to='/login' />},
    { path: "/superadmindues", element: isAllowed(['super']) ? <Superadmindues /> : <Navigate to='/login' />},
    { path: "/superadminlogin",element: isAllowed(['admin', 'hr', 'supervisor']) ? <Navigate to='/login' /> : <  Superlogin />   },
    { path: "/superadminusers", element:isAllowed(['super']) ? <Superadminusers /> : <Navigate to='/login' />},
    { path: "/guidecm", element: < Logino /> },
    { path: "/adpassword", element: isAllowed(['super']) ? <Adminpass /> : <Navigate to='/login' />},

    { path: "/payments", element:isAllowed(['super']) ? <SuperPayments /> : <Navigate to='/login' />},
    { path: "/otpvalidation", element: <Otpverify/> },
    { path: "/otpsuper", element: <Sverify/> },
      { path: "/supervisordashboard", element:isAllowed(['supervisor']) ? <Dashboard_suerpvisor /> : <Navigate to='/login' />},
      { path: "/quickguide", element:   isAllowed(['admin']) ?  <  Guidecm /> : <Navigate to='/login' />},
    { path: "/reportsdashboard", element: isAllowed(['admin', 'hr']) ? <ReportsDashboard /> : <Navigate to='/login' /> }, 
    { path: "/incometax", element: isAllowed(['admin', 'hr']) ? <Incometax /> : <Navigate to='/login' /> },
    { path: "/insurancedetails", element: isAllowed(['admin', 'hr']) ? <Insurancedetails /> : <Navigate to='/login' /> },
    { path: "/payrollcost", element: isAllowed(['admin', 'hr']) ? <Payrollcost /> : <Navigate to='/login' /> },  
    { path: "/myteams", element: isAllowed(['admin', 'hr', 'supervisor']) ? <MyTeams />  : <Navigate to='/login' />},
    { path: "/reminder", element: isAllowed(['admin', 'hr']) ? <Remainder /> : <Navigate to='/login' />},
    { path: "/allocation", element: isAllowed(['admin', 'hr']) ? <Allocation /> : <Navigate to='/login' />},
    { path: "/billing", element:   isAllowed(['admin']) ?  <  Billing /> : <Navigate to='/login' />},
    { path: "/payrolltake", element:   isAllowed(['admin', 'hr']) ?  <  Payrolltakehome /> : <Navigate to='/login' />},
    { path: "/loginact", element:isAllowed(['super']) ? <Loginact /> : <Navigate to='/login' />},
    { path: "/employeereport", element:   isAllowed(['admin', 'hr']) ?  <  Reportemployee /> : <Navigate to='/login' />},
    { path: "/reportencash", element:   isAllowed(['admin', 'hr']) ?  <  Reportencash /> : <Navigate to='/login' />},
    
    { path: "/warning", element: isAllowed(['admin', 'hr']) ? <Warning /> : <Navigate to='/login' />},
    { path: "/password", element: isAllowed(['admin', 'hr', 'supervisor','emp'])?  <  Passwordchange /> : <Navigate to='/login' />},
    
  ]);
  return routes;

  // <div className="App">
  //   <Header/> 
  //   {/* <Lheader /> */}
  //   {/* <Sidebar/> */}
  //   {/* <EmployeeSidebar /> */}
  //   {/* <EmailVerification /> */}
  //    {/*<CompanyAddress/>   */}
  //   {/*<CompanyTax /> */}
  //   {/*<BaseProfile />  */}
  //    {/* <PersonalDetails /> */}
  //    {/* <Todotask /> */}
  //    {/* <EmployeeList /> */}
  //    {/* <CompanyList /> */}
  //    {/* <CompanyDetails /> */}
  //    {/* <LeaveManagement /> */}
  //    {/* <LandingPage /> */}
  //    {/* <TimeSheet /> */}
  //    <CreateRoles />
  //    {/* <Emp_Profileedit_admin/>  */}
  //    {/* <Dashboard_admin/> */}
  //    {/* <Companyaccount/> */}
  //    {/* <Dashboard_Employee/> */}
  //    {/* <Companyaccount/> */}
  //     {/* <Workshedule/>  */}
  //        {/* <Worksheduleupdate/>   */}
  //   {/* <Tax/> */}
  //    {/* <Addtask/> */}
  //      {/* <AddempPayment/>     */}
  //    {/* <Addcompany/> */}
  //   {/* <YourWork/> */}
  // </div>

}

const AppWrapper = () => {
  const location = window.location.pathname;
  //location = location.substring(1);
  return (
    <Router>
      <Layout page={location.substring(1)}>
        <App />
      </Layout>
      {/* <App /> */}
    </Router >
  );
};

export default AppWrapper;

// import GuardedRoute from './Components/GuardedRouter';
// function App() {
//   const[isAutheticated, setisAutheticated] = useState(false);

//   function login(){
//     setisAutheticated(true);
//     console.log("loggedInUser:" + isAutheticated)
//   }

//   function logout(){
//     setisAutheticated(false);
//     console.log("loggedInUser:" + isAutheticated)
//   }

//   return ( 
//     <Router>
      
        
//       <Switch>
//         <Route exact path='/' component={LandingPage}/>
//         <GuardedRoute path='/admindashboard' component={Dashboard_admin} auth={isAutheticated} />
//         <Route path='/login' component={Login} />

//       </Switch>
//     </Router>

//   );
// }

// export default App;