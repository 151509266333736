import { React, useState, useEffect, useContext } from "react";
import Superheader from "./Superheader";
import Superside from "./Superside";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import Leaddetails from "./Leaddetails";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
const Loginact = () => {

    const [demolist, setdemolist] = useState([])
    const [query_date, SetdateQuery] = useState([]);
    const [endDate, SetEndDate] = useState("");
  
    
    const [adm, setad] = useState(0);
    const Getloginactivity = () => {
       
    let bas = {
        company_id: department,
        admin_id: adm,
        logindate: query_date,
        enddate: endDate,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/login_activity`, bas).then((res) => {
        console.log(res.data, 'admin');
        setdemolist(res.data);

    }).catch(() => {

    })

   

    }

    const Getlogin_activity = () => {
        console.log(endDate, 'dt');
    let bas = {
        company_id: department,
        emp_id: employee,
        logindate: query_date,
        enddate: endDate,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/loginactivity`, bas).then((res) => {
        console.log(res.data, 'emp');
        setdemolist(res.data);

    }).catch(() => {

    })

   

    }

    const [department_details, setdepartment_details] = useState([]);
    const Getcompany = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/companyall`).then((res) => {
            console.log(res.data, 'success');

            setdepartment_details(res.data);
        }).catch((error) => {
            console.log(error, 'success');
        });

    }

    const [empdetails, setempdetails] = useState([]);
    const Getemp = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/Employeegett_basic/${x}`).then((res) => {
            console.log(res.data, 'success');

            setempdetails(res.data);
        }).catch((error) => {
            console.log(error, 'success');
        });

    }
    const [admdetails, setadmdetails] = useState([]);

    const Getadmin = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/users_admin/${x}`).then((res) => {
            console.log(res.data, 'success');

            setadmdetails(res.data);
        }).catch((error) => {
            console.log(error, 'success');
        });

    }
    useEffect(() => {
        Getcompany();
    }, []);



    let navigate = useNavigate();
    const lead = () => {
        let path = `/superleads`;
        navigate(path);
    }
    const [department, Setdept] = useState("");
    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const [employee, setempno] = useState(0);
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setUserType(type);


    }
    const handleDepartmentChange = (event) => {
        const selectedcomp = event.target.value;

        Setdept(selectedcomp);
        Getemp(selectedcomp);
        Getadmin(selectedcomp);
    };

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = demolist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(demolist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const isValid = department && query_date && endDate && (employee || adm)

    return (
        <>
            <div className="bg-light vh-100 row  container-fluid">




                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">

                </div>

                <div className="col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10  ">

                  


                    <div className="row mt-3">
                        <div className="col-1 ">

                        </div>
                        <div className="col-3 ">
                            <p className=" mt-3 fw-bold fnt-clr" >Login Activity Search</p>
                        </div>


                        <div className="col-3 ">

                        </div>
                        <div className="col-2">

                        </div>
                    </div>
                    <div className="row mt-3">
<div className="col-1"></div>
                     

                        <div className="col-2 ">
                            <select
                                className="form-select inputcolor text-start text_box_size  mt-4" style={{fontSize:"13px"}}
                                value={department} onChange={handleDepartmentChange}
                            ><option value="">Select Company</option>
                                {department_details.map((x) =>
                                (<option value={x.company_id}>
                                    {x.company_name}</option>
                                ))}

                            </select>
                        </div>
                        <div className="col-2 ">
                            {(() => {
                                if (adm == 0) {
                                    return <select
                                      className="form-select inputcolor text-start  text_box_size mt-4" style={{fontSize:"13px"}}
                                      value={employee}
                                      onChange={(e) => setempno(e.target.value)}
                                    >
                                      <option value='0'>Select Employee</option>
                                      {empdetails.map((x) => (
                                        <option key={x.emp_id} value={x.emp_id}>{x.full_name}</option>
                                      ))}
                                    </select>
                                    
                                } else {
                                    return <select
                                        className="form-select inputcolor text-start  text_box_size mt-4"style={{fontSize:"13px"}}
                                        disabled

                                    >
                                        <option value="">Select Employee</option>


                                    </select>
                                }
                            })()}




                        </div>
                        <div className="col-2 ">
                            {(() => {
                                if (employee == 0) {
                                    return <select
                                        className="form-select inputcolor text-start text_box_size mt-4" style={{fontSize:"13px"}}
                                        value={adm} onChange={(e) => setad(e.target.value)}

                                    >
                                        <option value='0'>Select Admin</option>

                                        {admdetails.map((x) => (
                                            <option className="" value={x.user_id}  >
                                                {x.first_name}
                                            </option>
                                        ))}
                                    </select>
                                } else {
                                    return <select
                                        className="form-select inputcolor text-start  text_box_size mt-4" style={{fontSize:"13px"}}
                                        disabled

                                    >
                                        <option value="">Select Admin</option>


                                    </select>
                                }
                            })()}




                        </div>
                        <div className="col-2">
                    <small  className="text-center">Start Date</small>
                            <input type="date" class="form-control inputcolor text_box_size mt-2" style={{fontSize:"13px"}} placeholder="Select start date" aria-label=""onChange={(e) => { console.log(e.target.value); SetdateQuery(e.target.value) }} />

                            </div>
                        <div className="col-2">
                        <small className="text-center">End Date</small>
                    
                        <input 
  type="date" 
  className="form-control inputcolor text_box_size mt-2" style={{fontSize:"13px"}}
  placeholder="Select end date" 
  aria-label="" 
  onChange={(e) => { console.log(e.target.value ,'end'); SetEndDate(e.target.value) }} 
  min={query_date}
/>
                        </div>
                        
                        
                    </div>
                    <div className="row">
                        <div className="col mt-3">
                        
                        {(() => {
                                if (employee == 0) {
                           return<button className="btn btn-pri mx-auto d-grid " onClick={Getloginactivity}style={{width: "150px"}} disabled={!isValid}>Submit</button>
                        } else {
                            return<button className="btn btn-pri mx-auto d-grid" onClick={Getlogin_activity} style={{width: "150px"}} disabled={!isValid}>Submit</button>


                        }
                    })()}
                            
                        </div>
                    </div>
                    <div className="row ms-1">
                        <div className="card Card_1task mt-4 ms-5  ">
                            <div className="row mt-3">

                                <div className="col-2 d-grid" >

                                </div>
                                <div className="col-6 d-grid" >
                                </div>
                                <div className="col-2 d-grid" >
                                 </div>
                                <div className="col-2 " >
                                    
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col">
                                        <div className='row'>


                                            <div className='col'>

                                                <table class="table table-bordered mt-2">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>  <td scope="col"></td>

                                                                <td scope="col" className='fs-6 text-secondary'>ID</td>

                                                                <td scope="col" className='fs-6 text-secondary mobsimplytext'>Name</td>
                                                                <td scope="col" className='fs-6 text-secondary mobsimplytext'> Email</td>
                                                                <td scope="col" className='fs-6 text-secondary mobsimplytext'> Login date</td>
                                                                <td scope="col" className='fs-6 text-secondary mobsimplytext'>Login Time  </td>
                                                                <td scope="col" className='fs-6 text-secondary mobsimplytext'>Login IP </td>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {displayUsers.map((x, index) => {
                                                                return (
                                                                    <tr>
                                                                        <th scope="row"><div className="ms-3 ">  </div></th>

                                                                        <td className=''><p>{x.employee_id}{x.user_id}</p></td>
                                                                        <td className='pointer_'  > <p >{x.full_name}{x.first_name}</p></td>
                                                                        <td className=''><p>{x.company_name}</p></td>
                                                                        <td className=''><p>{moment(x.logintime).format('MMM Do YYYY')}</p></td>
                                                                        <td className=''><p>{moment(x.logintime).format('h:mm:ss a')}</p></td>
                                                                        <td className=''><p>{x.ip_address}</p></td>


                                                                    </tr>

                                                                );
                                                            })}




                                                        </tbody>

                                                    </table>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"paginationBttns"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"paginationDisabled"}
                                                    activeClassName={"paginationActive"}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                {/* Filter */}
                <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                        <div class="modal-content ">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body ">
                                <p className="text-secondary">All</p>
                                <p className="text-secondary">Pending</p>
                                <p className="text-secondary">Processed</p>
                                <p className="text-secondary">Canceled</p>
                                <div className='row mt-4'>

                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                        <button type="button" class="btn btn-pri">Filter</button>
                                    </div>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div></div>
        </>




    )
}
export default Loginact