import React, { useEffect, useState, useContext } from 'react';
import './BaseProfile.css';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AEHeader from '../Header/AEHeader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import { store } from '../../store/user';
import Select from 'react-select'
import CryptoJS from 'crypto-js';
import moment from 'moment';

const BaseProfile = (props) => {
    const { state } = useContext(store);
    console.log('base', state.user);

    const [password, setPassword] = useState("");

    const generatePassword = () => {
        const randomPassword =
            Math.random().toString(36).slice(2);

        setPassword(randomPassword);
        console.log(randomPassword, "pass");
        // copy the password to the clipboard & fire toast message

        // if (navigator.clipboard.writeText(randomPassword)) {
        //     //   alert("Password copied to your clipboard");
        //     console.log(randomPassword);
        // }
        //   onlogin();
        //   alet();
        //    alert(1000);
    };

    const [nationality_details, setNationalityDetails] = useState([]);

    const [tab_type, setTabType] = useState('Basic_Profile');
    const [startDate, setStartDate] = useState(new Date());

    const TabChange = (type) => {
        console.log('type', tab_type)
        if (type == 'Basic_Profile') {
            console.log('Basic_Profile', type);
            setTabType(type);
        } else {
            console.log('Job_Profile', type);
            setTabType(type);

        }

    }
    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const salarydetails = (x) => {
        let path = `/salarydetails `;
        navigate(path, { state: { x: x } });
    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => employee(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'No',
                    onClick: () => alert('canceled')
                }
            ]
        })
    }
    const allert = (x) => {
        confirmAlert({

            message: 'Data saved succesfully yes to continue to salary details',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => salarydetails(x),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },

            ]
        })
    }
    const { update, handleSubmit } = useForm();
    //  const [fname, setfname] = useState("");
    const [hire, sethire] = useState("");
    const [email, setemail] = useState("");
    const [res, setres] = useState("");
    const [lang, setlang] = useState("");
    const [manag, setmanag] = useState("");
    const [labcont, setlabcont] = useState("");
    const [wagetyp, setwagetyp] = useState("");
    const [definitecontract, setdefinitecontract] = useState(false);
    const [midname, SetMidname] = useState("");
    const [lstname, SetLstname] = useState("");
    const [residental, SetResidental] = useState("");
    const [national, Setnational] = useState("");
    const [jobTitle, SetjbTitle] = useState();
    const [department, Setdept] = useState();
    const [shift, Setshift] = useState("");
    const [usert, SetUserT] = useState("");
    const [work, Setwork] = useState("");
    const [labortype, setlabortype] = useState("");
    const [time, settime] = useState("");
    const [workloc, Setworkloc] = useState("");
    const [loginemail, setloginmail] = useState([]);
    const [wfo, Setwfo] = useState('');
    const [cduedate, SetCDueDate] = useState("");
    const [newJobtitleName, setNewJobtitleName] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const handleJobTitleChange = (event) => {
        const selectedDepartment = event.target.value;
        if (selectedDepartment === '1') {
            // display input field or modal to add new department
        } else {
            SetjbTitle(selectedDepartment);
        }
    };
    const handleNewJobtitleSubmit = (event) => {
        event.preventDefault(); // prevent form submission
        //alert("tst");
        try {
            let jobtitle = {
                job_title: newJobtitleName,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/job_title`, jobtitle).then((res) => {
                console.log('jobtitle added', res.data);
                setNewJobtitleName('');
                setShowMessage(true); // show the message
                setTimeout(() => {
                    setShowMessage(false); // hide the message after 3 seconds
                }, 3000);
            })


        } catch (error) {
            console.error(error);
        }
    };

    const [newDepartmentName, setNewDepartmentName] = useState("");


    const handleDepartmentChange = (event) => {
        const selectedDepartment = event.target.value;
        if (selectedDepartment === '1') {
            // display input field or modal to add new department
        } else {
            Setdept(selectedDepartment);
        }
    };
    const handleNewDepartmentSubmit = (event) => {
        event.preventDefault(); // prevent form submission
        //alert("tst");
        try {


            let cur = {

                departments: newDepartmentName,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id,
            };
            axios.post(`${process.env.REACT_APP_API_URL}/departments`, cur).then((res) => {
                console.log('user updated', res.data);
                setNewDepartmentName('');
                setShowMessage(true); // show the message
                setTimeout(() => {
                    setShowMessage(false); // hide the message after 3 seconds
                }, 3000);
            })
                ;

        } catch (error) {
            console.error(error);
        }
    };
    console.log(department, 'checkdep')

    // email validator
    const Getemaillist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/emailcheck`).then((res) => {

                setloginmail(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    useEffect(() => {
        Getemaillist();
    }, []);
    const checkifValidemail = (inputmail) => {
        let isValid = true;
        loginemail.forEach(item => {
            if (item.email.match(inputmail)) {
                isValid = false;
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (email != "") {
            if (checkifValidemail(email)) {
                try {
                    let basic = {
                        fullname: fname,
                        middlename: midname,
                        lastname: lstname,
                        nationality_id: national.value,
                        hiredate: hire,
                        email: email,
                        emailpayslip: "true",
                        language: lang.value,
                        jobtitle: jobTitle,
                        manager: manag.value,
                        department: department,
                        labourcontract: labcont,
                        shift: shift.value,
                        wagetype: wagetyp.value,
                        createdby: state?.user[0]?.user_id,
                        profileimg: image64,
                        user_id: state?.user[0]?.user_id,
                        residentalstatus: residental.value,
                        company_id: state?.user[0]?.company_id,
                        usertype: usert.value,
                        workschedule: work.value,
                        labourtype: labortype.value,
                        timesheet: time.value,
                        worklocation: workloc.value,
                        active_status: true,
                        wfo: wfo.value,
                        contractduedate: cduedate,
                    };


                    axios.post(`${process.env.REACT_APP_API_URL}/Employee_basic`, basic).then((res) => {
                        console.log('user updated', res.data);

                        onlogin(res?.data);
                        //onInsertaddtime(res?.data);
                        if (res?.data.timesheet === 2) {
                            console.log(res.data.timesheet, "timetest")
                            onInsertaddtime(res?.data);
                        }


                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Email ID already exist , existing email ID cannot be used  ',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style:{
                                backgroundColor:'#F36E35'
                               
                           }
                        },
                    ]
                });

            }
        } else {
            confirmAlert({
                message: 'Please fill all data',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    },
                ]
            });
        }
    }
    const [emplist, setEmpList] = useState("");
    const Getemplist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/Employeegett_basic/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setEmpList(res.data);

            }).catch(() => {

            })
    }



    const sendEmail = (x) => {
        const emailData = {

            user_email: `${email}`,
            user_name: `${fname}`,
            message: `${password}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailemp`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }
    const form = useRef();
    // handle events 
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });

    // handle submit


    // watch events 
    //  const email = watch('email')
    const fname = watch('fname')



    // handle disabled submit
    const isValid = email && fname && lstname && national && hire && residental
    let isValids
    if (definitecontract === false) {
        isValids = usert && wfo && work && time && workloc && labortype;
    } else {
        isValids = usert && wfo && work && time && workloc && labortype && definitecontract && cduedate;
    }




    const [selectedfile, setSelectedfile] = useState([]);

    const [image64, setImage64] = useState(`Images/grycir.svg`);
    const image = (e) => {
        setSelectedfile(e.target.files);
        console.log(e.target.files[0]);
        console.log(e.target.files[0].name);
        console.log(e.target.files[0].size);
        console.log(e.target.files[0].type);
    };
    const ConvertImageToBase64 = (event) => {
        // console.log('event', event.target.files[0]);
        let file = event.target.files[0];
        var reader = new FileReader();
        console.log('file upload');
        let base64;
        reader.readAsDataURL(file);
        reader.onload = () => {
            base64 = reader.result;
            console.log('base64', base64);
            setImage64(base64);
        };
        reader.onerror = (error) => {
            console.log('error :', error);
        };
    };
    const GetNationality = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/nationality`).then((res) => {
                console.log(res.data, 'success');
                let temp = res.data.map(item => {
                    return {
                        label: item.nationality,
                        value: item.nationality_id
                    }
                })
                setNationalityDetails(temp);


            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const onlogin = (x) => {
        let bas = {
            emp_id: x.emp_id,
            loginmail: email,
            password: password,
            admin: 'false',
            company: 'true',
            userid: state?.user[0]?.user_id,
            company_id: state?.user[0]?.company_id,
            active_status: true,
        };


        axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
            console.log('user updated', res.data);

            allert(x);

        }).catch(() => {

        })
    }

    
    const onInsertaddtime = (x) => {
        const currentDate = moment(); // Current date
        let startDate; // Start date for attendance
        const endDate = moment(); // Today's date
        let content;
      
        const workSchedule = {}; // Store the employee's work schedule
      
        // Fetch the employee's work schedule
        axios
          .get(`${process.env.REACT_APP_API_URL}/get_work_schedule/${x.emp_id}`)
          .then((res) => {
            if (res.data.length > 0) {
              const schedule = res.data[0];
              workSchedule.monday = schedule.monday;
              workSchedule.tuesday = schedule.tuesday;
              workSchedule.wednesday = schedule.wednesday;
              workSchedule.thursday = schedule.thursday;
              workSchedule.friday = schedule.friday;
              workSchedule.saturday = schedule.saturday;
              workSchedule.sunday = schedule.sunday;
            }
      
            // Check if hire date is within the current month and timesheet is 2
            if (
              moment(x.hiredate).isSameOrBefore(currentDate.endOf('month')) &&
              moment(x.hiredate).isSameOrAfter(currentDate.startOf('month')) &&
              x.timesheet === 2
            ) {
              startDate = moment(x.hiredate); // Start from the hire date
      
              while (startDate <= endDate) {
                let currentDateFormatted = startDate.format('YYYY-MM-DD');
                let totalhr;
      
                if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                  totalhr = 4; // Half working day
                  content = 'Present';
                } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                  totalhr = 8; // Full working day
                  content = 'Present';
                } else {
                  totalhr = 0; // weekend working day
                  content = 'Weekoff';
                }
      
                let time = {
                  emp_id: x.emp_id,
                  date: currentDateFormatted,
                  totalhr: totalhr,
                  content: content,
                };
      
                axios
                  .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                  .then((res) => {
                    console.log('User attendance added', res.data);
                  })
                  .catch(() => {});
      
                startDate.add(1, 'day');
              }
            } else {
              // Original code for adding attendance from the first day of the current month to the current date
              if (state.user) {
                axios
                  .get(`${process.env.REACT_APP_API_URL}/payrollcycles_att/${state.user[0].company_id}`)
                  .then((res) => {
                    if (res.data.length > 0) {
                      const payrollCycleEndDate = moment(res.data[0].enddate);
                      const endOfMonth = moment().endOf('month');
      
                      if (payrollCycleEndDate.isBefore(endOfMonth)) {
                        startDate = moment.max(moment(payrollCycleEndDate).add(1, 'day'), moment(x.hiredate)); // Start from the maximum date between next day after payroll cycle end date and hire date
                      } else {
                        startDate = moment.max(moment().startOf('month'), moment(x.hiredate)); // Start from the maximum date between first day of the current month and hire date
                      }
                    } else {
                      startDate = moment().startOf('month'); // Start from the first day of the current month
                    }
      
                    while (startDate <= endDate) {
                      let currentDateFormatted = startDate.format('YYYY-MM-DD');
                      let totalhr;
      
                      if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                        totalhr = 4; // Half working day
                        content = 'Present';
                      } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                        totalhr = 8; // Full working day
                        content = 'Present';
                      } else {
                        totalhr = 0; // weekend working day
                        content = 'Weekoff';
                      }
      
                      let time = {
                        emp_id: x.emp_id,
                        date: currentDateFormatted,
                        totalhr: totalhr,
                        content: content,
                      };
      
                      axios
                        .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                        .then((res) => {
                          console.log('User attendance added', res.data);
                        })
                        .catch(() => {});
      
                      startDate.add(1, 'day');
                    }
                  })
                  .catch(() => {});
              } else {
                startDate = moment().startOf('month'); // Start from the first day of the current month
      
                while (startDate <= endDate) {
                  let currentDateFormatted = startDate.format('YYYY-MM-DD');
                  let totalhr;
      
                  if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                    totalhr = 4; // Half working day
                    content = 'Present';
                  } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                    totalhr = 8; // Full working day
                    content = 'Present';
                  } else {
                    totalhr = 0; // weekend working day
                    content = 'Weekoff';
                  }
      
                  let time = {
                    emp_id: x.emp_id,
                    date: currentDateFormatted,
                    totalhr: totalhr,
                    content: content,
                  };
      
                  axios
                    .post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                    .then((res) => {
                      console.log('User attendance added', res.data);
                    })
                    .catch(() => {});
      
                  startDate.add(1, 'day');
                }
              }
            }
          })
          .catch(() => {});
      };
      


    useEffect(() => {
        generatePassword();
        GetNationality();
        Getusertype();
        GetShift();
    }, []);
    useEffect(() => {
        GetNationality();
        //Getjobtitle();
        Getusertype();
        //Getdepartment();
        GetShift();
        Getemplist();
        Getworklist();
        Getloc();
        Getmanagerlist();
    }, [state]);
    useEffect(() => {
        Getdepartment();
    }, [state, newDepartmentName]);
    useEffect(() => {
        Getjobtitle();
    }, [state, newJobtitleName]);

    const [job_title, setJob_title] = useState([])
    const [usertypedetails, setusertypeDetails] = useState([]);
    const [department_details, setdepartment_details] = useState([]);
    const [shift_details, setShiftDetails] = useState([]);
    const Getjobtitle = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/job_title/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');

                setJob_title(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const Getusertype = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/usertype/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                let usertemp = res.data.map(item => {
                    return {
                        label: item.usertype,
                        value: item.usertypeid
                    }
                })
                setusertypeDetails(usertemp);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const Getdepartment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/departments/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');

                setdepartment_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [complocation, setcomploc] = useState([])
    const Getloc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                let workloctemp = res.data.map(item => {
                    return {
                        label: item.worklocation.charAt(0).toUpperCase() + item.worklocation.slice(1),
                        value: item.location_id
                    }
                })
                setcomploc(workloctemp);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const GetShift = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/shift/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                let shifttemp = res.data.map(item => {
                    return {
                        label: item.shift_type,
                        value: item.shift_id
                    }
                })
                setShiftDetails(shifttemp);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [work_list, setWork_list] = useState([])
    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                let workscheduletemp = res.data.map(item => {
                    return {
                        label: item.workschedule_name.charAt(0).toUpperCase() + item.workschedule_name.slice(1),
                        value: item.workschedule_id
                    }
                })
                setWork_list(workscheduletemp);

            }).catch(() => {

            })
    }
    const [managerlist, setManagerlist] = useState([])
    const Getmanagerlist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/employeereportingmanager/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'succ_ess');
                let managertemp = res.data.map(item => {
                    return {
                        label: `${item.manager_name.charAt(0).toUpperCase() + item.manager_name.slice(1)} ${item.manager_l_name.charAt(0).toUpperCase() + item.manager_l_name.slice(1)}`,
                        value: item.emp_id
                    }
                })
                setManagerlist(managertemp);

            }).catch(() => {

            })
    }
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (e) => {
        setemail(e.target.value);

        // Check email format
        const regex = /\S+@\S+\.\S+/;
        if (!regex.test(e.target.value)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    return (
        <div className='bg-light vh-100 container-fluid'>


            <div className='row mt-1'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2 hid_tab'>

                </div>

                <div className='  col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mt-5 tabcl mob_bs'>
                    

                    <div className='row mt-2'>
                        <div className='col-3'></div>

                    </div>
                    <div className='row mt-5 border-bottom scroll-containerbasic'>
                        <div className='col-1  col-xxl-1  col-md-1 col-lg-1 col-xl-1'></div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><img className='' src={`Images/base.svg`} ></img></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-tabcolor border-bottom border-primary border-3 " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">Basic Details</a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="me-2 bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" class="bi bi-coin  " viewBox="0 0 16 16">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                </svg></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-tabcolor  text-secondary  " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">Salary Details </a>

                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-2  col-xxl-2  col-md-2 col-lg-2 col-xl-2'><img className='' src={`Images/personal.svg`} ></img></div>
                                <div className='col-8'><a class="nav-item nav-link  text-secondary   " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">PersonalDetails</a>

                                </div>
                                <div className='col-1 '>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill  " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-2 col-xxl-2  col-md-2 col-lg-2 col-xl-1'></div>
                                <div className='col-3 col-xxl-2  col-md-2 col-lg-2 col-xl-2'> <img className='' src={`Images/tax.svg`} ></img></div>
                                <div className='col-4'> <a class="nav-item nav-link  text-tabcolor text-secondary" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">Tax </a>

                                </div>
                                <div className='col-2 me-3'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill me-5 " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3'><img className='img-fluid' src={`Images/pay_1img.svg`} ></img></div>
                                <div className='col-5'><a class="nav-item nav-link  text-tabcolor   text-secondary " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">Payment  </a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                                <div className='col-2'></div>
                            </div>
                        </div>



                    </div>
                    <nav className='tabalign mobbasictab'>
                        <div class="nav mt-3 mx-5  " id="nav-tab" role="tablist">
                            <a class="nav-item nav-link  text-primary text-tabcolor text-center pointer_" id="nav-home-tab" data-toggle="tab" onClick={() => { TabChange("Basic_Profile") }} role="tab" aria-controls="nav-home" aria-selected="true">Basic Profile</a>
                            <a class="nav-item nav-link text-primary text-tabcolor pointer_" id="nav-profile-tab" data-toggle="tab" role="tab" onClick={() => { TabChange("Job_Profile") }} aria-controls="nav-profile" aria-selected="false">Job Profile</a>
                        </div>
                    </nav>
                    <form onSubmit={handleSubmit(onInsert)} ref={form} action="#" method="POST">
                        {(() => {
                            if (tab_type == "Basic_Profile") {
                                return <div className='row mt-2 bastextmob'>
                                    <div className='col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 '></div>
                                    <div className=' col-xxl-5 col-sm-5 col-md-7 col-lg-7 col-xl-5 ms-3'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <img className='img-fluid ms-4 mt-3' src={image64} ></img>
                                            </div>


                                            <div className='col-9'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">First Name</label>
                                                    <input

                                                        minlength="2" maxlength="40"
                                                        type='text'
                                                        name="user_name"
                                                        placeholder='First Name'
                                                        className={`form-control inputcolor basetext_box mt-1 ${errors.fname &&
                                                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                        {...register("fname", {
                                                            required: {
                                                                value: true,
                                                                message: ' *First Name is required'
                                                            },
                                                            minLength: {
                                                                value: 3,
                                                                message: "Please enter more than 3 characters",
                                                            },

                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    {errors.fname && <span className=' text-danger'>{errors.fname.message}</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class=" ms-1"></label>
                                                    <input type="text" minlength="1" maxlength="40" class="form-control inputcolor  basetext_box" placeholder="Middle Name " Value={midname} onChange={(e) => SetMidname(e.target.value)} id="exampleFormControlInput1" />
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Last Name</label>
                                                    <input type="text" minlength="1" maxlength="40" class="form-control inputcolor  basetext_box" placeholder="Last Name " Value={lstname} onChange={(e) => SetLstname(e.target.value)} id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>

                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Hire Date</label>
                                                    <input type="date" class="form-control inputcolor  basetext_box" placeholder="Hire Date " Value={hire} onChange={(e) => sethire(e.target.value)} id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Nationality</label>
                                                    <Select class="select_react text-start inputcolor basetext_box " value={national} onChange={(e) => Setnational(e)} aria-label=" select Day" placeholder="Nationality"
                                                        options={nationality_details}>


                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Email Id</label>
                                                    <input type="email" minlength="2" maxlength="64" name="user_email" class="form-control inputcolor basetext_box " Value={email} onChange={handleEmailChange} placeholder="Email" />
                                                    {emailError && <span className="text-danger">{emailError}</span>}

                                                </div>
                                                <div>
                                                    {errors.email && <span className=' text-danger basetext_box'>{errors.email.message}</span>}
                                                </div>


                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'>
                                                <label for="formFile" class="form-label mt-2 fw-bold ms-1 basetext_box">Profile Image</label>

                                            </div>
                                            <div className='col-8'>
                                                <input class="form-control inputcolor mt-2 " type="file" id="formFile" onChange={ConvertImageToBase64} />
                                                <span className=' text-danger basetext_box'>*Less than 620*420 pixel</span>
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Tax Resident Status</label>
                                                    <Select class="form-select inputcolor text-start  basetext_box " Value={residental} onChange={(e) => SetResidental(e)} aria-label=" select Day" placeholder="Tax Resident Status"
                                                        options={[
                                                            { value: '1', label: 'Resident' },
                                                            { value: '2', label: 'Non-Resident' },
                                                        ]}
                                                    >



                                                    </Select>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='row mt-2'>
                                            <div className='col'>
                                                <label for="formGroupExampleInput" class="ms-1">Language</label>
                                                <Select class="form-select inputcolor text-start  basetext_box " Value={lang} onChange={(e) => setlang(e)} aria-label=" select Day" placeholder="Language Preferance"
                                                    options={[
                                                        { value: 'English', label: 'English' },
                                                        { value: 'Vietnamese', label: 'Vietnamese' },
                                                    ]}>

                                                </Select>
                                            </div>

                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col d-grid'>

                                                <button class="btn ms-5 me-5 btn-control btn-pri text_box_size " type="button" onClick={() => { TabChange("Job_Profile") }} disabled={!isValid}>Next</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                    </div>


                                </div>

                            } else {
                                return <div className='row mt-1 bastextmob'>
                                    <div className='col-3 col-xxl-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 '></div>
                                    <div className=' col-xxl-5 col-sm-5 col-md-7 col-lg-7 col-xl-5 ms-3 '>


                                        <div className='row mt-3'>

                                            <div className='col-6'><div class="form-group"><label for="formGroupExampleInput" class=" ms-1">Job Title</label>
                                                <select
                                                    className="form-select text-secondary text-start  basetext_box"
                                                    value={jobTitle}
                                                    onChange={handleJobTitleChange}><option value="0">Select Job Title</option>
                                                    {job_title.map((x) =>
                                                    (<option value={x.job_id}>
                                                        {x.job_title}</option>
                                                    ))}
                                                    <option className='text-primary fw-bold' jobTitle>Add New Job Title</option>
                                                </select>
                                            </div>
                                                {jobTitle === "Add New Job Title" && (<form ><div class="form-group"><label for="newJobtitleName" class=" ms-1">New job title name</label>
                                                    <div className='row'>
                                                        <div className='col-10'>
                                                            <input
                                                                type="text"
                                                                className="form-control text-secondary text-start  basetext_box"
                                                                id="newJobtitleName"
                                                                value={newJobtitleName}
                                                                onChange={(event) => setNewJobtitleName(event.target.value)}
                                                            />
                                                        </div>
                                                        <div className='col-1'>
                                                            <button className='btn btn-pri' type="submit" onClick={handleNewJobtitleSubmit}>Add</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                </form>
                                                )}
                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">User Type</label>
                                                    <Select class="form-select inputcolor text-start  basetext_box" Value={usert} onChange={(e) => SetUserT((e))} aria-label=" select Day"
                                                        placeholder="User Type"
                                                        options={[
                                                            { value: '9', label: 'Admin' },
                                                            { value: '3', label: 'Hr Manager' },
                                                            { value: '2', label: 'Supervisor' },
                                                            { value: '10', label: 'Employee' },
                                                        ]}>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="ms-1">Reporting Manager</label>

                                                    <Select class="form-select inputcolor text-start  basetext_box" Value={manag} onChange={(e) => setmanag(e)} aria-label=" select Day"

                                                        placeholder="Reporting Manager"
                                                        options={managerlist}>

                                                    </Select>
                                                </div>
                                            </div>


                                            <div className='col-6'><div class="form-group"><label for="formGroupExampleInput" class=" ms-1">Departments</label>
                                                <select
                                                    className="form-select text-secondary text-start  basetext_box"
                                                    value={department}
                                                    onChange={handleDepartmentChange}><option value="0">Select department</option>
                                                    {department_details.map((x) =>
                                                    (<option value={x.id}>
                                                        {x.departments}</option>
                                                    ))}
                                                    <option className='text-primary fw-bold' department>Add New Department</option>
                                                </select>
                                            </div>
                                                {department === "Add New Department" && (<form ><div class="form-group"><label for="newDepartmentName" class=" ms-1">New department name</label>
                                                    <div className='row'>
                                                        <div className='col-10'>
                                                            <input
                                                                type="text"
                                                                className="form-control text-secondary text-start  basetext_box"
                                                                id="newDepartmentName"
                                                                value={newDepartmentName}
                                                                onChange={(event) => setNewDepartmentName(event.target.value)}
                                                            />
                                                        </div>
                                                        <div className='col-1'>
                                                            <button className='btn btn-pri' type="submit" onClick={handleNewDepartmentSubmit}>Add</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                </form>
                                                )}</div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Labour Type</label>
                                                    <Select class="form-select inputcolor text-start   basetext_box" Value={labortype} onChange={(e) => setlabortype(e)} aria-label=" select Day"
                                                        placeholder="Labour type"
                                                        options={[
                                                            { value: '1', label: 'Vietnamese(Permanent employee)' },
                                                            { value: '2', label: 'Non-Vietnamese/foreigner(Permanent employee)' },
                                                            { value: null, label: 'Contract Employee(Vietnamese/Non-Vietnamese)' },
                                                            { value: -2, label: 'Personal Services Contract' },
                                                        ]}>

                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class=" ms-1"></label>
                                                    <input type="text" minlength="2" maxlength="40" class="form-control inputcolor basetext_box" Value={labcont} onChange={(e) => setlabcont(e.target.value)} placeholder="Labor Contract Number" id="exampleFormControlInput1" />
                                                </div>
                                            </div>


                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-6'>

                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Work Mode</label>
                                                    <Select class="form-select inputcolor text-start  basetext_box" Value={wfo} onChange={(e) => Setwfo((e))} aria-label=" select Day" placeholder="Work Mode" options={[{ value: '1', label: 'Work From Office' }, { value: '0', label: 'Work From Home' },]}> </Select>
                                                </div>

                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Time Sheet</label>
                                                    <Select class="form-select inputcolor text-start   basetext_box " Value={time} onChange={(e) => settime(e)} aria-label=" select Day"
                                                        placeholder="TimeSheet"
                                                        options={[
                                                            { value: '1', label: 'TimeSheet Required' },
                                                            { value: '2', label: 'TimeSheet Not-Required' },


                                                        ]}>

                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Workschedule</label>
                                                    <Select class="form-select inputcolor text-start  basetext_box " Value={work} onChange={(e) => Setwork(e)} aria-label=" select Day"
                                                        placeholder="Workschedule"
                                                        options={work_list}>

                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-1">Work Location</label>
                                                    <Select class="form-select inputcolor text-start   basetext_box" Value={workloc} onChange={(e) => Setworkloc(e)} aria-label=" select Day"
                                                        placeholder="Work Location"
                                                        options={complocation}>
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='row mt-2'>
                                            <div className='col'>

                                                <div class="form-check">
                                                    <input class="form-check-input  " type="checkbox" id="flexCheckDefault" Value={definitecontract} value={true} onChange={(e) => setdefinitecontract(e.target.checked)} />
                                                    <label class="form-check-label  text_check_mid " for="flexCheckDefault">

                                                        If the employee's contract type is 'Definite' please select the checkbox
                                                    </label>
                                                </div>



                                                <div class="form-group ">
                                                    <label for="formGroupExampleInput" class=" ms-1" >Contract Expiry Date</label>
                                                    <input type='date' min={moment().format("YYYY-MM-DD")} class="form-control inputcolor text-start  basetext_box" Value={cduedate} onChange={(e) => SetCDueDate(e.target.value)} aria-label=" select Day" placeholder="Contract Expiry Date" ></input>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-6 d-grid'>
                                                <button class="btn btn-secondary text_box_size " type="button" onClick={alet}>Cancel</button>

                                            </div>
                                            <div className='col-6 d-grid'>
                                                <input
                                                    type='submit'
                                                    value='Next'
                                                    className={"btn btn-pri text_box_size   "}
                                                    disabled={!isValids}
                                                />
                                            </div>

                                        </div>


                                    </div>
                                    <div className='col-2 mt-5'>
                                        {showMessage && (
                                            <div class="alert alert-success msgwidth text-center" role="alert">
                                                Data added successfully!
                                            </div>
                                        )}
                                    </div>


                                </div>
                            }
                        })()}
                    </form>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-4'> <img className=" img-fluid  hide_tab mobsimplytext" src="./Images/basebot.svg" /></div>
                    </div>
                </div>

            </div>

            <AEHeader />
        </div>
    );
}
export default BaseProfile;
