import React, { useCallback, useState, useContext, useEffect } from 'react';
import './Payrolllist.css';
import { useNavigate, useLocation } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';

const PayrollList = () => {
    const { state } = useContext(store);
    console.log('payroll list', state.user);
    const { update, handleSubmit } = useForm();
    const [paylist, setPay_list] = useState([])
    const printRef = React.useRef();
    const printRef1 = React.useRef();
    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [job_title, setJob_title] = useState([])
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);

    }


    const location = useLocation();
    console.log('payroll_id: ', location?.state?.payroll_id);
    console.log('start date: ', location?.state?.startdate);
    console.log('end date: ', location?.state?.enddate);
    console.log('month: ', location?.state?.month);

    const [comment, setComment] = useState("");

    //add comments 
    const onInsert = (data, e) => {
        try {
            let comments = {
                user_id: state.user[0].user_id,
                paycomments: comment,
                comntcreatedby: state.user[0].user_id,
                paycycle_id: location?.state?.payroll_id,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/comments`, comments).then((res) => {
                console.log('jobtitle added', res.data);

                Getjobtitle();
                setComment("");
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [datalenght, setdatalenght] = useState();

    // main get method of payrolllist page
    const GetPaylist = () => {
        let payroll_cycle_id = location?.state?.payroll_id;
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/payattendance/${payroll_cycle_id}`).then((res) => {
                console.log(res.data, 'processedpay');
                setPay_list(res.data);
                setdatalenght(res.data.length);
            })
        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        GetPaylist();
        Getjobtitle();
        Getpaylist();
    }, [state]);

    useEffect(() => {
        GetTax();
    }, []);

    const onUpdateappstatus = () => {
        let payroll_cycle_id = location?.state?.payroll_id;
        let paystatus = {
            appstatus: "true",
        };


        axios.put(`${process.env.REACT_APP_API_URL}/payrollappstatus/${payroll_cycle_id}`, paystatus).then((res) => {
            console.log('employee_salary updated', res.data);

            window.location.reload();

        }).catch(() => {

        })
    }
    const [paidDate, setPaidDate] = useState("");

    //updating paid status 
    const onUpdatePaidstatus = () => {
        let payroll_cycle_id = location?.state?.payroll_id;
        let paidstatus = {
            paidstatus: "true",
            paiddate: paidDate,
        };


        axios.put(`${process.env.REACT_APP_API_URL}/paidstatus/${payroll_cycle_id}`, paidstatus).then((res) => {
            console.log('paid status', res.data);

            window.location.reload();

        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValid = paidDate

    const saverunpay = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to submit payroll',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdateappstatus(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to cancel')
                }
            ]
        })
    }
    const clickdelete = (pay_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(pay_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to delete')
                }
            ]
        })
    }
    const Delete = (pay_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/payroll/` + pay_id).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/addjobtitle";
                if (res.data == true) {

                }
                GetPaylist();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    //page no
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;



    const filteredUsers = paylist.filter((x) => x.full_name.toLowerCase().includes(searchTerm.toLowerCase()) || x.full_name.toLowerCase().includes(searchTerm.toLowerCase()));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);
    const pageCount = Math.ceil(paylist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    // View Details 

    const RenderEdit = () => {

        console.log('bind', bind_user);

        let earningstypeArray = [];
        if (bind_user.earningscomponents != null || bind_user.earningscomponents != undefined) {
            earningstypeArray = bind_user.earningscomponents.split(",");
        } else {
            earningstypeArray = [""];
        }
        let earningsatypeArray = [];
        if (bind_user.earningsamounts != null || bind_user.earningsamounts != undefined) {
            earningsatypeArray = bind_user.earningsamounts.split(",");
        } else {
            earningsatypeArray = [""]
        }
        let deductiontypeArray = [];
        if (bind_user.deductionscomponents != null || bind_user.deductionscomponents != undefined) {
            deductiontypeArray = bind_user.deductionscomponents.split(",");
        } else {
            deductiontypeArray = [""]
        }
        let deductionamtypeArray = [];
        if (bind_user.deductionsamounts != null || bind_user.deductionsamounts != undefined) {
            deductionamtypeArray = bind_user.deductionsamounts.split(",");
        } else {
            deductionamtypeArray = [""]
        }
        let nontaxableearningstypeArray = [];
        if (bind_user.nontaxableearningscomponents == null || bind_user.nontaxableearningscomponents == undefined) {
            nontaxableearningstypeArray = [""]
        } else {
            nontaxableearningstypeArray = bind_user.nontaxableearningscomponents.split(",");
        }
        let nontaxableearningsamtypeArray = [];
        if (bind_user.nontaxableearningsamounts != null || bind_user.nontaxableearningsamounts != undefined) {
            nontaxableearningsamtypeArray = bind_user.nontaxableearningsamounts.split(",");
        } else {
            nontaxableearningsamtypeArray = [""];
        }
        let nontaxabledeductionstypeArray = [];
        if (bind_user.nontaxabledeductioncomponents != null || bind_user.nontaxabledeductioncomponents != undefined) {
            nontaxabledeductionstypeArray = bind_user.nontaxabledeductioncomponents.split(",");
        } else {
            nontaxabledeductionstypeArray = [""];
        }
        let nontaxabledeductionsamtypeArray = [];
        if (bind_user.nontaxabledeductionamounts != null || bind_user.nontaxabledeductionamounts != undefined) {
            nontaxabledeductionsamtypeArray = bind_user.nontaxabledeductionamounts.split(",");
        } else {
            nontaxabledeductionsamtypeArray = [""];
        }

        // Map over the earningstype array and create a new array with each item in a separate line
        const earningstypeLines = earningstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const earningsam = earningsatypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const deductiontype = deductiontypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const deductionam = deductionamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const nontaxableearningstypeLines = nontaxableearningstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const nontaxableearningsam = nontaxableearningsamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const nontaxabledeductiontype = nontaxabledeductionstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const nontaxabledeductionam = nontaxabledeductionsamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });

        confirmAlert({
            title: 'Salary Details',

            message: <div className='row downsize  downmob1 '>

                <div className='row' >
                    <div className='col downmob'>
                        <div className='row mt-2'>
                            <div className='col-3'>

                                <p className='text-secondary text-capitalize fw-bold'>Name: {bind_user.full_name}</p>
                            </div>
                            <div className='col-2'>

                                <p className='text-secondary fw-bold'>Emp Id: {('000' + bind_user.employeeid).slice(-3)}</p>
                            </div>
                            <div className='col-3'>

                                <p className='text-secondary fw-bold'>Month: {bind_user.month} / {moment(bind_user.startdate).format('YYYY')}</p>
                            </div>
                            {(() => {
                                if (bind_user.paiddate == null) {
                                    return <div className='col-4'>
                                        <p className='text-secondary fw-bold'>Paid Date: Payment Pending</p>
                                    </div>
                                } else {
                                    return <div className='col-4'>
                                        <p className='text-secondary fw-bold'>Paid Date:{moment(bind_user.paiddate).format('DD/MM/YYYY')}</p>
                                    </div>
                                }
                            })()}

                        </div>
                        <div ref={printRef1}>
                            <h5>Summary</h5>
                            <div className="row">
                                <div className="ms-4 mt-2 onlytab">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                    </svg>
                                </div>
                            </div>
                            <table class="table table-bordered mt-4 year-buttonss">

                                <table class="table" >
                                    <thead>
                                        <tr>
                                            <td scope="col"></td>




                                            <td scope="col" className='fs-6 text-secondary '>Gross Pay</td>
                                            <td scope="col" className='fs-6 text-secondary '>Taxable Earnings</td>
                                            <td scope="col" className='fs-6 text-secondary'>Taxable Deductions</td>
                                            <td scope="col" className='fs-6 text-secondary'>Personal IncomeTax</td>
                                            <td scope="col" className='fs-6 text-secondary '>Non-Taxable Earnings</td>
                                            <td scope="col" className='fs-6 text-secondary'>Non-Taxable Deductions</td>
                                            <td scope="col" className='fs-6 text-secondary'>Take Home<small className='text-lowercase'>(đ)</small></td>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr className=''>
                                            <td scope="row"></td>


                                            <td className=''>{Math.round(bind_user.grossbasic).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                            {(() => {
                                                if (bind_user.taxableamt == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.taxableamt).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}
                                            {(() => {
                                                if (bind_user.grossded == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.grossded).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}
                                            {(() => {
                                                if (bind_user.numnon == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.numnon).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}
                                            {(() => {
                                                if (bind_user.totalnontaxable_earning == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}

                                            {(() => {
                                                if (bind_user.totalnontaxable_deduction == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}
                                            {(() => {
                                                if (bind_user.takehomepay == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.takehomepay).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                }
                                            })()}


                                        </tr>


                                    </tbody>
                                </table>
                            </table>
                            <h4 className='text-dark'>Salary Break Down</h4>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <h5>Taxable Earnings</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td>Gross Pay</td></td>
                                                <td className='text-lowercase'>{Math.round(bind_user.grossbasic).toLocaleString('en-US').replace(/,/g, '.')}đ</td>

                                            </tr>
                                            <tr>

                                                <td><td>OverTime</td></td>
                                                <td className='text-lowercase'>{Math.round(bind_user.overtimeamt).toLocaleString('en-US').replace(/,/g, '.')}đ</td>

                                            </tr>
                                            <tr>

                                                <td><td className='text-capitalize'>{earningstypeLines}</td></td>
                                                <td>{earningsam}</td>

                                            </tr>

                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                <td className='text-lowercase'>{Math.round(bind_user.taxableamt).toLocaleString('en-US').replace(/,/g, '.')}đ</td>

                                            </tr>


                                        </tbody>

                                    </table>
                                </div>
                                <div className='col-6'>
                                    <h5>Taxable Deductions</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td>Compulsory insurances</td></td>
                                                {(() => {
                                                    if (bind_user.compinsurance == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.compinsurance).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}

                                            </tr>
                                            <tr>

                                                <td><td>Personal Dependent Deduction</td></td>
                                                {(() => {
                                                    if (bind_user.personalddamt == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.personalddamt).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}

                                            </tr>
                                            <tr>

                                                <td><td className='text-capitalize'>{deductiontype}</td></td>
                                                <td className='text-lowercase'>{deductionam}</td>

                                            </tr>

                                            <tr>

                                                <td><td> OT, Night Shift, Holiday Premium Deduction</td></td>
                                                {(() => {
                                                    if (bind_user.otpremium == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.otpremium).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}

                                            </tr>
                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                {(() => {
                                                    if (bind_user.grossded == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.grossded).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}


                                            </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <h5>Non Taxable Earnings</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td className='text-capitalize'>{nontaxableearningstypeLines}</td></td>
                                                <td className='text-lowercase'>{nontaxableearningsam}</td>

                                            </tr>


                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                <td className='text-lowercase'>{Math.round(bind_user.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.')}đ</td>

                                            </tr>


                                        </tbody>

                                    </table>
                                </div>
                                <div className='col-6'>
                                    <h5>Non Taxable Deductions</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td className='text-capitalize'>{nontaxabledeductiontype}</td></td>
                                                <td className='text-lowercase'>{nontaxabledeductionam}</td>

                                            </tr>

                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                {(() => {
                                                    if (bind_user.totalnontaxable_deduction == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}


                                            </tr>


                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <h5>Employee Contributions</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td>Social Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.socialins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.socialins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>
                                            <tr>

                                                <td><td>Health Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.healthins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.healthins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>
                                            <tr>

                                                <td><td>Unemployment Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.unempins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.unempins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>

                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                {(() => {
                                                    if (bind_user.compinsurance == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.compinsurance).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}


                                            </tr>


                                        </tbody>

                                    </table>
                                </div>
                                <div className='col-6'>
                                    <h5>Employer Contributions</h5>
                                    <table class="table table-bordered mt-4 table-striped ">

                                        <thead>
                                            <tr>

                                                <th scope="col" className='fs-6 text-secondary '>Description</th>
                                                <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody className=''>
                                            <tr>

                                                <td><td>Social Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.compsocialins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.compsocialins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>
                                            <tr>

                                                <td><td>Health Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.comphealthins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.comphealthins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>
                                            <tr>

                                                <td><td>Unemployment Insurance</td></td>
                                                {(() => {
                                                    if (bind_user.compunempins == null) {
                                                        return <td>0</td>
                                                    } else {
                                                        return <td className='text-lowercase'>{Math.round(bind_user.compunempins).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}
                                            </tr>

                                            <tr>

                                                <td><td className='fw-bold grey'>Total</td></td>
                                                {(() => {
                                                    if (bind_user.companycompinsurance == null) {
                                                        return <td className='calicn '>0</td>
                                                    } else {
                                                        return <td className='calicn  text-lowercase'>{Math.round(bind_user.companycompinsurance).toLocaleString('en-US').replace(/,/g, '.')}đ</td>
                                                    }
                                                })()}


                                            </tr>


                                        </tbody>

                                    </table>
                                </div>
                            </div>




                        </div>

                    </div>
                </div>



            </div>,
            buttons: [
                {
                    label: 'Close',
                    className: 'closemob',
                   
                },



            ]
        })

    }


    //Download Payslip
    const RenderPayslip = () => {

        console.log('bind', bind_user);
        let earningstypeArray = [];
        if (bind_user.earningscomponents != null || bind_user.earningscomponents != undefined) {
            earningstypeArray = bind_user.earningscomponents.split(",");
        } else {
            earningstypeArray = [""];
        }
        let earningsatypeArray = [];
        if (bind_user.earningsamounts != null || bind_user.earningsamounts != undefined) {
            earningsatypeArray = bind_user.earningsamounts.split(",");
        } else {
            earningsatypeArray = [""]
        }
        let deductiontypeArray = [];
        if (bind_user.deductionscomponents != null || bind_user.deductionscomponents != undefined) {
            deductiontypeArray = bind_user.deductionscomponents.split(",");
        } else {
            deductiontypeArray = [""]
        }
        let deductionamtypeArray = [];
        if (bind_user.deductionsamounts != null || bind_user.deductionsamounts != undefined) {
            deductionamtypeArray = bind_user.deductionsamounts.split(",");
        } else {
            deductionamtypeArray = [""]
        }
        let nontaxableearningstypeArray = [];
        if (bind_user.nontaxableearningscomponents == null || bind_user.nontaxableearningscomponents == undefined) {
            nontaxableearningstypeArray = [""]
        } else {
            nontaxableearningstypeArray = bind_user.nontaxableearningscomponents.split(",");
        }
        let nontaxableearningsamtypeArray = [];
        if (bind_user.nontaxableearningsamounts != null || bind_user.nontaxableearningsamounts != undefined) {
            nontaxableearningsamtypeArray = bind_user.nontaxableearningsamounts.split(",");
        } else {
            nontaxableearningsamtypeArray = [""];
        }
        let nontaxabledeductionstypeArray = [];
        if (bind_user.nontaxabledeductioncomponents != null || bind_user.nontaxabledeductioncomponents != undefined) {
            nontaxabledeductionstypeArray = bind_user.nontaxabledeductioncomponents.split(",");
        } else {
            nontaxabledeductionstypeArray = [""];
        }
        let nontaxabledeductionsamtypeArray = [];
        if (bind_user.nontaxabledeductionamounts != null || bind_user.nontaxabledeductionamounts != undefined) {
            nontaxabledeductionsamtypeArray = bind_user.nontaxabledeductionamounts.split(",");
        } else {
            nontaxabledeductionsamtypeArray = [""];
        }

        // Map over the earningstype array and create a new array with each item in a separate line
        const earningstypeLines = earningstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const earningsam = earningsatypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const deductiontype = deductiontypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const deductionam = deductionamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const nontaxableearningstypeLines = nontaxableearningstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const nontaxableearningsam = nontaxableearningsamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });
        const nontaxabledeductiontype = nontaxabledeductionstypeArray.map((earning) => {
            return <div key={earning}>{earning}</div>;
        });
        const nontaxabledeductionam = nontaxabledeductionsamtypeArray.map((earning) => {
            return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
        });

        confirmAlert({


            message: <div className='row downsize downmob1'>

                <div className='row downmob' ref={printRef} >
                    <div className='row'>
                        <div className='col-sm-4'><img className='img-fluid' src={bind_user.company_logo} ></img></div>

                        <div className='col-sm-6 ms-sm-4'> <p className=' fw-bold fs-5 text-capitalize'>{bind_user.company_name}
                            <p className=' fw-normal fs-6 text-capitalize'>{bind_user.company_address1}</p></p></div>

                    </div>
                    <div className='row'>
                        <div className='col-sm-4'></div>

                        <div className='col-sm-6 ms-sm-4'> <p className=' fw-bold fs-5 text-capitalize text-black'>Payslip - {bind_user.month} / {moment(bind_user.startdate).format('YYYY')}
                            <p className=' fw-normal fs-6 text-capitalize ms-sm-5'>{bind_user.full_name}. {bind_user.last_name}</p></p></div>

                    </div>



                    <div className='row border-top'>
                        <div className='col-sm-6 mt-3'>
                            <h5>Employee Details</h5>
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td><td>Employee ID</td></td>
                                        <td>{('000' + bind_user.employeeid).slice(-3)}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Designation</td></td>
                                        <td>{bind_user.job_title}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Passport/National ID</td></td>
                                        <td>{bind_user.pass_port}</td>


                                    </tr>
                                    <tr>

                                        <td><td>Hire Date</td></td>
                                        <td>{moment(bind_user.hiredate).format('MMM Do YYYY')}</td>
                                    </tr>
                                    <tr>

                                        <td><td>Location </td></td>
                                        <td>{bind_user.city}</td>


                                    </tr>
                                    <tr>

                                        <td><td>Labour Contract Number</td></td>
                                        <td>{bind_user.labourcontract}</td>


                                    </tr>
                                    <tr>

                                        <td><td>Total Worked Days</td></td>
                                        <td>{bind_user.workeddays}</td>


                                    </tr>


                                </tbody>
                            </table>
                        </div>
                        <div className='col-sm-6 mt-3'>
                            <h5>Summary</h5>
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col-sm">Description</th>
                                        <th scope="col-sm">Amount</th>
                                        <th scope="col-sm">YTD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td><td>Gross Pay</td></td>
                                        <td>{Math.round(bind_user.grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        <td>{Math.round(bind_user.ytd_grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Taxable Earnings</td></td>
                                        {(() => {
                                            if (bind_user.taxableamt == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}
                                        <td className='calicn '>{Math.round(bind_user.ytd_taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Taxable Deductions</td></td>
                                        {(() => {
                                            if (bind_user.grossded == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}
                                        <td className='calicn '>{Math.round(bind_user.ytd_grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Personal IncomeTax</td></td>
                                        {(() => {
                                            if (bind_user.numnon == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.numnon).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}
                                        <td className='calicn '>{Math.round(bind_user.ytd_numnon).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>



                                    </tr>
                                    <tr>

                                        <td><td>Non-Taxable Earnings</td></td>
                                        {(() => {
                                            if (bind_user.totalnontaxable_earning == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}
                                        <td className='calicn '>{Math.round(bind_user.ytd_totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                    </tr>
                                    <tr>

                                        <td><td>Non-Taxable Deductions</td></td>
                                        {(() => {
                                            if (bind_user.totalnontaxable_deduction == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}

                                        <td className='calicn '>{Math.round(bind_user.ytd_totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                    </tr>

                                    <tr>

                                        <td><td className='fw-bold grey'>Take Home</td></td>
                                        {(() => {
                                            if (bind_user.takehomepay == null) {
                                                return <td className='calicn '>0</td>
                                            } else {
                                                return <td className='calicn '>{Math.round(bind_user.takehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                            }
                                        })()}
                                        <td className='calicn '>{Math.round(bind_user.ytd_takehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-sm-6'>
                                <h5>Taxable Earnings</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col-sm" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>Amount</th>

                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td>Gross Pay</td></td>
                                            <td>{Math.round(bind_user.grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                        </tr>
                                        <tr>

                                            <td><td>OverTime</td></td>
                                            <td>{Math.round(bind_user.overtimeamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                        </tr>
                                        <tr>

                                            <td><td className='text-capitalize'>{earningstypeLines}</td></td>
                                            <td>{earningsam}</td>

                                        </tr>

                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            <td>{Math.round(bind_user.taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                            <div className='col-6-sm'>
                                <h5>Taxable Deductions</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col-sm" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td>Compulsory insurances</td></td>
                                            {(() => {
                                                if (bind_user.compinsurance == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}

                                        </tr>
                                        <tr>

                                            <td><td>Personal Dependent Deduction</td></td>
                                            {(() => {
                                                if (bind_user.personalddamt == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.personalddamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}

                                        </tr>
                                        <tr>

                                            <td><td className='text-capitalize'>{deductiontype}</td></td>
                                            <td>{deductionam}</td>

                                        </tr>

                                        <tr>

                                            <td><td> OT, Night Shift, Holiday Premium Deduction</td></td>
                                            {(() => {
                                                if (bind_user.otpremium == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.otpremium).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}

                                        </tr>
                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            {(() => {
                                                if (bind_user.grossded == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}


                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-sm-6'>
                                <h5>Non Taxable Earnings</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col-sm" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td className='text-capitalize'>{nontaxableearningstypeLines}</td></td>
                                            <td>{nontaxableearningsam}</td>

                                        </tr>


                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            <td>{Math.round(bind_user.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                            <div className='col-sm-6'>
                                <h5>Non Taxable Deductions</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col-sm" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td className='text-capitalize'>{nontaxabledeductiontype}</td></td>
                                            <td>{nontaxabledeductionam}</td>

                                        </tr>

                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            {(() => {
                                                if (bind_user.totalnontaxable_deduction == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}


                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-sm-6'>
                                <h5>Employee Contributions</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col-sm" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>Amount</th>
                                            <th scope="col-sm" className='fs-6 text-secondary '>YTD</th>
                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td>Social Insurance</td></td>
                                            {(() => {
                                                if (bind_user.socialins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.socialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_socialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>
                                        <tr>

                                            <td><td>Health Insurance</td></td>
                                            {(() => {
                                                if (bind_user.healthins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.healthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_healthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>
                                        <tr>

                                            <td><td>Unemployment Insurance</td></td>
                                            {(() => {
                                                if (bind_user.unempins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.unempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_unempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>

                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            {(() => {
                                                if (bind_user.compinsurance == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}

                                            <td className='calicn '>{Math.round(bind_user.ytd_compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                            <div className='col-sm-6'>
                                <h5>Employer Contributions</h5>
                                <table class="table table-bordered mt-4 table-striped ">

                                    <thead>
                                        <tr>

                                            <th scope="col" className='fs-6 text-secondary '>Description</th>
                                            <th scope="col" className='fs-6 text-secondary '>Amount</th>
                                            <th scope="col" className='fs-6 text-secondary '>YTD</th>
                                        </tr>
                                    </thead>

                                    <tbody className=''>
                                        <tr>

                                            <td><td>Social Insurance</td></td>
                                            {(() => {
                                                if (bind_user.compsocialins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.compsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_compsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>
                                        <tr>

                                            <td><td>Health Insurance</td></td>
                                            {(() => {
                                                if (bind_user.comphealthins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.comphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_comphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>
                                        <tr>

                                            <td><td>Unemployment Insurance</td></td>
                                            {(() => {
                                                if (bind_user.compunempins == null) {
                                                    return <td>0</td>
                                                } else {
                                                    return <td>{Math.round(bind_user.compunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td>{Math.round(bind_user.ytd_compunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                        </tr>

                                        <tr>

                                            <td><td className='fw-bold grey'>Total</td></td>
                                            {(() => {
                                                if (bind_user.companycompinsurance == null) {
                                                    return <td className='calicn '>0</td>
                                                } else {
                                                    return <td className='calicn '>{Math.round(bind_user.companycompinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                }
                                            })()}
                                            <td className='calicn '>{Math.round(bind_user.ytd_companycominsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>


                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                        </div>


                    </div>
                    <p className='text-center text-black fs-6 fw-bold'>This is a Computer-Generated Pay Slip
                        <p className='text-center text-black'>Signature is not required </p></p>

                </div>

                <div className='col'>



                </div>

            </div>,
            buttons: [

                {
                    label: 'Close',

                }, {
                    label: 'Download',
                    className: 'btn-pri bt_pri',
                    onClick: () => handleDownloadPdf(),
                },





            ]
        })

    }

    const continueDeleteAll = () => {
        let temp = paylist.map(i => {
            return i.pay_id
        })
        try {
            axios.delete(`${process.env.REACT_APP_API_URL}/payrollMultiple/` + temp).then((res) => {
                console.log(res.data, '-- success');
                window.location.reload();
            })
        } catch (err) {
            console.log(err.message);
            window.location.reload();
        }
    }

    const deleteAll = () => {
        confirmAlert({
            message: 'Are you sure want to delete all data?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => continueDeleteAll(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        })
    }

    // download payslip pdf code
    const handleDownloadPdf = () => {
        const element = printRef.current;

        html2canvas(element, { useCORS: true, scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = imgWidth / imgHeight;

            let pdfHeight = pageHeight;
            let position = 0;

            while (position < imgHeight) {
                const pdfWidth = pdfHeight * ratio;

                // Check if the content fits on the current page
                if (position + pdfHeight > imgHeight) {
                    pdfHeight = imgHeight - position;
                }

                // Add a new page if the content doesn't fit on the current page
                if (pdfHeight > pageHeight) {
                    pdf.addPage([pdfWidth, pdfHeight]);
                    pdfHeight = pageHeight;
                }

                pdf.addImage(imgData, 'PNG', (pageWidth - pdfWidth) / 2, position, pdfWidth, pdfHeight, undefined, 'FAST', false, { align: 'center' });

                position += pdfHeight;
            }

            pdf.save('Payslip.pdf');
        });
    };

    const handleDownloadPdf1 = async () => {
        const element = printRef1.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('Emp1payroll.pdf');
    };

    let navigate = useNavigate();
    const backpay = () => {
        let path = `/payrolecycle`;
        navigate(path, { state: { payroll_id: location?.state?.payroll_id, startdate: location?.state?.startdate, enddate: location?.state?.enddate, month: location?.state?.month } });
    }
    const [tax_details, setTaxDetails] = useState([]);

    const GetTax = (x) => {
        let payroll_cycle_id = location?.state?.payroll_id;
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/payrolllimit/${payroll_cycle_id}`).then((res) => {
                console.log(res.data, 'success');
                setTaxDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const Getjobtitle = (x) => {
        let paycycle_id = location?.state?.payroll_id;
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/comments/${paycycle_id}`).then((res) => {
                console.log(res.data, 'success');
                setJob_title(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    //get method of payrollcycle
    const [palist, setPayList] = useState([]);
    const Getpaylist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payrollcycles_att/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'payid');
                setPayList(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    return (
        <div className='bg-light vh-100 container-fluid'>

            <div className='row mt-4'>
                <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                </div>
                <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 mt-4 tbl'>
                    <input type="text" class="form-control grey mt-4 ms-sm-5 searchbox" placeholder="Search by Employee Name"
                        onChange={(event) => { setSearchTerm(event.target.value); }} id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <div class=" card  ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                    <div className='row'>
                                        <div className='col-3 d-grid mt-2'><button class="btn btn-secondary btnh" onClick={backpay}>Back</button></div>



                                        {tax_details.map((x, index) => {
                                            return (
                                                <div className='col-7 mt-2 d-grid  col-md-6 col-lg-6 col-xl-7 col-xxl-7 '>
                                                    <form onSubmit={handleSubmit(saverunpay)} action="#" method="POST">
                                                        {palist.map((y, index) => {
                                                            return (<div>
                                                                {(() => {
                                                                    if (x.appstatus == true || y.payroll_id !== location?.state?.payroll_id) {
                                                                        return <button class="btn btn-pri   " disabled>Submit Payroll</button>
                                                                    }
                                                                    else {
                                                                        return <button type='submit' class="btn btn-pri   " >Submit Payroll</button>
                                                                    }
                                                                })()}
                                                            </div>);
                                                        })}
                                                    </form>

                                                </div>
                                            );
                                        })}

                                    </div>


                                    {/* <button class="btn btn-pri ms-3 w-25">Submit and Approve </button> */}
                                </div>

                                <div className='col-sm-1 hide_tab'></div>
                                <div className='col-sm-2 col-md-3 col-lg-3 col-xl-2 col-xxl-2 comleft'>
                                    <div className='row ms-sm-4'>
                                        <div className='col-sm-2 mt-2 pointer_'>
                                            <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
                                                <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                                                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </div>
                                        <div className='col-sm-9    mt-2 text-primary '>Comments</div>
                                    </div>
                                </div>

                                {tax_details.map((x, index) => {
                                    return (
                                        <div className='col-sm-3  col-md-3 col-lg-3 col-xl-3 col-xxl-3 comleft2'>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    {palist.map((y, index) => {
                                                        return (<div>
                                                            {(() => {
                                                                if (x.appstatus == true && (x.paidstatus == null || x.paidstatus == false) && y.payroll_id == location?.state?.payroll_id) {
                                                                    return <button class="btn btn-pri ms-sm-5  mt-2 recbtn" data-bs-toggle="modal" data-bs-target="#exampleModal1">Record Payment</button>
                                                                }
                                                                else {
                                                                    return <button class="btn btn-pri ms-sm-5  mt-2 recbtn" data-bs-toggle="modal" data-bs-target="#exampleModal1" disabled>Record Payment</button>
                                                                }
                                                            })()}
                                                        </div>);
                                                    })}
                                                </div>
                                                <div className='col-sm-1 mt-2 pointer_ hide_all'>
                                                    <svg id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical threedotrig" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                        <li><a className={"dropdown-item" + (x.paidstatus ? " disabled" : "")}><svg onClick={deleteAll} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-trash3 send_img" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                        </svg></a></li>
                                                    </ul>
                                                </div>

                                                <div className='col-1  mt-2 pointer_ onlytab hide_tab'>
                                                    <svg id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                        <li><a className={"dropdown-item" + (x.paidstatus ? " disabled" : "")}><svg onClick={deleteAll} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-trash3 send_img" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                        </svg></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}

                            </div>
                            <div className='row' ref={printRef}>
                                <div className='col-sm'>
                                    {(() => {
                                        if (datalenght == 0) {
                                            return <div className='mt-5'><h4> Payroll Not Processed  !</h4>

                                            </div>
                                        } else {
                                            return <>
                                                <div className="row">
                                                    <div className="ms-4 mt-2 onlytab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <table class="table table-bordered mt-4 overflow-x-auto year-buttonss">
                                                    <table class="table table-striped" >
                                                        <thead>
                                                            <tr className=' '>
                                                                <td scope="col"></td>



                                                                <td scope="col" className='headfont  text-center text-uppercase grey'>Name</td>

                                                                <td scope="col" className='headfont  text-center text-uppercase grey'>Gross Salary</td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase grey'>Taxable Earnings</td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase greyh'>Taxable Deductions</td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase greyh'>Income Tax</td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase grey'>Non-Taxable Earnings</td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase greyh'>Non-Taxable Deductions</td>

                                                                <td scope="col" className='headfont  text-center text-uppercase greyh'>Take Home<small className='text-lowercase'>(đ)</small> </td>
                                                                <td></td>
                                                                <td scope="col" className='headfont  text-center text-uppercase greyh'>Status</td>
                                                                <td></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {displayUsers.filter((x) => x.full_name.toLowerCase().includes(searchTerm.toLowerCase()) || x.full_name.toLowerCase().includes(searchTerm.toLowerCase())).map((x, index) => {

                                                                return (
                                                                    <>

                                                                        <tr className=''>
                                                                            <td scope="row"></td>


                                                                            <td className=' text-center text-capitalize'>{x.full_name}.{x.last_name}</td>
                                                                            <td className=' text-center'>{Math.round(x.grossbasic).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.taxableamt == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.taxableamt).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.grossded == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.grossded).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.numnon == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.numnon).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.totalnontaxable_earning == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.totalnontaxable_deduction == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}

                                                                            {(() => {
                                                                                if (x.takehomepay == null) {
                                                                                    return <td className='calicn  text-center'>0</td>
                                                                                } else {
                                                                                    return <td className='calicn  text-center'>{Math.round(x.takehomepay).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                                }
                                                                            })()}
                                                                            <td></td>
                                                                            {(() => {
                                                                                if (x.paidstatus == true) {
                                                                                    return <td className='calicn  text-center'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" class="bi bi-coin" viewBox="0 0 16 16">
                                                                                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                                                                    </svg>

                                                                                    </td>
                                                                                } else {
                                                                                    if (x.appstatus == true) {
                                                                                        return <td className='calicn  text-center'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#0080FF" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                                        </svg></td>
                                                                                    } else {
                                                                                        return <td className='calicn  text-center'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="orange" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                                                        </svg></td>
                                                                                    }
                                                                                }
                                                                            })()}


                                                                            <td className='pointer_'><svg id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                            </svg>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    <a onClick={() => ShowDetails(x, 'edit')} className="dropdown-item">View Details</a>

                                                                                    <a onClick={() => {
                                                                                        if (x.paidstatus === true) {
                                                                                            ShowDetails(x, 'view');
                                                                                        } else {
                                                                                            return false;
                                                                                        }
                                                                                    }} className={`dropdown-item ${x.paidstatus === null ? 'disabled' : ''}`}>
                                                                                        Download Payslip
                                                                                    </a>

                                                                                    <a onClick={() => clickdelete(x.pay_id)} className={"dropdown-item" + (x.paidstatus ? " disabled" : "")}>Delete</a>


                                                                                </ul>
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                );
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </table>
                                            </>
                                        }

                                    })()}
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable ">
                            <div class="modal-content ">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">Comments</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div class="modal-body ">
                                    <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                        <div className='row'>

                                            <input
                                                minlength="1" maxlength="90"
                                                type='text'
                                                value={comment} onChange={(e) => setComment(e.target.value)}
                                                placeholder='Enter your comments here'
                                                className={` form-control inputcolor  `}
                                            />
                                            <div className='col-sm-2'>
                                                <button type='submit' className='btn btn-pri mt-2'>Add</button>
                                            </div>
                                            <table class="table table-bordered mt-2">
                                                <table class="table  table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='fs-6 fw-bold grey'> User</td>

                                                            <td scope="col" className='fs-6 fw-bold  grey'>Comments</td>
                                                            <td scope="col" className='fs-6 fw-bold  grey'>Date</td>
                                                            <td scope="col" ></td>


                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {job_title.map((x, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{x.first_name}</td>
                                                                    <td className=''>{x.paycomments}</td>
                                                                    <td>{moment(x.createdon).format('MMM Do YYYY, h:mm:ss a')}</td>
                                                                    <td></td>

                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </table>
                                            </table>
                                        </div>
                                    </form>




                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable ">
                            <div class="modal-content ">
                                <div class="modal-header">
                                    <h7 class="modal-title fs-5 fw-bold" id="exampleModalLabel">Record Payments</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div class="modal-body ">
                                    <div className='row'>
                                        <p className='text-secondary fw-bold'>You are about to record Payment for this payroll</p>
                                        <div className='row'>
                                            <div className='col-sm-5'>
                                                <p>Employees paid on</p>
                                            </div>
                                            <div className='col-sm-5'>
                                                <input
                                                    type='date'
                                                    min={moment().format("YYYY-MM-DD")}
                                                    value={paidDate} onChange={(e) => setPaidDate(e.target.value)}
                                                    placeholder='Select Date'
                                                    className={` form-control inputcolor  `}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-sm-3 d-grid'>
                                            <form onSubmit={handleSubmit(onUpdatePaidstatus)} action="#" method="POST">
                                                <button type='submit' className='btn btn-pri w-100 mt-2' disabled={!isValid}>Confirm</button>
                                            </form>
                                        </div>

                                        <div className='col-sm-3 d-grid'>
                                            <button type='submit' data-bs-dismiss="modal" aria-label="Close" className='btn btn-secondary mt-2'>Cancel</button>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div>{(() => {
                    switch (user_type) {
                        case "view": return RenderPayslip();
                        case "edit": return RenderEdit();
                        //case "delete": return RenderDelete();
                        default: return "";
                    }
                })()}</div>
            </div>
            <Lheader />
        </div>
    );
}
export default PayrollList;