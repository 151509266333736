import React from 'react'
import { store } from '../../store/user'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { useForm } from 'react-hook-form';
const Superlogin = () => {

  const { update, handleSubmit } = useForm();

  let navigate = useNavigate();
  const goadh = () => {
    //debugger;
    navigate("/superadmindashboard");
  };
  const goverify = (x) => {

    let path = `/otpsuper`;
    navigate(path, { state: { x: x } });
    // navigate( `/otpvalidation`);

  }
  const goland = () => {
    //debugger;
    navigate("/");
  };
  const [user_details, setuserDetails] = useState([]);
  const [user, setUserId] = useState([]);
  const [mail, setmail] = useState("");
  const [password, setpassword] = useState("");
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const onlogin = (data, e) => {
    let bas = {
      loginid: mail,
      password_admin: password,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/login_admin`, bas).then((res) => {
      console.log('valid User', res.data);
      if (res.data.length > 0) {
        if (res.data[0].status == true) {
          const data = localStorage.getItem('username');
          console.log('logged', data);
          if (data == res.data[0].loginid) {
            setuserDetails(res.data);
            localStorage.setItem('user_details', JSON.stringify(res.data));
            localStorage.setItem('username', res.data[0].loginid);
            setUserId(res.data[0].admin_id );
            console.log(res.data[0].admin_id ,'login_id')
            dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
            goadh();
          }
          else {
            alert("OTP required");
            goverify(res.data);


          }


        }
        else {
          alert("Account disabled");
        }

      }


      else {
        alert("Wrong credential")
      }

    }).catch(() => {
      //  alert("wrong password");
    })
  }

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (e) => {
    setpassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (

    <div className=' logscr '>
      <div className='row '>
        <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 '>
        <div className='row'>
        <div className='col '>
            <img className='img-fluid loginimg pointer_ ' onClick={goland} src={`Images/sygl_img.png`} alt="Logo" />
            <img className='imgbore' src={`/Images/sign_img.png`} alt="SY Image" />
        
       </div>
    

</div>
        </div>
        <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 bg-white'>
          <div className='row mt-4'>
            <p className='text-center fs-5 fw-bold fnt-clr '>Sign In</p>
          </div>
          <form onSubmit={handleSubmit(onlogin)} action="#" method="POST">
            <div className='row mt-5'>
              <div className='col-2'> </div>
              <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
                <label for="exampleInputEmail1" class="form-label ">Login ID*</label>
                <input type="email" class=" form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setmail(e.target.value) }} />
              </div>




            </div>
            <div className='row mt-3'>
              <div className='col-2'> </div>
              <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
                <label for="exampleInputEmail1" class="form-label">Password*</label>
                <div className="input-group">
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control inputcolor text_box_size"
          id="password"
          onChange={handlePasswordChange}
          value={password}
        />
       
      </div>
                {/* <input type="password" class="  form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setpassword(e.target.value) }} /> */}
              </div>




            </div>
           
            <div className='row mt-4'>

<div className='col-6'></div>

            <div className='col-6'>
                  <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => setShowPassword(!showPassword)}/>
  <label htmlFor="exampleCheck1" className="form-check-label">
  {showPassword ? 'Hide' : 'Show'}
</label>
<label class="form-check-label ms-2" for="exampleCheck1">Password</label>
</div>    
                  </div>

            </div>
            <div className='row mt-4'>
              <div className='col-2'> </div>

              <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-grid'>
                <button type="submit" class="btn btn-pri  ">Log In</button>
              </div>

            </div>

          </form>
        </div>
      </div>

    </div>
  )
}

export default Superlogin
