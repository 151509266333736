import React, { useCallback, useState, useContext, useEffect } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';

const Billing = () => {
    const [query, SetQuery] = useState("");
    const { state } = useContext(store);
    console.log('leave', state.user);
    let navigate = useNavigate();
    const leavedetails = () => {
        let path = `/leavedetails`;
        navigate(path);
    }
    const leave = () => {
        let path = `/leave`;
        navigate(path);
    }


    const [payment, setpayment] = useState([]);
    const [company_list, setCompany_list] = useState([]);
    const Getcompanylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/billingpay/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }
    const GetPayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getallpaymentofuser/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'pay');
                setpayment(res.data);

            }).catch(() => {

            })
        }
    }
    const [pending, setpending] = useState([]);
    const Getpenpayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getpenpaymentofuser/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'pay');
                setpending(res.data);

            }).catch(() => {

            })
        }
    }
    const [latest, setlatest] = useState([]);
    const Getsetlatest = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/latestpay/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'success');
                setlatest(res.data);

            }).catch(() => {

            })
        }
    }

    useEffect(() => {
        Getcompanylist();
        GetPayment();
        Getpenpayment();
        Getsetlatest();
    }, [state]);




    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;

    const filteredUsers = payment.filter((x) => {
        const paymentDate = moment(x.payment_date).format('YYYY-MM');
        if (typeof paymentDate === "string" && typeof query === "string") {
            return paymentDate.toLowerCase().includes(query.toLowerCase());
        }
        return false;
    });



    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);



    const pageCount = Math.ceil(payment.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const differenceInTime = today.getTime() - firstDayOfMonth.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));




    return (
        <div className='bg-light vh-100 container-fluid '>
            <div className='row mt-4'>
                <div className='col-sm-2 hid_tab'>

                </div>
                <div className='col-sm-10  bg-light  mt-4  col-xxl-10  col-md-12 col-lg-12 col-xl-10 tbl'>
                    <input
                        type="date"
                        className="form-control inputcolor mt-4 ms-sm-5 searchbox"
                        onChange={(event) => {
                            let formattedDate = "";
                            if (event.target.value) {
                                const selectedDate = new Date(event.target.value);
                                const year = selectedDate.getFullYear();
                                const month = selectedDate.getMonth() + 1;
                                formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
                            }
                            SetQuery(formattedDate);
                        }}
                        id="month-input"
                        aria-label="Month and Year"
                        placeholder="Month and Year"
                    />





                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon searchic" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <div class="card card  ms-sm-5 shadow mb-5 p-2 bg-white rounded">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-sm'>
                                    <p className=' fs-4 fnt-clr '> Billing Details</p>


                                </div>

                            </div>
                            <div className='row pointer_'>
                                <div className='col-sm'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            {company_list.map((x, index) => {
                                                return (

                                                    <div className='row'>
                                                        <div className='col-sm-2'>
                                                            {(() => {
                                                                if (x.user_image == null || x.user_image == '') {
                                                                    return <div className="" > <img className='img-fluid' src={`Images/complogo.jpg`} style={{ height: 65, width: 65 }}></img> </div>
                                                                } else {
                                                                    return <div className=""  > <img className='img-fluid im_g' src={x.user_image} style={{ height: 48, width: 65 }} ></img> </div>
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <p className='mt-4 greyh'>{x.first_name}</p>
                                                        </div>
                                                    </div>




                                                );
                                            })}
                                        </div>
                                        <div className='col-6'>

                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-6'>
                                            {company_list.map((x, index) => {
                                                return (

                                                    <div className='row'>
                                                        <div className='col-sm-4 '>
                                                            <small className='text-secondary grey '>Billing Address</small><br></br>
                                                            <small className='greyh'>{x.company_address1}</small>
                                                            <small className='greyh'>{x.company_address2}</small>
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <small className='text-secondary grey'>Plan Type </small><br></br>

                                                            {(() => {
                                                                if (x.trail == false) {
                                                                    return <p className="text greyh">Subscribed</p>
                                                                } if (x.trail == true) {
                                                                    return <p className="text-warning" >Trial Period</p>
                                                                } if (x.trail == null) {
                                                                    return <p className="text-danger">In-Active</p>
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className='col-sm-4 '>
                                                            <small className='text-secondary grey'>Billing Cycle </small><br></br>
                                                            <p className='greyh'>Monthly</p>
                                                        </div>
                                                    </div>



                                                );
                                            })}


                                        </div>
                                        {latest.map((y, index) => {
                                            return (
                                                <div className='row mt-3'>
                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            Start date

                                                        </small>
                                                        <p className='greyh'>{moment(y.createdon).format('DD-MMM-YYYY')}</p>
                                                    </div>

                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            Due date
                                                        </small>
                                                        <p><p className='greyh'>{moment(y.payment_date).add(1, 'month').startOf('month').format('DD-MMM-YYYY')}</p></p>
                                                    </div>

                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            Last Paid date
                                                        </small>
                                                        <p className='greyh'>{moment(y.paymentdate).format('DD-MMM-YYYY')}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                       
                                        {(()=>{
if(pending.length==0){
return(<h4 className=' fw-bold grey'>No Dues !!</h4>)
}
else{
    return(
        <div className='row'>
        <table class=" mt-2">
            <table class="table year-buttonss" style={{ border: "none", borderColor: "white" }}>
                <thead>
                    <tr>
                        <td scope="col" className=' headfont fs-6  text-secondary grey'>Pending Month</td>

                        <td scope="col" className='headfont fs-6  text-secondary grey'>Pending Amount</td>

                        <td scope="col" className=' headfont fs-6  text-secondary grey mobsimplytext'>Tax</td>
                        <td className='ms-2'></td>
                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Total</td>

                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Due Date </td>

                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Due Days</td>






                    </tr>
                </thead>
                <tbody>
                    
                    {pending.map((x, index) => {
                        return (
                            <tr>
                                <td className='text-start greyh'>
                                    {(() => {
                                        if (x.amount != null) {
                                            return <p>{moment(x.payment_date).format('MMM Do YY')}</p>
                                        } else {
                                            return <p> {moment(firstDayOfMonth).format('MMM Do YY')}</p>
                                        }
                                    })()}
                                </td>


                                <td className='text-start greyh'><p>{(2500000 - x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>

                                <td className='text-start greyh'><p>{(250000 - x.tax).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                <td className='ms-2'></td>
                                <td className='text-start greyh'><p>{((250000 - x.tax) + (2500000 - x.amount)).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                <td className='text-start greyh'>{moment(x.payment_date).format('MMM Do YY')}</td>
                                <td className='text-danger text-start greyh '>
                                    {(() => {
                                        const paymentDate = new Date(x.payment_date);
                                        const differenceIn_Time = new Date().getTime() - paymentDate.getTime();
                                        const difference_InDays = Math.floor(differenceIn_Time / (1000 * 3600 * 24));

                                        if (x.amount != null) {
                                            return <p>{difference_InDays} Days</p>;
                                        } else {
                                            return <p className="">{differenceInDays} Days</p>;
                                        }
                                    })()}


                                </td>


                            </tr>
                        )
                    })}
                </tbody>

            </table>
        </table>

    </div>
    )

}
                                                        })()}
                                        <div className='row mt-4'>
                                            <h5 className='text-secondary grey fw-bold'>Paid History</h5>
                                            <table class="table table-bordered mt-2 year-buttonss">
                                                <table class="table" style={{ border: "none", borderColor: "white" }}>
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'>Year</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'>Month</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Payment ID</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Paid Date</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>Amount Paid </td>







                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {displayUsers.filter((x) => { console.log(moment(x.payment_date).format('YYYY-MM-DD'), query); return (moment(x.payment_date).format('YYYY-MM-DD')).includes(query) }).map((x, index) => {

                                                            return (
                                                                <tr>
                                                                    <td className=''><p className="text-start greyh">{moment(x.payment_date).format('YYYY')}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>

                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{moment(x.payment_date).format('MMM')}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{('0000' + x.payment_id).slice(-4)}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>                                                                    <td className=''>{(() => {
                                                                        if (x.paymentdate != null) {
                                                                            return <p className="text-start greyh">
                                                                                {moment(x.paymentdate).format('MMM-DD-YYYY')}</p>

                                                                        } else {
                                                                            return <p className="text-start greyh">
                                                                                {moment(x.payment_date).format('MMM-DD-YYYY')}</p>
                                                                        }
                                                                    })()}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{x.amount.toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>



                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>

                                                </table>
                                            </table>

                                        </div>

                                    </div>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable popupright">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p class=" fs-6 ms-4 fw-bold">Leave status</p>
                                        <div className='row'>
                                            <div className='col-6'>

                                                <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => SetQuery(e.target.value)}>
                                                    <option value="">ALL</option>

                                                    <option value="true">Approved</option>
                                                    <option value="false">Rejected</option>
                                                    <option value="pending">Pending</option>

                                                </select>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="s">


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div class="leaveml">
                
            </div>
            <Lheader />
        </div>
    );
}
export default Billing;