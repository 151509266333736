import React, { useState, useContext, useEffect } from 'react';
import './CompanyList.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Chart from "react-apexcharts";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";


const CompanyList = () => {
    const { state } = useContext(store);
    console.log('company list', state.user);
    const [company_list, setCompany_list] = useState([])
    const { update, handleSubmit } = useForm();
    const [bind_user, setBindUser] = useState([]);
    const [user_type, setUserType] = useState('');
    const [image64, setImage64] = useState("");
    const [selectedfile, setSelectedfile] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const printRef = React.useRef();



    const image = (e) => {

        setSelectedfile(e.target.files);
        console.log(e.target.files[0]);
        console.log(e.target.files[0].name);
        console.log(e.target.files[0].size);
        console.log(e.target.files[0].type);
    };

    //image to convert to base64 while storing

    const ConvertImageToBase64 = (event) => {
        // console.log('event', event.target.files[0]);
        let file = event.target.files[0];
        var reader = new FileReader();
        console.log('file upload');
        let base64;
        reader.readAsDataURL(file);
        reader.onload = () => {
            base64 = reader.result;
            console.log('base64', base64);
            setImage64(base64);
        };

        reader.onerror = (error) => {
            console.log('error :', error);
        };

    };

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('companylist.pdf');
    };

    //Main get method of company list 

    const Getcompanylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }

    const Getcompanydata = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setBindUser(res.data[0]);

            }).catch(() => {

            })
        }
    }

    useEffect(() => {
        Getbanklist();
        Getpaytotal();
        Getpayyear();
    }, [state]);
    useEffect(() => {
        Getcompanylist();
        Getempcount();
        Getcompanyaddress();
    }, [state]);

    let navigate = useNavigate();
    const addcompany = () => {
        let path = `/companyaddress`;
        navigate(path);
    }
    const companydetails = () => {
        let path = `/companydetails`;
        navigate(path);
    }
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        //setComname(data.company_name);
        //setcompanyadd1(data.company_address1);
        //setcompanyadd2(data.company_address2);
        setcity(data.city_id);
        //setpincode(data.pincode);
        setPCode(data.phonecode);
        setFCode(data.faxcode);
        setcountry(data.country);
        //setphonenumber(data.phone_number);
        setfax(data.company_fax_address);
        setProvi(data.province);
        setWebid(data.company_website_address);
        setImage64(data.company_logo);
        setcompanytitle(data.company_legalname);
        //setcompanytaxcode(data.company_tax_code);
        setcompanybank(data.bank_id);
        setUserType(type);
    }

    const alet = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ShowDetails(''),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to cancel'),

                }
            ]
        })
    }

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update'),

                }
            ]
        })
    }
    const savetax = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdatetax(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update'),

                }
            ]
        })
    }
    const savebank = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to update',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdatebank(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to update'),

                }
            ]
        })
    }
    const back = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to go back',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ShowDetails(''),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to go back'),

                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // watch events
    const com_name = watch('com_name')
    const companyadd1 = watch('companyadd1')
    const companyadd2 = watch('companyadd2')
    const pincode = watch('pincode')
    const phone_number = watch('phone_number')
    const companytaxcode = watch('companytaxcode')

    const [startDate, setStartDate] = useState(new Date());
    const [type_address, setTypeAddress] = useState('back');
    const [type_tax, setTypeTax] = useState('back');
    const [type_bank, setTypeBank] = useState('back');
    //const [com_name, setComname] = useState("");
    //const [companyadd1, setcompanyadd1] = useState("");
    //const [companyadd2, setcompanyadd2] = useState("");
    const [city, setcity] = useState("");
    //const [pincode, setpincode] = useState("");
    const [country, setcountry] = useState("");
    //const [phone_number, setphonenumber] = useState("");
    const [fax, setfax] = useState("");
    const [companylegalname, setcompanytitle] = useState("");
    //const [companytaxcode, setcompanytaxcode] = useState("");
    const [email, setEmailid] = useState("");
    const [web, setWebid] = useState("");
    const [companybankname, setcompanybank] = useState("");
    const [provi, setProvi] = useState("");
    const [vbanklist, setvbanklist] = useState([]);
    const [pcode, setPCode] = useState("+84");
    const [fcode, setFCode] = useState("");

    // update method of company_address

    const onUpdate = (company_id, e) => {
        if (!com_name || !companyadd1 || !phone_number || phone_number.lenght > '6') {
            alert('Company Name, Address , Phone No is required. Please provide a valid data.');
            return;
        }
        let company_add = {
            company_id: bind_user.company_id,
            status: "true",
            company_name: com_name,
            company_address1: companyadd1,
            company_address2: companyadd2,
            city: city,
            pincode: pincode,
            country: country,
            phone_number: phone_number,
            company_fax_address: fax,
            company_logo: image64,
            company_website_address: web,
            province: provi,
            phonecode: pcode,
            faxcode: fcode,

        };

        axios.put(`${process.env.REACT_APP_API_URL}/company_address/${bind_user.company_id}`, company_add).then((res) => {
            console.log('company address updated', res.data);
            Getcompanydata();
            TabChange("back");

            Getcompanylist();

        }).catch(() => {

        })
    }
    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };
    // update method of company_tax

    const onUpdatetax = (companytax_id, e) => {
        if (!companylegalname || !companytaxcode) {
            alert('Company legal Name, Tax code is required. Please provide a valid data.');
            return;
        }
        let company_tax = {

            companytax_id: bind_user.companytax_id,
            company_legalname: companylegalname,
            company_tax_code: companytaxcode
        };

        axios.put(`${process.env.REACT_APP_API_URL}/company_tax/${bind_user.companytax_id}`, company_tax).then((res) => {
            console.log('company address updated', res.data);
            Getcompanydata();
            TabChange1("back");
            Getcompanylist();
        }).catch(() => {

        })
    }

    // update method of company_bank

    const onUpdatebank = (company_bank_id, e) => {

        let company_bank = {

            company_bank_id: bind_user.company_bank_id,
            bank_id: companybankname,


        };

        axios.put(`${process.env.REACT_APP_API_URL}/company_bank/${bind_user.company_bank_id}`, company_bank).then((res) => {
            console.log('company address updated', res.data);
            Getcompanydata();
            TabChange2("back");
            Getcompanylist();
        }).catch(() => {

        })
    }


    const TabChange = (type) => {
        console.log('type', type_address)
        if (type == 'Address_edit') {
            console.log('Address_edit', type);
            setTypeAddress(type);
        }
        if (type == 'back') {
            console.log('back', type);
            setTypeAddress(type);
        }
    }
    const TabChange1 = (type) => {
        console.log('type', type_address)
        if (type == 'tax_edit') {
            console.log('tax_edit', type);
            setTypeTax(type);
        }
        if (type == 'back') {
            console.log('back', type);
            setTypeTax(type);
        }
    }
    const TabChange2 = (type) => {
        console.log('type', type_address)
        if (type == 'bank_edit') {
            console.log('bank_edit', type);
            setTypeBank(type);
        }
        if (type == 'back') {
            console.log('back', type);
            setTypeBank(type);
        }
    }
    const [paylist, setPaylist] = useState([]);
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                // type: 'datetime',
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

            },
            title: {
                text: 'Payroll Cost Monthly Analyser',
                align: 'left',
                style: {
                    fontSize: "14px",
                    color: '#0080FF'
                }
            },
            // fill: {

            //     colors: ['#0080FF', 'transparent']
            // },
            yaxis: {
                min: 1000000,
                max: 1000000000
            },
            dataLabels: {
                hideOverlappingLabels: true,
                style: {
                    colors: ['transparent']
                }

            }
        },
        series: [{

            name: 'Net Profit',

            data: [],

            color: 'rgba(0, 128, 255)'

        }, {

            name: 'Revenue',

            data: [],

            color: 'rgba(0, 227, 150 )'

        }, {

            name: 'Free Cash Flow',

            data: [],

            color: 'rgba(254, 176, 25)'

        }]

    })
    const [statee, set_state] = useState({
        options: {
            chart: {
                height: 150,
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                // type: 'datetime',
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

            },
            title: {
                text: 'Employee Headcount by Month',
                align: 'left',
                style: {
                    fontSize: "14px",
                    color: '#0080FF',
                    fontFamily: 'Comfortaa',
                    color: '#717372'
                }
            },
            fill: {

                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 100
            },

            dataLabels: {
                hideOverlappingLabels: true,

                style: {

                    colors: ['transparent']

                }





            }
        },

        series: [{
            name: 'Employee count',
            data: []
        }],

    })
    const Getpaytotalall = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payroll_graph/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'paylist');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
                        console.log(result.data, 'pay');
                        setPaylist(result.data);
                    })
                } else {
                    setPaylist(res.data);
                }
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    useEffect(() => {
        Getpaytotalall();
    }, []);

    useEffect(() => {
        const grossAmountData = paylist.map((entry) => Math.round(entry.grosamount));
        const netPayData = paylist.map((entry) => Math.round(entry.payrollcycle_amount));
        const taxAmountData = paylist.map((entry) => Math.round(entry.incometax));
        const currentYear = new Date().getFullYear(); // Get current year
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; // List of months
        const categories = months.map((month) => `${month} ${currentYear}`); // Add current year to each month

        setstate((prevState) => ({
            ...prevState,
            xaxis: {
                type: 'datetime',
                categories: months,


            },
            series: [
                {
                    name: 'Gross Amount',
                    data: grossAmountData,
                },
                {
                    name: 'Net Pay',
                    data: netPayData,
                },
                {
                    name: 'Tax Amount',
                    data: taxAmountData,
                },
            ],
        }));
    }, [paylist]);

    const [emp_count, setempcount] = useState([])
    const empcount = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_emp_countg/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'co_unt');
                setempcount(res.data);

            }).catch(() => {

            })
        }

    }
    useEffect(() => {
        const empc = emp_count.map((entry) => entry.employee_count);

        set_state((prevState) => ({
            ...prevState,


            series: [
                {
                    name: 'Employee Count',
                    data: empc,
                }
            ],
        }));
    }, [emp_count]);


    useEffect(() => {
        empcount();

    }, [state]);
    const goemp = () => {
        navigate("/companylist");

    };


    const addwrk = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }

    const Getbanklist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/bank/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setEmpList(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [paytotal, setPaytotal] = useState([]);
    const Getpaytotal = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payroll1cycle/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setPaytotal(res.data);
            }).catch(() => {

            })

        }

    }
    const [payyear, setPayyear] = useState([]);
    const Getpayyear = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payroll1cycleyear/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'year');
                setPayyear(res.data);
            }).catch(() => {

            })

        }

    }
    const [emplist, setEmpList] = useState([]);
    const [cmplist, setCmpList] = useState([]);
    const Getempcount = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address_count/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setCmpList(res.data);

            }).catch(() => {

            })
        }
    }


    const [countr, setcountryDetails] = useState([]);


    const Getcountry = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const [countrphcode, setcountryDetailsphcode] = useState([]);


    const Getcountryphonecode = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country_phonecode`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetailsphcode(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    useEffect(() => {
        Getcountry();
        Getvbanklist();
        Getvcitylist();
        Getcountryphonecode();
    }, []);
    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }

    const [comp_list, setComp_list] = useState([])
    const Getcompanyaddress = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companyaddressvbank/${state?.user[0].company_id}`).then((res) => {
                console.log(res.data, 'compbank');
                setComp_list(res.data);

            }).catch(() => {

            })
        }
    }
    const Getvbanklist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vbank`).then((res) => {
                console.log(res.data, 'success');
                setvbanklist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    const RenderView = () => {
        console.log('bind', bind_user);
        return <div className="container-fluid comedi">

            <div className="row  mt-5">
                <div className="col-sm-2 col-xxl-2 col-md-1 col-lg-1 col-xl-2  ms-5"></div>
                <div className="col-sm-9 col-xxl-9 col-md-11 col-lg-11 col-xl-9 ">
                    <div className="row">

                        <div className="col-sm-6  border-end">
                            <div className="row">
                                <div className="col-sm-6 ">
                                    <div className="row">
                                        <div className="col-5">
                                            {(() => {
                                                if (bind_user.company_logo == null || bind_user.company_logo == '') {
                                                    return <img className='img-fluid mt-4' src={`Images/complogo.jpg`} style={{ height: 65, width: 65 }}></img>
                                                } else {
                                                    return <img className='img-fluid mt-4 im_g' src={bind_user.company_logo} style={{ height: 65, width: 65 }} ></img>
                                                }
                                            })()}

                                        </div>
                                        <div className="col-7 texttab-az">
                                            <p className="mt-4" style={{ fontSize: "11px" }}> {bind_user.company_name}
                                                <p className="" >{bind_user.city}
                                                </p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 ">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="text-primary mt-3">Website</p>
                                            {(() => {
                                                if (bind_user.company_website_address == null || bind_user.company_website_address == '') {
                                                    return <p class="" >Nil</p>
                                                } else {
                                                    return <p class=" text-lowercase" >{bind_user.company_website_address}</p>
                                                }
                                            })()}

                                        </div>
                                        <div className="col-6">

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-6 ">
                                    <div className="row ">
                                        <div className="col-7">
                                            <p className="text-primary mt-3">Phone Number</p>
                                            <p>{bind_user.phone_number}</p>
                                        </div>
                                        <div className="col-5">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">

                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="monthly"
                                id="uncontrolled-tab-example"
                                className="mb-3 border-bottom border-primary">

                                <Tab eventKey="monthly" className="" title="Monthly">


                                    {paytotal.filter((val) => {
                                        if (searchTerm == "") {
                                            return val
                                        } else if (val.startdate.includes(searchTerm) || val.enddate.includes(searchTerm)) {
                                            return val
                                        }
                                    }).
                                        map((x, index) => {
                                            return (
                                                <div className="row">
                                                    <p className="text-primary">
                                                        {x.month}
                                                    </p>
                                                    <div className="col-sm-4">
                                                        <p className="text-secondary companynamesize">
                                                            Total Wage </p>{(() => {
                                                                if (x.grosamount == null) {
                                                                    return <p className="fs-4 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.grosamount.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}

                                                    </div>
                                                    <div className="col-sm-4 ">
                                                        <p className="text-secondary companynamesize">
                                                            Total Net Pay </p>{(() => {
                                                                if (x.payrollcycle_amount == null) {
                                                                    return <p className="fs-5 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.payrollcycle_amount.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}
                                                    </div>
                                                    <div className="col-sm-4 ">
                                                        <p className="text-secondary companynamesize">
                                                            Total IncomeTax</p>{(() => {
                                                                if (x.incometax == null) {
                                                                    return <p className="fs-4 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.incometax.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')} <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    <div class="card tabcardsize" >
                                        <div className='row'>
                                            <div className='col-2  ms-5'>
                                                <img className='img-fluid mx-auto d-block mt-2' src={`Images/clock.svg`} ></img>
                                            </div>
                                            <div className='col-7  text-white fw-bold text-center mt-3 pointer_' onClick={addwrk}>Update Work Schedule</div>

                                        </div>
                                    </div>

                                    <div className="row mt-2 chart_graph">
                                        <Chart

                                            options={statee.options}
                                            series={statee.series}
                                            type="bar"
                                            height="300px"

                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="yearly" className="" title="Yearly">
                                    {payyear.filter((val) => {
                                        if (searchTerm == "") {
                                            return val
                                        } else if (val.startdate.includes(searchTerm) || val.enddate.includes(searchTerm)) {
                                            return val
                                        }
                                    }).
                                        map((x, index) => {
                                            return (
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="text-secondary companynamesize">
                                                            Total Wage</p>{(() => {
                                                                if (x.gross == null) {
                                                                    return <p className="fs-5 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.gross.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')}  <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}

                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p className="text-secondary companynamesize">
                                                            Total Net Pay </p>{(() => {
                                                                if (x.netpay == null) {
                                                                    return <p className="fs-5 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.netpay.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')}  <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p className="text-secondary companynamesize">
                                                            Total Income Tax</p>{(() => {
                                                                if (x.tax == null) {
                                                                    return <p className="fs-5 fw-bold text-black">0</p>


                                                                } else {
                                                                    return <p className="fs-6 fw-bold text-black">{x.tax.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')}  <small className='text-lowercase hide_tab'>đ</small></p>
                                                                }
                                                            })
                                                                ()}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    <div class="card tabcardsize " >
                                        <div className='row'>
                                            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ms-5'>
                                                <img className='img-fluid mx-auto d-block mt-2' src={`Images/clock.svg`} ></img>
                                            </div>
                                            <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 text-white fw-bold text-center mt-2 pointer_' onClick={addwrk}>Update Work Schedule</div>

                                        </div>
                                    </div>
                                    <div className="row mt-2 chart_graph">
                                        <Chart

                                            options={stat_e.options}
                                            series={stat_e.series}
                                            type="bar"
                                            height="250"

                                        />
                                    </div>
                                </Tab>


                            </Tabs>

                        </div>

                        <div className="col-sm-6 tabcol-sec">
                            <Tabs
                                defaultActiveKey="address"
                                id="uncontrolled-tab-example"
                                className="mb-3 ms-3">

                                <Tab eventKey="address" className="ms-5" title="Address">
                                    {(() => {
                                        if (type_address == "Address_edit") {
                                            return <div>
                                                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                                                    <div className='row'>
                                                        <div className='col-sm-12 '>
                                                            <div className='mt-3'>
                                                                <div className='ms-1'>
                                                                    <small className='text-secondary ms-3'>Company Name</small>
                                                                    <div class="input-group ">
                                                                        {/* <input type="text" class="form-control inputcolor ms-3 textbox-work " Value={bind_user.company_name} onChange={(e) => setComname(e.target.value)} placeholder="Company Name" aria-label="Username" /> */}
                                                                        <input
                                                                            minlength="3" maxlength="50"
                                                                            type='text'
                                                                            placeholder='Company Name'
                                                                            Value={bind_user.company_name}
                                                                            className={`form-control inputcolor ms-3 txtaddr ${errors.com_name &&
                                                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                            {...register("com_name", {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: ' * Company name is required'
                                                                                },
                                                                                minLength: {
                                                                                    value: 3,
                                                                                    message: "Please enter Full Company Name ,minimum 3 characters required",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 50,
                                                                                    message:
                                                                                        "Maximum allowed length is 50 characters ",
                                                                                },
                                                                                pattern: {
                                                                                    value: /[a-zA-Z]+/,
                                                                                    message: 'Please enter only alphabets'
                                                                                },
                                                                            })}
                                                                        />
                                                                        <div>
                                                                            {errors.com_name && <span className='error_comp text-danger'>{errors.com_name.message}</span>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className="col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                                    <img className='img-fluid ms-3' src={image64} ></img>

                                                                </div>
                                                                <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3'>
                                                                    <label for="formFile" class="form-label  ms-4">Company Logo</label>
                                                                </div>
                                                                <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                                                    <div class="mt-2">
                                                                        <input class="form-control inputcolor" type="file" id="formFile" onChange={ConvertImageToBase64} />
                                                                        <span className=' text-danger text_box_size'>*Less than 620*420 pixel</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <div className='ms-3'>
                                                                    <div class="mt-2">
                                                                        <small className='text-secondary ms-2'>Address1</small>
                                                                        {/* <input type="address" class="form-control inputcolor  txtaddr  bg-light" Value={bind_user.company_address1} onChange={(e) => setcompanyadd1(e.target.value)} placeholder="Address 1" id="exampleFormControlInput1" /> */}
                                                                        <input
                                                                            minlength="3" maxlength="100"
                                                                            type='text'
                                                                            Value={bind_user.company_address1}
                                                                            placeholder='Address1'
                                                                            className={` form-control inputcolor   txtaddr required ${errors.companyadd1 &&
                                                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                            {...register("companyadd1", {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: ' * Address 1 field is required'
                                                                                },
                                                                                minLength: {
                                                                                    value: 3,
                                                                                    message: "Please enter Full Address ,minimum 3 characters required",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 100,
                                                                                    message:
                                                                                        "Maximum allowed length is 100 characters ",
                                                                                },
                                                                                pattern: {
                                                                                    value: /[a-zA-Z]+/,
                                                                                    message: 'Please enter only alphabets'
                                                                                },
                                                                            })}
                                                                        />

                                                                        <div>
                                                                            {errors.companyadd1 && <span className='text-danger'>{errors.companyadd1.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <div className='ms-3'>
                                                                    <div class="">
                                                                        <small className='text-secondary ms-2'>Address2</small>
                                                                        {/* <input type="address" class="form-control inputcolor txtaddr bg-light" Value={bind_user.company_address2} onChange={(e) => setcompanyadd2(e.target.value)} placeholder="Address 2" id="exampleFormControlInput1" /> */}
                                                                        <input
                                                                            minlength="3" maxlength="100"
                                                                            type='text'
                                                                            Value={bind_user.company_address2}
                                                                            placeholder='Address 2'
                                                                            //onChange={(e) => setcompanyadd2(e.target.value)}
                                                                            className={` form-control inputcolor txtaddr ${errors.companyadd2 &&
                                                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                            {...register("companyadd2", {
                                                                                required: {
                                                                                    value: true,

                                                                                },
                                                                                minLength: {
                                                                                    value: 3,
                                                                                    message: "Please enter Full Address,minimum 3 characters required",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 100,
                                                                                    message:
                                                                                        "Maximum allowed length is 100 characters ",
                                                                                },
                                                                                pattern: {
                                                                                    value: /[a-zA-Z0-9]+/,

                                                                                },
                                                                            })}
                                                                        />
                                                                        <div>
                                                                            {errors.companyadd2 && <span className='text-danger'>{errors.companyadd2.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=' mt-2'>
                                                                <div className='ms-3 '>
                                                                    <small className='text-secondary ms-2'>Country</small>
                                                                    <select class="form-select inputcolor  txtaddr" Value={bind_user.country} onChange={(e) => setcountry(e.target.value)} aria-label=" select Day" >

                                                                        <option selected disabled>{bind_user.country}</option>

                                                                        {countr.map((x, index) => {
                                                                            return (
                                                                                <option value={x.country_name} >{x.country_name}</option>
                                                                            );
                                                                        })}

                                                                    </select>
                                                                    <div class="">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-6 '>
                                                                    {(() => {

                                                                        if (bind_user.country == "Vietnam ") {
                                                                            return <div class="">
                                                                                {/* <input type="address" class="form-control inputcolor txtaddr " Value={city} onChange={(e) => setcity(e.target.value)} placeholder="City/Town*" id="exampleFormControlInput1" /> */}
                                                                                <small className='text-secondary ms-4'>City/Town</small>
                                                                                <select class="form-control inputcolor text-start ms-3 txtaddr" Value={bind_user.city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                    <option selected disabled>{bind_user.city} </option>
                                                                                    {vcitylist.map((x, index) => {
                                                                                        return (
                                                                                            <option value={x.city_id}>{x.city}</option>
                                                                                        );
                                                                                    })}
                                                                                </select>

                                                                            </div>
                                                                        } else {
                                                                            return <div class="">
                                                                                <small className='text-secondary ms-4'>City/Town</small>
                                                                                <input type="address" class="form-control inputcolor ms-3 txtaddr " Value={bind_user.city} onChange={(e) => setcity(e.target.value)} placeholder="City/Town*" id="exampleFormControlInput1" />


                                                                            </div>
                                                                        }
                                                                    })()}

                                                                </div>
                                                                <div className='col-6 '>
                                                                    <div class="">
                                                                        <small className='text-secondary ms-2'>Province</small>
                                                                        <input type="address" class="form-control inputcolor txtaddr bg-light" value={bind_user.province} onChange={(e) => setProvi(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=' row mt-2 '>

                                                                <div className='col-12'>
                                                                    <div class="ms-3">
                                                                        <small className='text-secondary ms-2'>Post Code</small>
                                                                        {/* <input type="address" class="form-control inputcolor txtaddr bg-light" Value={bind_user.pincode} onChange={(e) => setpincode(e.target.value)} placeholder="Pincode" id="exampleFormControlInput1" /> */}
                                                                        <input

                                                                            type='text'

                                                                            minlength="3" maxlength="10"
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            Value={bind_user.pincode}
                                                                            placeholder='Post Code'
                                                                            className={` form-control inputcolor txtaddr ${errors.pincode &&
                                                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                            {...register("pincode", {
                                                                                required: {
                                                                                    value: true,

                                                                                },
                                                                                minLength: {
                                                                                    value: 3,
                                                                                    message: "Please enter Full Post Code,Minimun 3 characters",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 10,
                                                                                    message:
                                                                                        "Maximum allowed length is 10 characters ",
                                                                                },
                                                                                pattern: {
                                                                                    value: /[0-9]+/,
                                                                                    message: 'can enter only Numbers'
                                                                                },
                                                                            })}
                                                                        />
                                                                        <div>
                                                                            {errors.pincode && <span className='text-danger'>{errors.pincode.message}</span>}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-2'>
                                                                <div className='ms-3'>
                                                                    <div class="">
                                                                        <small className='text-secondary ms-2'>Phone Number</small>
                                                                        <div class="input-group  border bg-white phonedrop txtaddr">

                                                                            <select class="text-start inputcolor phonedrop" Value={bind_user.phonecode} onChange={(e) => setPCode(e.target.value)} aria-label=" select Day">
                                                                                <option selected disabled>{bind_user.phonecode}</option>
                                                                                {countrphcode.map((x, index) => {
                                                                                    return (
                                                                                        <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                    );
                                                                                })}
                                                                            </select>

                                                                            {/* <input type="tel" class="form-control inputcolor border-top-0 border-bottom-0 border-end-0 bg-light" Value={bind_user.phone_number} onChange={(e) => setphonenumber(e.target.value)} aria-label="Text input with dropdown button" id="phone" name="phone" pattern="+91[7-9]{2}-[0-9]{3}-[0-9]{4}" placeholder="Phone Number" width={"-10%"} /> */}
                                                                            <input
                                                                                onPaste={handle_Paste}
                                                                                type='tel'
                                                                                placeholder='Phone Number'
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                minlength="7" maxlength="12"
                                                                                Value={bind_user.phone_number}
                                                                                className={` form-control inputcolor border-top-0 border-bottom-0 ${errors.phone_number &&
                                                                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                                {...register("phone_number", {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: ' * phone number field is required'
                                                                                    },
                                                                                    minLength: {
                                                                                        value: 7,
                                                                                        message: "Minimum add 7 digits",
                                                                                    },
                                                                                    maxLength: {
                                                                                        value: 12,
                                                                                        message:
                                                                                            "Maximum allowed length is 12 characters ",
                                                                                    },
                                                                                    pattern: {
                                                                                        value: /[0-9]+/,
                                                                                        message: 'Please enter only numbers'
                                                                                    },
                                                                                })}
                                                                            />
                                                                            <div>
                                                                                {errors.phone_number && <span className='error_ph text-danger'>{errors.phone_number.message}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <div className='ms-3'>
                                                                    <small className='text-secondary ms-2'>Fax</small>
                                                                    <div class="input-group  border bg-white phonedrop txtaddr">

                                                                        <select class="text-start inputcolor phonedrop" Value={bind_user.faxcode} onChange={(e) => setFCode(e.target.value)} aria-label=" select Day">
                                                                            <option selected disabled>{bind_user.faxcode} </option>
                                                                            {countrphcode.map((x, index) => {
                                                                                return (
                                                                                    <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                );
                                                                            })}
                                                                        </select>

                                                                        <input type="tel" class="form-control inputcolor border-top-0 border-bottom-0 border-end-0 bg-light" Value={bind_user.company_fax_address} onChange={(e) => setfax(e.target.value)} onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                            minlength="7" maxlength="12" aria-label="Text input with dropdown button" id="phone" placeholder="Fax Code" width={"-10%"} />
                                                                    </div>
                                                                    {/* <div class="">
                                                                        <input type="address" class="form-control inputcolor txtaddr bg-light " Value={bind_user.company_fax_address} onChange={(e) => setfax(e.target.value)} placeholder="Fax" id="exampleFormControlInput1" />
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className='mt-2'>
                                                                <div className='ms-3'>
                                                                    <div class="">
                                                                        <small className='text-secondary ms-2'>Website</small>
                                                                        <input minlength="2" maxlength="45" type="address" class="form-control inputcolor txtaddr bg-light " Value={bind_user.company_website_address} onChange={(e) => setWebid(e.target.value)} placeholder="Website" id="exampleFormControlInput1" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                                    <button class="btn btn-secondary ms-4" type="button" onClick={() => { TabChange("back") }}>Cancel</button>
                                                                </div>

                                                                <div className="col-6  col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">

                                                                    <button class="btn btn-pri " onSubmit={handleSubmit(save)} type="submit">Update</button>

                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'></div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        } if (type_address == "back") {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize">Company Name
                                                    <p className="text-black fs-6">{bind_user.company_name}</p></p>
                                                <p className="text-secondary companynamesize">Address 1
                                                    <p className="text-black fs-6">{bind_user.company_address1}</p></p>
                                                <p className="text-secondary companynamesize">Address 2
                                                    <p className="text-black fs-6">{bind_user.company_address2}</p></p>
                                                <p className="text-secondary companynamesize">Country
                                                    <p className="text-black fs-6">{bind_user.country}</p></p>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className="text-secondary companynamesize">City/Town
                                                            <p className="text-black fs-6">{bind_user.city}</p></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className="text-secondary companynamesize">Province
                                                            <p className="text-black fs-6">{bind_user.province}</p></p>
                                                    </div>
                                                </div>
                                                <div className='row'>

                                                    <div className='col-12'>
                                                        <p className="text-secondary companynamesize">Post Code
                                                            <p className="text-black fs-6">{bind_user.pincode}</p></p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className="text-secondary companynamesize">Phone Number
                                                            <p className="text-black fs-6">{bind_user.phonecode}  {bind_user.phone_number}</p></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className="text-secondary companynamesize">Fax
                                                            <p className="text-black fs-6">{bind_user.faxcode}  {bind_user.company_fax_address}</p></p>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-secondary" type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                    </div>

                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-pri" onClick={() => { TabChange("Address_edit") }} type="button">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}


                                </Tab>

                                <Tab eventKey="tax" className="ms-5" title="Tax">
                                    {(() => {
                                        if (type_tax == "tax_edit") {
                                            return <div>
                                                <form onSubmit={handleSubmit(savetax)} action="#" method="POST">
                                                    <div className='row'>
                                                        <div className='col-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                            <div className='mt-4'>
                                                                <div className=''>
                                                                    <small className='text-secondary ms-4'>Comapny Legal Name</small>
                                                                    <div class="input-group">
                                                                        <input minlength="2" maxlength="30" type="text" class="form-control inputcolor textbox-work ms-3 mt-2" Value={bind_user.company_legalname} onChange={(e) => setcompanytitle(e.target.value)} placeholder="Comapny Legal Name" aria-label="Username" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-4'>
                                                                <div className=''>
                                                                    <div class="ms-3">
                                                                        <small className='text-secondary ms-2'>Comapny Tax Code</small>
                                                                        {/* <input type="address" class="form-control inputcolor  txtaddr  bg-light" Value={bind_user.company_tax_code} onChange={(e) => setcompanytaxcode(e.target.value)} placeholder="Company Tax Code" id="exampleFormControlInput1" /> */}
                                                                        <input
                                                                            minlength="2" maxlength="30"
                                                                            type='text'
                                                                            Value={bind_user.company_tax_code}
                                                                            placeholder='Company Tax Code'
                                                                            className={` form-control inputcolor txtaddr ${errors.companytaxcode &&
                                                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                                            {...register("companytaxcode", {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: ' * Tax Code field is required'
                                                                                },
                                                                                minLength: {
                                                                                    value: 2,
                                                                                    message: "Please enter Full code",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 30,
                                                                                    message:
                                                                                        "Maximum allowed length is 30  ",
                                                                                },
                                                                                pattern: {
                                                                                    value: /[a-zA-Z0-9]+/,

                                                                                },
                                                                            })}
                                                                        />

                                                                        <div>
                                                                            {errors.companytaxcode && <span className=' text-danger mt-1'>{errors.companytaxcode.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-5'>
                                                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                                    <button class="btn btn-secondary ms-3" type="button" onClick={() => { TabChange1("back") }}>Cancel</button>
                                                                </div>

                                                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">

                                                                    <button class="btn btn-pri" type="submit" onSubmit={handleSubmit(savetax)}>Update</button>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </form>
                                            </div>

                                        } if (type_tax == "back") {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize mt-5">Company Legal Name
                                                    <p className="text-black fs-6">{bind_user.company_legalname}</p></p>
                                                <p className="text-secondary companynamesize">Company Tax Code
                                                    <p className="text-black fs-6">{bind_user.company_tax_code}</p></p>

                                                <div className="row">
                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-secondary" type="submit" onClick={() => { ShowDetails('') }}>Back</button>

                                                    </div>

                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-pri" onClick={() => { TabChange1("tax_edit") }} type="submit">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}
                                </Tab>

                                <Tab eventKey="bank" className="ms-5" title="Bank" >
                                    {(() => {
                                        if (type_bank == "bank_edit") {
                                            return <div>
                                                <form onSubmit={handleSubmit(savebank)} action="#" method="POST">
                                                    <div className='row'>
                                                        <div className='col-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                            <div className='mt-5'>
                                                                <div className='ms-1'>
                                                                    {comp_list.map((b, index) => {
                                                                        return (<div>
                                                                            {(() => {
                                                                                console.log(b.country, 'check');
                                                                                if (b.country == "Vietnam ") {
                                                                                    return <> <small className='text-secondary ms-2'>Bank Name</small>
                                                                                        <select class="form-select inputcolor text-start  bankaccountbox" Value={bind_user.vbankname} onChange={(e) => setcompanybank(e.target.value)} aria-label=" select Day">
                                                                                            <option selected disabled>{bind_user.vbankname}</option>
                                                                                            {vbanklist.map((x, index) => {
                                                                                                return (
                                                                                                    <option value={x.vbankid} onChange={(e) => setcompanybank(e.target.value)} >{x.vbankname}</option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </>
                                                                                } else {
                                                                                    return <> <small className='text-secondary ms-2'>Bank Name</small>
                                                                                        <select class="form-select inputcolor text-start text-secondary bankaccountbox" Value={bind_user.bankname} onChange={(e) => setcompanybank(e.target.value)} aria-label=" select Day">
                                                                                            <option selected disabled>{bind_user.bankname}</option>
                                                                                            {emplist.map((x, index) => {
                                                                                                return (
                                                                                                    <option value={x.id} onChange={(e) => setcompanybank(e.target.value)} >{x.bankname}</option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </>
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                        );
                                                                    })}


                                                                </div>
                                                            </div>
                                                            <div className='row mt-5'>
                                                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                                    <button class="btn btn-secondary ms-3" type="button" onClick={() => { TabChange2("back") }}>Cancel</button>
                                                                </div>

                                                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">

                                                                    <button class="btn btn-pri " type="submit" onSubmit={handleSubmit(savebank)}>Update</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>
                                        } if (type_bank == "back") {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize mt-5">Bank Name
                                                    <p className="text-black fs-6">{bind_user.vbankname}</p></p>


                                                <div className="row">
                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-secondary " type="button" onClick={() => { ShowDetails('') }}>Back</button>

                                                    </div>

                                                    <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid">
                                                        <button class="btn btn-pri " onClick={() => { TabChange2("bank_edit") }} type="button">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>


            </div>


            <Lheader />
        </div>;
    }
    return (
        <div className='bg-light vh-100 container-fluid scrl' >

            <div className='row'>
                <div className='col-sm-2 col-xxl-2  col-md-2 col-lg-2 col-xl-2 hid_tab'>

                </div>
                <div className='col-sm-10 col-xxl-10  col-md-12 col-lg-12 col-xl-10 bg-light mt-5 '>

                    <div class="card taskcard shadow ms-sm-5 mb-5 bg-white rounded">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-sm-10'>

                                    <p className=' ms-2 fs-4 fnt-clr'>Company Details</p>
                                </div>


                            </div>
                            <div className='row' ref={printRef} >
                                <div className='col-sm '>

                                    <div className="row">
                                        <div className="ms-4 mt-2 onlytab hide_tab">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <table class="table table-bordered mt-4 year-buttonss">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <td scope="col"></td>
                                                    <td scope="col" className='headfont  text-center text-uppercase grey'>Name</td>

                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>City</td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>No of Employees  </td>

                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Company Tax Code  </td>
                                                    <td scope="col" className='headfont  text-center text-uppercase grey'>Status </td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {company_list.filter((val) => {
                                                    if (searchTerm == "") {
                                                        return val
                                                    } else if (val.company_name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                                                        return val
                                                    }
                                                }).map((x, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            {(() => {
                                                                if (x.company_logo == null || x.company_logo == '') {
                                                                    return <td scope="row"><div className="text-center" > <img className='img-fluid' src={`Images/complogo.jpg`} style={{ height: 65, width: 65 }}></img> </div></td>
                                                                } else {
                                                                    return <td scope="row"><div className=" text-center"  > <img className='img-fluid im_g' src={x.company_logo} style={{ height: 65, width: 65 }} ></img> </div></td>
                                                                }
                                                            })()}

                                                            <td className='mt-2 txt_tp text-center pointer_ greyh' onClick={() => ShowDetails(x, 'view')}>{x.company_name}</td>
                                                            <td className=' txt_tp text-center greyh'>{x.city}</td>
                                                            {cmplist.map((x, index) => {
                                                                return (
                                                                    <td className='  txt_tp text-center greyh'>{x.case}</td>
                                                                );
                                                            })}
                                                            <td className=' txt_tp text-center greyh'>{x.company_tax_code}</td>
                                                            {(() => {
                                                                if (x.status == true) {
                                                                    return <td className='txt_tp text-center greyh'>Active</td>
                                                                } else {
                                                                    return <td className='txt_tp text-center greyh'>Inactive</td>
                                                                }
                                                            })()}
                                                            <td >{ }</td>

                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();
                    //case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />
        </div>
    );
}
export default CompanyList;