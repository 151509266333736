import "./Emp_profile_edit.css";
import Lheader from '../Lheader/Lheader';
import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { store } from '../../store/user';

const Adminprofile = (props) => {
    const { state } = useContext(store);
    console.log('profile', state.user);
    const [adminprofile, setUserDetails] = useState([]);

    let navigate = useNavigate();

    const gohome = () => {
        navigate("/admindashboard");
    };
    const Getadmin = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/get_admin/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);
            }).catch(() => {

            })
        }
        // var id = this.props.match.params.emp_id;

    }
    useEffect(() => {
        Getadmin();
    },
        [state]);

    const onUpdateUserprofile = () => {

        let image = {

            user_image: image64

        };
        console.log("user", image);

        axios.put(`${process.env.REACT_APP_API_URL}/update_userimage/${state.user[0].user_id}`, image).then((res) => {
            console.log('profile updated', res.data);
            onlogin();

            gohome();
        }).catch(() => {
            alert("image size is large ,insert Less than 620*420 pixel*")
        })
    }
    const [image64, setImage64] = useState("");
    const [image, set_Image64] = useState();
    const ConvertImageToBase64 = (event) => {
        // console.log('event', event.target.files[0]);
        set_Image64(true);
        let file = event.target.files[0];
        var reader = new FileReader();
        console.log('file upload');
        let base64;
        reader.readAsDataURL(file);
        reader.onload = () => {
            base64 = reader.result;
            console.log('base64', base64);
            setImage64(base64);
        };
        reader.onerror = (error) => {
            console.log('error :', error);
        };
    };

    // update context api for profile image
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const [login, setlogin] = useState("");
    const [user, setUserId] = useState([]);
    const onlogin = (data, e) => {
        let bas = {
            loginmail: state?.user[0]?.loginmail,
            password: state?.user[0]?.password,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
            console.log('valid User', res.data);
            //  Getloginuser () ;
            if (res.data.length > 0) {

                setlogin(res.data);
                localStorage.setItem('user_details', JSON.stringify(res.data));
                setUserId(res.data[0].login_id);
                console.log(res.data[0].login_id, 'login_id')
                dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
            }


        }).catch(() => {
            //  alert("wrong password");
        })
    }
    return (
        <div className="bg-light vh-100 container-fluid">
            <div className="row mt-5">
                <div className="    col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 hid_tab"></div>
                <div className="col-sm-5  col-xxl-5 col-sm-4 col-md-8 col-lg-8 col-xl-5  mt-3 ">
                    <p className="text-end fw-bold fs-4 fnt-clr"> Admin Profile</p>
                    {adminprofile.map((x, index) => {
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-sm-4">
                                        <p className="text-primary fw-bold">First Name</p>
                                        <p className="">
                                            {x.first_name}
                                        </p>
                                        <p className="text-primary fw-bold">Last Name</p>
                                        <p className="">
                                            {x.lastuname}
                                        </p>
                                        <p className="text-primary fw-bold">Email</p>
                                        <p className="text-lowercase">
                                            {x.email}
                                        </p>
                                        <p className="text-primary fw-bold">Company Name</p>
                                        <p className="">
                                            {x.company_name}
                                        </p>
                                        <p className="text-primary fw-bold">Profile Image</p>
                                        <img className='img-fluid ' src={x.user_image} ></img>
                                        <img className='mt-4 img-fluid' src={image64} ></img>
                                        <p className="text-primary fw-bold">New Profile Image</p>

                                        <input class="form-control inputcolor mt-2 " type="file" id="formFile" onChange={ConvertImageToBase64} />
                                        <div className="row">
                                            <div className="col-sm-6 d-grid">
                                                {(() => {
                                                    if (image == true) {
                                                        return <button className="btn btn-pri mt-3 " onClick={onUpdateUserprofile}  >Update </button>
                                                    } else {
                                                        return <button className="btn btn-pri mt-3 " onClick={onUpdateUserprofile} disabled>Update </button>
                                                    }
                                                })()}

                                            </div><div className="col-sm-6 d-grid">
                                                <button className=' btn btn-secondary  mt-3   ' onClick={gohome}  > Back</button>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        );
                    })}
                </div>
                <div className="  col-sm-4  col-xxl-4 col-sm-3 col-md-4 col-lg-4 col-xl-4  mt-5 ">
                    <img className="rounded mx-auto  mt-5 d-block img-fluid" src="./Images/Your_Work.svg"></img>
                </div>

            </div>
            <Lheader />
        </div>
    )
}
export default Adminprofile