import React, { useState, useContext } from 'react';
import './CompanyTax.css';
import { useNavigate, useLocation } from "react-router-dom";
import EHeader from '../Header/EHeader'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';

const CompanyTax = (props) => {
    const { state } = useContext(store);
    console.log('companytax', state.user);
    const [user_details, setuserDetails] = useState([]);
    const [user, setUserId] = useState([]);
    const { update, handleSubmit } = useForm();

    const location = useLocation();
    console.log('company_id: ', location?.state?.company_id,);
    console.log('company_name: ', location?.state?.company_name,);


    const [companylegalname, setcompanytitle] = useState(location?.state?.company_name || "");
    //const [companytaxcode, setcompanytaxcode] = useState("");

    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const companytaxcode = watch('companytaxcode')


    // handle disabled submit
    const isValid = companytaxcode


    const onInsert = (data, e) => {
        try {
            let tax = {
                company_id: location?.state?.company_id,
                company_legalname: companylegalname,
                company_tax_code: companytaxcode
            };

            axios.post(`${process.env.REACT_APP_API_URL}/company_tax`, tax).then((res) => {
                console.log('tax details added', res.data);
                //alert(res?.data?.company_id);
                companypayment(res?.data?.company_id);


                //onlogin();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const onlogin = (data, e) => {
        let bas = {
            loginmail: state?.user[0]?.loginmail,
            password: state?.user[0]?.password,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
            console.log('valid User', res.data);
            //  Getloginuser () ;
            if (res.data.length > 0) {

                setuserDetails(res.data);
                localStorage.setItem('user_details', JSON.stringify(res.data));
                setUserId(res.data[0].login_id);
                console.log(res.data[0].login_id, 'login_id')
                dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
            }


        }).catch(() => {
            //  alert("wrong password");
        })
    }
    const alert = (id) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Data saved successfully click Ok to continue',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => companypayment(id),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },

            ]
        })
    }


    let navigate = useNavigate();
    const companypayment = (id) => {
        let path = `/companyaccount`;
        navigate(path, { state: { company_id: id } });
    }
    return (
        <div className='container-fluid scrl'>

            <div className='row  mt-5'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2'>

                </div>
                <div className=' col-xxl-10 col-sm-10 col-md-9 col-lg-10 col-xl-10 mt-3'>
                    {/* <a class="nav-link text-primary fw-bold mobhide mobcompy " aria-current="page" ><img className=" mobhide" src="./Images/mobcompany.svg"></img>Company Information</a> */}
                    <div className=' fw-normal  text-center fw-bold mt-4'> <a className='fs-6' style={{ textDecoration: "none", color: " #0080FF" }}>Step:1</a>  Let's add your company details  </div>
                    <div className='border-bottom'>
                        <nav className='tabs-center'>
                            <div class="nav mt-3 " id="nav-tab" role="tablist">
                                <div className='row scroll-containercomp'>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 addlefmob"> <img className='img-fluid ms-4' src={`Images/add.svg`} ></img></div>
                                            </div>
                                            <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'>
                                                <a class="nav-item nav-link active text-secondary text-tabcolor addlefmob" id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">Address</a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#53B7B7" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 taxlefmob"> <img className='img-fluid ms-4' src={`Images/tax.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link  text-tabcolor  border-bottom border-primary border-3 taxlefmob" style={{ color: " #0080FF" }} id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">Tax </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <div class=" mt-1 banklefmob"> <img className='img-fluid ms-5' src={`Images/bank.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link text-secondary text-tabcolor banklefmob1" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">Bank </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>

                    </div>
                    <div className='row'>
                        <div className='col-3 col-xxl-3 col-sm-3 col-md-1 col-lg-3 col-xl-3'>
                        </div>
                        <div className=' col-xxl-5 col-sm-5 col-md-9 col-lg-5 col-xl-5 addtextmob'>
                            <div className='ms-1 mt-5'>
                                <div class="form-group">
                                    <label for="formGroupExampleInput" class=" ms-5">Company Legal Name</label>
                                    <div class="input-group ms-5">
                                        <input minlength="2" maxlength="50" type="text" class="form-control inputcolor textbox-work " Value={companylegalname} onChange={(e) => setcompanytitle(e.target.value)} placeholder="Company Legal Name" aria-label="Username" />
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className=''>
                                    <div class="mt-3">
                                        {/* <input type="address" class=" ms-1 form-control inputcolor txtaddr ms-5" Value={companytaxcode} onChange={(e) => setcompanytaxcode(e.target.value)} placeholder="Company Tax Code" id="exampleFormControlInput1" /> */}
                                        <div class="">
                                            <div class="form-group">
                                                <label for="formGroupExampleInput" class="required ms-5">Company Tax Code</label>
                                                <input
                                                    minlength="2" maxlength="30"
                                                    type='text'
                                                    placeholder='Company Tax Code'
                                                    className={` form-control inputcolor ms-5 txtaddr ${errors.companytaxcode &&
                                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                    {...register("companytaxcode", {
                                                        required: {
                                                            value: true,
                                                            message: ' * Tax Code field is required'
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: "Please enter Full code",
                                                        },
                                                        maxLength: {
                                                            value: 30,
                                                            message:
                                                                "Maximum allowed length is 30  ",
                                                        },
                                                        pattern: {
                                                            value: /[a-zA-Z0-9]+/,

                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div>
                                                {errors.companytaxcode && <span className='ms-5 text-danger mt-1'>{errors.companytaxcode.message}</span>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'></div>
                                <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-4'>
                                    <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                        <button class="btn btn-pri ms-5 w-75 nxtbtn1" type="submit" disabled={!isValid}>Next</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br></br><br></br>
                    {/* <a class="nav-link text-secondary fw-bold mobhide text  mobcompy1 " ><img className="mobhide" src="./Images/mobwork.svg"></img>Work Schedules</a>
                    <a class="nav-link fw-bold  text-secondary mobhide mobcompy" ><img className="mobhide" src="./Images/Account.png"></img>Add Employes</a> */}


                </div>
            </div>
            <div className='row'>
                <div className='col-5'></div>
                <div className='col-3 mobsimplytext hide_tab'>
                    <img className="img-fluid d-block mx-auto ms-3" src="./Images/midimg1.png" />
                </div>
                <div className='col-3'></div>
            </div>



            <EHeader /></div>
    );
}
export default CompanyTax;
