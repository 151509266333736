import React, { useCallback, useState, useContext, useEffect } from 'react';

import './Payrolllist.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useForm } from 'react-hook-form';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { store } from '../../store/user';
import ReactPaginate from "react-paginate";

const Newpayroll = () => {
    const { state } = useContext(store);
    console.log('New payrolcycle', state.user);
    const { update, handleSubmit } = useForm();
    const [palist, setPayList] = useState([]);
    const [startdt, setstartdt] = useState("");
    const [enddt, setenddt] = useState("");
    const [paytype, setpaytype] = useState("");
    const [mon, setMon] = useState("");
    const [cmplist, setCmpList] = useState([]);
    const printRef = React.useRef();
    const [searchTerm, setSearchTerm] = useState("");
    const [paytotal, setPaytotal] = useState([]);
    let navigate = useNavigate();
    const companydetails = () => {
        let path = `/newpayroll`;
        navigate(path);
    }
    const payrolcycle = (id, startdate, enddate, month) => {
        let path = `/payrolecycle`;
        navigate(path, { state: { payroll_id: id, startdate: startdate, enddate: enddate, month: month } });
    }
    const leaveEnc = () => {
        let path = `/leave`;
        navigate(path);
    }

    const deletePayrollNow = (id) => {
        try {
            axios.delete(`${process.env.REACT_APP_API_URL}/payrollcycle/` + id).then((res) => {
                console.log(res.data, 'success');
                window.location.reload();
            })
        } catch (err) {
            console.log(err.message);
        }
    };

    const deletePayroll = (id, count) => {
        if (count == null || count == 0) {
            confirmAlert({
                message: 'Are you sure want to delete the payroll cycle?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deletePayrollNow(id),
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            })
        }
    }
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [storedMonth, setStoredMonth] = useState();

    const handleChange = (event) => {
        if (!storedMonth) {
            setStoredMonth(event.target.value);
            setMon(event.target.value);
        } else {
            const selectedIndex = months.indexOf(event.target.value);
            const storedIndex = months.indexOf(storedMonth);

            if (selectedIndex === storedIndex + 1 || (selectedIndex === 0 && storedIndex === months.length - 1)) {
                setMon(event.target.value);
                // save selectedMonth to database or local storage here
            } else {
                alert('Please select the months in sequence');
            }
        }
    };


    const Getpaylist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/payrollcycles/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setPayList(res.data);
                setStoredMonth(res.data[0].month)
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const Getpaytotal = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/paytotal`).then((res) => {
                console.log(res.data, 'success');
                setPaytotal(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getpaytotal();
    }, []);
    useEffect(() => {
        Getpaylist();
        Getcmplist();
    }, [state]);

    // handle disabled submit
    const isValid = mon && startdt && enddt

    const checkifValidDate = (inputDate) => {
        let isValid = true;
        palist.forEach(item => {
            if (moment(item.startdate).isSameOrBefore(inputDate) && moment(item.enddate).isSameOrAfter(inputDate)) {
                isValid = false;
            }
        });
        return isValid
    }
    const checkifValidProcess = (inputDate) => {
        let isValid = true;
        palist.forEach(item => {
            if (item.payrollcycle_amount === null) {
                isValid = false;
            }
        });
        return isValid
    }

    // const onInsert = (data, e) => {
    //     if (startdt != '' && enddt != '') {
    //         if (checkifValidDate(startdt) && checkifValidDate(enddt)) {
    //             if (checkifValidProcess()) {
    //                 try {
    //                     let pay = {
    //                         startdate: startdt,
    //                         enddate: enddt,
    //                         payrolltype: paytype,
    //                         user_id: state?.user[0]?.user_id,
    //                         company_id: state?.user[0]?.company_id,
    //                         month: mon,
    //                     };

    //                     axios.post(`${process.env.REACT_APP_API_URL}/payrollcycle`, pay).then((res) => {
    //                         console.log('payroll cycle added', res.data);
    //                         Getpaylist();
    //                         setMon("");
    //                         setstartdt("");
    //                         setenddt("");

    //                     })
    //                 } catch (err) {
    //                     console.log(err.message);
    //                 }
    //             } else {
    //                 confirmAlert({

    //                     message: <div>
    //                         <p className='fs-5 fw-bold'>Error</p>
    //                         <p>Last month payroll not processed !</p>
    //                     </div>,
    //                     buttons: [
    //                         {
    //                             label: 'Close',
    //                             onClick: () => { },
    //                         },
    //                     ]
    //                 });
    //             }
    //         } else {
    //             confirmAlert({
    //                 message: 'Please select the start date or end date which has no existing payrolls',
    //                 buttons: [
    //                     {
    //                         label: 'Close',
    //                         onClick: () => { },
    //                     },
    //                 ]
    //             });
    //         }
    //     } else {
    //         confirmAlert({
    //             message: 'Please select both Start date & End date',
    //             buttons: [
    //                 {
    //                     label: 'Close',
    //                     onClick: () => { },
    //                 },
    //             ]
    //         });
    //     }
    // }


    const onInsert = (data, e) => {
        if (startdt !== '' && enddt !== '') {
            const startDate = moment(startdt);
            const endDate = moment(enddt);

            if (endDate.isBefore(startDate)) {
                confirmAlert({
                    message: 'End date cannot be before the start date.',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ],
                });
                return;
            }

            const daysInRange = (endDate.diff(startDate, 'days') + 1);
            if (daysInRange < 28 || daysInRange > 31) {
                confirmAlert({
                    message: 'The date range should be between 28 and 31 days.',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ],
                });
                return;
            }
            if (palist.length > 0) {
                const previousPayrollCycleMonth = palist[0].month; // Get the month from the previous payroll cycle
                const currentMonthIndex = months.indexOf(mon);

                if (currentMonthIndex !== -1) {
                    const previousMonthIndex = months.indexOf(previousPayrollCycleMonth);
                    const allowedMonthIndex = (previousMonthIndex + 1) % 12;

                    if (currentMonthIndex !== allowedMonthIndex) {
                        confirmAlert({
                            message: 'Please select the month in sequence based on previous payroll cycles.',
                            buttons: [
                                {
                                    label: 'Close',
                                    onClick: () => { },
                                    style: {
                                        backgroundColor: '#F36E35'

                                    }
                                },
                            ],
                        });
                        return;
                    }
                }
            }

            let previousPayrollCycleEndDate = null;
            if (palist.length > 0) {
                previousPayrollCycleEndDate = moment(palist[0].enddate);

                const nextStartDate = previousPayrollCycleEndDate.add(1, 'day');

                if (!nextStartDate.isSame(startDate)) {
                    confirmAlert({
                        message: 'Start date should be the day after the previous payroll cycle end date.',
                        buttons: [
                            {
                                label: 'Close',
                                onClick: () => { },
                                style: {
                                    backgroundColor: '#F36E35'

                                }
                            },
                        ],
                    });
                    return;
                }
            } else {
                const selectedMonth = moment(mon, 'MMMM').format('MMMM');
                const startMonth = startDate.format('MMMM');
                if (startMonth !== selectedMonth) {
                    confirmAlert({
                        message: 'The start date should be within the selected month.',
                        buttons: [
                            {
                                label: 'Close',
                                onClick: () => { },
                                style: {
                                    backgroundColor: '#F36E35'

                                }
                            },
                        ],
                    });
                    return;
                }
            }

            if (checkifValidDate(startDate) && checkifValidDate(endDate)) {
                if (checkifValidProcess()) {
                    try {
                        let pay = {
                            startdate: startdt,
                            enddate: enddt,
                            payrolltype: paytype,
                            user_id: state?.user[0]?.user_id,
                            company_id: state?.user[0]?.company_id,
                            month: mon,
                        };

                        axios.post(`${process.env.REACT_APP_API_URL}/payrollcycle`, pay).then((res) => {
                            console.log('payroll cycle added', res.data);
                            handleSelectChange();
                            Getpaylist();
                            setMon('');
                            setstartdt('');
                            setenddt('');
                        });
                    } catch (err) {
                        console.log(err.message);
                    }
                } else {
                    confirmAlert({
                        message: (
                            <div>
                                <p className='fs-5 fw-bold'>Error</p>
                                <p>Last month's payroll was not processed!</p>
                            </div>
                        ),
                        buttons: [
                            {
                                label: 'Close',
                                onClick: () => { },
                                style: {
                                    backgroundColor: '#F36E35'

                                }
                            },
                        ],
                    });
                }
            } else {
                confirmAlert({
                    message: 'Please select a start date and end date that have no existing payrolls.',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ],
                });
            }
        } else {
            confirmAlert({
                message: 'Please select both start date and end date.',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ],
            });
        }
    };




    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message: 'Are you sure want to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => window.location = "/newpayroll",
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Are you sure not to save')
                }
            ]
        })
    }
    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('payrolllist.pdf');
    };

    const Getcmplist = (task_id, x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address_count/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setCmpList(res.data);

            }).catch(() => {

            })
        }
    }
    const payrlist = (id, startdate, enddate, month) => {
        let path = `/payrolllist`;
        navigate(path, { state: { payroll_id: id, startdate: startdate, enddate: enddate, month: month } });
    }
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 4;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = palist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(palist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [finance, setfin] = useState([]);

    const Getfinancial = (x) => {

        if (state.user) {

            axios.get(`${process.env.REACT_APP_API_URL}/financialyear/${state?.user[0]?.company_id}`).then((res) => {

                console.log(res.data, 'fin');

                setfin(res.data);




            }).catch((error) => {

                console.log(error, 'success');

            });

        }

    }

    useEffect(() => {

        Getfinancial();

    }, [state]);

    console.log(finance);

    const monthNames = [

        'January',

        'February',

        'March',

        'April',

        'May',

        'June',

        'July',

        'August',

        'September',

        'October',

        'November',

        'December',

    ];

    const handleSelectChange = () => {

        const selectedMonth = mon;

        console.log(selectedMonth, 'selectedMonth');

        console.log(finance, finance[0]?.startmonth, finance[0]?.endmonth, 'selectedMonth');

        if (finance[0]?.startmonth && finance[0]?.endmonth) {

            const startMonthValue = new Date(finance[0]?.startmonth).getMonth();

            const endMonthValue = new Date(finance[0]?.endmonth).getMonth();

            console.log(monthNames[startMonthValue], monthNames[endMonthValue], 'selectedMonth');

            if (selectedMonth === monthNames[endMonthValue] || selectedMonth === monthNames[startMonthValue]) {



                confirmAlert({




                    message: <div>

                        <p className='fs-5 fw-bold'>Leave encashment applicable</p>



                    </div>,

                    buttons: [

                        {

                            label: 'Proceed',

                            onClick: () => { leaveEnc() },
                            style: {
                                backgroundColor: '#F36E35'

                            }

                        }

                    ]

                });

            }

        }

    };

    return (
        <div className='bg-light vh-100 container-fluid'>

            <div className='row mt-5'>
                <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2  hid_tab'>

                </div>
                <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3  '>

                    <p className=' text-center mt-4 fnt-clr tbl '>
                        Select Payroll Cycle
                    </p>
                    <div className="row ms-sm-5">
                        <div className='col-1'></div>
                        <div className='col-sm-3'>
                            <p className='ms-sm-2 required greyh'> Month</p>

                            <select class="form-select inputcolor text-start text_box_size inputcolor " value={mon} onChange={(e) => setMon(e.target.value)}>
                                <option value="" >Select a month</option>
                                {months.map((month) => (
                                    <option key={month} value={month}>
                                        {month}
                                    </option>
                                ))}
                            </select>



                        </div>
                        <div className="col-sm-3 ">
                            <p className='ms-sm-2 required greyh'> Start Date</p>
                            <input type="date" class="form-control inputcolor  text_box_size inputcolor " value={startdt} onChange={(e) => setstartdt(e.target.value)} placeholder="" aria-label="" />




                        </div>

                        <div className="col-sm-3">
                            <p className='ms-sm-2 required greyh'> End Date</p>
                            <input type="date" class="form-control inputcolor text_box_size inputcolor" value={enddt} onChange={(e) => setenddt(e.target.value)} placeholder="" aria-label="" />

                        </div>

                    </div>
                    <div className='row mt-3'>
                        <div className='col-sm-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ms-4'>

                        </div>
                        <div className='col-sm-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-grid'>
                            <form onSubmit={handleSubmit(onInsert)} action="#" className='d-grid' method="POST">
                                <button class="btn btn-pri mt-4 ms-3" type="submit" disabled={!isValid}>New Payroll</button>
                            </form>
                        </div>

                    </div>
                    <div class="mt-2 card taskcard ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-10'>
                                    <p className=' fs-4 fnt-clr'>Payroll History</p>
                                </div>
                                <div className='col-2 pointer_'>
                                    <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                    </svg>


                                </div>

                            </div>
                            <div className='row' ref={printRef}>
                                <div className='col-sm'>
                                    <div className="row">
                                        <div className="ms-4 mt-2 onlytab">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <table class="table table-bordered mt-4 year-buttonss">
                                        <table class="table">
                                            <thead>
                                                <tr>



                                                    <td scope="col" className='headfont   text-uppercase  text-center grey'>Payroll Month </td>

                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Total Employees</td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Employees Processed</td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Total Gross Pay<small className='text-lowercase'>(đ)</small></td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Take Home<small className='text-lowercase'>(đ)</small></td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Income Tax<small className='text-lowercase'>(đ)</small></td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Payroll Status   </td>
                                                    <td scope="col" className='headfont   text-center text-uppercase grey'>Payment Status   </td>


                                                </tr>
                                            </thead>
                                            <tbody>

                                                {displayUsers.filter((val) => {
                                                    if (searchTerm == "") {
                                                        return val
                                                    } else if (val.month.includes(searchTerm)) {
                                                        return val
                                                    }
                                                }).
                                                    map((x, index) => {
                                                        return (
                                                            <tr >
                                                                <td onClick={() => { payrolcycle(x.payroll_id, x.startdate, x.enddate, x.month) }} className='  text-center pointer_ greyh'>{x.month}<p className='clickable' >({moment(x.startdate).format('DDMMMYY')}/{moment(x.enddate).format('DDMMMYY')})</p></td>

                                                                {/* {cmplist.map((y, index) => {
                                                                    return ( */}
                                                                <td className='text-center txt_tp greyh' >{x.totalemp}</td>
                                                                {/* );
                                                                })} */}

                                                                {(() => {

                                                                    if (x.noofemp == null) {
                                                                        return <td className=' text-center txt_tp'>0</td>
                                                                    } else {
                                                                        return <td className=' text-center txt_tp greyh'>{x.noofemp}</td>
                                                                    }
                                                                })()}

                                                                <td className=' text-center txt_tp text-lowercase greyh'>{Math.round(x.grosamount).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                <td className=' text-center txt_tp text-lowercase greyh'>{Math.round(x.payrollcycle_amount).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                <td className=' text-center txt_tp text-lowercase greyh'>{Math.round(x.incometax).toLocaleString('en-US').replace(/,/g, '.')}</td>
                                                                {(() => {
                                                                    if (x.noofemp == 0) {
                                                                        return <td className=' text-center txt_tp greyh'>Not Processed </td>
                                                                    }
                                                                    if (x.noofemp < x.totalemp && x.noofemp > 0 && (x.runpayemp == 0)) {
                                                                        return <td className=' text-center greyh'>Partially Processed</td>
                                                                    } else if (x.noofemp == x.totalemp && x.runpayemp != x.totalemp) {
                                                                        return <td className=' text-center txt_tp greyh'>Processed</td>
                                                                    } else if (x.runpayemp < x.totalemp && x.runpayemp > 0) {
                                                                        return <td className=' text-center greyh'>Partially Completed</td>
                                                                    } else if (x.runpayemp == x.totalemp) {
                                                                        return <td className=' text-center txt_tp greyh'>Completed</td>
                                                                    }

                                                                })()}
                                                                {(() => {
                                                                    if (x.paidemp == 0) {
                                                                        return <td className=' text-center txt_tp greyh'>Pending </td>
                                                                    } else {
                                                                        return <td className=' text-center txt_tp greyh'>{x.paidemp}  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                        </svg></td>
                                                                    }
                                                                })()}

                                                                <td className='txt_tp pointer_'><svg onClick={() => { payrlist(x.payroll_id, x.startdate, x.enddate, x.month) }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4EB4E6" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                </svg></td>
                                                                <td className={x.noofemp == null || x.noofemp == 0 ? 'clickable txt_tp pointer_ inputcolor' : 'disable-click txt_tp pointer_  '} onClick={() => { deletePayroll(x.payroll_id, x.noofemp) }}>
                                                                    Delete
                                                                </td>


                                                            </tr>
                                                        );
                                                    })}

                                            </tbody>
                                        </table>
                                    </table>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <p class=" fs-6 ms-sm-4 fw-bold">Month</p>
                                    <div className='row'>
                                        <div className='col-sm-6'>

                                            <select class="form-select inputcolor popupdrop ms-sm-4" aria-label=" select Day" onChange={(event) => { setSearchTerm(event.target.value); }}>
                                                <option value="">ALL</option>

                                                <option value='January' >January</option>
                                                <option value='February' >February</option>
                                                <option value='March' >March</option>
                                                <option value='April' >April</option>
                                                <option value='May' >May</option>
                                                <option value='June' >June</option>
                                                <option value='July' >July</option>
                                                <option value='August' >August</option>
                                                <option value='September' >September</option>
                                                <option value='October' >October</option>
                                                <option value='November' >November</option>
                                                <option value='December' >December</option>

                                            </select>


                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div class="s">


                        </div>
                    </div>
                </div>
            </div>
            <Lheader />
        </div>
    )
}
export default Newpayroll